import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  configurationList: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getConfigurationList({ commit }) {
    const r = await api.get('send_message/layers');
    commit('SET_CONFIGURATION_LIST', r.data.data);
  },
  async addConfiguration({ dispatch }, payload) {
    await api.post('send_message/layers', payload);
    dispatch('getConfigurationList');
  },
  async editConfiguration({ dispatch }, body) {
    const { id, ...payload } = body;
    await api.put(`send_message/layers/${id}`, payload);
    dispatch('getConfigurationList');
  },
  async deleteConfiguration({ dispatch }, id) {
    await api.delete(`send_message/layers/${id}`);
    dispatch('getConfigurationList');
  },
  async sendMessage(store, { protocol, body, bulk }) {
    await api.post(`send_message/${protocol}${bulk ? '/bulk' : ''}`, body);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
