/**
 * @typedef EventDef
 * @property {string} action Wartość, która pojawi się jako akcja zdarzenia w raportach zdarzeń Google Analytics.
 * @property {string} [category='general'] Kategoria wydarzenia.
 * @property {string} [label] Etykieta wydarzenia.
 * @property {Object.<string, string|number|boolean>} [params] Dane zdarzenia, wyświetlane w etykiecie, jeżeli
 * etykieta nie ustawiona, dodane z przydrostkiem `param_`.
 * @property {Object.<string, string|number|boolean>} [extra_params] Dodatkowe dane zdarzenia, dla filtrowania, klucze
 * dodane jak leci.
 * @property {number} [value] Nieujemna liczba całkowita, która pojawi się jako wartość zdarzenia.
 */

//https://serversideup.net/building-a-queue-with-vue-3-and-vuex-4/
const state = {
  /**
   * Lista wywyłanych zdarzeń
   * @type {EventDef[]} pending
   */
  pending: [],
  /**
   * Aktywne zdarzenie
   * @type {?EventDef} event
   */
  active: undefined,
};
const mutations = {
  addPendingEvent: (state, /** @type {EventDef} */ event) => {
    state.pending.push(event);
  },
  setSendingEvent(state, /** @type {?EventDef} */ event) {
    state.active = event;
  },
  popCurrentEvent(state) {
    state.pending.shift();
  },
};
const getters = {
  PENDING: state => {
    return state.pending;
  },
  ACTIVE(state) {
    return state.active;
  },
};
const actions = {
  createEvent({ commit, state, dispatch }, /** @type {EventDef} */ event) {
    commit('addPendingEvent', event);
    //console.log('GoogleAnalytics:createEvent', JSON.parse(JSON.stringify(event)));

    if (!state.active) dispatch('sendingNextEvent');
  },
  sendingNextEvent({ commit, state }) {
    if (state.pending.length > 0) {
      commit('setSendingEvent', state.pending[0]);
      commit('popCurrentEvent');
    } else {
      commit('setSendingEvent', undefined);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
