<template>
  <v-menu
    :attach="attach"
    :value="value"
    v-on="$listeners"
    :absolute="isAbsolute"
    offset-y
    transition="scroll-y-transition"
    :close-on-content-click="closeOnContentClick"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, ...$props, attrs }">
        <v-tooltip bottom color="font">
          <template #activator="{ on: onTooltip }">
            <v-btn small icon v-bind="$attrs" v-on="{ ...on, ...onTooltip }" style="width: auto">
              <v-icon :color="$_colors.iconIdle" style="width: auto" :style="{ fontSize: $attrs['font-size'] }">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          {{ $i18n.t('button.options') }}
        </v-tooltip>
      </slot>
    </template>
    <v-list style="min-width: 135px">
      <slot name="top-item"> </slot>
      <template v-for="item in items">
        <slot :name="`${item.name}.title`">
          <v-tooltip bottom color="font" :key="getItemKey(item)" :disabled="!item.tooltip">
            <template #activator="{ on }">
              <span v-on="on">
                <v-list-item
                  style="min-height: 30px"
                  :key="item.id || item.name"
                  v-on="getListeners(item)"
                  @click="$emit('selectedItem', item.name, item)"
                  :disabled="item.disabled"
                  v-if="exactItems"
                >
                  <v-list-item-title
                    class="text-item text-left text-subtitle-2 font-weight-regular"
                    v-text="item.name"
                  />
                </v-list-item>
                <v-list-item
                  style="min-height: 30px"
                  :key="item.name"
                  @click="$emit(item.name)"
                  v-else
                  :disabled="item.disabled"
                >
                  <v-list-item-title
                    class="text-item text-left text-subtitle-2 font-weight-regular"
                    v-text="item.customName || $i18n.t(`menu.${item.name}`, item.params)"
                  />
                </v-list-item>
              </span>
            </template>
            {{ item.tooltip }}
          </v-tooltip>
        </slot>
        <slot :name="item.name" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DotsMenu',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
    },
    exactItems: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getListeners(item) {
      return Object.keys(this.$listeners).reduce((total, key) => {
        return {
          ...total,
          [key]: () => {
            this.$emit(key, item);
          },
        };
      }, {});
    },
    getItemKey(item) {
      return `${this.$_getHash(item.name + (item.params ? JSON.stringify(item.params) : ''))}_tooltip`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
