<template>
  <base-rounded-button
    class="pa-4"
    :color="hasUnconfirmedAlarms ? 'error' : 'rgba(235,235,235,0.5)'"
    :icon-color="hasUnconfirmedAlarms ? 'white' : $_colors.iconIdle"
    size="x-small"
    icon="mdi-alarm-light"
    has-border
    @click="open"
    :disabled="!alarmsConfigured"
    :tooltip="alarmsConfigured ? '' : $i18n.t('snackbar.modulesNotConfigurate.SCADA_ALARMS')"
  />
</template>
<script>
import { call, get } from 'vuex-pathify';
export default {
  name: 'AlarmsButton',
  computed: {
    alarms: get('alarms/alarms'),
    alarmsConfigured: get('admin/additionalModules@SCADA_ALARMS.configured'),
    intervalSetting: get('admin/settingsValues@scada_alarms_refresh_interval'),
    hasUnconfirmedAlarms() {
      return this.alarms?.unconfirmed_alarms?.total_count || false;
    },
  },
  data: () => ({
    interval: undefined,
  }),
  methods: {
    getAlarms: call('alarms/getAlarms'),
    open() {
      this.$router.push({ name: 'alarms' });
    },
    async init() {
      const action = async () => {
        await this.getAlarms();
        const features = this.alarms.unconfirmed_alarms.data.map((alarm, idx) => {
          const properties = { ...alarm };
          delete properties.geometry;
          const feature = {
            id: idx,
            geometry: alarm.geometry,
            properties,
            type: 'Feature',
          };
          return feature;
        });
        this.$root.$emit('toggleScadaAlarms', features);
      };
      action();
      this.interval = setInterval(
        () => {
          action();
        },
        this.intervalSetting * 60 * 1000
      );
    },
  },
  created() {
    if (this.alarmsConfigured) {
      this.init();
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.$root.$emit('toggleScadaAlarms');
  },
};
</script>
