import Vue from 'vue';
import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  metadata: {},
  relationValuesMappingCache: {},
  relationValuesMapping: {},
  treeViewMetadata: {},
  treeviews: [],
};

const mutations = {
  ...make.mutations(state),
  SET_CACHED_ATTRIBUTES_SCHEMA: (state, payload) => {
    const { object, key, value } = payload;
    Vue.set(state[object], key, value);
  },
  DELETE_CACHED_RELATIONS_BY_DATASOURCE: (state, value) => {
    for (const relationKey of Object.keys(state.relationValuesMappingCache)) {
      const datasource = relationKey.split('@')[1];
      if (datasource === value) {
        Vue.delete(state.relationValuesMappingCache, relationKey);
      }
    }
  },
  SET_TREE_VIEW_METADATA: (state, value) => {
    const { layerId, data } = value;
    Vue.set(state.treeViewMetadata, layerId, data);
  },
  SET_DATASOURCE_RELATION_VALUES_MAPPING: (state, data) => {
    state.relationValuesMapping = Object.freeze({
      ...state.relationValuesMapping,
      ...data,
    });
  },
};

const actions = {
  async getAllRelationValuesMapping({ commit }) {
    const r = await api.get('/dataio/data_sources/relation_values_mapping/all');
    commit('SET_RELATION_VALUES_MAPPING', Object.freeze(r.data.data));
  },
  async updateDatasourceRelationValuesMapping({ commit }, datasource) {
    if (!Object.keys(state.relationValuesMapping).includes(datasource)) return;
    const r = await api.post(`/dataio/data_sources/relation_values_mapping/all/${datasource}`, {});
    commit('SET_DATASOURCE_RELATION_VALUES_MAPPING', Object.freeze(r.data.data));
  },
  async getDatasourceRelationValuesMapping(store, { datasource, payload }) {
    const r = await api.post(`/dataio/data_sources/relation_values_mapping/all/${datasource}`, payload);
    return r.data.data;
  },
  async getRelationValuesMappingCache(state, payload) {
    const { mainAttribute, mainDatasource } = payload;
    const r = await api.get(`/dataio/data_sources/${mainDatasource}/${mainAttribute}/relation_values_mapping`);
    return r.data.data.map(({ display_value, value }) => ({
      value,
      text: display_value,
    }));
  },
  async getTreeFeature(state, data) {
    const { layerId, fid } = data;
    const r = await api.get(`/tree_view/${layerId}/read/${fid}`, {
      returnErrorData: true,
      skipDefaultErrorHandler: true,
    });
    return r.data.data;
  },
  async getTreeMetadata({ commit }, data) {
    const { layerId } = data;
    const r = await api.get(`/tree_view/${layerId}/metadata`);
    commit('SET_TREE_VIEW_METADATA', { layerId, data: r.data.data });
  },
  async getTreeviews({ commit }) {
    const r = await api.get('tree_view');
    commit('SET_TREEVIEWS', r.data.data);
  },
  async addTreeview({ dispatch }, payload) {
    await api.post('tree_view', payload);
    await dispatch('getTreeviews');
  },
  async deleteTreeview({ dispatch }, id) {
    await api.delete(`tree_view/${id}`);
    await dispatch('getTreeviews');
  },
  async editTreeview({ dispatch }, { id, payload } = {}) {
    await api.put(`tree_view/${id}`, payload);
    await dispatch('getTreeviews');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
