export default {
  methods: {
    refreshLogoCache() {
      this.url = this.url + `?cache=${Date.now()}`;
    },
  },
  mounted() {
    this.refreshLogoCache();
    this.$root.$on('refreshLogo', this.refreshLogoCache);
  },
};
