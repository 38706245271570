import { get } from 'vuex-pathify';
export default {
  data: () => ({
    sewerLayersIdsMixinProxy: null,
  }),
  computed: {
    analysisResult: get('congestions/analysisResult'),
    metadata: get('layers/metadata'),
    sewerDataSources: get('admin/modulesMapping@sewer'),
    sewerDataSourcesIdAttributesNames() {
      const sewerDataSourcesIdAttributesNames = {};
      for (const key of Object.keys(this.sewerDataSources)) {
        const dataSourceName = this.sewerDataSources[key];
        const idAttributeName = this.metadata[dataSourceName].attributes_schema.id_name;
        const filterIdAttribute = this.metadata[dataSourceName].attributes_use_datasource_qualified_names
          ? idAttributeName
          : `${dataSourceName}.${idAttributeName}`;
        sewerDataSourcesIdAttributesNames[dataSourceName] = filterIdAttribute;
      }
      return sewerDataSourcesIdAttributesNames;
    },
    sewerLayersFilteredFeaturesIds() {
      const sewerDataSourcesFilteresFeaturesIds = {};
      sewerDataSourcesFilteresFeaturesIds['terminals_in_range'] = this.analysisResult.terminals;
      sewerDataSourcesFilteresFeaturesIds['manholes_endangered'] = Object.keys(this.analysisResult.manholes)
        .filter(key => this.analysisResult.manholes[key] && this.analysisResult.manholes[key] >= 0)
        .map(key => parseInt(key));
      sewerDataSourcesFilteresFeaturesIds['manholes_secure'] = Object.keys(this.analysisResult.manholes)
        .filter(key => this.analysisResult.manholes[key] < 0)
        .map(key => parseInt(key));
      sewerDataSourcesFilteresFeaturesIds['manholes_nodata'] = Object.keys(this.analysisResult.manholes)
        .filter(key => this.analysisResult.manholes[key] === null)
        .map(key => parseInt(key));
      return sewerDataSourcesFilteresFeaturesIds;
    },
    congestionProject() {
      return {
        layers: [
          {
            data_source_name: this.sewerDataSources.sewer_manholes_data_source,
            filter_expression: {
              $IN: {
                [this.sewerDataSourcesIdAttributesNames[this.sewerDataSources.sewer_manholes_data_source]]: this
                  .sewerLayersFilteredFeaturesIds['manholes_endangered'],
              },
            },
            geometry_type: 'multipoint',
            id: 'manholes_endangered',
            mvtId: this.sewerLayersIdsMixinProxy?.manhole_layer,
            labels_visible: true,
            name: this.$i18n.t('sidebar.congestionAnalysis.manholesEndangered'),
            opcaity: 1,
            style: {
              'circle-color': '#bf360c',
              'circle-radius': 5,
              'fill-opacity': 1,
              labels: {
                attributes: [],
                'font-color': '#000000',
                'font-size': 12,
                'font-weight': 'bold',
                'offset-x': 0,
                'offset-y': -10,
                'stroke-color': '#FFFFFF',
                'stroke-visible': true,
                'stroke-width': 3,
              },
            },
            type: 'features_layer',
            visible: true,
          },
          {
            data_source_name: this.sewerDataSources.sewer_manholes_data_source,
            filter_expression: {
              $IN: {
                [this.sewerDataSourcesIdAttributesNames[this.sewerDataSources.sewer_manholes_data_source]]: this
                  .sewerLayersFilteredFeaturesIds['manholes_secure'],
              },
            },
            geometry_type: 'multipoint',
            id: 'manholes_secure',
            mvtId: this.sewerLayersIdsMixinProxy?.manhole_layer,
            labels_visible: true,
            name: this.$i18n.t('sidebar.congestionAnalysis.manholesSecure'),
            opcaity: 1,
            style: {
              'circle-color': '#188038',
              'circle-radius': 5,
              'fill-opacity': 1,
              labels: {
                attributes: [],
                'font-color': '#000000',
                'font-size': 12,
                'font-weight': 'bold',
                'offset-x': 0,
                'offset-y': -10,
                'stroke-color': '#FFFFFF',
                'stroke-visible': true,
                'stroke-width': 3,
              },
            },
            type: 'features_layer',
            visible: true,
          },
          {
            data_source_name: this.sewerDataSources.sewer_manholes_data_source,
            filter_expression: {
              $IN: {
                [this.sewerDataSourcesIdAttributesNames[this.sewerDataSources.sewer_manholes_data_source]]: this
                  .sewerLayersFilteredFeaturesIds['manholes_nodata'],
              },
            },
            geometry_type: 'multipoint',
            id: 'manholes_nodata',
            mvtId: this.sewerLayersIdsMixinProxy?.manhole_layer,
            labels_visible: true,
            name: this.$i18n.t('sidebar.congestionAnalysis.manholesNoData'),
            opcaity: 1,
            style: {
              'circle-color': '#6c6c6c',
              'circle-radius': 5,
              'fill-opacity': 1,
              labels: {
                attributes: [],
                'font-color': '#000000',
                'font-size': 12,
                'font-weight': 'bold',
                'offset-x': 0,
                'offset-y': -10,
                'stroke-color': '#FFFFFF',
                'stroke-visible': true,
                'stroke-width': 3,
              },
            },
            type: 'features_layer',
            visible: true,
          },
          {
            data_source_name: this.sewerDataSources.sewer_terminals_data_source,
            filter_expression: {
              $IN: {
                [this.sewerDataSourcesIdAttributesNames[this.sewerDataSources.sewer_terminals_data_source]]: this
                  .sewerLayersFilteredFeaturesIds['terminals_in_range'],
              },
            },
            geometry_type: 'multilinestring',
            id: 'terminals_in_range',
            mvtId: this.sewerLayersIdsMixinProxy?.terminal_layer,
            labels_visible: false,
            name: this.$i18n.t('sidebar.congestionAnalysis.terminalsInRange'),
            opcaity: 1,
            style: {
              'fill-opacity': 1,
              labels: {},
              'line-color': '#bf360c',
              'line-dash': [],
              'line-width': 3,
            },
            type: 'features_layer',
            visible: false,
          },
        ],
      };
    },
  },
};
