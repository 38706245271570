import api from '@/services/api';
import { make } from 'vuex-pathify';
const state = {
  isFeatureWatched: false,
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async addWatching({ commit }, payload) {
    await api.post(`/watch_object`, payload);
    commit('SET_IS_FEATURE_WATCHED', true);
  },
  async stopWatching({ commit }, payload) {
    await api.post(`/stop_watching_object`, payload);
    commit('SET_IS_FEATURE_WATCHED', false);
  },
  async checkWatching({ commit }, payload) {
    const { datasource, object_id } = payload;
    const r = await api.get(`/watcher/current_user/${datasource}/${object_id}`);
    commit('SET_IS_FEATURE_WATCHED', r.data.data);
    return r.data.data;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
