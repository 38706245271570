import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  notifications: [],
};

const mutations = {
  ...make.mutations(state),
  UPDATE_NOTIFICATION(state, data) {
    const { id, values } = data;
    const foundItemIndex = state.notifications.findIndex(item => item.id === id);
    if (foundItemIndex < 0) return;
    state.notifications[foundItemIndex] = {
      ...state.notifications[foundItemIndex],
      ...values,
    };
  },
};

const actions = {
  async getNotifications({ commit }) {
    const r = await api.get('notifications');
    commit('SET_NOTIFICATIONS', r.data.data);
  },
  async addNotification({ dispatch }, payload) {
    await api.post('notifications', payload);
    await dispatch('getNotifications');
  },
  async deleteNotification({ dispatch }, id) {
    await api.delete(`notifications/${id}`);
    await dispatch('getNotifications');
  },
  async updateNotification({ dispatch }, data) {
    const { id, payload } = data;
    await api.put(`notifications/${id}`, payload);
    await dispatch('getNotifications');
  },
  async changeNotificationStatus({ commit }, data) {
    const { isActive, id } = data;
    const r = await api.put(`notifications/${id}/${isActive ? 'enable' : 'disable'}`);
    const { updated, active } = r.data.data;
    commit('UPDATE_NOTIFICATION', { id: updated, values: { active } });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
