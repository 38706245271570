import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  hydrantsAvailableLayers: [],
  hydrantsLayerId: null,
  hydrantsInspections: {},
  hydrantInspectionsAll: {},
};

const mutations = {
  ...make.mutations(state),
  SET_HYDRANTS_INSPECTIONS: (state, data) => {
    const { hydrantId, features } = data;
    const inspections = [];
    for (const feature of features) {
      inspections.push({
        ...feature.properties,
        ...{ id: feature.id },
      });
    }
    Vue.set(state.hydrantsInspections, hydrantId, inspections);
  },
  SET_HYDRANTS_INSPECTIONS_ALL: (state, data) => {
    const { features } = data;
    const inspections = {};
    for (const feature of features) {
      inspections[feature.id] = {
        ...feature.properties,
        ...{ id: feature.id },
      };
    }
    state.hydrantInspectionsAll = inspections;
  },
};

const actions = {
  async addHydrantInspection({ dispatch }, feature) {
    const r = await api.post(`/hydrants/inspections`, { feature });
    await dispatch('getHydrantsInspectionsAll');
    return r;
  },
  async deleteHydrantInspection(store, hydrant_inspection_id) {
    await api.delete(`/hydrants/inspections/${hydrant_inspection_id}`);
  },
  async editHydrantInspection({ dispatch }, payload) {
    const { feature, inspection_id } = payload;
    await api.put(`/hydrants/inspections/${inspection_id}`, { feature });
    await dispatch('getHydrantsInspectionsAll');
  },
  async editHydrantsLayerId({ dispatch }, payload) {
    await api.put('/hydrants/selected_layer', payload);
    await dispatch('getHydrantsLayerId');
  },
  async getHydrantsAvailableLayers({ commit }) {
    const r = await api.get('/hydrants/available_layers');
    commit('SET_HYDRANTS_AVAILABLE_LAYERS', r.data.data);
  },
  async getHydrantsLayerId({ commit }) {
    const r = await api.get(`/hydrants/selected_layer`);
    commit('SET_HYDRANTS_LAYER_ID', r.data.layer_id);
  },
  async getHydrantsInspections({ commit }, payload) {
    const { hydrantId, features_filter, sorting } = payload;
    const r = await api.post(`/hydrants/inspections/read`, { features_filter, sorting });
    commit('SET_HYDRANTS_INSPECTIONS', {
      hydrantId,
      features: r.data.data.features.features,
    });
  },
  async getHydrantsInspectionsAll({ commit }) {
    const r = await api.post(`/hydrants/inspections/read`, {});
    commit('SET_HYDRANTS_INSPECTIONS_ALL', {
      features: r.data.data.features.features,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
