import { make } from 'vuex-pathify';

const state = {
  isVisible: true,
  isFetched: false,
  isProjectLayersFetched: false,
  isStickyButtonsLoading: false,
  isStickyButtonsVisible: false,
  isStickyButtonsValid: false,
  stickyButtons: [],
  isStickyButtonsModalLoading: false,
  isStickyButtonsModalVisible: false,
  isStickyButtonsModalValid: false,
  stickyButtonsModal: [],
  currentTab: 0,
  hasUnsavedChanges: false,
  unsavedChangesNext: undefined,
};

const mutations = make.mutations(state);

export default {
  namespaced: true,
  state,
  mutations,
};
