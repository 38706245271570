import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { Point } from 'ol/geom';

export default {
  methods: {
    addFresnelZoneLineLayer() {
      const newLayer = new VectorLayer({
        id: 'fresnelZoneLineLayer',
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: feature => {
          return [
            new Style({
              stroke: new Stroke({
                color: 'rgba(255, 0, 0, 1)',
                width: 1,
                lineDash: [10, 10],
              }),
            }),
            ...feature
              .getGeometry()
              .getCoordinates()
              .map((coordinate, index) => {
                const point = new Point(coordinate);
                return new Style({
                  geometry: point,
                  image: new Circle({
                    radius: 3,
                    stroke: new Stroke({
                      color: 'rgba(255, 0, 0, 1)',
                    }),
                    fill: new Fill({
                      color: 'rgba(255, 0, 0, 1)',
                    }),
                  }),
                  text: new Text({
                    text: `${String.fromCharCode(65 + index)}`,
                    font: '12px inter',
                    fill: new Fill({
                      color: this.$_colors.font,
                    }),
                    stroke: new Stroke({
                      color: 'white',
                      width: 1,
                    }),
                    placement: 'point',
                    offsetX: 0,
                    offsetY: -10,
                  }),
                });
              }),
          ];
        },
      });
      this.map.addLayer(newLayer);
    },
    toggleFresnelZoneLine(geometry) {
      let layer = this.getLayerById('fresnelZoneLineLayer');
      if (!geometry && layer) {
        this.clearLayer('fresnelZoneLineLayer');
        return;
      }
      if (!layer) {
        this.addFresnelZoneLineLayer();
        layer = this.getLayerById('fresnelZoneLineLayer');
      } else {
        this.clearLayer('fresnelZoneLineLayer');
      }
      const olGeometry = new GeoJSON().readGeometry(geometry, {
        dataProjection: this.$_config.defaultEpsg,
        featureProjection: this.$_config.defaultEpsg,
      });
      const feature = new Feature({ geometry: olGeometry });
      layer.getSource().addFeature(feature);
    },
  },
  mounted() {
    this.$root.$off('toggleFresnelZoneLine');
    this.$root.$on('toggleFresnelZoneLine', this.toggleFresnelZoneLine);
  },
};
