import socketsDefaultEvents from '@/mixins/sockets/socketsDefaultEvents';
export default {
  mixins: [socketsDefaultEvents],
  computed: {
    socketsDefault() {
      return {
        name: 'default',
        wsUrl: import.meta.env.VUE_APP_WS_URL
          ? `${import.meta.env.VUE_APP_WS_URL}/api/async/ws?session_id=${this.sessionId}&token=${this.token}`
          : '',
        wsDev: import.meta.env.VUE_APP_WS_DEV === 'true',
        reconnectionSettings: {
          basic_time: 1000,
          time_multiplier: 2,
          max_time: 64000,
        },
      };
    },
  },
  methods: {
    socketCloseDefault({ name } = {}) {
      this.socketsData[name].socketState.manuallyClosed = true;
      this.socketsData[name].socketState.socketConnection?.close();
    },
    socketConnectDefault({ name, reconnecting = false } = {}) {
      if (
        (this.socketsData[name].socketState.currentReconnectionTime && !reconnecting) ||
        !this.socketsData[name].wsUrl
      ) {
        if (this.socketsData[name].wsDev && !this.socketsData[name].wsUrl)
          console.log('[Websocket] Env variable VUE_APP_WS_URL is not set');
        return;
      }
      this.socketsData[name].socketState.socketConnection = new WebSocket(this.socketsData[name].wsUrl);
      this.socketsData[name].socketState.socketConnection.onopen = () => {
        if (this.socketsData[name].wsDev) console.log('[Websocket] Connection opened');
        this.socketsData[name].socketState.dateOpened = this.$dayjs();
        this.socketsData[name].socketState.manuallyClosed = false;
        this.socketsData[name].socketState.currentReconnectionTime = null;
      };
      this.socketsData[name].socketState.socketConnection.onmessage = e => {
        const data = JSON.parse(e.data);
        if (this.socketsData[name].wsDev) console.log('[Websocket] Message:', data);
        if (data.event && Object.keys(this.socketDefaultEventsDict).includes(data.event)) {
          this[this.socketDefaultEventsDict[data.event]](data.data || {});
        } else if (data.event && this.socketsData[name].wsDev) {
          console.log('[Websocket] Event unhandled');
        }
        if (this.socketsData[name].additionalOnMessageAction) {
          this.socketsData[name].additionalOnMessageAction(e.data);
        }
      };
      this.socketsData[name].socketState.socketConnection.onerror = () => {
        if (this.socketsData[name].wsDev) console.log('[Websocket] Connection error');
        this.socketsData[name].socketState.manuallyClosed = false;
        const newReconnectionTime = this.socketsData[name].socketState.currentReconnectionTime
          ? this.socketsData[name].socketState.currentReconnectionTime *
            this.socketsData[name].reconnectionSettings.time_multiplier
          : this.socketsData[name].reconnectionSettings.basic_time;
        if (
          this.socketsData[name].reconnectionSettings.max_time &&
          (this.socketsData[name].reconnectionSettings.max_time < newReconnectionTime || !newReconnectionTime)
        ) {
          if (this.socketsData[name].wsDev) console.log('[Websocket] Reconnection timeout');
          this.$store.set('snackbar/PUSH_ERROR!', {
            message: this.$i18n.t('errors.websocketReconnectionTimeout'),
          });
          this.socketsData[name].socketState.currentReconnectionTime = null;
          this.socketsData[name].socketState.manuallyClosed = true;
        } else {
          this.socketsData[name].socketState.currentReconnectionTime = newReconnectionTime;
          if (this.socketsData[name].wsDev)
            console.log(
              `[Websocket] Connection attempt will take place in ${
                this.socketsData[name].socketState.currentReconnectionTime / 1000
              } second${this.socketsData[name].socketState.currentReconnectionTime > 1000 ? 's' : ''}.`
            );
          setTimeout(() => {
            this.socketConnect({ name, reconnecting: true });
          }, newReconnectionTime);
        }
      };
      this.socketsData[name].socketState.socketConnection.onclose = () => {
        if (this.socketsData[name].wsDev) {
          const duration = this.socketsData[name].socketState.dateOpened
            ? this.$dayjs().diff(this.socketsData[name].socketState.dateOpened, 's')
            : 0;
          this.socketsData[name].socketState.dateOpened = undefined;
          if (duration) console.log(`[Websocket] Closed after ${duration}s of connection`);
        }
        if (
          !this.socketsData[name].socketState.manuallyClosed &&
          !this.socketsData[name].socketState.currentReconnectionTime
        ) {
          if (this.socketsData[name].wsDev) console.log('[Websocket] Closed unexpectedly, trying to reconnect...');
          this.socketConnect();
        }
      };
    },
    socketSendDefault({ name, data } = {}) {
      if (this.socketsData[name].socketState.socketConnectionState === 1) {
        this.socketsData[name].socketState.socketConnection.send(JSON.stringify(data));
      } else if (this.socketsData[name].socketState.socketConnectionState === 0) {
        setTimeout(() => {
          this.socketSend({ name, data });
        }, 1000);
      }
    },
  },
};
