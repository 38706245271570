<template>
  <v-row no-gutters class="justify-start">
    <v-col cols="auto">
      <v-row no-gutters>
        <v-col cols="auto">
          <v-badge overlap :content="badgeValue" :value="hasBadge" color="error" :offset-x="28">
            <base-button
              v-bind="{ ...$props, ...$attrs, ...buttonStyle }"
              toggleable
              v-on="$listeners"
              @click="toggle"
            />
          </v-badge>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="subheaderTranslationPath">
        <v-col>
          <template v-if="Array.isArray(subheaderTranslationPath)">
            <v-row no-gutters v-for="(subheader, idx) of subheaderTranslationPath" :key="idx" style="height: 14px">
              <v-col cols="auto" style="height: inherit">
                <span class="font-italic font-weight-light button-toggleable-subheader">{{ $i18n.t(subheader) }}</span>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-col cols="auto">
              <italic-subheader :translation-path="subheaderTranslationPath"> </italic-subheader>
            </v-col>
          </template>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';
import { asArray, asString } from 'ol/color';

export default {
  name: 'BaseButtonToggleable',
  components: {
    ItalicSubheader,
  },
  props: {
    colorActive: {
      type: String,
    },
    colorInActive: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    subheaderTranslationPath: {
      type: [String, Array],
    },
    hasBadge: {
      type: Boolean,
      default: false,
    },
    badgeValue: {
      type: [String, Number],
    },
  },
  computed: {
    buttonStyle() {
      return {
        buttonStyle: {
          backgroundColor: this.computedIsActive
            ? this.colorActive
              ? this.colorActive
              : `${this.$_colors.primarybackground} !important`
            : this.colorInActive
            ? this.colorInActive
            : 'rgba(0, 0, 0, 0.04) !important',
          border: this.hideBorder
            ? 0
            : this.computedIsActive
            ? '1px solid rgba(26, 115, 232, 0.3) !important'
            : '1px solid rgba(0, 0, 0, 0.2) !important',
          color: this.computedIsActive ? this.$_colors.primary : '#61646d',
        },
        elevation: this.$attrs.elevation ?? 2,
        color: this.computedIsActive
          ? this.colorActive
            ? this.colorActive
            : this.$_colors.primarybackground
          : this.colorInActive
          ? this.colorInActive
          : 'rgba(225,225,225,1)',
      };
    },
    computedIsActive: {
      get() {
        return this.isActive;
      },
      set(nV) {
        this.$emit('update:isActive', nV);
      },
    },
  },
  methods: {
    hexToRgba(hex, opacity) {
      const [r, g, b] = Array.from(asArray(hex));
      return asString([r, g, b, opacity]);
    },
    toggle() {
      this.computedIsActive = !this.computedIsActive;
      this.$emit('change', !this.computedIsActive);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-toggleable-subheader {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.59);
}
</style>
