import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import { LineString } from 'ol/geom';
import turfDestination from '@turf/destination';
import { transform } from 'ol/proj';
import { circular } from 'ol/geom/Polygon.js';
export default {
  data: () => ({
    linesData: {
      alfa: {
        name: 'alfa',
        strokeColor: 'rgba(245, 14, 14, 1)',
      },
      beta: {
        name: 'beta',
        strokeColor: 'rgba(0, 147, 59, 1)',
      },
      gamma: {
        name: 'gamma',
        strokeColor: 'rgba(0, 43, 193, 1)',
      },
    },
  }),
  methods: {
    addAntennaSignalDeterminationLayer() {
      const newLayer = new VectorLayer({
        id: 'antennaSignalDeterminationCircleLayer',
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: feature => {
          return this.getAntennaSignalDeterminationStyle(feature);
        },
      });
      this.map.addLayer(newLayer);
    },
    getAntennaSignalDeterminationStyle(feature) {
      const [type, name] = [feature.get('type'), feature.get('name')];
      if (type === 'circle') {
        return new Style({
          fill: new Fill({
            color: 'rgba(17, 20, 240, 0.15)',
          }),
          stroke: new Stroke({
            color: 'rgba(17, 20, 240, 1)',
            width: 2,
          }),
          zIndex: 10,
        });
      } else if (type === 'line') {
        return new Style({
          stroke: new Stroke({
            color: this.linesData[name].strokeColor,
            width: 2,
          }),
          zIndex: 9,
        });
      }
    },
    toggleAntennaSignalDeterminationCircle(value) {
      let layer = this.getLayerById('antennaSignalDeterminationCircleLayer');
      if (!value && layer) {
        this.clearLayer('antennaSignalDeterminationCircleLayer');
        return;
      }
      if (!layer) {
        this.addAntennaSignalDeterminationLayer();
        layer = this.getLayerById('antennaSignalDeterminationCircleLayer');
      } else {
        this.clearLayer('antennaSignalDeterminationCircleLayer');
      }

      const centerGeometry = new GeoJSON().readGeometry(value.geometry, {
        dataProjection: this.$_config.defaultEpsg,
        featureProjection: this.$_config.defaultEpsg,
      });
      const centerDefaultCoords = centerGeometry.getCoordinates();
      const centerGpsCoords = transform(centerDefaultCoords, this.$_config.defaultEpsg, 'EPSG:4326');

      //Lines
      const centerGpsGeojson = {
        type: 'Feature',
        geometry: {
          coordinates: centerGpsCoords,
          type: 'Point',
        },
      };
      this.$_objectToArray(this.linesData).forEach(lineData => {
        const { name } = lineData;
        const destinationCoord = transform(
          turfDestination(centerGpsGeojson, value.radius / 1000, value[name])?.geometry.coordinates,
          'EPSG:4326',
          'EPSG:3857'
        );
        const lineStringGeom = new LineString([centerDefaultCoords, destinationCoord]);
        const lineStringFeature = new Feature({ geometry: lineStringGeom, type: 'line', name });
        layer.getSource().addFeature(lineStringFeature);
      });

      //Circle
      const circleGeometry = circular(centerGpsCoords, value.radius, 128);
      circleGeometry.transform('EPSG:4326', this.$_config.defaultEpsg);
      const circleFeature = new Feature({ geometry: circleGeometry, type: 'circle' });
      layer.getSource().addFeature(circleFeature);
    },
  },
  mounted() {
    this.$root.$off('toggleAntennaSignalDeterminationCircle');
    this.$root.$on('toggleAntennaSignalDeterminationCircle', this.toggleAntennaSignalDeterminationCircle);
  },
};
