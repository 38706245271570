import axios from 'axios';
import router from '@/router';
import store from '@/store';

import { getErrorObject } from '@/assets/js/utility';
import { defaultSRID } from '@/assets/js/variables';

const defaultRequestInterceptor = config => {
  const { data, noWrap } = config;
  if (!noWrap) {
    if (data) {
      config.data = { data };
    }
  }
  const { token, sessionId } = store.state.authentication;
  if (token) {
    config.headers['X-Access-Token'] = token;
  }
  if (sessionId) {
    config.headers['X-WebSocket-ID'] = sessionId;
  }
  config.headers['X-Response-SRID'] = defaultSRID;
  return config;
};

const defaultResponseConfigInterceptor = config => {
  return config;
};

const defaultResponseErrorInterceptor = error => {
  const errorObject = getErrorObject(error);
  if (errorObject.isShowSnackbar) {
    store.set('snackbar/PUSH_ERROR!', errorObject);
  }
  if (errorObject.isLogout) {
    router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
  }
  if (!errorObject.skipDefaultErrorHandler) {
    store.dispatch('gtag/createEvent', {
      action: 'exception',
      category: 'api_request',
      label: errorObject.message,
      params: {
        type: errorObject.type,
      },
    });
  }
  console.log(`%cAPI call error -> ${errorObject.message}`, 'color: red');
  throw errorObject.returnErrorData ? errorObject : new Error(errorObject.message);
};

const getAxiosInstance = (useV2 = false) => {
  if (useV2 && import.meta.env.VUE_APP_USE_V2 !== 'true') return;
  const instance = axios.create({
    baseURL: useV2 ? import.meta.env.VUE_APP_API_V2_URL : import.meta.env.VUE_APP_API_URL,
    timeout: 0,
    headers: {},
  });
  instance.interceptors.request.use(defaultRequestInterceptor);
  instance.interceptors.response.use(defaultResponseConfigInterceptor, defaultResponseErrorInterceptor);
  return instance;
};

const mainApiInstance = getAxiosInstance();
const v2ApiInstance = getAxiosInstance(true);

export { v2ApiInstance as apiV2 };
export default mainApiInstance;
