<template>
  <v-row no-gutters v-if="isStickyButtonsVisible">
    <v-col cols="auto" v-for="(button, idx) of stickyButtons" :key="idx">
      <button-main-dialog
        v-bind="button"
        :class="{ 'ma-0': true }"
        :loading="isStickyButtonsLoading && !button.cancel"
        :disabled="(isStickyButtonsLoading && button.cancel) || (!isStickyButtonsValid && !button.cancel)"
        @click="action(button.action, button.cancel)"
      />
    </v-col>
  </v-row>
</template>
<script>
import { get, sync } from 'vuex-pathify';
import ButtonMainDialog from '@/components/ButtonMainDialog';

export default {
  name: 'MainDialogStickyButtons',
  components: {
    ButtonMainDialog,
  },
  computed: {
    stickyButtons: sync('sidebar/stickyButtonsModal'),
    isStickyButtonsLoading: get('sidebar/isStickyButtonsModalLoading'),
    isStickyButtonsValid: get('sidebar/isStickyButtonsModalValid'),
    isStickyButtonsVisible: sync('sidebar/isStickyButtonsModalVisible'),
  },
  methods: {
    action(action, cancel) {
      if (action) {
        this.$root.$emit(action);
      }
      if (cancel) {
        this.$emit('onClose');
      }
    },
  },
};
</script>
