import api from '@/services/api';
import { make } from 'vuex-pathify';
const state = {
  balanceProfiles: [],
  iwaProfiles: [],
  dmaProfiles: [],

  defaultBalanceProfile: null,
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async generateWaterBalanceDma(store, payload) {
    const r = await api.post(`/water_balance/dma/generate`, payload);
    return r.data.data;
  },
  async balanceFlowMeters(store, data) {
    const { layerId, payload } = data;
    await api.put(`/water_balance/balance/flow_meters/${layerId}`, payload);
  },
  async generateWaterBalanceStatistics(store, data) {
    const { name, payload } = data;
    const r = await api.post(`/water_balance/dma/statistics/${name}`, payload);
    return r.data.data;
  },

  async saveBalanceProfile({ dispatch }, payload) {
    const r = await api.post(`/water_balance/balance/profile`, payload);
    await dispatch('getBalanceProfiles');
    return r.data.data;
  },
  async getBalanceProfiles({ commit }) {
    const r = await api.get(`/water_balance/balance/profile`);
    commit('SET_DEFAULT_BALANCE_PROFILE', r.data.data.find(profile => profile.default)?.id || null);
    commit('SET_BALANCE_PROFILES', r.data.data);
  },
  async deleteBalanceProfile({ dispatch }, profileId) {
    await api.delete(`/water_balance/balance/profile/${profileId}`);
    await dispatch('getBalanceProfiles');
  },
  async setDefaultBalanceProfile(store, profileId) {
    await api.put(`/water_balance/balance/profile/${profileId}/default`);
  },
  async editBalanceProfile({ dispatch }, data) {
    const { profileId, payload } = data;
    await api.put(`/water_balance/balance/profile/${profileId}`, payload);
    await dispatch('getBalanceProfiles');
  },
  async getIwaProfiles({ commit }) {
    const r = await api.get(`/water_balance/iwa/profile`);
    commit('SET_IWA_PROFILES', r.data.data);
  },
  async saveIwaProfile({ dispatch }, payload) {
    const r = await api.post(`/water_balance/iwa/profile`, payload);
    await dispatch('getIwaProfiles');
    return r.data.data;
  },
  async deleteIwaProfile({ dispatch }, profileId) {
    await api.delete(`/water_balance/iwa/profile/${profileId}`);
    await dispatch('getIwaProfiles');
  },
  async editIwaProfile({ dispatch }, data) {
    const { profileId, payload } = data;
    await api.put(`/water_balance/iwa/profile/${profileId}`, payload);
    await dispatch('getIwaProfiles');
  },
  async getDmaProfiles({ commit }) {
    const r = await api.get(`/water_balance/dma/profile`);
    commit('SET_DMA_PROFILES', r.data.data);
  },
  async saveDmaProfile({ dispatch }, payload) {
    const r = await api.post(`/water_balance/dma/profile`, payload);
    await dispatch('getDmaProfiles');
    return r.data.data;
  },
  async deleteDmaProfile({ dispatch }, profileId) {
    await api.delete(`/water_balance/dma/profile/${profileId}`);
    await dispatch('getDmaProfiles');
  },
  async editDmaProfile({ dispatch }, data) {
    const { profileId, payload } = data;
    await api.put(`/water_balance/dma/profile/${profileId}`, payload);
    await dispatch('getDmaProfiles');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
