import { GeoJSON } from 'ol/format';
import { transform } from 'ol/proj';
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature.js';

import lineArc from '@turf/line-arc';

export default {
  methods: {
    toggleCircularSector(value, data, centerPointCoordinates) {
      if (!value) {
        if (this.getLayerById('circularSector')) this.map.removeLayer(this.getLayerById('circularSector'));
        return;
      }
      const { azimuth, radius, angle } = data;
      this.clearCustomLayer('circularSector');
      if (!this.getLayerById('circularSector')) {
        this.addCustomLayer(null, 'circularSector', {
          style: this.defaultSelectionStyle(),
        });
      }
      let circularArc = lineArc(
        transform(centerPointCoordinates, 'EPSG:3857', 'EPSG:4326'),
        radius / 1000,
        this.$_getBearingFromAzimuth(azimuth - angle / 2),
        this.$_getBearingFromAzimuth(azimuth + angle / 2) + 0.00001
      );
      const circularArcCoordinates = new GeoJSON()
        .readFeature(circularArc, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        })
        .getGeometry()
        .getCoordinates();
      circularArcCoordinates.push(centerPointCoordinates);
      circularArcCoordinates.unshift(centerPointCoordinates);
      this.customLayerAddFeatures([new Feature({ geometry: new Polygon([circularArcCoordinates]) })], 'circularSector');
    },
    selectFeaturesInCircularSector() {
      try {
        const circularSectorGeometry = this.getLayerById('circularSector')
          .getSource()
          .getFeatures()[0]
          .getGeometry();
        this.selectFeaturesByLocation(circularSectorGeometry);
      } catch {
        //
      }
    },
  },
  mounted() {
    this.$root.$off('toggleCircularSector');
    this.$root.$off('selectFeaturesInCircularSector');
    this.$root.$on('toggleCircularSector', this.toggleCircularSector);
    this.$root.$on('selectFeaturesInCircularSector', this.selectFeaturesInCircularSector);
  },
};
