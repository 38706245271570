<template>
  <v-menu
    offset-y
    :value="isReverseGeocoderToolActive"
    :close-on-content-click="false"
    :close-on-click="closeOnClick"
    :attach="true"
    v-model="isOpened"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="{ background: $attrs.isActive }"
        icon
        small
        v-bind="{ ...$attrs, attrs, ...$props }"
        :retain-focus-on-click="false"
        v-on="{ ...on, ...$listeners }"
        @click="toggleGeocoder"
        :color="$_colors.iconIdle"
      >
        <v-icon size="21px !important" :class="{ activeicon: $attrs.isActive }">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card :loading="!isFetched">
      <v-list>
        <v-list-item dense>
          <v-row no-gutters style="flex-grow: initial">
            <v-col style="flex-grow: 0">
              <v-icon color="primary">mdi-information</v-icon>
            </v-col>
            <v-col class="pl-2 text-left" style="line-height: normal">
              <span class="body-2 text-caption" style="color: DimGrey">
                {{ $i18n.t('navbar.reverseGeocoderInfo') }}
              </span>
            </v-col>
          </v-row>
        </v-list-item>
        <template v-if="!Object.values(informations).find(info => info.value === undefined)">
          <v-list-item v-for="[key, obj] in Object.entries(informations)" :key="key" dense>
            <v-row no-gutters style="flex-grow: initial; align-items: center">
              <v-col style="flex-grow: 0">
                <v-icon>{{ obj.icon }}</v-icon>
              </v-col>
              <v-col class="px-2">
                <span class="body-2" :class="{ 'no-data-value': !obj.value }">
                  {{ obj.value ? obj.value : $i18n.t('navbar.reverseGeocoderNoData') }}
                </span>
              </v-col>
              <v-col v-if="obj.value" style="flex-grow: 0">
                <v-tooltip v-model="obj.tooltipVisible" bottom color="primary">
                  <template v-slot:activator="{ attrs }">
                    <v-btn color="primary" v-bind="attrs" @click="copyToClipboard(key, obj.value)" icon small>
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $i18n.t('tooltips.valueCopied') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { get, call } from 'vuex-pathify';

export default {
  name: 'TheNavbarToolsPanelReverseGeocoder',
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    closeOnClick: true,
    isOpened: false,
    informations: {
      address: {
        value: undefined,
        icon: 'mdi-home',
        tooltipVisible: false,
      },
      parcel: {
        value: undefined,
        icon: 'mdi-layers',
        tooltipVisible: false,
      },
    },
    isFetched: true,
  }),
  computed: {
    dataSources: get('layers/metadata'),
    isReverseGeocoderToolActive: get('tools/toolStatus@isReverseGeocoderToolActive'),
    reverseGeocoderCoordinates: get('map/reverseGeocoderCoordinates'),
    settingsParcelsProvider: get('admin/settingsValues@parcels_provider'),
    settingsAddressesProvider: get('admin/settingsValues@addresses_provider'),
    parcelSettings: get('admin/modulesMapping@parcels'),
    addressesSettings: get('admin/modulesMapping@addresses'),
    selectedAddressesDataSource: get('addresses/selectedDataSource'),
  },
  methods: {
    searchAddressGugik: call('search/searchAddressGugik'),
    searchUldkParcelByXY: call('search/searchUldkParcelByXY'),
    getDataSourceFeatures: call('layers/getDataSourceFeatures'),
    getNearestNeighboursDataSourceFeatures: call('layers/getNearestNeighboursDataSourceFeatures'),
    clearData() {
      Object.values(this.informations).forEach(info => (info.value = undefined));
    },
    copyToClipboard(key, data) {
      navigator.clipboard.writeText(data);
      this.informations[key].tooltipVisible = true;
      setTimeout(() => {
        this.informations[key].tooltipVisible = false;
      }, 1500);
    },
    toggleGeocoder() {
      if (this.isReverseGeocoderToolActive && !this.isOpened) {
        this.isOpened = true;
        return;
      }
      this.$root.$emit('reverseGeocoder-action', this.isReverseGeocoderToolActive ? false : true);
    },
    async setAddress(coords) {
      if (this.settingsAddressesProvider === 'gugik_api') {
        const r = await this.searchAddressGugik({ coords, srid: this.$_config.defaultEpsg.split(':')[1] });
        return r.results
          ? `${r.results['1'].street ? `${r.results['1'].street} ` : ``}${
              r.results['1'].number ? r.results['1'].number : ''
            }${r.results['1'].city ? `, ${r.results['1'].city}` : ''}`
          : null;
      } else {
        const pointGeometry = {
          type: 'Point',
          crs: {
            type: 'name',
            properties: {
              name: this.$_config.defaultEpsg || 'EPSG:4326',
            },
          },
          coordinates: coords,
        };
        const r = await this.getNearestNeighboursDataSourceFeatures({
          dataSource: this.addressesSettings.addresses_datasource,
          nearest_neighbours_buffer: 100,
          limit: 1,
          with_geometry: false,
          geometry: pointGeometry,
        });
        return r.features.length > 0
          ? `${
              r.features[0].properties[this.addressesSettings.attribute_street]
                ? `${r.features[0].properties[this.addressesSettings.attribute_street]} `
                : ``
            }${
              r.features[0].properties[this.addressesSettings.attribute_number]
                ? r.features[0].properties[this.addressesSettings.attribute_number]
                : ''
            }${
              r.features[0].properties[this.addressesSettings.attribute_place]
                ? `, ${r.features[0].properties[this.addressesSettings.attribute_place]}`
                : ''
            }`
          : null;
      }
    },
    async setParcel(coords) {
      let projectionEpsg = this.$_config.defaultEpsg;
      if (this.settingsParcelsProvider === 'uldk') {
        const r = await this.searchUldkParcelByXY({
          coords: {
            x: coords[0],
            y: coords[1],
            epsg: this.$_config.defaultEpsg.split(':')[1],
            params: ['geom_wkt', 'numer', 'obreb'],
          },
        });
        if (r !== null && r.error) {
          this.$store.set('snackbar/PUSH_MESSAGE!', {
            message: this.$i18n.t('snackbar.notFoundPlotWithError', { error: r.error }),
          });
          this.$root.$emit('clearReverseGeocoderLayer', 'reverseGeocoderParcel');
          return null;
        }
        let wkt = r.geom_wkt;
        if (wkt.indexOf(';') >= 0) {
          projectionEpsg = `EPSG:${wkt.split(';')[0].split('=')[1]}`;
          wkt = wkt.split(';')[1];
        }
        this.$root.$emit('addParcelLayer', wkt, 'wkt', projectionEpsg);
        return `${r.numer} ${r.obreb ? `(${r.obreb})` : ''}`;
      } else {
        const point = {
          type: 'Point',
          crs: {
            type: 'name',
            properties: {
              name: this.$_config.defaultEpsg || 'EPSG:4326',
            },
          },
          coordinates: coords,
        };
        const data = await this.getDataSourceFeatures({
          dataSource: this.parcelSettings.parcels_datasource,
          filters: {
            '%SPATIALLY_INTERSECTS': {
              [`${this.parcelSettings.parcels_datasource}.${
                this.dataSources[this.parcelSettings.parcels_datasource]?.attributes_schema.geometry_name ?? 'geom'
              }`]: [point],
            },
          },
        });
        if (data.features.length === 0) {
          this.$root.$emit('clearReverseGeocoderLayer', 'reverseGeocoderParcel');
          return null;
        }
        this.$root.$emit('addParcelLayer', data.features[0].geometry, 'geojson', projectionEpsg);
        return `${String(data.features[0].properties[this.parcelSettings.attribute_number])} ${
          data.features[0].properties[this.parcelSettings.attribute_region]
            ? `(${data.features[0].properties[this.parcelSettings.attribute_region]})`
            : ''
        }`;
      }
    },
    async getData(coords) {
      this.isOpened = true;
      this.closeOnClick = this.isFetched = false;
      const [address, parcel] = await Promise.all([this.setAddress(coords), this.setParcel(coords)]);
      this.informations.address.value = address;
      this.informations.parcel.value = parcel;
      this.isFetched = this.closeOnClick = true;
    },
  },
  watch: {
    reverseGeocoderCoordinates(nV) {
      if (nV.length > 0) {
        this.getData(nV);
      } else {
        this.clearData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  @include activeToolButtonStyle();
}
.no-data-value {
  opacity: 0.7;
  font-style: italic;
}
::v-deep .activeicon * {
  color: map-get($colors, 'link') !important;
}
</style>
