import api from '@/services/api';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';
const state = {
  inspectors: {},
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async getInspectors({ commit }) {
    const r = await api.get('zdm/inspectors/inspectors_to_districts');
    commit('SET_INSPECTORS', arrayToObject(r.data.data, 'district_id', 'users'));
  },
  async editInspectors({ dispatch }, payload) {
    await api.put('zdm/inspectors/inspectors_to_districts', { inspectors_to_districts: payload });
    await dispatch('getInspectors');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
