import Vue from 'vue';
import { make } from 'vuex-pathify';

import api from '@/services/api';

const state = {
  tasks: {},
  tasksCategories: [],
};

const mutations = {
  ...make.mutations(state),
  DELETE_TASK: (state, task_id) => {
    Vue.delete(state.tasks, task_id);
  },
  SET_TASKS: (state, data) => {
    const { tasksProps, crs } = data;
    const tasks = {};
    for (const task of tasksProps) {
      tasks[task.id] = {
        ...task.properties,
        ...{ id: task.id },
        ...{ geometry: { ...task.geometry, ...{ crs } } },
      };
    }
    state.tasks = tasks;
  },
};

const actions = {
  async addTask({ dispatch }, feature) {
    const r = await api.post(`/tasks`, { feature });
    await dispatch('getTasks');
    return r;
  },
  async addTasksCategory({ dispatch }, data) {
    await api.post('tasks/categories', data);
    await dispatch('getTasksCategories');
  },
  async deleteTask({ commit }, task_id) {
    await api.delete(`/tasks/${task_id}`);
    commit('DELETE_TASK', task_id);
  },
  async deleteTasksCategory({ dispatch }, payload) {
    const { id } = payload;
    await api.delete(`tasks/categories/${id}`);
    await dispatch('getTasksCategories');
  },
  async editTask({ dispatch }, payload) {
    const { feature, task_id } = payload;
    await api.put(`/tasks/${task_id}`, { feature });
    await dispatch('getTasks');
  },
  async editTasksCategory({ dispatch }, payload) {
    const { id, name } = payload;
    await api.put(`tasks/categories/${id}`, { name });
    await dispatch('getTasksCategories');
  },
  async getTask(store, id) {
    const r = await api.get(`/tasks/${id}`);
    return r.data.data;
  },
  async getTasks({ commit }) {
    const r = await api.post('tasks/read', {});
    commit('SET_TASKS', { tasksProps: r.data.data.features.features, crs: r.data.data.features.crs });
  },
  async getTasksCategories({ commit }) {
    const r = await api.get('tasks/categories');
    commit('SET_TASKS_CATEGORIES', r.data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
