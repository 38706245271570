<template>
  <div @mouseenter="onHover" @mouseleave="onLeave">
    <slot>
      <component
        ref="data-input"
        :is="formatedDataType"
        v-bind="{ ...$props, ...$_filterObjectKeys($attrs, { notAllowedKeys: 'id' }), ...staticProps }"
        :items="computedItems"
        :descriptors="dataType"
        v-on="getListeners($listeners)"
        :isEditingOn="isEditingOn"
        @update:error="$emit('update:error', $event)"
        :values.sync="computedValues"
        v-model="computedValue"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template v-for="(value, key) in $scopedSlots" #[key]="scope">
          <slot :name="key" v-bind="scope" />
        </template>
      </component>
    </slot>
    <v-tooltip
      v-if="tooltip"
      :activator="$refs['data-input']"
      :value="tooltipVisible"
      v-bind="tooltipBind"
      :nudge-top="hideDetails ? 0 : 25"
      color="font"
    >
      {{ tooltip }}
    </v-tooltip>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { camelCase, cloneDeep } from 'lodash';
import { capitalize } from '@/assets/js/utility';

const components = {};
const requireComponent = import.meta.glob('@/components/DataInput*.vue', { eager: true });
Object.entries(requireComponent).forEach(([fileName, content]) => {
  const componentName = capitalize(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );
  components[componentName] = content.default;
});

export default {
  name: 'DataInput',
  components,
  inheritAttrs: false,
  props: {
    dataType: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipBind: {
      type: Object,
      default: () => {
        return { bottom: true };
      },
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    minValue: {
      type: Number,
      default: null,
    },
    maxValue: {
      type: Number,
      default: null,
    },
    autoConvertSelect: {
      type: Boolean,
      default: true,
    },
    disabledSorting: {
      type: Boolean,
      default: false,
    },
    isEditingOn: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    tooltipVisible: false,
    staticProps: {
      outlined: true,
      dense: true,
    },
  }),
  computed: {
    isKeyBindingActive: sync('tools/isKeyBindingActive'),
    computedItems() {
      if (!this.$attrs.items) {
        return;
      }
      if (this.disabledSorting) {
        return this.$attrs.items;
      }
      const sortKey = this.$attrs['item-text'] || this.$attrs['itemText'] || 'text';
      return this.$_sortObjectsByAttribute(cloneDeep(this.$attrs.items), sortKey);
    },
    formatedDataType() {
      if (!this.dataType) {
        throw new Error('Prop dataType is required');
      }
      if (this.autoConvertSelect && this.dataType.name === 'select' && this.computedItems?.length > 8) {
        return `DataInputAutocomplete`;
      }
      const capitalizedName = capitalize(this.dataType.name);
      return `DataInput${capitalizedName}`;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    computedValues: {
      get() {
        return this.values;
      },
      set(nV) {
        this.$emit('update:values', nV);
      },
    },
  },
  methods: {
    getListeners(listeners) {
      return this.$_filterObjectKeys(listeners, { notAllowedKeys: 'input' });
    },
    onHover() {
      if (this.tooltip) this.tooltipVisible = true;
    },
    onLeave() {
      if (this.tooltip) this.tooltipVisible = false;
    },
    onFocus() {
      this.toggleKeyBindingActive(true);
    },
    onBlur() {
      this.toggleKeyBindingActive(false);
    },
    toggleKeyBindingActive(value) {
      this.isKeyBindingActive = value;
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
::v-deep {
  .v-input__slot {
    margin-bottom: 3px;
    padding: 0 12px !important;
    input {
      padding: 4px 0 2px !important;
    }
    .v-input__icon--clear {
      height: 20px;
      .v-icon {
        font-size: 19px !important;
      }
    }
  }
  .v-text-field:not(.v-select) {
    margin-top: 0;
  }
  .v-input__icon {
    width: 20px !important;
    min-width: 20px !important;
  }
  .v-input__append-inner {
    padding-left: 0 !important;
    .v-input__icon--clear {
      height: 24px !important;
    }
  }
}
</style>
