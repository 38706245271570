<template>
  <v-tooltip bottom color="font">
    <template v-slot:activator="{ on: onTooltip }">
      <v-btn
        icon
        :retain-focus-on-click="false"
        v-on="{ ...onTooltip }"
        :class="{ background: isGeolocationToolActive }"
        x-small
      >
        <v-icon :size="16" :color="isGeolocationToolActive ? 'link' : $_colors.iconIdle" @click="toggleGeolocation">
          mdi-crosshairs-gps
        </v-icon>
      </v-btn>
    </template>
    {{ toolTooltip }}
  </v-tooltip>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'TheNavbarToolsPanelGeolocation',
  computed: {
    isGeolocationToolActive: get('tools/toolStatus@isGeolocationToolActive'),
    toolTooltip() {
      return this.isGeolocationToolActive
        ? this.$i18n.t('tooltips.hideLocation')
        : this.$i18n.t('tooltips.showLocation');
    },
  },
  methods: {
    toggleGeolocation() {
      this.$root.$emit('geolocation-action', this.isGeolocationToolActive ? false : true);
    },
  },
  beforeDestroy() {
    if (this.isGeolocationToolActive) this.$root.$emit('geolocation-action', false);
  },
};
</script>

<style lang="scss" scoped>
.background {
  @include activeToolButtonStyle();
}
</style>
