<template>
  <v-row no-gutters class="justify-start">
    <v-col cols="auto">
      <v-row no-gutters>
        <v-col cols="auto">
          <slot>
            <base-button v-bind="{ ...$props, ...$attrs }" v-on="$listeners"></base-button>
          </slot>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="subheaderTranslationPath" class="pt-1">
        <v-col cols="auto" class="text-left">
          <italic-subheader :translation-path="subheaderTranslationPath"> </italic-subheader>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'BaseButtonSubheader',
  components: {
    ItalicSubheader,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: '',
    },
    translationPath: {
      type: String,
      default: '',
    },
    isPlural: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    /**
     * text that should not be translated, e.g layer card text.
     */
    straightText: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    /**
     * Custom width and height in pixels.
     * Overrides size prop.
     */
    customSize: {
      type: Number,
      default: null,
    },
    subheaderTranslationPath: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
