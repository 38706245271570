import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  lanternPatterns: [],
  zdmActions: [],
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async addLantern(store, body) {
    return await api.post('zdm_data/street_lamps', body);
  },
  async copyLantern(store, { id, body }) {
    return await api.post(`zdm_data/street_lamps/${id}/copy`, body);
  },
  async getDatasource({ commit }) {
    const r = await api.get('zdm/inspectors/districts_selected_datasource');
    commit('SET_DATA', r.data.data);
  },
  async getPatterns({ commit }) {
    const r = await api.get('zdm_data/street_lamps/patterns');
    commit('SET_LANTERN_PATTERNS', r.data.data);
  },
  async addPattern({ dispatch }, payload) {
    await api.post('zdm_data/street_lamps/patterns', payload);
    await dispatch('getPatterns');
  },
  async editPattern({ dispatch }, payload) {
    const { pattern_id, body } = payload;
    await api.put(`zdm_data/street_lamps/patterns/${pattern_id}`, body);
    await dispatch('getPatterns');
  },
  async getPattern(store, pattern_id) {
    return await api.get(`zdm_data/street_lamps/patterns/${pattern_id}`);
  },
  async deletePattern({ dispatch }, pattern_id) {
    await api.delete(`zdm_data/street_lamps/patterns/${pattern_id}`);
    await dispatch('getPatterns');
  },
  async getActions({ commit }) {
    const r = await api.get('zdm_data/actions');
    commit('SET_ZDM_ACTIONS', r.data.data);
  },
  async addAction({ dispatch }, payload) {
    await api.post('zdm_data/actions', payload);
    await dispatch('getActions');
  },
  async editAction({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`zdm_data/actions/${id}`, body);
    await dispatch('getActions');
  },
  async deleteAction({ dispatch }, id) {
    await api.delete(`zdm_data/actions/${id}`);
    await dispatch('getActions');
  },
  async getRelations(store, data) {
    const { dataSource, featureId } = data;
    const r = await api.get(`zdm_data/relations/${dataSource}/${featureId}`);
    return r.data.data;
  },
  async zdmPowerSource(store, { payload = { with_circuits: true }, params = { background: true } } = {}) {
    return (await api.post('zdm_power_source', payload, { params }))?.data.data;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
