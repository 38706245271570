<template>
  <v-card style="overflow-y: unset">
    <v-card-text class="px-0 py-0">
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn color="primary" text @click="$emit('cancel')" class="text-capitalize">
            {{ $i18n.t('button.cancel') }}
          </v-btn>
          <v-btn color="primary" text @click="$emit('save')" class="text-capitalize" :disabled="isSaveDisabled">
            {{ $i18n.t('button.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'InputCard',
  props: {
    isSaveDisabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
