<template>
  <main-dialog
    v-bind="$props"
    :attach="attach"
    :content-class="contentClass"
    :is-visible.sync="computedIsVisible"
    :is-valid="isValid"
    :underline-title="true"
    :underline-footer="true"
    :title="title"
    :max-height="maxHeight"
    large
    :modal-width="computedWidth"
    :header-shadow="headerShadow"
    body-padding="0"
    :is-buttons-right="isButtonsRight"
    v-on="$listeners"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #body>
      <v-row
        no-gutters
        class="mainlargedialog-body-wrapper"
        :class="{ 'px-5': !noPaddingsX, 'py-6': !noPaddingsY }"
        :style="`height: ${bodyHeight ? bodyHeight : ''}; min-height : ${minBodyHeight ? minBodyHeight : ''}`"
      >
        <slot name="body"> </slot>
      </v-row>
    </template>
    <template #buttons>
      <slot name="buttons"> </slot>
    </template>
  </main-dialog>
</template>
<script>
/**
 * @typedef ButtonDef
 * @property {String} key wartość dla @key
 * @property {String} label etykieta
 * @property {Boolean} cancel bprzycisk anulowania
 * @property {('primary'|'success'|'error')} color=primary kolor
 */

import MainDialog from '@/components/MainDialog';

export default {
  name: 'MainLargeDialog',
  components: {
    MainDialog,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    attach: {
      type: [String, Boolean],
      default: false,
    },
    minBodyHeight: {
      type: String,
      required: false,
    },
    isButtonsRight: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    agreeText: {
      type: String,
      required: false,
    },
    disagreeText: {
      type: String,
      required: false,
    },
    hasAgree: {
      type: Boolean,
      default: true,
    },
    /**
     * @type {Array.<ButtonDef>}
     */
    buttons: {
      type: Array,
      default: () => [],
    },
    dialogWidth: {
      type: String,
      default: '40%',
    },
    bodyHeight: {
      type: String,
      required: false,
    },
    maxBodyHeight: {
      type: String,
      required: false,
    },
    headerShadow: {
      type: Boolean,
      default: false,
    },
    noPaddingsX: {
      type: Boolean,
      default: false,
    },
    noPaddingsY: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      required: false,
    },
    isInputDialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isAgreeVisible: {
      type: Boolean,
      default: true,
    },
    useStickyButtons: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: 'main-large-dialog',
    },
  },
  computed: {
    computedIsVisible: {
      get() {
        return this.isVisible;
      },
      set(nV) {
        this.$emit('update:isVisible', nV);
      },
    },
    computedWidth() {
      if (this.dialogWidth === '40%') {
        return this.isInputDialog ? '28%' : '40%';
      }
      return this.dialogWidth;
    },
  },
};
</script>
<style lang="scss" scoped></style>
