<template>
  <v-textarea
    ref="input"
    v-bind="$attrs"
    :type="type"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    v-on="$listeners"
    @paste="onPaste"
    @keydown="checkValue"
    :counter="computedCounter"
    @input="onInput"
    :hint="hint"
    persistent-hint
    :no-resize="noResize"
    :background-color="backgroundColor"
    :class="textClass"
    :row-height="rowHeight"
  >
    <template v-for="(value, key) in $scopedSlots" #[key]>
      <slot :name="key" />
    </template>
    <template #message="{message}">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
  </v-textarea>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'DataInputTextarea',
  inheritAttrs: false,
  components: {
    ItalicSubheader,
  },
  props: {
    descriptors: {
      required: false,
      type: Object,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
    rowHeight: {
      type: String,
      default: '24',
    },
    label: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
    hint: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    computedCounter() {
      if (
        !(this.hasAttribute(this.$attrs.readonly) || this.hasAttribute(this.$attrs.disabled)) &&
        this.descriptors &&
        this.descriptors.max_length
      ) {
        return this.descriptors.max_length;
      }
      return false;
    },
  },
  methods: {
    /**
     * When we put an boolean attribute on component like this <v-text-field disabled>
     * this.$attrs.disabled is '' (empty string) and if statement returns false.
     */
    hasAttribute(attribute) {
      return attribute !== undefined && attribute !== false;
    },
    checkValue(e) {
      const { keyCode, ctrlKey, metaKey } = e;
      if ((metaKey || ctrlKey) && (keyCode === 86 || keyCode === 67 || keyCode === 65)) {
        return;
      }
      const allowedKeyCodes = [8, 9, 37, 39];
      if (allowedKeyCodes.includes(e.keyCode)) {
        return;
      } else if (e.target.selectionStart !== e.target.selectionEnd) {
        return;
      } else if (this.descriptors.max_length) {
        if ((this.value ? this.value.length : 0) >= this.descriptors.max_length) {
          e.preventDefault();
          return;
        }
      }
    },
    onInput(e) {
      this.$emit('input', e);
    },
    onPaste(e) {
      if (
        e.clipboardData.getData('text').length +
          (this.value ? this.value.length : 0) -
          (e.target.selectionEnd - e.target.selectionStart) >
        this.descriptors.max_length
      ) {
        console.log('Wklejana wartość jest zbyt długa');
        //TODO: Zmienić console.log na alert
        e.preventDefault();
        return;
      }
    },
  },
};
</script>
