export default {
  methods: {
    getDefaultValueHandler({ policy_type, value }) {
      if (policy_type === 'fixed') {
        return value;
      }
      const dict = {
        current_date: this.getCurrentDate,
        current_time: this.getCurrentTime,
        current_date_time: this.getCurrentDateTime,
      };
      return dict[value] ? dict[value]() : null;
    },
    getCurrentDate() {
      return this.$dayjs().format('YYYY-MM-DD');
    },
    getCurrentTime() {
      return this.$dayjs().format('HH:mm:ss');
    },
    getCurrentDateTime() {
      return this.$dayjs().format('YYYY-MM-DDTHH:mm:ss.000');
    },
  },
};
