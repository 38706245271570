/* eslint-disable */
/**
 * Variables are stored in:
 * - separate JS files - there might be some cases where they
 * will be required outside .vue files or before Vue instance has been
 * initialized (no access to `this`),
 * - dotenv files - enviromental variables, for example default language,
 * - SCSS files - some CSS variables must be passed to JS, for example
 * colors (color of snackbar must be passed from JS).
 * Each variable has `$_` prefix to indicate
 * that it is global variable that should not be mutated - (https://vuejs.org/v2
 * style-guide/#Private-property-names-essential)
 */
import colors from '@/assets/styles/_export.module.scss';
import * as variables from '@/assets/js/variables';

const attachVariables = (Vue, variables, wrappingObject, excludedVariables = []) => {
  const filteredKeys = Object.keys(variables).filter(key => !excludedVariables.includes(key));
  const object = filteredKeys.reduce((total, key) => {
    return Object.defineProperty(total, key, {
      value: Object.freeze(variables[key]),
    });
  }, {});
  const prefixedWrappingObject = `$_${wrappingObject}`;
  Object.assign(Vue.prototype, { [prefixedWrappingObject]: object });
};

export default {
  install(Vue) {
    attachVariables(Vue, variables, 'config');
    attachVariables(Vue, colors, 'colors');
  },
};
