import Vue from 'vue';
import { make } from 'vuex-pathify';

import api from '@/services/api';

import { arrayToObject, groupByProperty } from '@/assets/js/utility';

const state = {
  currentSessionId: null,
  session: undefined,
  sessions: {},
};
const mutations = {
  ...make.mutations(state),
  ADD_SESSION: (state, { id, value } = {}) => {
    Vue.set(state.sessions, id, value);
  },
  DELETE_SESSION: (state, id) => {
    Vue.delete(state.sessions, id);
  },
  SET_SESSIONS: (state, value) => {
    state.sessions = arrayToObject(value);
  },
  SET_SESSION_NAME: (state, { id, name, withCurrent = false } = {}) => {
    Vue.set(state.sessions[id], 'name', name);
    if (withCurrent) {
      Vue.set(state.session, 'name', name);
    }
  },
};
const actions = {
  async addSession({ dispatch }, payload) {
    const r = await api.post('zdm_data/sessions', payload);
    await dispatch('getSessions');
    return r.data.data.id;
  },
  async deleteSession({ dispatch }, id) {
    await api.delete(`zdm_data/sessions/${id}`);
    await dispatch('getSessions');
  },
  async editSession(state, { id, payload }) {
    await api.put(`zdm_data/sessions/${id}`, payload);
  },
  async getSession({ commit, state }, { id, dataSources = [], update = false }) {
    const promises = dataSources.map(dataSource => {
      return api.get(`zdm_data/session_objects/${id}/${dataSource}`);
    });
    const r = await Promise.all(promises);
    const sessionObjects = {};
    dataSources.forEach((dataSource, index) => {
      sessionObjects[dataSource] = groupByProperty(r[index].data.data?.features || [], 'metadata', 'operation');
    });
    const oldSession = JSON.parse(JSON.stringify(state.session || {}));
    const isSameSession = oldSession.id === id;
    const session = {
      ...state.sessions[id],
      session_objects:
        update && isSameSession ? { ...(oldSession.session_objects || {}), ...sessionObjects } : sessionObjects,
    };
    commit('SET_SESSION', session);
  },
  async getSessions({ commit }) {
    const r = await api.get('zdm_data/sessions');
    commit('SET_SESSIONS', r.data.data.sessions);
  },

  async addFeatureToSession({ dispatch, state }, payload) {
    await api.post('zdm_data/session_objects', payload);
    const { session_id: sessionId, datasource: dataSource } = payload;
    if (state.session.id === payload.session_id) {
      await dispatch('getSession', { id: sessionId, dataSources: [dataSource], update: true });
    }
  },
  async editFeatureFromSession({ dispatch, state }, { dataSource, featureId, sessionId, payload } = {}) {
    await api.put(`zdm_data/session_objects/${sessionId}/${dataSource}/${featureId}`, payload);
    if (state.session.id === sessionId) {
      await dispatch('getSession', { id: sessionId, dataSources: [dataSource], update: true });
    }
  },
  async addFeatureToDelete({ dispatch }, { sessionId, featureId, dataSource } = {}) {
    await api.delete(`zdm_data/session_objects/${sessionId}/${dataSource}/${featureId}`);
    await dispatch('getSession', { id: sessionId, dataSources: [dataSource], update: true });
  },
  async deleteObjectFromSession({ dispatch }, { sessionId, featureId, dataSourcesToRefresh } = {}) {
    await api.delete(`zdm_data/session_objects/${sessionId}/${featureId}`);
    if (dataSourcesToRefresh && dataSourcesToRefresh.length) {
      await dispatch('getSession', { id: sessionId, dataSources: dataSourcesToRefresh, update: true });
    }
  },
  async deleteObjectsFromSession({ dispatch }, { sessionId, operation, dataSource } = {}) {
    await api.delete(`zdm_data/session_objects/${sessionId}/${dataSource}/clear`, { params: { operation } });
    await dispatch('getSession', { id: sessionId, dataSources: [dataSource], update: true });
  },

  async mergeSession({ dispatch }, { sessionId, params } = {}) {
    const r = await api.post(`zdm_data/session/${sessionId}/merge`, {}, { params });
    await dispatch('getSessions');
    return r;
  },
  async validateSession(state, sessionId) {
    return await api.post(`zdm_data/session/${sessionId}/validate`);
  },
  async getCableRelations(state, payload) {
    const r = await api.post('zdm_data/relations/cable', payload);
    return r.data.data;
  },
  async getCableIntersections(state, payload) {
    const r = await api.post('zdm_data/sessions/intersections/cables', payload);
    return r.data.data?.map(e => {
      return { text: e.name, value: +e.id || +e.id === 0 ? +e.id : e.id, circuits: e.circuits };
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
