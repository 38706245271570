<template>
  <v-text-field
    v-bind="{ ...$attrs, ...$props }"
    type="text"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    v-on="computedListeners"
    persistent-hint
  >
    <template #message="{message}">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
  </v-text-field>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  components: {
    ItalicSubheader,
  },
  name: 'DataInputDecimal',
  props: {
    descriptors: {
      required: false,
    },
    label: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
  },
  computed: {
    computedListeners() {
      return {
        ...this.$listeners,
        blur: e => {
          this.checkNumber(e);
        },
        input: e => {
          this.setValue(e);
        },
        paste: e => {
          this.onPaste(e);
          this.$emit('paste', e);
        },
        keydown: e => {
          this.checkValue(e);
          this.$emit('keydown', e);
        },
      };
    },
  },
  methods: {
    checkNumber(e) {
      const inputValue = e.target.value;
      if (inputValue.slice(-1) === '-') {
        e.target.value = inputValue + '1';
        this.$emit('input', parseInt(inputValue + '1'));
      }
      if (inputValue.slice(-1) === '.') {
        e.target.value = inputValue.slice(0, -1);
        this.$emit('input', parseInt(inputValue.slice(0, -1)));
      }
    },
    checkValue(e) {
      const { keyCode, key, metaKey, ctrlKey } = e;
      const allowedKeyCodes = [8, 9, 37, 39];
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;
      const oldValue = e.target.value.toString();

      if (selectionStart === 0 && (selectionEnd > 0 || !oldValue.includes('-'))) {
        allowedKeyCodes.push(173);
      }
      if ((ctrlKey || metaKey) && (keyCode === 86 || keyCode === 67 || keyCode === 65)) {
        return;
      } else if (allowedKeyCodes.includes(keyCode)) {
        return;
      }

      const sumValue = oldValue.substring(0, selectionStart) + key + oldValue.substring(selectionEnd);
      this.validateValue(sumValue, e);
    },
    onPaste(e) {
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;
      const oldValue = e.target.value.toString();
      const pasteValue = e.clipboardData.getData('text');

      const sumValue = oldValue.substring(0, selectionStart) + pasteValue + oldValue.substring(selectionEnd);
      this.validateValue(sumValue, e);
    },
    setValue(v) {
      if ((Number.parseFloat(v) || v == '0') && !(v === '-' || v.slice(-1) === '.')) {
        this.$emit('input', v || v == '0' ? Number.parseFloat(v) : null);
      } else if (v === '') {
        this.$emit('input', null);
      }
    },
    validateValue(val, event) {
      if (val) {
        if (
          val
            .toString()
            .replace('.', '')
            .replace('-', '').length > 20
        ) {
          event.preventDefault();
          return;
        } else if (!/^-?\d+(?:[.]\d*?)?$/.test(val)) {
          event.preventDefault();
          return;
        } else if (
          this.descriptors.max_digits &&
          val.slice(-1) === '.' &&
          val.length > (val.includes('-') ? this.descriptors.max_digits + 1 : this.descriptors.max_digits)
        ) {
          event.preventDefault();
          return;
        } else {
          const precisionLength = val.split('.')[1] ? val.split('.')[1].length : 0;
          const overallLength = val.replace('.', '').replace('-', '').length;
          if (this.descriptors.decimal_places && precisionLength > this.descriptors.decimal_places) {
            event.preventDefault();
            return;
          }
          if (this.descriptors.max_digits && overallLength > this.descriptors.max_digits) {
            event.preventDefault();
            return;
          }
        }
      }
    },
  },
};
</script>
