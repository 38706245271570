<template>
  <v-menu
    class="mobile-badge-menu"
    :value="showMobileBadgeInfo"
    :close-on-content-click="false"
    :close-on-click="false"
    :open-on-click="false"
    min-width="325px"
    offset-y
    nudge-left="50"
    nudge-top="7"
    attach
  >
    <template v-slot:activator="{ on: menuOn }">
      <v-tooltip bottom color="font">
        <template #activator="{ on: tooltipOn }">
          <v-btn
            v-on="{ ...tooltipOn, ...menuOn, ...$listeners }"
            v-bind="{ ...$attrs, ...$props }"
            :class="{ divider: $attrs.hasDivider }"
            :color="$_colors.iconIdle"
            icon
            small
            @click="toggleMobile"
          >
            <v-badge :value="showMobileDialogInfo" color="primarylight" dot :offset-x="10" :offset-y="10" bordered>
              <v-icon size="19px">
                {{ icon }}
              </v-icon>
            </v-badge>
          </v-btn>
        </template>
        {{ $i18n.t('navbar.MOBILE') }}
      </v-tooltip>
    </template>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters class="mobile-badge-read-version-triangle-box">
          <v-col cols="1">
            <v-icon size="24px" color="primarylight"> mdi-home </v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters class="mobile-badge-read-version-text text-body-2 pa-3">
          <v-col cols="11" class="text-left">
            {{ $i18n.t('sidebar.mobileVersion.newVersionAvailable', { version: mobileAppUploadVersion }) }}
          </v-col>
          <v-col cols="1" class="text-right">
            <v-icon size="19px" color="white" @click="setLastMobileBadgeReadVersion(mobileAppUploadVersion)">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-menu>
</template>
<script>
import mobileAppVersion from '@/mixins/mobileAppVersion';
import { debounce } from 'lodash';
export default {
  name: 'TheNavbarToolsMobileButton',
  mixins: [mobileAppVersion],
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    toggleMobile() {
      this.$root.$emit('MOBILE-action');
      this.setLastMobileBadgeReadVersion();
      this.setLastMobileDialogReadVersion();
    },
    async init() {
      if (this.appKind) await this.getMobileMetadata(this.appKind);
    },
  },
  mounted() {
    this.init = debounce(this.init, 2000);
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.divider {
  position: relative;
  margin-right: 18px;
  &::after {
    content: '';
    position: absolute;
    @include border(right);
    height: 100%;
    right: -9px;
    top: 0;
  }
}
.mobile-badge-read-version-text {
  background-color: map-get($colors, 'primarylight');
  color: white;
  font-size: 11px !important;
  font-weight: 500 !important;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mobile-badge-read-version-triangle-box {
  height: 11px;
  padding-left: 52px;
}
.mobile-badge-menu {
  ::v-deep {
    .v-menu__content {
      box-shadow: unset !important;
    }
  }
}
</style>
