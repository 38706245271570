<template>
  <v-tooltip bottom color="font" :disabled="!isTooltipVisible">
    <template #activator="{ on }">
      <span v-on="on">
        <data-input
          v-isContentWrapped="{ method: setIsContentWrapped, elGetter: elementGetter }"
          v-on="$listeners"
          v-bind="{ ...$props, ...$attrs, ...(editable ? {} : { backgroundColor: '#f0f0f0' }) }"
          :disabled="!isEditingOn || !editable || $attrs.disabled"
          :placeholder="computedPlaceholder"
          :label="computedLabel"
          v-model="computedValue"
          :isEditingOn="isEditingOn"
          :clearable="$attrs.clearable === false ? false : nullable"
          :style="disableWidthLimit ? '' : 'max-width: 23vw'"
          :values.sync="computedValues"
        >
          <template v-for="(value, key) in $scopedSlots" #[key]="scope">
            <slot :name="key" v-bind="scope" />
          </template>
        </data-input>
      </span>
    </template>
    <i18n v-if="!isContentWrapped && !editable" path="sidebar.noEditingAvailable" tag="span">
      <template #name>
        <span class="font-italic">
          {{ label }}
        </span>
      </template>
    </i18n>
    <span v-else>
      {{ value }}
    </span>
  </v-tooltip>
</template>

<script>
import DataInput from '@/components/DataInput';

import isContentWrapped from '@/mixins/directives/isContentWrapped';

export default {
  name: 'FeatureCardDataRowInput',
  mixins: [isContentWrapped],
  components: {
    DataInput,
  },
  props: {
    isEditingOn: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    dataType: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Boolean, Date, Number, Array],
      default: null,
    },
    unit: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    nullable: {
      type: Boolean,
      default: true,
    },
    disableWidthLimit: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    computedLabel() {
      return this.label
        ? this.editable && this.required
          ? `${this.label}* ${this.unit ? `[${this.unit}]` : ''}`
          : `${this.label} ${this.unit ? `[${this.unit}]` : ''}`
        : '';
    },
    computedPlaceholder() {
      return this.$attrs.placeholder === undefined ? '' : this.$attrs.placeholder;
    },
    isTooltipVisible() {
      return this.isContentWrapped || (this.isEditingOn && !this.editable);
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    computedValues: {
      get() {
        return this.values;
      },
      set(nV) {
        this.$emit('update:values', nV);
      },
    },
  },
  methods: {
    elementGetter(el) {
      return el.getElementsByTagName('input')[0];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6);
  }
  .theme--light.v-input--is-disabled input,
  .theme--light.v-select .v-select__selection--disabled,
  .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87);
  }
  .theme--light.v-text-field.v-input--is-disabled .v-input__slot::before {
    border-image: unset !important;
  }
  .v.select__selections .v-input {
    padding-bottom: 30px !important;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded):not(.v-text-field--outlined)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0;
  }
}
</style>
