<template>
  <v-subheader style="height: auto;" class="font--text toolsInfo">
    <v-tooltip bottom color="font" :disabled="!tooltip">
      <template #activator="{ on }">
        <div v-on="on" class="d-flex align-center text-truncate folderInfo" style="max-width: 13vw">
          {{ text }}
          <slot name="content" />
        </div>
      </template>
      {{ tooltip }}
    </v-tooltip>
  </v-subheader>
</template>

<script>
export default {
  name: 'TheNavbarToolsPanelMapInfoItem',
  props: {
    text: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.toolsInfo {
  font-size: 10px;
}
</style>
