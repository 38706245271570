<template>
  <v-menu offset-y :close-on-content-click="false" :attach="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="{ background: $attrs.isActive }"
        icon
        small
        v-bind="{ ...$attrs, attrs, ...$props }"
        v-on="{ ...on, ...$listeners }"
        :disabled="$attrs.disabled"
        :color="$_colors.iconIdle"
      >
        <v-icon size="19px">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card :loading="areResultsLoading">
      <v-list>
        <v-list-item>
          <data-input
            :menu-props="{ contentClass: 'persist-menu-width menu-max-width-content' }"
            :data-type="{ name: 'autocomplete' }"
            style="min-width: 350px"
            class="pa-0 ma-0 text-body-2 my-2"
            :prepend-icon="icon"
            append-icon=""
            :label="label"
            :color="$_colors.iconIdle"
            :items="!query ? cachedResults || [] : results || []"
            item-text="description"
            :item-value="'geometry_wkt' || 'description'"
            v-model="selected"
            :search-input.sync="query"
            @update:search-input="search"
            :filter="computedFilterFunction"
            @change="zoomTo"
            clearable
            :hint="hint"
            return-object
            :hide-no-data="
              areResultsLoading ||
              !((currentSearchMode === 'address_points__gugik_api' || layer === 'google') && results)
            "
            :hide-details="!hint"
          >
            <template #no-data>
              <div class="pa-2">
                <italic-subheader
                  :straight-text="searchNodataTooltip"
                  style="font-size: 11px !important; text-align: left"
                />
              </div>
            </template>
            <template #item="{ parent, item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-node-renderer :vnodes="parent.genFilteredText(item.description)" />
                  </v-list-item-title>
                  <italic-subheader
                    v-if="item.jednostka"
                    :straight-text="formattedSubinfos(item.jednostka)"
                    style="font-size: 11px !important"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </data-input>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { debounce } from 'lodash';
import { call, get } from 'vuex-pathify';
import DataInput from '@/components/DataInput';
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'TheNavbarToolsPanelSearch',
  components: {
    DataInput,
    ItalicSubheader,
  },
  props: {
    layer: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    query: null,
    selected: undefined,
    areResultsLoading: false,
    results: null,
    cachedResults: [],
  }),
  computed: {
    addressesMapping: get('admin/modulesMapping@addresses'),
    parcelsMapping: get('admin/modulesMapping@parcels'),
    settings: get('admin/settingsValues'),
    currentSearchMode() {
      return `${this.layer}__${this.settings?.addresses_provider}`;
    },
    label() {
      return this.$i18n.t(`navbar.${this.name}`);
    },
    hint() {
      switch (this.currentSearchMode) {
        case 'address_points__gugik_api':
          return this.$i18n.t('admin.addressProviderGugikHint');
        default:
          return null;
      }
    },
    searchNodataTooltip() {
      switch (this.layer) {
        case 'google':
          return this.$i18n.t('navbar.addressGoogleNoData');
        default:
          return this.$i18n.t('navbar.addressNoData');
      }
    },
    suffixedLayer() {
      return `${this.layer}_uploaded`;
    },
    computedFilterFunction() {
      return this.layer === 'parcels' ? this.filterFunction : v => v;
    },
  },
  methods: {
    getDataSourceFeatures: call('layers/getDataSourceFeatures'),
    searchByLayer: call('search/searchByLayer'),
    searchParcelsByLayer: call('search/searchParcelsByLayer'),
    searchAddress: call('addresses/searchAddress'),
    searchGugik: call('addresses/searchGugik'),
    searchGoogle: call('search/searchGoogleAddresses'),
    getSearchFunction() {
      switch (this.layer) {
        case 'address_points': {
          const availableProvidersActions = {
            gugik_api: async query => {
              return query.length > 2 ? await this.searchGugik(query) : [];
            },
            system: async query => {
              const r = await this.searchAddress({ params: { search_text: query, whole_word: false } });
              return r.data.data;
            },
          };
          return availableProvidersActions[this.settings?.addresses_provider];
        }
        case 'parcels': {
          return async query => {
            const r = await this.searchParcelsByLayer({ params: { search_text: query, limit: 200 } });
            return r.data.data;
          };
        }
        case 'google': {
          return async query => {
            const r = await this.searchGoogle({ address: query });
            return r;
          };
        }
      }
    },
    async search(query) {
      if (this.layer === 'parcels' && this.results?.some(e => e.description === query)) {
        this.results = this.results.filter(e => e.description.includes(query));
        this.selected = this.results.find(e => e.description === query);
        this.zoomTo(this.selected);
        return;
      }
      if (!query) {
        this.results = null;
        return;
      }
      this.areResultsLoading = true;
      try {
        const r = await this.getSearchedElements(query);
        this.results = r;
        /**
         * Simple queue with a capacity of 50 elements to prevent the list from growing indefinitely
         */
        this.cachedResults = [...(r || []), ...(this.cachedResults || [])].slice(0, 50);
      } catch (error) {
        console.log('~ error', error);
      } finally {
        this.areResultsLoading = false;
      }
    },
    zoomTo(selected) {
      if (!selected) {
        this.$root.$emit('removeMarker', this.layer);
        return;
      }
      this.$root.$emit('createGeomAndFitView', selected.geom || selected.geometry, {
        isDrawMarker: true,
        layerId: this.layer,
      });
    },
    filterFunction(item, queryText, itemText) {
      return itemText.startsWith(queryText);
    },
    formattedSubinfos(text) {
      return text.replaceAll(/{|}/g, '').replaceAll(',', ', ');
    },
  },
  created() {
    this.getSearchedElements = this.getSearchFunction();
    // https://forum.vuejs.org/t/lodash-debounce-not-working-when-placed-inside-a-method/86334/4
    this.search = debounce(this.search, 400);
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__icon {
    i {
      font-size: 21px !important;
    }
    button {
      font-size: 21px !important;
    }
  }
  .v-select__slot {
    label {
      font-size: 14px;
    }
  }
  .v-list-item__title {
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0 !important;
  }
}
</style>
