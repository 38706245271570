import { make } from 'vuex-pathify';

import api from '@/services/api';

const state = {
  companyName: '',
};
const mutations = make.mutations(state);

const actions = {
  async getNmtMetadata() {
    return await api.get('dem/meta', {
      skipDefaultErrorHandler: true,
    });
  },
  async downloadWcs(store, payload) {
    return await api.post('dem/wcs/download', payload);
  },
  async getWcsDownloadStatus() {
    return await api.get('dem/wcs/download/status');
  },
  async cancelWcsDownloading() {
    return await api.get('dem/wcs/download/cancel');
  },
  async deleteNmt() {
    return await api.delete('dem');
  },

  async getProfile(store, payload) {
    return await api.post('dem/elevation_profile', payload);
  },
  async getFresnelProfile(store, data) {
    const { payload, fileName } = data;
    return await api.post(`fresnel_area/elevation_profile/${fileName}`, payload);
  },
  async getVisibilityAnalysisResult(store, payload) {
    return await api.post('viewshed', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
