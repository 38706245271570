import api from '@/services/api';
import { make } from 'vuex-pathify';
import { saveFileFromBackend } from '@/assets/js/utility';

const state = {
  reportsGroups: [],
  reports: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getCustomReportsGroups({ commit }) {
    const r = await api.get('custom_reports/groups');
    commit('SET_REPORTS_GROUPS', r.data.data);
  },
  async addCustomReportGroup({ dispatch }, payload) {
    await api.post('custom_reports/groups', payload);
    await dispatch('getCustomReportsGroups');
    await dispatch('getCustomReports');
  },
  async editCustomReportGroup({ dispatch }, payload) {
    const { id, data } = payload;
    await api.put(`custom_reports/groups/${id}`, data);
    await dispatch('getCustomReportsGroups');
    await dispatch('getCustomReports');
  },
  async deleteCustomReportGroup({ dispatch }, id) {
    await api.delete(`custom_reports/groups/${id}`);
    await dispatch('getCustomReportsGroups');
    await dispatch('getCustomReports');
  },
  async getCustomReports({ commit }) {
    const r = await api.get('custom_reports/reports');
    commit('SET_REPORTS', r.data.data);
  },
  async addCustomReport({ dispatch }, payload) {
    await api.post('custom_reports/reports', payload);
    await dispatch('getCustomReports');
  },
  async editCustomReport({ dispatch }, payload) {
    const { id, data } = payload;
    await api.put(`custom_reports/reports/${id}`, data);
    await dispatch('getCustomReports');
  },
  async deleteCustomReport({ dispatch }, id) {
    await api.delete(`custom_reports/reports/${id}`);
    await dispatch('getCustomReports');
  },
  async validateQuery(store, report_id) {
    await api.post(`custom_reports/execute/${report_id}/run`);
  },
  async generateCustomReport(store, data) {
    const { reportIds, payload } = data;
    const r = await api.post(`custom_reports/execute/${reportIds}/generate`, payload, {
      params: { background: false },
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
