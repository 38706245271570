<template>
  <v-btn
    class="button-main-dialog font-weight-medium"
    :class="{ 'font--text': cancel, cancel: cancel }"
    elevation="0"
    :style="{ opacity: computedOpacity }"
    v-on="$listeners"
    :dark="$attrs.disabled"
    v-bind="{
      small,
      outlined: cancel,
      color: cancel ? undefined : color,
      ...$attrs,
    }"
  >
    {{ computedText }}
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonMainDialog',
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    /**
     * @type {('primary'|'success'|'error')} color=primary kolor
     */
    color: {
      type: String,
      default: 'primary',
    },
    translationPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedText() {
      return this.text || this.$i18n.t(this.translationPath);
    },
    computedOpacity() {
      return this.$attrs.disabled ? 0.5 : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  &.v-btn {
    letter-spacing: 0.0178571429em !important;
    min-width: 70px !important;
    text-transform: initial;
    border-radius: 2px !important;
    line-height: normal;
    &:not(.cancel) {
      border: 1px solid rgb(47, 91, 183) !important;
      background-image: -webkit-linear-gradient(top, rgb(77, 144, 254), rgb(71, 135, 237));
      background-color: rgb(53, 122, 232);
      &:hover {
        border: 1px solid rgb(48, 121, 234) !important;
        background-image: -webkit-linear-gradient(top, rgb(77, 144, 254), rgb(53, 122, 232));
        background-color: rgb(77, 144, 254);
      }
    }
    &.v-size--small {
      margin-right: 16px !important;
      font-size: 11px;
      height: 27px;
      padding: 5.5px 15px;
    }
    &.v-size--default {
      margin-left: 18px !important;
      font-size: 13px;
      height: 32px;
      padding: 8px 14.5px;
    }
    &.cancel {
      border-color: rgba(0, 0, 0, 0.1);
      background-image: -webkit-linear-gradient(top, #f5f5f5, #f1f1f1);
      background-color: rgb(248, 248, 248);
      &:hover {
        border-color: rgb(198, 198, 198);
        background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
        background-color: rgb(245, 245, 245);
      }
    }
  }
}
</style>
