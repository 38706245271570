import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  analyzes: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getLocationAnalyzes({ commit }) {
    const r = await api.get('location_analysis');
    commit('SET_ANALYZES', r.data.data);
  },
  async getLocationAnalysis(store, id) {
    const r = await api.get(`location_analysis/${id}`);
    return r.data.data;
  },
  async deleteLocationAnalysis({ dispatch }, id) {
    await api.delete(`location_analysis/${id}`);
    await dispatch('getLocationAnalyzes');
  },
  async editLocationAnalysis({ dispatch }, { id, payload } = {}) {
    await api.put(`location_analysis/${id}`, payload);
    await dispatch('getLocationAnalyzes');
  },
  async runLocationAnalysis({ dispatch }, payload) {
    const r = await api.post('location_analysis', payload);
    if (payload.name) await dispatch('getLocationAnalyzes');
    return r.data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
