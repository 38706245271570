import api from '@/services/api';
import { make } from 'vuex-pathify';
import Vue from 'vue';

const state = {
  styles: {},
  defaultStyle: {},
  currentStyle: {},
};
const mutations = {
  ...make.mutations(state),
  SET_CURRENT_STYLE: (state, value) => {
    const { dataSource, style } = value;
    Vue.set(state.currentStyle, dataSource, style);
  },
  SET_DEFAULT_STYLE: (state, value) => {
    const { dataSource, style } = value;
    Vue.set(state.defaultStyle, dataSource, style);
  },
  SET_STYLES: (state, value) => {
    const { dataSource, styles } = value;
    Vue.set(state.styles, dataSource, styles);
  },
};
const actions = {
  async getStyles({ commit }, dataSourceName) {
    const r = await api.get(`/styles/${dataSourceName}`);
    commit('SET_STYLES', { dataSource: dataSourceName, styles: r.data.data });
  },
  async getStyle(state, payload) {
    const { dataSourceName, style_id } = payload;
    const r = await api.get(`/styles/${dataSourceName}/${style_id}`);
    return r.data.data;
  },
  async addStyle({ dispatch }, data) {
    const { payload, dataSourceName } = data;
    await api.post(`/styles/${dataSourceName}`, payload);
    await dispatch('getStyles', dataSourceName);
  },
  async editStyle({ dispatch }, data) {
    const { payload, dataSourceName, style_id } = data;
    await api.put(`/styles/${dataSourceName}/${style_id}`, payload);
    await dispatch('getStyles', dataSourceName);
  },
  async deleteStyle({ dispatch }, data) {
    const { dataSourceName, style_id } = data;
    await api.delete(`/styles/${dataSourceName}/${style_id}`);
    await dispatch('getStyles', dataSourceName);
  },
  setCurrentStyle({ commit }, value) {
    commit('SET_CURRENT_STYLE', value);
  },
  setDefaultStyle({ commit }, value) {
    commit('SET_DEFAULT_STYLE', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
