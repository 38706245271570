<template>
  <v-container fluid no-gutters class="pa-0 d-flex flex-column" style="height: 100%">
    <the-navbar-top-panel />
    <the-navbar-tools-panel />
  </v-container>
</template>

<script>
import TheNavbarToolsPanel from '@/components/TheNavbarToolsPanel';
import TheNavbarTopPanel from '@/components/TheNavbarTopPanel';

export default {
  name: 'TheNavbar',
  components: {
    TheNavbarTopPanel,
    TheNavbarToolsPanel,
  },
};
</script>

<style lang="scss" scoped></style>
