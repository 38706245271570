import { call, get } from 'vuex-pathify';
export default {
  computed: {
    mobileAppMetadata: get('admin/mobileAppMetadata@:appKind'),
    mobileAppUploadVersion() {
      return this.mobileAppMetadata?.version || '';
    },
    mobileAppUploadDate() {
      return this.mobileAppMetadata?.date_uploaded || '';
    },
    mobileAppUploadDateDays() {
      if (!this.mobileAppUploadDate) return null;
      const todayDate = this.$dayjs();
      const mobileAppUploadDate = this.$dayjs(this.mobileAppUploadDate);
      const days = todayDate.diff(mobileAppUploadDate, 'day');
      return days;
    },
    showMobileBadgeInfo() {
      if (!this.mobileAppUploadVersion) return false;
      if (this.mobileAppUploadDateDays > this.newMobileAppInfoDuration) {
        return false;
      } else if (this.mobileAppUploadVersion !== this.lastMobileBadgeReadVersion) {
        return true;
      } else {
        return false;
      }
    },
    showMobileDialogInfo() {
      if (!this.mobileAppUploadVersion) return false;
      if (this.mobileAppUploadDateDays > this.newMobileAppInfoDuration) {
        return false;
      } else if (this.mobileAppUploadVersion !== this.lastMobileDialogReadVersion) {
        return true;
      } else {
        return false;
      }
    },
    mobileVersionMapped() {
      const date = this.$dayjs(this.mobileAppUploadDate).format('DD.MM.YYYY');
      return this.$i18n.t('sidebar.mobileVersion.currentVersion', { version: this.mobileAppUploadVersion, date });
    },
    url() {
      return `${import.meta.env.VUE_APP_API_URL}/mobile_app/${this.appKind}/latest`;
    },
  },
  data: () => ({
    appKind: import.meta.env.VUE_APP_APP_KIND ? `${import.meta.env.VUE_APP_APP_KIND}` : '',
    lastMobileBadgeReadVersion: localStorage.getItem('mobile-badge-read-version') || '',
    lastMobileDialogReadVersion: localStorage.getItem('mobile-dialog-read-version') || '',
    newMobileAppInfoDuration: import.meta.env.VUE_APP_NEW_MOBILE_VERSION_DURATION
      ? parseInt(import.meta.env.VUE_APP_NEW_MOBILE_VERSION_DURATION)
      : 3,
  }),
  methods: {
    getMobileMetadata: call('admin/getMobileMetadata'),
    setLastMobileBadgeReadVersion(version = this.mobileAppUploadVersion || null) {
      if (!version) return;
      localStorage.setItem('mobile-badge-read-version', version);
      this.lastMobileBadgeReadVersion = version;
    },
    setLastMobileDialogReadVersion(version = this.mobileAppUploadVersion || null) {
      if (!version) return;
      localStorage.setItem('mobile-dialog-read-version', version);
      this.lastMobileDialogReadVersion = version;
    },
  },
};
