import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
export default {
  data: () => ({
    measurementsModuleStyle: {
      'circle-color': 'rgba(255, 0, 0, 1)',
      'circle-radius': 5,
      'fill-opacity': 1,
      labels: {},
    },
  }),
  methods: {
    toggleMeasurementsFeatures(features, zoom = false) {
      this.getLayerById('measurementsModule')
        ?.getSource()
        ?.clear();
      if (!features || features?.features?.length === 0) return;
      if (!this.getLayerById('measurementsModule')) {
        const newLayer = new VectorLayer({
          id: 'measurementsModule',
          isSpecial: true,
          zIndex: 999,
          opacity: 1,
          source: new VectorSource(),
          style: f => this.getStyle(f, this.measurementsModuleStyle, 'point', false, false),
        });
        this.map.addLayer(newLayer);
      }
      this.getLayerById('measurementsModule')
        .getSource()
        .addFeatures(
          new GeoJSON().readFeatures(features, {
            dataProjection: features.crs.properties.name,
            featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          })
        );
      if (zoom) {
        this.fitView(
          this.getLayerById('measurementsModule')
            ?.getSource()
            ?.getExtent()
        );
      }
    },
  },
  mounted() {
    this.$root.$off('toggleMeasurementsFeatures');
    this.$root.$on('toggleMeasurementsFeatures', this.toggleMeasurementsFeatures);
  },
};
