<template>
  <navbar-menu-tool
    v-model="isMenuOpen"
    :menu-data="{ name: 'areaSearch', position: 'top', allowOtherLeft: true }"
    :button-bind="{ icon, tooltip }"
  >
    <template #content>
      <v-row justify="space-between" class="pa-5 pb-2" no-gutters>
        <v-col cols="auto" class="mr-2">
          <div>
            <dots-menu
              :items="dotsItemsArea"
              @multiple="turnOnDrawing('multiple')"
              @freehand="turnOnDrawing('freehand')"
              @single="turnOnDrawing('single')"
            >
              <template #activator="{ on }">
                <div>
                  <base-button
                    v-on="getButtonListeners(on)"
                    translation-path="sidebar.searchArea.selectArea"
                    :disabled="isDrawn"
                    :button-style="buttonStyle"
                    class="mr-0 mt-1"
                  />
                  <v-tooltip v-if="isDrawn || !!selectedFeatures.length" color="font" bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on" color="primary" tag="button" class="mt-1 ml-1" @click="zoomToArea">
                        mdi-magnify
                      </v-icon>
                    </template>
                    {{ $i18n.t('sidebar.searchArea.zoomToArea') }}
                  </v-tooltip>
                </div>
              </template>
            </dots-menu>
            <link-underlined
              v-if="isDrawn || !!selectedFeatures.length"
              translation-path="sidebar.searchArea.clearSelection"
              :disabled="isLoading"
              underline-only-on-hover
              classes="font-weight-regular font--text mt-2"
              @click="clearSelection"
            />
          </div>
        </v-col>
        <v-col cols="auto" class="d-flex align-start mr-2">
          <div v-for="(input, idx) in inputs.area" :key="input.name" class="d-flex align-center input-area">
            <data-input v-model="payload[input.name]" v-bind="input" />
            <span class="ml-1">ha</span>
            <span v-if="idx === 0" class="font-weight-bold mx-2">-</span>
          </div>
        </v-col>
        <v-col
          v-for="input in [inputs.params, inputs.excluded_layers]"
          :key="input.name"
          style="min-width: 0 !important"
          class="flex-grow-1 mr-2"
        >
          <data-input v-model="$data[input.name]" v-bind="input">
            <template #prepend-item>
              <v-list-item @click="toggleSelectAll(input.name)">
                <v-list-item-action>
                  <v-icon :color="$data[input.name].length > 0 ? 'primary' : ''">
                    {{ getIconSelectAll(input.name) }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> {{ $i18n.t('button.selectAllItems') }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
            <template #selection="{ item, index }">
              <v-tooltip color="font" bottom>
                <template #activator="{ on }">
                  <v-chip v-if="index < 2" v-on="on">
                    <span class="d-inline-block text-truncate">{{ item.text }}</span>
                  </v-chip>
                </template>
                {{ item.text }}
              </v-tooltip>
              <span v-if="index === 2" style="white-space: nowrap" class="text-caption grey--text">
                {{ `(+${$i18n.tc('admin.other', $data[input.name].length - 2)})` }}
              </span>
            </template>
          </data-input>
        </v-col>
        <v-col cols="auto" style="max-width: 36px">
          <base-button
            icon="mdi-magnify"
            icon-size="22px"
            :button-style="{
              iconColor: 'white',
              backgroundColor: isFormValid && !isLoading ? $_colors.primary : 'rgb(210, 210, 210)',
              borderRadius: 'unset',
            }"
            :disabled="!isFormValid || isLoading"
            is-icon
            class="ma-0 mt-1"
            @click="search"
          />
        </v-col>
      </v-row>
      <v-row align="center" class="px-5 pb-2" no-gutters>
        <template v-if="isDrawn || !!selectedFeatures.length">
          <v-col v-for="(input, idx) in inputs.search" :key="input.name" cols="auto">
            <data-input v-model="payload[input.name]" v-bind="input" :class="{ 'ml-4': !!idx }" />
          </v-col>
        </template>
        <v-spacer />
        <v-col cols="auto" class="d-flex" @click="resetAll">
          <v-icon small color="error" :disabled="isLoading"> mdi-cancel </v-icon>
          <link-underlined
            translation-path="sidebar.searchArea.resetAll"
            underline-only-on-hover
            classes="font-weight-regular error--text ml-1"
            :disabled="isLoading"
          />
        </v-col>
        <v-col v-if="!!results" cols="auto" class="ml-2">
          <dots-menu :items="dotsItemsSave" @geojson="saveFile('geojson')" @kml="saveFile('kml')" @copy="toggleCopy()">
            <template #activator="{ on }">
              <v-tooltip color="font" bottom>
                <template #activator="{ on: onTooltip }">
                  <span v-on="{ ...onTooltip }">
                    <v-icon v-on="{ ...on }" color="success" tag="button">
                      mdi-content-save
                    </v-icon>
                  </span>
                </template>
                {{ $i18n.t('sidebar.searchArea.saveLayerToFile') }}
              </v-tooltip>
            </template>
          </dots-menu>
        </v-col>
      </v-row>
      <table-buttons-copy-objects-dialog
        v-if="isCopyLoaded"
        :is-visible.sync="isCopyVisible"
        :is-loading.sync="isCopyLoading"
        input-layer-id="investment-area-search"
        :geojson="results"
        :geojson-copy-attributes="resultAttributes"
      />
    </template>
  </navbar-menu-tool>
</template>

<script>
import { call, get } from 'vuex-pathify';
import { createBox } from 'ol/interaction/Draw';
import { transformExtent } from 'ol/proj';
import { KML, GeoJSON } from 'ol/format';

import DotsMenu from '@/components/DotsMenu';
import DataInput from '@/components/DataInput';
import LinkUnderlined from '@/components/LinkUnderlined';
import NavbarMenuTool from '@/components/NavbarMenuTool';

import getBbox from '@/mixins/map/getBbox';

export default {
  name: 'TheNavbarToolsPanelInvestmentAreaSearch',
  components: {
    DotsMenu,
    DataInput,
    LinkUnderlined,
    NavbarMenuTool,
    TableButtonsCopyObjectsDialog: () => import('@/components/TableButtonsCopyObjectsDialog'),
  },
  mixins: [getBbox],
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
  data: vm => ({
    isMenuOpen: false,
    sharedData: null,
    isFormValid: false,
    isLoading: false,
    isDrawn: false,
    isDrawing: false,
    selectedFeatures: [],
    payload: {
      bbox: null,
      geojson: null,
      is_intersection: true,
      is_within: true,
    },
    params: ['lasy', 'kowr', 'amw', 'pn', 'pk', 'oso', 'soo'],
    excluded_layers: [],
    dotsItemsArea: [
      { name: 'multiple' },
      { name: 'freehand' },
      { name: 'single', customName: vm.$i18n.t('menu.selectFeatures') },
    ],
    dotsItemsSave: [
      { name: 'geojson', customName: vm.$i18n.t('menu.toGeojsonFile') },
      { name: 'kml', customName: vm.$i18n.t('menu.toKmlFile') },
      { name: 'copy', customName: vm.$i18n.t('menu.copyToLayer') },
    ],
    results: null,
    isCopyLoaded: false,
    isCopyVisible: false,
    isCopyLoading: false,
  }),
  computed: {
    moduleShareableData: get('moduleSharing/moduleSharingData'),
    selectedFeaturesLayers: get('map/selectedFeatures'),
    layers: get('layers/layers'),
    currentProjectId: get('layers/project.id'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers.filter(
        layer => layer.geometry_type === 'polygon' || layer.geometry_type === 'multipolygon'
      );
    },
    projectLayersMapped() {
      return this.projectLayers.map(layer => ({ value: layer.id, text: layer.name }));
    },
    excludedLayersStorage() {
      return (JSON.parse(localStorage.getItem('searchAreaExcludedLayers')) || []).filter(layerId =>
        this.projectLayersMapped.map(el => el.value).includes(layerId)
      );
    },
    buttonStyle() {
      return this.isDrawing
        ? {
            backgroundColor: `${this.$_colors.primarybackground} !important`,
            border: '1px solid rgba(26, 115, 232, 0.3) !important',
            color: this.$_colors.primary,
          }
        : {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
            border: '1px solid rgba(0, 0, 0, 0.2) !important',
            color: '#61646D',
          };
    },
    inputs() {
      return {
        search: [...(this.selectedFeatures?.length > 1 ? ['is_intersection'] : []), 'is_within'].map(input => {
          return {
            name: input,
            dataType: { name: 'checkbox' },
            label: this.$i18n.t(`sidebar.searchArea.${input}`),
            disabled: this.isLoading,
            hideDetails: true,
          };
        }),
        area: ['area_ha_from', 'area_ha_to'].map(input => {
          return {
            name: input,
            dataType: { name: 'decimal', decimal_places: 3 },
            label: this.$i18n.t(`sidebar.searchArea.${input.split('_').slice(-1)}`),
            disabled: this.isLoading,
            hideDetails: true,
          };
        }),
        params: {
          name: 'params',
          dataType: { name: 'select' },
          label: this.$i18n.t('sidebar.searchArea.excludedAreas'),
          items: ['lasy', 'kowr', 'amw', 'pn', 'pk', 'oso', 'soo'].map(input => ({
            value: input,
            text: this.$i18n.t(`sidebar.searchArea.${input}`),
          })),
          chips: true,
          multiple: true,
          autoConvertSelect: false,
          disabled: this.isLoading,
          hideDetails: true,
        },
        excluded_layers: {
          name: 'excluded_layers',
          dataType: { name: 'select' },
          label: this.$i18n.t('sidebar.searchArea.exludedLayers'),
          items: this.projectLayersMapped,
          chips: true,
          multiple: true,
          autoConvertSelect: false,
          disabled: this.isLoading,
          hideDetails: true,
        },
      };
    },
    resultAttributes() {
      return [{ attribute: 'id_dzialki', dataType: { name: 'text' }, label: 'TERYT' }];
    },
  },
  watch: {
    'payload.bbox': {
      handler(nV) {
        this.isFormValid = !!nV;
      },
    },
    'payload.geojson': {
      handler(nV) {
        this.isFormValid = !!nV;
      },
    },
    selectedFeatures(nV) {
      this.isFormValid = !!nV.length;
    },
    moduleShareableData: {
      handler(nV) {
        if (nV?.destinationTool === 'searchArea') this.setSharedData();
      },
      deep: true,
    },
    currentProjectId: {
      handler(nV, oV) {
        if (nV !== oV) this.excluded_layers = this.excludedLayersStorage;
      },
      immediate: true,
      deep: true,
    },
    isMenuOpen(nV) {
      if (!nV) {
        this.isLoading = false;
        if (this.sharedData) {
          this.$root.$emit('toggleCustomVectorLayer', {
            id: `externalTool_${this.sharedData.tool}`,
          });
        }
        this.clearSelection();
      }
    },
  },
  methods: {
    getSearchedArea: call('externalSystems/getSearchedArea'),
    setSharedData() {
      this.isMenuOpen = true;
      this.sharedData = JSON.parse(JSON.stringify(this.moduleShareableData));
      this.$store.set('moduleSharing/CLEAR_SHAREABLE_DATA!');
      this.payload.geojson = JSON.parse(this.sharedData.geometry);
      this.isDrawn = true;
    },
    deleteFeature({ layerId, id: featureId }) {
      if (featureId && layerId) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [layerId]: [featureId],
          },
        });
      }
      const featureIndex = this.selectedFeatures.findIndex(ft => ft.id === featureId && ft.layerId === layerId);
      this.selectedFeatures.splice(featureIndex, 1);
    },
    deleteFeatures() {
      for (const layerId of Object.keys(this.selectedFeaturesLayers)) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [layerId]: [],
          },
        });
      }
      this.selectedFeatures = [];
    },
    clearSelection() {
      this.isDrawing = false;
      this.isDrawn = false;
      this.payload.bbox = null;
      this.payload.geojson = null;
      if (this.selectedFeatures.length) this.deleteFeatures();
      this.$root.$emit('clearSidebarGeometry');
      this.$root.$emit('identification-action', false);
      if (this.sharedData) {
        this.$root.$emit('toggleCustomVectorLayer', {
          id: `externalTool_${this.sharedData.tool}`,
        });
        this.sharedData = null;
      }
    },
    async zoomToArea() {
      if (this.payload.bbox) {
        this.$root.$emit('fitView', transformExtent(this.payload.bbox, 'EPSG:4326', this.$_config.defaultEpsg));
      } else if (this.payload.geojson) {
        this.$root.$emit('createGeomAndFitView', this.payload.geojson);
      } else if (this.selectedFeatures.length) {
        const selectedFeatures = Object.entries(this.selectedFeaturesLayers).reduce((total, current) => {
          if (current[1].length) return [...total, { layerId: +current[0], featuresIds: current[1] }];
          else return total;
        }, []);
        const bbox = await this.getMultipleLayersFeaturesBbox(selectedFeatures);
        this.$root.$emit('fitView', bbox);
      }
    },
    resetAll() {
      if (!this.isLoading) {
        this.clearSelection();
        this.$root.$emit('toggleCustomVectorLayer', { id: 'searchArea' });
        this.params = this.excluded_layers = [];
        this.results = null;
      }
    },
    getButtonListeners(on) {
      return this.isDrawing ? { click: this.turnOffSelecting } : on;
    },
    onDrawend(e, type) {
      this.isDrawing = false;
      this.isDrawn = true;
      if (type === 'multiple') {
        this.payload.bbox = transformExtent(
          e.feature.getGeometry().getExtent(),
          this.$_config.defaultEpsg,
          'EPSG:4326'
        );
      }
      if (type === 'freehand') {
        this.payload.geojson = {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: { name: this.$_config.defaultEpsg || 'EPSG:4326' },
          },
          coordinates: e.feature.getGeometry().getCoordinates(),
        };
      }
    },
    turnOnDrawing(type) {
      this.isDrawing = true;
      if (type === 'single') {
        this.$root.$emit('identification-action', true, { specialType: 'addByMap' });
        this.$root.$off('assignedObjects');
        this.$root.$on('assignedObjects', identifiedFeatures => {
          const features = identifiedFeatures.map(feature => feature.features);
          for (const feature of features) {
            const { dataSource = '', layerId, id: featureId } = feature[0];
            if (!dataSource) return;
            const isFeatureSelected = this.selectedFeatures.some(el => el.layerId === layerId && el.id === featureId);
            if (isFeatureSelected) {
              this.deleteFeature(feature[0]);
            } else {
              this.selectedFeatures = this.selectedFeatures.concat(feature);
              this.$store.set('map/ADD_SELECTED_FEATURES!', {
                type: 'selectedFeatures',
                features: {
                  [layerId]: [featureId],
                },
              });
              this.$root.$emit('setProjectLayerStyle', layerId);
            }
          }
        });
      } else {
        this.$root.$emit('drawSidebarGeometry', {
          drawendCallback: e => this.onDrawend(e, type),
          geometryFunction: type === 'multiple' ? createBox() : null,
          geometryType: 'polygon',
          isInitSnapping: false,
          freehand: type === 'freehand' ? true : false,
          ...(type === 'multiple' ? { drawType: 'Circle' } : {}),
        });
      }
    },
    turnOffSelecting() {
      if (this.selectedFeatures.length) this.isDrawn = true;
      this.isDrawing = false;
      this.$root.$emit('selection-action');
    },
    toggleSelectAll(input) {
      if (this[input].length === this.inputs[input].items.length) this[input] = [];
      else this[input] = this.inputs[input].items.map(item => item.value);
    },
    getIconSelectAll(input) {
      if (this[input].length === this.inputs[input].length) return 'mdi-checkbox-marked';
      else if (this[input].length) return 'mdi-minus-box';
      else return 'mdi-checkbox-blank-outline';
    },
    saveFile(type) {
      const contentString =
        type === 'kml'
          ? `<?xml version="1.0" encoding="utf-8" ?>${new KML().writeFeatures(
              new GeoJSON().readFeatures(this.results),
              {
                featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
                dataProjection: 'EPSG:4326',
              }
            )}`
          : JSON.stringify(this.results);
      const file = new Blob([contentString], {
        type: `${type === 'kml' ? 'text/xml' : 'application/geo+json'};charset=utf-8;`,
      });
      this.$_saveFile(URL.createObjectURL(file), `${this.$i18n.t('sidebar.searchArea.areas')}.${type}`);
    },
    toggleCopy() {
      this.isCopyLoaded = true;
      this.isCopyVisible = true;
    },
    async search() {
      this.isLoading = true;
      try {
        const payload = JSON.parse(JSON.stringify(this.payload));
        this.params.forEach(param => {
          payload[`area_ha_${param}_from`] = this.payload.area_ha_from || null;
          payload[`area_ha_${param}_to`] = this.payload.area_ha_to || null;
        });
        if (this.selectedFeatures.length) {
          payload.layers = [];
          this.selectedFeatures.forEach(feature => {
            payload.layers.push({ layer_id: feature.layerId, ids: [feature.id] });
          });
        } else {
          payload.is_intersection = false;
        }
        if (this.excluded_layers.length) payload.excluded_layers = this.excluded_layers;
        localStorage.setItem('searchAreaExcludedLayers', JSON.stringify(this.excluded_layers));
        this.results = await this.getSearchedArea(payload);
        const geojson = { ...this.results };
        this.$root.$emit('toggleCustomVectorLayer', {
          id: 'searchArea',
          geometry: geojson,
          fitView: true,
        });
        this.clearSelection();
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-select .v-chip {
    height: 24px;
  }
  .input-area .v-input__control {
    width: 75px;
  }
  .v-input--checkbox {
    margin-top: 0;
    & .v-label {
      color: #202124;
      margin-left: -5px;
    }
  }
}
::v-deep .active-icon * {
  color: map-get($colors, 'link') !important;
}
.background-icon {
  @include activeToolButtonStyle();
}
.menu-content {
  top: 0 !important;
  left: 25px !important;
  width: calc(71vw - 50px) !important;
  min-width: calc(71vw - 50px) !important;
}
</style>
