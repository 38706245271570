import Vue from 'vue';
import { make } from 'vuex-pathify';

const state = {
  activeMenus: [],
};

const mutations = {
  ...make.mutations(state),
  SET_ACTIVE_TOOL(state, { data, value }) {
    if (value) {
      Vue.set(state, 'activeMenus', [
        ...state.activeMenus.filter(item => {
          return (
            item.name !== data.name &&
            (data.allowOtherTop || item.position !== 'top') &&
            (data.allowOtherLeft || item.position !== 'left')
          );
        }),
        data,
      ]);
    } else {
      Vue.set(
        state,
        'activeMenus',
        state.activeMenus.filter(item => item.name !== data.name)
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
