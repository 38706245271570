import api from '@/services/api';
import { make } from 'vuex-pathify';
import { flattenObject } from '@/assets/js/utility';

const state = {
  legalizationSummary: {},
  minMaxWaterConsumptionValues: {},
  partition: undefined,
  partitionByDma: [],
  waterUsageDateRange: undefined,
  unassignedWatermetersCount: null,
};

const mutations = {
  ...make.mutations(state),
  SET_PARTITION_BY_DMA(state, zones) {
    const computedZones = {};
    for (const zone of zones) {
      computedZones[zone.dma_id] = flattenObject(zone);
    }
    state.partitionByDma = computedZones;
  },
};

const actions = {
  async getLegalizationSummary({ commit }) {
    const r = await api.get(`billings/legalization_summary`);
    commit('SET_LEGALIZATION_SUMMARY', r.data.data);
  },
  async getMinMaxWaterConsumptionValues({ commit }) {
    const r = await api.get(`billings/water_usage/current_values_range`);
    commit('SET_MIN_MAX_WATER_CONSUMPTION_VALUES', r.data.data._day_average_usage);
  },
  async getPartition({ commit }, data) {
    const r = await api.post(`billings/partition`, data);
    commit('SET_PARTITION', flattenObject(r.data.data));
  },
  async getPartitionByDma({ commit }, params) {
    const r = await api.get(`billings/partition_by_dma`, { params });
    commit('SET_PARTITION_BY_DMA', r.data.data);
  },
  async getWaterUsageDateRange({ commit }) {
    const r = await api.get(`billings/water_usage/date_range`);
    commit('SET_WATER_USAGE_DATE_RANGE', r.data.data);
  },
  async getPartitionWatermeters(store, params) {
    return await api.get(`/billings/partitions/watermeters`, { params });
  },
  async asignPartitionWatermeter(store, payload) {
    const { partitionId, watermeterId } = payload;
    return await api.post(`billings/partitions/${partitionId}/watermeters`, { billing_ids: [watermeterId] });
  },
  async deletePartitionWatermeter(store, id) {
    return await api.delete(`/billings/partitions/watermeters`, { data: { watermeters_ids: [id] } });
  },
  async sendSms(store, payload) {
    return await api.post(`billings/sms`, payload);
  },
  async getUnassignedWatermeters({ commit }) {
    const r = await api.get('billings/watermeters/unassigned');
    commit('SET_UNASSIGNED_WATERMETERS_COUNT', r?.data?.data?.length || 0);
    return r;
  },
  async getUnassignedWatermetersCount({ commit }) {
    const r = await api.get('billings/watermeters/unassigned/count');
    commit('SET_UNASSIGNED_WATERMETERS_COUNT', r?.data?.data);
  },
  async migrateBillingsData(store, { params } = {}) {
    return await api.get('billings/migrate', { params });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
