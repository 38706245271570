import { sync } from 'vuex-pathify';
import { Style, Stroke } from 'ol/style';
import { Draw } from 'ol/interaction';
import WKT from 'ol/format/WKT';
import { GeoJSON } from 'ol/format';

export default {
  computed: {
    isReverseGeocoderToolActive: sync('tools/toolStatus@isReverseGeocoderToolActive'),
  },
  methods: {
    addReverseGeocoderLayer() {
      this.addCustomLayer(null, 'reverseGeocoderCoordinates', {
        zIndex: 1000,
        style: this.getMarkerStyle(),
      });
    },
    addParcelLayer(geometry, format, projectionEpsg) {
      let geom;
      if (format === 'wkt') {
        geom = new WKT().readGeometry(geometry, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          dataProjection: projectionEpsg || 'EPSG:4326',
        });
      } else if (format === 'geojson') {
        geom = new GeoJSON().readGeometry(geometry, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          dataProjection: projectionEpsg || 'EPSG:4326',
        });
      }
      this.addCustomLayer(geom, 'reverseGeocoderParcel', {
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#BF360C',
            width: 3,
          }),
        }),
      });
    },
    clearReverseGeocoderLayer(id) {
      if (this.getLayerById(id)) {
        this.map.removeLayer(this.getLayerById(id));
      }
    },
    clearReverseGeocoderInteraction() {
      this.map.removeInteraction(this.getInteractionByName('reverseGeocoderCoordinates'));
      if (this.getLayerById('reverseGeocoderCoordinates')) {
        this.map.removeLayer(this.getLayerById('reverseGeocoderCoordinates'));
      }
      if (this.getLayerById('reverseGeocoderParcel')) {
        this.map.removeLayer(this.getLayerById('reverseGeocoderParcel'));
      }
      this.$store.set('map/SET_REVERSE_GEOCODER_COORDINATES!', []);
    },
    async toggleReverseGeocoder(value) {
      if (value && this.activeTool !== 'reverseGeocoder') {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === 'reverseGeocoder') {
        this.deactivateToolHandler('reverseGeocoder');
      }
      this.$nextTick(() => {
        this.isReverseGeocoderToolActive = value;
        if (value) {
          if (!this.getLayerById('reverseGeocoderCoordinates')) {
            this.addReverseGeocoderLayer();
          }
          const coordinatesInteraction = new Draw({
            source: this.getLayerById('reverseGeocoderCoordinates').getSource(),
            type: 'Point',
          });
          coordinatesInteraction.set('name', 'reverseGeocoderCoordinates');
          coordinatesInteraction.on('drawend', e => {
            this.clearCustomLayer('reverseGeocoderCoordinates');
            const coordinates = e.feature.getGeometry().getCoordinates();
            e.feature.setId(1);
            this.$store.set('map/SET_REVERSE_GEOCODER_COORDINATES!', coordinates);
          });
          this.map.addInteraction(coordinatesInteraction);
          this.activeTool = 'reverseGeocoder';
        } else {
          this.clearReverseGeocoderInteraction();
        }
      });
    },
  },
  mounted() {
    this.$root.$on('reverseGeocoder-action', this.toggleReverseGeocoder);
    this.$root.$on('addParcelLayer', this.addParcelLayer);
    this.$root.$on('clearReverseGeocoderLayer', this.clearReverseGeocoderLayer);
  },
};
