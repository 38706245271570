<template>
  <the-navbar-button v-bind="$props">
    <template #default>
      <v-subheader
        style="height: auto"
        class="text-subtitle-2 font-weight-regular py-1 px-2 font--text"
        v-on="$listeners"
        v-text="additionalModuleName"
      />
    </template>
  </the-navbar-button>
</template>

<script>
import TheNavbarButton from '@/components/TheNavbarButton';

export default {
  name: 'TheNavbarTopPanelMenuButton',
  components: {
    TheNavbarButton,
  },
  props: {
    name: {
      type: String,
    },
    isToolWorking: {
      type: Boolean,
      default: true,
    },
    hasCustomAction: {
      type: Boolean,
      default: false,
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    verboseName: {
      type: String,
      default: '',
    },
    moduleLayerId: {
      type: Number,
      default: null,
    },
    routePath: {
      type: String,
    },
  },
  computed: {
    additionalModuleName() {
      return this.verboseName || this.$i18n.t(`navbar.${this.name}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
