<template>
  <v-tooltip bottom color="font">
    <template #activator="{ on }">
      <v-btn
        icon
        v-on="on"
        outlined
        small
        class="hide-panel-button"
        elevation="1"
        @click="isSidebarVisible = !isSidebarVisible"
      >
        <v-icon dark small>
          {{ isSidebarVisible ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
        </v-icon>
      </v-btn>
    </template>
    {{ isSidebarVisible ? $i18n.t('sidebar.collapse') : $i18n.t('sidebar.expand') }}
  </v-tooltip>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  name: 'HideSidebarButton',
  computed: {
    isSidebarVisible: sync('sidebar/isVisible'),
  },
};
</script>

<style lang="scss" scoped>
.hide-panel-button {
  border: 0.8px solid rgba(0, 0, 0, 0.2) !important;
  background-color: #fff;
  transform: translateX(-50%);
  z-index: 4;
}
</style>
