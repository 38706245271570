import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  selectedDataSources: [],
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async getDatasourceSettings(store, name) {
    const r = await api.get(`dataio/selected_datasources/${name}`);
    return r.data.data;
  },
  async getDatasourcesSettings({ commit }) {
    const r = await api.get('dataio/selected_datasources');
    commit('SET_SELECTED_DATA_SOURCES', r.data.data);
  },
  async editDatasourceSettings(store, payload) {
    const { name, settings } = payload;
    await api.post(`dataio/selected_datasources/${name}`, settings);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
