import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  investments: {},
  categories: [],
};

const mutations = {
  ...make.mutations(state),
  DELETE_INVESTMENT: (state, investment_id) => {
    Vue.delete(state.investments, investment_id);
  },
  SET_INVESTMENTS: (state, data) => {
    const { investmentsProps, crs } = data;
    const investments = {};
    for (const investment of investmentsProps) {
      investments[investment.id] = {
        ...investment.properties,
        ...{ id: investment.id },
        ...{ geometry: { ...investment.geometry, ...{ crs } } },
      };
    }
    state.investments = investments;
  },
};

const actions = {
  async addInvestment({ dispatch }, feature) {
    const r = await api.post(`/investments`, { feature });
    await dispatch('getInvestments');
    return r;
  },
  async deleteInvestment({ commit }, investment_id) {
    await api.delete(`/investments/${investment_id}`);
    commit('DELETE_INVESTMENT', investment_id);
  },
  async editInvestment({ dispatch }, payload) {
    const { feature, investment_id } = payload;
    await api.put(`/investments/${investment_id}`, { feature });
    await dispatch('getInvestments');
  },
  async getInvestment(store, id) {
    const r = await api.get(`/investments/${id}`);
    return r.data.data;
  },
  async getInvestments({ commit }) {
    const r = await api.post('investments/read', {});
    commit('SET_INVESTMENTS', { investmentsProps: r.data.data.features.features, crs: r.data.data.features.crs });
  },
  async getCategories({ commit }) {
    const r = await api.get('/investments/categories');
    commit('SET_CATEGORIES', r.data.data);
  },
  async addCategory({ dispatch }, data) {
    await api.post('/investments/categories', data);
    await dispatch('getCategories');
  },
  async editCategory({ dispatch }, payload) {
    const { data, category_id } = payload;
    await api.put(`/investments/categories/${category_id}`, data);
    await dispatch('getCategories');
  },
  async deleteCategory({ dispatch }, category_id) {
    await api.delete(`/investments/categories/${category_id}`);
    await dispatch('getCategories');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
