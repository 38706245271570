<template>
  <v-row no-gutters>
    <v-col v-for="button in items" :key="button.name" style="padding: 0 2px; height: 24px;">
      <template v-if="button.divider">
        <div class="tools-divider ml-2 mr-1" />
      </template>
      <the-navbar-tools-panel-item v-else v-bind="button" v-on="$listeners">
        <template #default="button">
          <slot :name="button.name" v-bind="button"> </slot>
        </template>
      </the-navbar-tools-panel-item>
    </v-col>
  </v-row>
</template>

<script>
import TheNavbarToolsPanelItem from '@/components/TheNavbarToolsPanelItem';

export default {
  name: 'TheNavbarToolsPanelItems',
  components: {
    TheNavbarToolsPanelItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tools-divider {
  height: 28px;
  @include border(right);
}
</style>
