<template>
  <v-combobox
    v-on="$listeners"
    v-bind="{ ...$props, ...$attrs }"
    :menu-props="menuProps"
    :no-data-text="$i18n.t('admin.noData')"
  >
    <template v-for="(value, key) in $scopedSlots" #[key]="scope">
      <slot :name="key" v-bind="scope" />
    </template>
    <template #message="{message}">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
  </v-combobox>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';
export default {
  name: 'DataInputCombobox',
  components: {
    ItalicSubheader,
  },
  model: {
    event: 'change',
  },
  props: {
    fixedHeight: {
      type: Boolean,
      default: true,
    },
    value: {
      default: null,
    },
    menuProps: {
      type: Object,
      default: () => ({
        offsetY: true,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item__title {
    @include selectListElement();
  }
  .theme--light.v-icon.v-icon.v-icon--disabled {
    display: none;
  }
  .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
