import api from '@/services/api';
const actions = {
  async checkLightHeadStatus(store, headId) {
    const r = await api.get(`/lighting-control/ping/${headId}`);
    return r.data.data;
  },
  async getLightHeadParameters(store, headId) {
    const r = await api.get(`/lighting-control/status/${headId}`);
    return r.data.data;
  },
  async getHeadStats(store, data) {
    const { headId, payload } = data;
    const r = await api.post(`/lighting-control/stats/${headId}`, payload);
    return r.data.data;
  },
  async getHeadsStats(store, payload) {
    const r = await api.post('/lighting-control/stats', payload);
    return r.data.data;
  },
  async lightingControlAction(store, payload) {
    const { headId, action, value } = payload;
    await api.post(`/lighting-control/action/${action}/${headId}`, value);
  },
};

export default {
  namespaced: true,
  actions,
};
