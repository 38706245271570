import api from '@/services/api';
import Vue from 'vue';
import { make } from 'vuex-pathify';
const state = {
  history: {},
};
const mutations = {
  ...make.mutations(state),
  SET_HISTORY: (state, data) => {
    const { dataSourceName, featureId, history } = data;
    if (!state.history.dataSourceName) {
      Vue.set(state.history, dataSourceName, {});
    }
    Vue.set(state.history[dataSourceName], featureId, history);
  },
};
const actions = {
  async getHistory({ commit }, payload) {
    const { data_source_name, feature_id } = payload;
    const r = await api.get(`/history/${data_source_name}/${feature_id}`);
    commit('SET_HISTORY', { dataSourceName: data_source_name, featureId: feature_id, history: r.data.data });
  },
  async restoreHistory(state, { id, params } = {}) {
    await api.post(`/history/${id}/restore`, {}, { params });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
