<template>
  <v-select
    v-on="getListeners($listeners)"
    :menu-props="menuProps"
    v-bind="{ noDataText: $i18n.t('admin.noData'), ...$props, ...$attrs }"
    :class="{ 'v-select--wrapped': wrapped }"
    v-model="computedValue"
  >
    <template v-for="(value, key) in $scopedSlots" #[key]="scope">
      <slot :name="key" v-bind="scope" />
    </template>
    <template #message="{ message }">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template #prepend-item>
      <slot name="prepend-item" />
    </template>
    <template #no-data>
      <slot name="no-data" />
    </template>
  </v-select>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'DataInputSelect',
  components: {
    ItalicSubheader,
  },
  props: {
    value: {
      default: null,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
    menuProps: {
      type: Object,
      default: () => ({
        offsetY: true,
      }),
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
  },
  methods: {
    getListeners(listeners) {
      return this.$_filterObjectKeys(listeners, { notAllowedKeys: 'input' });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select--wrapped :deep(.v-chip) {
  margin: 0 !important;
}
.v-select--wrapped :deep(.v-input__slot) {
  height: auto !important;
}
.v-select--wrapped :deep(.v-select__selections) {
  flex-wrap: wrap !important;
  gap: 0.3rem 0.4rem;
}
::v-deep {
  .v-list-item__title {
    @include selectListElement();
  }
  .theme--light.v-icon.v-icon.v-icon--disabled {
    display: none;
  }
  .v-select__selections {
    flex-wrap: nowrap;
    input {
      display: none !important;
    }
  }
}
</style>
