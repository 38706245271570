import Vue from 'vue';

const state = {
  statusCheckers: [],
};

const mutations = {
  PUSH_STATUS_CHECKER: (state, value) => {
    Vue.set(state.statusCheckers, state.statusCheckers.length, value);
  },
  REMOVE_STATUS_CHECKER_BY_ID: (state, id) => {
    const index = state.statusCheckers.findIndex(statusChecker => statusChecker.id === id);
    Vue.delete(state.statusCheckers, index);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
