import Collection from 'ol/Collection';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';

import { get } from 'vuex-pathify';

import zdmEditorSettings from '@/mixins/zdmEditorSettings';

export default {
  mixins: [zdmEditorSettings],
  computed: {
    zdmDataLayers: get('admin/modulesMapping@zdm_data.layers'),
    zdmEditorSession: get('zdm_editor/session'),
    layersMetadata: get('layers/metadata'),
  },
  methods: {
    deleteZdmEditorSession() {
      for (const layerId of Object.values(this.zdmDataLayers)) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'erroredFeatures',
          features: {
            [layerId]: [],
          },
        });
      }
      const layers = new Collection();
      this.getLayerById('zdmEditorLayers').setLayers(layers);
      this.map.updateSize();
    },
    addZdmEditorSession(close = false) {
      if (close) {
        this.deleteZdmEditorSession();
      }
      this.$nextTick(() => {
        const { session_objects: sessionObjects } = this.zdmEditorSession;
        for (const key of Object.keys(sessionObjects)) {
          const idAttributeName = this.layersMetadata[key].attributes_schema.id_name;
          const { insert: toAdd, delete: toDelete } = sessionObjects[key];
          if (toAdd && toAdd.length) {
            const toAddGeoJson = toAdd.map(feature => {
              return { ...feature, id: feature.metadata.id };
            });
            this.toggleZdmEditorNewObjects(this.zdmEditorNewObjectsDataSources[key], toAddGeoJson);
          }
          if (toDelete && toDelete.length) {
            let tabName = '';
            for (const name of Object.keys(this.zdmDataDataSources)) {
              if (this.zdmDataDataSources[name].datasource === key) {
                tabName = name;
                break;
              }
            }
            if (tabName) {
              const layerId = this.zdmDataLayers[tabName];
              this.$store.set('map/ADD_SELECTED_FEATURES!', {
                type: 'erroredFeatures',
                features: {
                  [layerId]: toDelete.map(e => e.properties[idAttributeName]),
                },
              });
            }
          }
        }
      });
    },
    toggleZdmEditorNewObjects(metadata, features = []) {
      const { id, opacity, dataSourceName, visible, style, geometryType, labelsVisible, zIndex } = metadata;
      const vectorLayer = new VectorLayer({
        className: `ol-layer ol-layer-vector-${id}`,
        id,
        opacity,
        data_source_name: dataSourceName,
        visible,
        isSpecial: true,
        source: new VectorSource({
          format: new GeoJSON(),
          features: new GeoJSON().readFeatures(
            {
              features: features.map(feature => {
                return {
                  ...feature,
                  properties: { ...feature.properties, layerId: id, featureId: feature.metadata.id },
                };
              }),
              type: 'FeatureCollection',
              crs: { type: 'name', properties: { name: this.$_config.defaultEpsg } },
            },
            {
              dataProjection: this.$_config.defaultEpsg,
              featureProjection: this.$_config.defaultEpsg,
            }
          ),
        }),
        zIndex: 100 + zIndex,
        style: f => this.getFeatureStyle(f, style, geometryType, labelsVisible, id),
      });
      this.getLayerById('zdmEditorLayers')
        .getLayers()
        .push(vectorLayer);
    },
    setZdmEditLayerVisible({ layerId, value }) {
      const layer = this.getLayerById(layerId, 'zdmEditorLayers');
      if (layer) {
        layer.setVisible(value);
      }
    },
  },
  watch: {
    zdmEditorSession: {
      immediate: false,
      handler(nV, oV) {
        this[`${nV ? 'add' : 'delete'}ZdmEditorSession`](oV ? true : false);
      },
    },
  },
  mounted() {
    this.$root.$off('setZdmEditLayerVisible');
    this.$root.$on('setZdmEditLayerVisible', this.setZdmEditLayerVisible);
  },
};
