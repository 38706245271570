<template>
  <v-subheader v-on="{ ...listeners, ...(disabled ? {} : $listeners) }" class="text-caption pa-0" style="height: auto;">
    <a
      :class="[
        disabled && 'text--disabled',
        ...(!underlineOnlyOnHover || hovered ? ['text-decoration-underline'] : []),
        classes,
      ]"
      v-text="$i18n.t(translationPath)"
    >
    </a>
  </v-subheader>
</template>

<script>
export default {
  name: 'LinkUnderlined',
  props: {
    translationPath: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: 'link--text font-italic',
    },
    underlineOnlyOnHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...(this.underlineOnlyOnHover
          ? {
              mouseenter: () => {
                this.hovered = true;
              },
              mouseleave: () => {
                this.hovered = false;
              },
            }
          : {}),
      };
    },
  },
  data: () => ({
    hovered: false,
  }),
};
</script>

<style lang="scss" scoped>
.text--disabled {
  cursor: default !important;
}
</style>
