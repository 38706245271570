import api from '@/services/api';
import { make } from 'vuex-pathify';
import { arrayToObject, saveFileFromBackend } from '@/assets/js/utility';

const state = {
  profiles: {},
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getProfiles({ commit }) {
    const r = await api.get('smart_match/profiles');
    commit('SET_PROFILES', arrayToObject(r.data.data));
  },
  async addProfile({ dispatch }, payload) {
    await api.post('smart_match/profiles', payload);
    await dispatch('getProfiles');
  },
  async editProfile({ dispatch }, { id, payload }) {
    await api.put(`smart_match/profiles/${id}`, payload);
    await dispatch('getProfiles');
  },
  async deleteProfile({ dispatch }, id) {
    await api.delete(`smart_match/profiles/${id}`);
    await dispatch('getProfiles');
  },
  async exportProfile(store, id) {
    const r = await api.get(`smart_match/profile/${id}/export`, { timeout: 0, responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers);
  },
  async importProfile({ dispatch }, { dataSourceName, payload }) {
    await api.post(`smart_match/profile/${dataSourceName}/import`, payload);
    await dispatch('getProfiles');
  },
  async getProfileMappings(store, id) {
    const r = await api.get(`smart_match/mappings/${id}`);
    return r.data.data;
  },
  async editProfileMapping(store, { payload, id }) {
    await api.put(`smart_match/mappings/${id}`, payload);
  },
  async editProfileMappingAttribute(store, { payload, id }) {
    await api.put(`smart_match/mappings/${id}/attribute`, payload);
  },
  async deleteProfileMapping(store, id) {
    await api.delete(`smart_match/mappings/${id}`);
  },
  async addProfileMapping(store, { id, payload }) {
    await api.post(`smart_match/mappings/${id}`, payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
