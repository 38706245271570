import { get } from 'vuex-pathify';
export default {
  computed: {
    dmaDataSourceName: get('admin/modulesMapping@billing.dma_datasource_name'),
    dmaLayerId: get('admin/modulesMapping@billing.dma_layer_id'),
    watermetersDatasourceName: get('admin/modulesMapping@billing.watermeters_datasource_name'),
    layers: get('layers/layers'),
  },
  methods: {
    isTopologicalDataSource({ name: dataSourceName, scope: dataSourceScope } = {}) {
      return (
        (dataSourceScope === 'water' || dataSourceScope === 'sewer') &&
        (!this.dmaDataSourceName || dataSourceName !== this.dmaDataSourceName) &&
        (!this.watermetersDatasourceName || dataSourceName !== this.watermetersDatasourceName)
      );
    },
    isTopologicalLayer({ id: layerId, layer_scope: layerScope } = {}) {
      return (
        (layerScope === 'water' || layerScope === 'sewer') &&
        (!this.dmaLayerId || layerId !== this.dmaLayerId) &&
        (!this.watermetersDatasourceName || this.layers[layerId]?.data_source_name !== this.watermetersDatasourceName)
      );
    },
  },
};
