import { call, get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';
import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  computed: {
    easementsLayerId: get('admin/modulesMapping@transmission_easement.layer_id'),
    easementsDataSource: get('admin/modulesMapping@transmission_easement.datasource_name'),
    easementsLayer: get('layers/featuresLayers@:easementsLayerId'),
    easementsFeatures: get('layers/layersFeatures@:easementsLayerId'),
    layersFilters: get('layers/layersFilters'),
  },
  methods: {
    getLayerFeatures: call('layers/getLayerFeatures'),
    getFeaturesLayer: call('layers/getFeaturesLayer'),
    setCurrentStyle: call('styles/setCurrentStyle'),
    setDefaultStyle: call('styles/setDefaultStyle'),
    setLoaded: call('modules/setLoaded'),
    async toggleEasements(value) {
      if (value) {
        await Promise.all([
          this.getFeaturesLayer(this.easementsLayerId),
          this.getLayerFeatures({ layer_id: this.easementsLayerId }),
        ]);
        this.addEasementsLayer();
        this.toggleModuleIdentification({ isActive: true, moduleRoute: 'transmission_easement' });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        if (!this.projectLayers.find(layer => layer.id === this.easementsLayerId)) {
          this.removeProjectLayers([this.easementsLayerId]);
        }
        if (this.layersFilters[this.easementsLayerId]) {
          delete this.layersFilters[this.easementsLayerId];
        }
        this.setLoaded({ dataSource: this.easementsDataSource, value: false });
      }
    },
    toggleEasementsFilter() {
      this.removeProjectLayers([this.easementsLayerId]);
      this.addEasementsLayer();
    },
    addEasementsLayer() {
      this.removeProjectLayers([this.easementsLayerId]);
      const { data_source_name, id, type, group_id, name, geometry_type, style_web } = this.easementsLayer;
      const style = JSON.parse(JSON.stringify(style_web));

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.easementsLayer.id]
                ? this.layersFilters[this.easementsLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, geometry_type, true, id),
        renderMode: 'image',
      });
      const defaultStyle = {
        datasource: this.easementsDataSource,
        name: this.$i18n.t('default.defaultStyle'),
        isDefault: true,
        style: style,
      };
      this.setCurrentStyle({ dataSource: this.easementsDataSource, style: defaultStyle });
      this.setDefaultStyle({ dataSource: this.easementsDataSource, style: defaultStyle });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      this.setLoaded({ dataSource: this.easementsDataSource, value: true });
    },
    async setEasementStyle(style) {
      const { id, geometry_type } = this.easementsLayer;
      const layersArray = this.getLayerById('layers').getLayers().getArray();
      const layer = layersArray.find(layer => layer.get('id') === this.easementsLayerId);
      layer.setStyle(f => this.getFeatureStyle(f, style.style, geometry_type, false, id));
    },
  },
  mounted() {
    this.$root.$on('easementsStyleChanged', this.setEasementStyle);
  },
};
