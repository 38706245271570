import { get } from 'vuex-pathify';

import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';

export default {
  computed: {
    ilsSession: get('ils_easy/session'),
    ilsStreetLampInventoryDataSourceName: get('admin/modulesMapping@ils.datasource'),
    ilsNumberAttributeName: get('admin/modulesMapping@ils.settings.street_lamp_number_attribute'),

    ilsTreeviews: get('attributesSchema/treeviews'),
    ilsTreeviewId: get('admin/modulesMapping@ils.settings.tree_view_id'),
    ilsTreeview() {
      return this.ilsTreeviews.find(treeview => treeview.id === this.ilsTreeviewId);
    },
    ilsMainLayerId() {
      return this.ilsTreeview?.layer;
    },
  },
  methods: {
    clearIlsSessionObjects() {
      this.$store.set('map/DELETE_SELECTED_FEATURES!', {
        type: 'erroredFeatures',
        features: {
          [this.ilsMainLayerId]: [],
        },
      });
      const layer = this.getLayerById('ils_street_lamp');
      if (layer) layer.getSource().clear();
    },
    toggleIlsSessionObjects(session) {
      this.clearIlsSessionObjects();
      this.$nextTick(() => {
        const { insert: toAdd, delete: toDelete } = session;
        if (toAdd && toAdd.length) {
          const toAddGeoJson = toAdd.map(feature => {
            return { ...feature, id: feature.metadata.id };
          });
          this.toggleIlsSessionNewObjects(toAddGeoJson);
        }
        if (toDelete && toDelete.length) {
          this.$store.set('map/ADD_SELECTED_FEATURES!', {
            type: 'erroredFeatures',
            features: {
              [this.ilsMainLayerId]: toDelete.map(e => e.metadata.object_id),
            },
          });
        }
      });
    },
    toggleIlsSessionNewObjects(features) {
      const metadata = {
        id: 'ils_street_lamp',
        dataSourceName: this.ilsStreetLampInventoryDataSourceName,
        geometryType: 'point',
        labelsVisible: true,
        opacity: 1,
        style: {
          'circle-color': '#188038',
          'circle-radius': 5,
          'fill-opacity': 1,
          labels: {
            attributes: [this.ilsNumberAttributeName],
            'font-color': '#188038',
            'font-size': 14,
            'font-weight': 'bold',
            'offset-x': 0,
            'offset-y': -15,
            'stroke-color': '#FFFFFF',
            'stroke-visible': true,
            'stroke-width': 3,
          },
        },
        visible: true,
        zIndex: 4,
      };
      const layer = this.getLayerById('ils_street_lamp');
      const olFeatures = new GeoJSON().readFeatures(
        {
          features: features.map(feature => {
            return {
              ...feature,
              properties: { ...feature.properties, layerId: metadata.id, featureId: feature.metadata.id },
            };
          }),
          type: 'FeatureCollection',
          crs: { type: 'name', properties: { name: this.$_config.defaultEpsg } },
        },
        {
          dataProjection: this.$_config.defaultEpsg,
          featureProjection: this.$_config.defaultEpsg,
        }
      );
      if (layer) {
        layer.getSource().clear();
        layer.getSource().addFeatures(olFeatures);
      } else {
        this.addIlsSessionLayer(olFeatures, metadata);
      }
    },
    addIlsSessionLayer(features = [], metadata) {
      const { id, opacity, dataSourceName, visible, style, geometryType, labelsVisible, zIndex } = metadata;
      const vectorLayer = new VectorLayer({
        className: `ol-layer ol-layer-vector-${id}`,
        id,
        opacity,
        data_source_name: dataSourceName,
        visible,
        isSpecial: true,
        source: new VectorSource({
          format: new GeoJSON(),
          features,
        }),
        zIndex: 100 + zIndex,
        style: f => this.getFeatureStyle(f, style, geometryType, labelsVisible, id),
      });
      this.map.addLayer(vectorLayer);
    },
  },
  watch: {
    ilsSession: {
      handler(nV) {
        this.toggleIlsSessionObjects(nV);
      },
      deep: true,
    },
  },
};
