<template>
  <v-col style="padding: 0">
    <v-row no-gutters>
      <v-col cols="5">
        <component
          v-model="newListValue"
          :is="formatedDataType"
          :label="label"
          :placeholder="placeholder"
          :descriptors="descriptors.allowed_data_type"
        />
      </v-col>
      <v-col class="pt-2" cols="2">
        <v-btn fab small color="primary" class="mr-1" @click="addToList">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col style="height: 70px; overflow: auto" cols="5" v-if="value.length > 0">
        <v-subheader style="height: 20px" v-for="(v, idx) in value" :key="idx">
          <span style="overflow: hidden; max-width: 100%">
            {{ v | maxLength }}
            <v-icon @click="deleteFromList(v)" style="cursor:pointer" small>mdi-close</v-icon>
          </span>
        </v-subheader>
      </v-col>
      <!--
      <v-col cols="5" v-else>
        <v-subheader style="height: 20px">
          <span style="overflow: hidden; max-width: 100%">
            {{ placeholder | maxLength }}
          </span>
        </v-subheader>
      </v-col>
      -->
    </v-row>
  </v-col>
</template>
<script>
import { capitalize } from '@/assets/js/utility';

export default {
  name: 'DataInputList',
  data: () => ({
    newListValue: '',
  }),
  props: {
    descriptors: {
      required: false,
      type: Object,
    },
    label: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
  },
  methods: {
    addToList() {
      if (this.newListValue.toString().trim() && !this.value.includes(this.newListValue)) {
        this.value.push(this.newListValue);
      }
    },
    deleteFromList(v) {
      this.value.splice(this.value.indexOf(v), 1);
    },
  },
  filters: {
    maxLength: val => {
      if (val.length > 10) {
        return `${val.slice(0, 10)}...`;
      }
      return val;
    },
  },
  computed: {
    formatedDataType() {
      const componentName = `DataInput${capitalize(this.descriptors.allowed_data_type.name)}`;
      return () => import( /* @vite-ignore */ `@/components/${componentName}`);
    },
  },
};
</script>
