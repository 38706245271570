import { call, get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';
import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  computed: {
    foldersLayerId: get('admin/modulesMapping@folders.layer_id'),
    foldersDataSource: get('admin/modulesMapping@folders.datasource_name'),
    foldersLayer: get('layers/featuresLayers@:foldersLayerId'),
    foldersFeatures: get('layers/layersFeatures@:foldersLayerId'),
    layersFilters: get('layers/layersFilters'),
  },
  methods: {
    getLayerFeatures: call('layers/getLayerFeatures'),
    setCurrentStyle: call('styles/setCurrentStyle'),
    setDefaultStyle: call('styles/setDefaultStyle'),
    getFeaturesLayer: call('layers/getFeaturesLayer'),
    setLoaded: call('modules/setLoaded'),
    async toggleFolders(value) {
      if (value) {
        await Promise.all([
          this.getFeaturesLayer(this.foldersLayerId),
          this.getLayerFeatures({ layer_id: this.foldersLayerId }),
        ]);
        this.addFoldersLayer();
        this.toggleModuleIdentification({ isActive: true, moduleRoute: 'folder' });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        if (!this.projectLayers.find(layer => layer.id === this.foldersLayerId)) {
          this.removeProjectLayers([this.foldersLayerId]);
        }
        if (this.layersFilters[this.foldersLayerId]) {
          delete this.layersFilters[this.foldersLayerId];
        }
        this.setLoaded({ dataSource: this.foldersDataSource, value: false });
      }
    },
    toggleFoldersFilter() {
      this.removeProjectLayers([this.foldersLayerId]);
      this.addFoldersLayer();
    },
    addFoldersLayer() {
      this.removeProjectLayers([this.foldersLayerId]);
      const { data_source_name, id, type, group_id, name, geometry_type, style_web } = this.foldersLayer;
      const style = JSON.parse(JSON.stringify(style_web));

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.foldersLayer.id]
                ? this.layersFilters[this.foldersLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, geometry_type, true, id),
        renderMode: 'image',
      });
      const defaultStyle = {
        datasource: this.foldersDataSource,
        name: this.$i18n.t('default.defaultStyle'),
        isDefault: true,
        style: style,
      };
      this.setCurrentStyle({ dataSource: this.foldersDataSource, style: defaultStyle });
      this.setDefaultStyle({ dataSource: this.foldersDataSource, style: defaultStyle });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      this.setLoaded({ dataSource: this.foldersDataSource, value: true });
    },
    async setFolderStyle(style) {
      const { id, geometry_type } = this.foldersLayer;
      const layersArray = this.getLayerById('layers').getLayers().getArray();
      const layer = layersArray.find(layer => layer.get('id') === this.foldersLayerId);
      layer.setStyle(f => this.getFeatureStyle(f, style.style, geometry_type, false, id));
    },
  },
  mounted() {
    this.$root.$on('folderStyleChanged', this.setFolderStyle);
  },
};
