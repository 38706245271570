import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  suggestions: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getSuggestions({ commit }, { data_source_name, feature_id }) {
    const r = await api.get(`dataio/data_sources/${data_source_name}/features/${feature_id}/update_suggestions`);
    commit('SET_SUGGESTIONS', r.data.data);
  },
  async addSuggestion({ dispatch }, payload) {
    const { datasource, feature } = payload;
    await api.post('update_suggestions', payload);
    await dispatch('getSuggestions', { data_source_name: datasource, feature_id: feature });
  },
  async deleteSuggestion({ dispatch }, payload) {
    const { data_source_name, feature_id, suggestion_id } = payload;
    await api.delete(`update_suggestions/${suggestion_id}`);
    await dispatch('getSuggestions', { data_source_name, feature_id });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
