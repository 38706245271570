import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  reports: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getCustomReports({ commit }) {
    const r = await api.get('zdm_custom_reports');
    commit('SET_REPORTS', r.data.data);
  },
  async addCustomReport({ dispatch }, payload) {
    await api.post('zdm_custom_reports', payload);
    await dispatch('getCustomReports');
  },
  async editCustomReport({ dispatch }, payload) {
    const { id, data } = payload;
    await api.put(`zdm_custom_reports/${id}`, data);
    await dispatch('getCustomReports');
  },
  async deleteCustomReport({ dispatch }, id) {
    await api.delete(`zdm_custom_reports/${id}`);
    await dispatch('getCustomReports');
  },
  async validateQuery(store, report_id) {
    await api.post(`zdm_custom_reports/${report_id}/run`);
  },
  async getCustomReportId(store, { id, payload }) {
    const r = await api.post(`/zdm_custom_reports/${id}`, payload);
    return r.data.data.tid;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
