import { get } from 'vuex-pathify';

import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

export default {
  computed: {
    coordinatesEpsgs: get('map/coordinatesEpsgs'),
    arrayEpsg() {
      return Object.keys(this.coordinatesEpsgs).reduce((total, current) => {
        return [
          ...total,
          {
            text: this.coordinatesEpsgs[current].name,
            value: current,
            def: this.coordinatesEpsgs[current].proj4text,
            srid: this.coordinatesEpsgs[current].auth_srid,
          },
        ];
      }, []);
    },
  },
  methods: {
    registerCoordinateSystems() {
      this.arrayEpsg
        .filter(crs => crs.def)
        .forEach(crs => {
          proj4.defs(crs.value, crs.def);
        });
      register(proj4);
    },
  },
  mounted() {
    this.registerCoordinateSystems();
  },
};
