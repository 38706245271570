<template>
  <transition-group name="list" tag="div">
    <v-snackbar
      v-for="(snackbar, index) in latestSnackbars"
      :style="{ top: `${index * -55}px` }"
      :value="true"
      :key="snackbar.id"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      @input="onInput($event, index)"
      :elevation="2"
    >
      <span>
        {{ $i18n.te(`errors.${snackbar.message}`) ? $i18n.t(`errors.${snackbar.message}`) : snackbar.message }}
      </span>
      <template #action="{ attrs }">
        <v-btn v-if="snackbar.customActionButton" text v-bind="attrs" v-on="snackbar.customActionButton.listeners">
          <span>
            {{ snackbar.customActionButton.title }}
          </span>
        </v-btn>
        <v-btn :icon="snackbar.buttonType === 'icon'" text v-bind="attrs" @click="popSnackbar(index)">
          <v-icon v-if="snackbar.buttonType === 'icon'">mdi-close</v-icon>
          <span v-else>
            {{ $i18n.t('dialog.disagreeClose') }}
          </span>
        </v-btn>
      </template>
    </v-snackbar>
  </transition-group>
</template>

<script>
import { get } from 'vuex-pathify';

const MAX_SNACKBARS_AMOUNT = 3;

export default {
  name: 'TheSnackbar',
  data: () => ({
    snackbars: [],
  }),
  computed: {
    /**
     * Array of errors that occured during session.
     * @type {Object[]} errors
     * @param {string} errors[].message - Error message that is displayed in
     * snackbar.
     * @param {number} errors[].status - Error code (if the error is a
     * consequence of API request - currently not displayed but might be usefull someday).
     */
    errors: get('snackbar/errors'),
    successfullyMessages: get('snackbar/successfullyMessages'),
    messages: get('snackbar/messages'),
    latestSnackbars() {
      return this.snackbars.slice(0, MAX_SNACKBARS_AMOUNT);
    },
  },
  watch: {
    errors(nV) {
      this.pushSnackbar({ ...this.getLast(nV), color: this.$_colors.error });
    },
    successfullyMessages(nV) {
      this.pushSnackbar({ ...this.getLast(nV), color: this.$_colors.success });
    },
    messages(nV) {
      this.pushSnackbar(this.getLast(nV));
    },
  },
  methods: {
    getLast(nV) {
      return nV[nV.length - 1];
    },
    pushSnackbar({ message, buttonType = 'icon', color = this.$_colors.primary, timeout = 5000, customActionButton }) {
      this.snackbars.push({ message, buttonType, color, timeout, id: Date.now(), customActionButton });
    },
    popSnackbar(index) {
      this.snackbars.splice(index, 1);
    },
    onInput(isVisible, index) {
      if (isVisible) {
        return;
      }
      this.popSnackbar(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
}
.list-enter {
  transform: translateX(-30px);
}
.list-leave-to {
  transform: translateX(30px);
}
</style>
