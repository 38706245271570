<template>
  <component
    :is="textComponent"
    ref="input"
    v-bind="{ ...$attrs, ...(wrapText && { autoGrow: true, rows: 1, noResize: true }) }"
    :type="type"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    v-on="computedListeners"
    :counter="computedCounter"
    :hint="hint"
    persistent-hint
  >
    <template v-for="(value, key) in $scopedSlots" #[key]>
      <slot :name="key" />
    </template>
    <template #message="{message}">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
  </component>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';
import { VTextarea, VTextField } from 'vuetify/lib/components';

// let resizeObserver;

export default {
  name: 'DataInputText',
  components: {
    ItalicSubheader,
  },
  props: {
    descriptors: {
      required: false,
      type: Object,
    },
    label: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
    hint: {
      type: String,
      default: null,
    },
    disableNulling: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    wrapText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedListeners() {
      return {
        paste: e => this.onPaste(e),
        keydown: e => this.checkValue(e),
        'keydown.enter.prevent': () => this.$emit('agree'),
        ...this.$listeners,
        input: e => this.setValue('input', e),
        change: e => this.setValue('change', e),
      };
    },
    computedCounter() {
      if (
        !(this.hasAttribute(this.$attrs.readonly) || this.hasAttribute(this.$attrs.disabled)) &&
        this.descriptors &&
        this.descriptors.max_length
      ) {
        return this.descriptors.max_length;
      }
      return false;
    },
    textComponent() {
      return this.wrapText ? VTextarea : VTextField;
    },
  },
  methods: {
    /**
     * When we put an boolean attribute on component like this <v-text-field disabled>
     * this.$attrs.disabled is '' (empty string) and if statement returns false.
     */
    hasAttribute(attribute) {
      return attribute !== undefined && attribute !== false;
    },
    checkValue(e) {
      const { keyCode, ctrlKey, metaKey } = e;
      if ((ctrlKey || metaKey) && (keyCode === 86 || keyCode === 67 || keyCode === 65)) {
        return;
      }
      const allowedKeyCodes = [8, 9, 37, 39];
      if (allowedKeyCodes.includes(e.keyCode)) {
        return;
      } else if (e.target.selectionStart !== e.target.selectionEnd) {
        return;
      } else if (this.descriptors.max_length) {
        if ((this.value ? this.value.length : 0) >= this.descriptors.max_length) {
          e.preventDefault();
          return;
        }
      }
    },
    setValue(type, v) {
      this.$emit(type, !v ? (!this.disableNulling ? null : '') : v);
    },
    // onInputResize(entries) {
    //   const textareaWidth = entries[0].target.offsetWidth;
    //   if (textareaWidth !== this.textareaWidth) {
    //     this.textareaWidth = textareaWidth;
    //     this.$refs.input && this.$refs.input.calculateInputHeight();
    //   }
    // },
    onPaste(e) {
      if (
        this.descriptors.max_length &&
        e.clipboardData.getData('text').length +
          (this.value ? this.value.length : 0) -
          (e.target.selectionEnd - e.target.selectionStart) >
          this.descriptors.max_length
      ) {
        alert('Wklejana wartość jest zbyt długa');
        e.preventDefault();
        return;
      }
    },
  },
  // mounted() {
  //   if (!this.wrapText) return;
  /**
   * Vuetify 2 have a problem with auto-growing on window being resized.
   * Possible fix was abandoned in v2 and moved into v3 milestones (https://github.com/vuetifyjs/vuetify/issues/6995).
   * Here's ported ResizeObserver solution from Vuetify 3, restricted to width-check only
   * https://github.com/vuetifyjs/vuetify/pull/14175/commits/1f20adf4a1ef1c34de355aa0184cbaf9f03a9c2a#diff-012db834b8c84cd8afe3d2bdef69238c7b2177a3cbf633c36d261c60e4dc8dceR123-R129
   */
  //   this.$nextTick(() => {
  //     resizeObserver = new ResizeObserver(this.onInputResize);
  //     resizeObserver.observe(this.$refs.input.$el);
  //   });
  // },
  // beforeDestroy() {
  //   resizeObserver?.disconnect();
  // },
};
</script>
