import api from '@/services/api';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';

const state = {
  dataAccessRestrictions: {},
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getDataAccessRestrictions({ commit }) {
    const r = await api.get('users/groups/datasources');
    commit('SET_DATA_ACCESS_RESTRICTIONS', arrayToObject(r.data.data));
  },
  async addDataAccessRestriction({ dispatch }, payload) {
    await api.post('users/groups/datasources', payload);
    await dispatch('getDataAccessRestrictions');
  },
  async editDataAccessRestriction({ dispatch }, { group_id, datasource, payload }) {
    await api.put(`users/groups/${datasource}/${group_id}`, payload);
    await dispatch('getDataAccessRestrictions');
  },
  async deleteDataAccessRestriction({ dispatch }, { group_id, datasource }) {
    await api.delete(`users/groups/${datasource}/${group_id}`);
    await dispatch('getDataAccessRestrictions');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
