<template>
  <v-tooltip bottom color="font" :disabled="isTooltipDisabled">
    <template #activator="{ on, attrs}">
      <v-hover #default="{hover}" :disabled="$attrs.disabled">
        <span v-on="on">
          <v-btn
            :color="color"
            :class="{
              'elevation-0': !hover || !hasShadowEffect,
              'elevation-4': hover && hasShadowEffect,
              border: hasBorder,
              'mx-1': !disableDefaultMargin,
            }"
            v-on="listeners"
            v-bind="{ ...buttonSize, ...$attrs, attrs }"
            fab
            :dark="!$attrs.disabled"
          >
            <template v-if="innerText">
              <span :style="{ color: iconColor }" :class="innerTextClasses.join(' ')">{{ innerText }}</span>
            </template>
            <v-icon :color="iconColor" :size="iconSize" :style="computedIconColor">
              {{ icon }}
            </v-icon>
            <template #loader>
              <main-loader :color="loaderColor" :size="loaderSize" />
            </template>
          </v-btn>
        </span>
      </v-hover>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
import { get } from 'vuex-pathify';
import MainLoader from '@/components/MainLoader.vue';
export default {
  name: 'BaseRoundedButton',
  components: { MainLoader },
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    innerText: {
      type: String,
      default: '',
    },
    innerTextClasses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },

    /**
     * Custom width and height in pixels.
     * Overrides size prop.
     */
    customSize: {
      type: Number,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'white',
    },
    tooltip: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
    },
    hasShadowEffect: {
      type: Boolean,
      default: true,
    },
    loaderColor: {
      type: String,
      default: 'primary',
    },
    loaderSize: {
      type: Number,
      default: 24,
    },
    disableDefaultMargin: {
      type: Boolean,
      default: false,
    },
    disableAutoFocusEnterAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEnterPressed: get('tools/isPressed@enter'),
    buttonSize() {
      if (this.customSize) {
        return {
          width: this.customSize,
          height: this.customSize,
        };
      }
      return {
        [this.size]: true,
      };
    },
    isTooltipDisabled() {
      return !this.tooltip;
    },
    computedIconColor() {
      return {
        '--icon-color': this.$_colors[this.iconColor] || this.iconColor,
      };
    },
    listeners() {
      return this.disableAutoFocusEnterAction
        ? {
            ...this.$listeners,
            ...(this.$listeners.click
              ? {
                  click: () => {
                    if (this.isEnterPressed) return;
                    else this.$listeners.click();
                  },
                }
              : {}),
          }
        : this.$listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  filter: brightness(105%);
}
.v-icon--is-component {
  height: unset;
  width: unset;
}
.v-btn--icon.v-size--x-small .v-icon,
.v-btn--fab.v-size--x-small .v-icon {
  height: unset;
  width: unset;
}
::v-deep {
  .v-icon {
    fill: var(--icon-color) !important;
  }
  .border {
    border: 0.5px solid rgba(0, 0, 0, 0.18) !important;
  }
}
</style>

<style lang="scss">
.v-icon__component {
  height: auto !important;
  width: auto !important;
  fill: currentColor !important;
  // path {
  // fill: currentColor !important;
  // }
}
</style>
