import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {};

const mutations = make.mutations(state);

const actions = {
  async migrateAntennaBearingHistoryData(store, { params } = {}) {
    return await api.get('antenna_bearings_history/migrate', { params });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
