import { get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';
import { scaleLinear } from 'd3-scale';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  data: () => ({
    isWaterDissolutionVisualisation: false,
    isLegalizationVisualisation: false,
    isBillingAnomalyVisualisation: false,
    billingAnomalyRanges: [],
    watermetersRouteNames: {
      waterDissolutionWatermeters: 'waterDissolutionWatermetersInfo',
      waterDissolutionWatermetersInfo: 'waterDissolutionWatermetersInfo',
      legalization: 'legalizationInfo',
      legalizationInfo: 'legalizationInfo',
      billingAnomalyDetection: 'billingAnomalyDetectionInfo',
      billingAnomalyDetectionInfo: 'billingAnomalyDetectionInfo',
    },
  }),
  computed: {
    minMaxWaterConsumptionValues: get('billings/minMaxWaterConsumptionValues'),
    dayAverageUsageAttributeName: get(
      'admin/modulesMapping@billing.watermeters_datasource_attributes_mapping.day_average_usage_attribute_name'
    ),
    legalizationStatusAttributeName: get(
      'admin/modulesMapping@billing.watermeters_datasource_attributes_mapping.legalization_status_attribute_name'
    ),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    watermetersDataSource: get('admin/modulesMapping@billing.watermeters_datasource_name'),
    watermetersLayerId: get('admin/modulesMapping@billing.layer_id'),
    watermetersLayer: get('layers/featuresLayers@:watermetersLayerId'),
    layersFilters: get('layers/layersFilters'),
    legalizationStatusesTranslations: get('admin/modulesMapping@billing.legalization_status_mapping'),
    legalizationStatusesColors() {
      return {
        before_due: 'rgba(24,128,56,1)',
        soon_past_due: 'rgba(255,136,0,1)',
        past_due: 'rgba(191,54,12,1)',
        no_data: 'rgba(108,108,108,1)',
      };
    },
  },
  methods: {
    async toggleWatermeters(value) {
      if (value) {
        await this.getFeaturesLayer(this.watermetersLayerId);
        this.addWatermetersLayer();
        this.$root.$emit('watermetersFetched');
        this.toggleModuleIdentification({ isActive: true, moduleRoute: this.watermetersRouteNames[this.$route.name] });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        const projectWatermeters = this.projectLayers.find(layer => layer.id === this.watermetersLayerId);
        if (projectWatermeters && projectWatermeters.visible) {
          const layer = this.getLayerById(this.watermetersLayerId, 'layers');
          layer.set('isSpecial', false);
          layer.setStyle(feature => {
            return this.getFeatureStyle(
              feature,
              projectWatermeters.style,
              this.watermetersLayer.geometry_type,
              true,
              this.watermetersLayerId
            );
          });
        } else {
          this.removeProjectLayers([this.watermetersLayerId]);
          if (this.layersFilters[this.watermetersLayerId]) {
            delete this.layersFilters[this.watermetersLayerId];
          }
        }
        this.isWaterDissolutionVisualisation = false;
        this.isLegalizationVisualisation = false;
        this.isBillingAnomalyVisualisation = false;
        this.billingAnomalyRanges = [];
      }
    },
    toggleWatermetersFilter() {
      this.removeProjectLayers([this.watermetersLayerId]);
      this.addWatermetersLayer();
      this.toggleModuleIdentification({ isActive: true, moduleRoute: this.watermetersRouteNames[this.$route.name] });
    },
    addWatermetersLayer() {
      if (!this.watermetersLayer) {
        return;
      }
      this.removeProjectLayers([this.watermetersLayerId]);
      const { data_source_name, id, type, group_id, name } = this.watermetersLayer;
      let style = {};
      const projectWatermeters = this.projectLayers.find(layer => layer.id === this.watermetersLayerId);
      if (projectWatermeters) {
        style = projectWatermeters.style;
      } else {
        style = JSON.parse(JSON.stringify(this.watermetersLayer.style_web));
        style.minzoom = 1;
        style.labels = { attributes: [] };
      }

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,

          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.watermetersLayer.id]
                ? this.layersFilters[this.watermetersLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, this.watermetersLayer.geometry_type, true, id),
        renderMode: 'image',
      });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      if (this.isWaterDissolutionVisualisation) {
        this.toggleWaterDissolutionVisualisation(true);
      }
      if (this.isLegalizationVisualisation) {
        this.toggleLegalizationVisualisation(true);
      }
      if (this.isBillingAnomalyVisualisation) {
        this.toggleBillingAnomalyVisualisation({ value: true, ranges: this.billingAnomalyRanges });
      }
    },
    getWaterDissolutionVisualisationRanges(numberOfClasses = 5, rampColor = '#006D2C', rampColors = []) {
      let colors = [];
      if (rampColors && rampColors.length > 0) {
        numberOfClasses = rampColors.length;
        const classesNumbers = Array.from({ length: 5 }, (v, k) => k + 2);
        colors = scaleLinear().domain(classesNumbers).range(rampColors);
      } else {
        colors = scaleLinear()
          .domain([1, numberOfClasses + 1])
          .range(['white', rampColor]);
      }
      const values = scaleLinear()
        .domain([1, numberOfClasses + 1])
        .range([this.minMaxWaterConsumptionValues.min, this.minMaxWaterConsumptionValues.max]);
      const rangesColors = [];
      for (let idx = 2; idx < numberOfClasses + 2; idx++) {
        rangesColors.push(colors(idx));
      }
      const ranges = [];
      for (let idx = 2; idx < numberOfClasses + 1; idx++) {
        const roundValue = Math.round(values(idx) * 100) / 100;
        ranges.push(roundValue);
      }
      return { ranges, rangesColors };
    },
    toggleLegalizationVisualisation(value) {
      const layer = this.getLayerById(this.watermetersLayerId, 'layers');
      if (!layer) {
        return;
      }
      if (value) {
        const values = {};
        for (const status in this.legalizationStatusesTranslations) {
          values[this.legalizationStatusesTranslations[status]] = {
            'circle-color': this.legalizationStatusesColors[status],
            'circle-radius': 3,
            'fill-opacity': 1,
            labels: [],
            value: this.legalizationStatusesTranslations[status],
          };
        }
        const style = {
          'circle-color': '#c7c7c7',
          'circle-radius': 3,
          'fill-opactity': 1,
          labels: [],
          uniques: {
            property: this.legalizationStatusAttributeName,
            values,
          },
        };
        this.setProjectLayerStyle(this.watermetersLayerId, { style, forceLayerData: this.watermetersLayer });
        this.$root.$emit('updateLegalizationVisualisationMapLegend');
        this.isWaterDissolutionVisualisation = false;
        this.isBillingAnomalyVisualisation = false;
        this.billingAnomalyRanges = [];
      } else {
        const projectWatermeters = this.projectLayers.find(layer => layer.id === this.watermetersLayerId);
        if (!projectWatermeters) {
          this.removeProjectLayers([this.watermetersLayerId]);
          return;
        }
        const style = JSON.parse(JSON.stringify(this.watermetersLayer.style_web));
        style.minzoom = 1;
        style.labels = { attributes: [] };
        this.setProjectLayerStyle(this.watermetersLayerId);
      }
      this.isLegalizationVisualisation = value;
    },
    toggleBillingAnomalyVisualisation({ value, ranges }) {
      const layer = this.getLayerById(this.watermetersLayerId, 'layers');
      if (!layer) {
        return;
      }
      if (value) {
        const style = {
          'circle-color': '#bababa',
          'circle-radius': 5,
          'fill-opacity': 1,
          'fill-outline-color': '#ffffff',
          'fill-outline-opacity': 1,
          'fill-outline-width': 1,
          ranges: {
            values: ranges.map(range => {
              const { color, size, minValue, maxValue } = range;
              return {
                'circle-color': color,
                'circle-radius': size,
                'fill-opacity': 1,
                'fill-outline-color': '#ffffff',
                'fill-outline-opacity': 1,
                'fill-outline-width': 1,
                labels: {},
                'max-value': maxValue,
                'min-value': minValue,
              };
            }),
            property: this.dayAverageUsageAttributeName,
          },
        };
        this.setProjectLayerStyle(this.watermetersLayerId, { style, forceLayerData: this.watermetersLayer });
        this.isWaterDissolutionVisualisation = false;
        this.isLegalizationVisualisation = false;
      } else {
        const projectWatermeters = this.projectLayers.find(layer => layer.id === this.watermetersLayerId);
        if (!projectWatermeters) {
          this.removeProjectLayers([this.watermetersLayerId]);
          return;
        }
        const style = JSON.parse(JSON.stringify(this.watermetersLayer.style_web));
        style.minzoom = 1;
        style.labels = { attributes: [] };
        this.setProjectLayerStyle(this.watermetersLayerId);
      }
      this.isBillingAnomalyVisualisation = value;
      this.billingAnomalyRanges = ranges;
    },
    toggleWaterDissolutionVisualisation(value) {
      const layer = this.getLayerById(this.watermetersLayerId, 'layers');
      if (!layer) {
        return;
      }
      if (value) {
        const customColorsRamp = ['#440154', '#3A528B', '#20908D', '#5DC962', '#FDE725'];
        const { ranges, rangesColors } = this.getWaterDissolutionVisualisationRanges(5, 'red', customColorsRamp);
        const styleRanges = rangesColors
          .map((color, index) => {
            if (index === 0) {
              return { minValue: this.minMaxWaterConsumptionValues.min, maxValue: ranges[index], color };
            } else if (index === ranges.length) {
              return { minValue: ranges[index - 1], maxValue: this.minMaxWaterConsumptionValues.max, color };
            } else {
              return { minValue: ranges[index - 1], maxValue: ranges[index], color };
            }
          })
          .map(range => {
            const { color, minValue, maxValue } = range;
            return {
              'circle-color': color,
              'circle-radius': 5,
              'fill-opacity': 1,
              'fill-outline-color': 'white',
              'fill-outline-opacity': 1,
              'fill-outline-width': 1.5,
              labels: {},
              'max-value': maxValue,
              'min-value': minValue,
            };
          });
        const style = {
          'circle-color': '#bababa',
          'circle-radius': 5,
          'fill-opacity': 1,
          'fill-outline-color': '#ffffff',
          'fill-outline-opacity': 1,
          'fill-outline-width': 1,
          ranges: {
            values: styleRanges,
            property: this.dayAverageUsageAttributeName,
          },
        };
        const data = {
          ranges,
          rangesColors,
          name: this.dayAverageUsageAttributeName,
        };
        this.setProjectLayerStyle(this.watermetersLayerId, { style, forceLayerData: this.watermetersLayer });
        this.$root.$emit('updateWaterDissolutionVisualisationMapLegend', data);
        this.isLegalizationVisualisation = false;
        this.isBillingAnomalyVisualisation = false;
        this.billingAnomalyRanges = [];
      } else {
        const projectWatermeters = this.projectLayers.find(layer => layer.id === this.watermetersLayerId);
        if (!projectWatermeters) {
          this.removeProjectLayers([this.watermetersLayerId]);
          return;
        }
        const style = JSON.parse(JSON.stringify(this.watermetersLayer.style_web));
        style.minzoom = 1;
        style.labels = { attributes: [] };
        this.setProjectLayerStyle(this.watermetersLayerId);
      }
      this.isWaterDissolutionVisualisation = value;
    },
  },
  mounted() {
    this.$root.$on('toggleWatermeters', this.toggleWatermeters);
    this.$root.$on('toggleWatermetersFilter', this.toggleWatermetersFilter);
    this.$root.$on('toggleWaterDissolutionVisualisation', this.toggleWaterDissolutionVisualisation);
    this.$root.$on('toggleLegalizationVisualisation', this.toggleLegalizationVisualisation);
    this.$root.$on('toggleBillingAnomalyVisualisation', this.toggleBillingAnomalyVisualisation);
  },
};
