<template>
  <v-row no-gutters :class="{ 'fill-height': fillHeight }" :style="`${fillWidth ? 'width: 100%' : ''}`">
    <v-col cols="12" :class="{ 'fill-height': fillHeight }">
      <v-row no-gutters v-if="!hideHeader">
        <v-col :cols="hasTabsTools ? 12 - tabsToolsCols : 12">
          <v-tabs
            v-if="tabsVisible"
            :value="value"
            @change="$emit('change', $event)"
            hide-slider
            v-bind="$attrs"
            :class="tabsClasses.join(' ')"
          >
            <v-tooltip bottom color="font" v-for="(tab, index) in tabs" :key="index" :disabled="!tab.disabled">
              <template #activator="{ on }">
                <div v-on="on" class="extra-div" :class="{ border: hasBorder }">
                  <v-tab class="text-body-2 font--text font-weight-regular" :disabled="tab.disabled">
                    {{ tab.name }}
                  </v-tab>
                </div>
              </template>
              {{ $i18n.t('snackbar.toolNotActive') }}
            </v-tooltip>
          </v-tabs>
        </v-col>
        <v-col :cols="tabsToolsCols" v-if="hasTabsTools">
          <slot name="tabsTools"> </slot>
        </v-col>
      </v-row>
      <slot name="top"> </slot>
      <v-tabs-items :value="value">
        <slot> </slot>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'GreyTabs',
  inheritAttrs: false,
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    fillWidth: {
      type: Boolean,
      default: false,
    },
    tabsClasses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasTabsTools: {
      type: Boolean,
      default: false,
    },
    tabsToolsCols: {
      type: [Number, String],
      default: 2,
    },
    tabsVisible: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// .border {
//   @include border();
// }
.extra-div {
  display: flex;
  height: auto;
  box-sizing: content-box !important;
}
.extra-div:first-of-type.border {
  @include border(left);
}
.extra-div:last-of-type.border {
  @include border(right);
}

::v-deep {
  .v-tab {
    text-transform: none;
    letter-spacing: 0.0892857143em;
    @include border(top);
    @include border(bottom);
  }
  .v-tab--active {
    background-color: rgba(207, 207, 207, 0.25);
    font-weight: 500 !important;
  }
  .v-window-item {
    padding-top: 20px;
  }
}
</style>
