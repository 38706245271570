import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

Vue.use(Vuex);

function getImportedModules(r) {
  return Object.entries(r).reduce((modules, [path, module]) => {
    modules[
      path
        .split(/[\\/]/)
        .pop()
        .replace(/\.[^.]+$/, '')
    ] = module.default;
    return modules;
  }, {});
}

const mainImportedModules = import.meta.glob('@/store/*.js', { eager: true });
let mainStores = getImportedModules(mainImportedModules);
/**
 * V2 stores only work with VUE_APP_API_V2_URL env variable provided with VUE_APP_USE_V2 set to true.
 *
 * A loop below assumes that there is at least one action in V2 store.
 * Every V2 store must have the name of a existing store in current modules set.
 * If an existing action from any store is supposed to be overwritten,
 * new V2 action must have it's exact same name and be placed in a store with corresponding name.
 * e.g. store/v2/layers/getTile will overwrite store/layers/getTile action.
 *
 * Custom mutations are also supported on demand.
 */
if (import.meta.env.VUE_APP_USE_V2 === 'true' && import.meta.env.VUE_APP_API_V2_URL) {
  const v2ImportedModules = import.meta.glob('@/store/v2/*.js', { eager: true });
  const v2Stores = getImportedModules(v2ImportedModules);
  Object.entries(v2Stores).forEach(([storeName, moduleData]) => {
    let mainModuleData = mainStores[storeName];
    if (!mainModuleData) return;
    mainStores[storeName] = {
      ...mainModuleData,
      actions: {
        ...mainModuleData.actions,
        ...moduleData.actions,
      },
      ...(moduleData.mutations && { mutations: { ...mainModuleData.mutations, ...moduleData.mutations } }),
    };
  });
}

export default new Vuex.Store({
  plugins: [pathify.plugin],
  strict: import.meta.env.MODE !== 'production',
  modules: mainStores,
});
