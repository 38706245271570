import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  locksAvailablePhoneNumbers: null,
  locksMessageSchema: '',
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getLocksMessageSchema({ commit }) {
    const r = await api.get(`failures/sms_notifications/message_template`);
    commit('SET_LOCKS_MESSAGE_SCHEMA', r.data.data);
  },
  async getLocksPhoneNumbers({ commit }, data) {
    const r = await api.post(`failures/sms_notifications/available_phone_numbers`, data);
    commit('SET_LOCKS_AVAILABLE_PHONE_NUMBERS', r.data.data.available_phone_numers_count);
  },
  //eslint-disable-next-line
  async sendLocksSmsMessage({ commit }, data) {
    await api.post('failures/sms_notifications', data);
  },
  //eslint-disable-next-line
  async sendTestSms({ commit }, data) {
    await api.post(`sms/test`, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
