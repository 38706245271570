<template>
  <v-subheader
    class="text-caption font-italic font-weight-light pa-0"
    style="height: auto; line-height: 1rem !important; color: inherit; white-space: pre-wrap"
  >
    <slot>{{ computedText }}</slot>
  </v-subheader>
</template>

<script>
export default {
  name: 'ItalicSubheader',
  props: {
    translationPath: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    straightText: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedText() {
      if (!this.$i18n.te(this.translationPath) && !this.straightText) {
        return this.translationPath;
      }
      return this.straightText ? this.straightText : this.$i18n.t(this.translationPath, this.params);
    },
  },
};
</script>

<style lang="scss" scoped></style>
