<template>
  <main-large-dialog
    v-bind="{ ...$props, ...$attrs }"
    :is-visible.sync="computedIsVisible"
    :title="titleTranslation"
    :header-shadow="true"
    :is-buttons-right="isButtonsRight"
    :dialog-width="dialogWidth"
    v-on="$listeners"
    :is-valid="computedIsValid"
    @modalClosed="onClose"
  >
    <template #body>
      <slot name="body">
        <div class="flex w-full" :class="{ 'mb-10': !disableDefaultFormBottomPadding }">
          <slot name="upperForm"> </slot>
          <v-row no-gutters>
            <v-col :class="{ 'pb-5': !disableDefaultFormBottomPadding }">
              <v-form ref="form" v-model="isValid" @submit.prevent="onSubmit" :autocomplete="autocomplete">
                <main-input-dialog-row
                  v-for="input in inputs"
                  v-bind="input"
                  :key="input.name"
                  :value="input.value"
                  @input="emitValue($event, input.name)"
                >
                  <template v-for="(value, key) in $scopedSlots" #[key]="scope">
                    <slot :name="key" v-bind="scope" />
                  </template>
                </main-input-dialog-row>
              </v-form>
            </v-col>
          </v-row>
          <slot name="lowerForm" :isFormValid="isValid"> </slot>
          <slot name="info" />
        </div>
      </slot>
    </template>
    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </main-large-dialog>
</template>

<script>
import MainInputDialogRow from '@/components/MainInputDialogRow';
import MainLargeDialog from '@/components/MainLargeDialog';

export default {
  name: 'MainInputDialog',
  components: {
    MainLargeDialog,
    MainInputDialogRow,
  },
  props: {
    isButtonsRight: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    inputs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    agreeText: {
      type: String,
      required: false,
    },
    disagreeText: {
      type: String,
      required: false,
    },
    titleTranslation: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: String,
      default: '27%',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    resetOnClose: {
      type: Boolean,
      default: true,
    },
    disableDefaultFormBottomPadding: {
      type: Boolean,
      default: false,
    },
    customIsValid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    values: {},
    isValid: false,
  }),
  computed: {
    computedIsVisible: {
      get() {
        return this.isVisible;
      },
      set(nV) {
        this.$emit('update:isVisible', nV);
      },
    },
    computedIsValid() {
      return this.isValid && this.customIsValid;
    },
  },
  watch: {
    isVisible() {
      this.$refs.form?.resetValidation();
    },
  },
  methods: {
    onClose() {
      this.$emit('modalClosed');
      if (this.resetOnClose) {
        this.$refs.form?.reset();
      }
    },
    onSubmit() {
      if (!this.computedIsValid) {
        return;
      }
      this.$emit('agree');
    },
    emitValue(value, name) {
      this.inputs.find(i => i.name === name).value = value;
    },
  },
};
</script>
