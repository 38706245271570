<template>
  <v-row no-gutters>
    <v-col style="width: 100%" :class="{ 'pt-4': !title }">
      <v-row no-gutters v-if="title" class="pl-4">
        <card-title-row
          is-subtitle
          :straight-title="title"
          :has-buttons="false"
          :has-icon-column="false"
          :title-classes="['text-caption', 'font-weight-medium', 'text--secondary']"
        />
      </v-row>
      <v-row no-gutters v-if="hasCountingChips" style="position: relative">
        <v-col class="slider-counting-label" :style="sliderCountingLabelStyle('left')">
          <v-tooltip bottom color="font">
            <template #activator="{ on }">
              <v-chip
                label
                color="rgba(0,0,0,0.08)"
                text-color="font"
                class="px-2 text-body-2 x-small"
                style="height: 24px"
                v-on="on"
              >
                {{ countingLeft }}
              </v-chip>
            </template>
            {{ $i18n.t('sidebar.numberOfFeaturesInRange') }}
          </v-tooltip>
        </v-col>
        <v-col class="slider-counting-label" :style="sliderCountingLabelStyle('right')">
          <v-tooltip bottom color="font">
            <template #activator="{ on }">
              <v-chip
                label
                color="rgba(0,0,0,0.08)"
                text-color="font"
                class="px-2 text-body-2 x-small"
                style="height: 24px"
                v-on="on"
              >
                {{ countingRight }}
              </v-chip>
            </template>
            {{ $i18n.t('sidebar.numberOfFeaturesInRange') }}
          </v-tooltip>
        </v-col>
      </v-row>
      <v-slider v-bind="$attrs" v-model="computedValue">
        <template v-for="(value, key) in $scopedSlots" #[key]>
          <slot :name="key" />
        </template>
      </v-slider>
      <v-row no-gutters v-if="hasTumbLabel" style="position: relative">
        <v-col class="text-body-2 text-left slider-minmax-label">
          {{ $attrs.min.toFixed(2) }}
        </v-col>
        <v-col class="slider-thumb-label" :style="sliderThumbLabelStyle">
          <data-input v-bind="sliderThumbLabelInput" :value="computedValue.toFixed(2)" @change="updateTumbValue" />
        </v-col>
        <v-col class="text-body-2 text-right slider-minmax-label">
          {{ $attrs.max.toFixed(2) }}
        </v-col>
      </v-row>
    </v-col>
    <slot name="valueLabel" v-if="!hideValueLabel">
      <v-col
        cols="2"
        class="value-label pl-0 text-center"
        :class="{ 'pt-6': !title }"
        :align-self="`${title ? 'center' : 'auto'}`"
      >
        {{ label }}
      </v-col>
    </slot>
  </v-row>
</template>
<script>
import CardTitleRow from '@/components/CardTitleRow';
export default {
  name: 'DataInputSlider',
  components: {
    CardTitleRow,
    DataInput: () => import('@/components/DataInput'),
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    countingLeft: {
      type: [Number, String],
      default: 0,
    },
    countingRight: {
      type: [Number, String],
      default: 0,
    },
    hasCountingChips: {
      type: Boolean,
      default: false,
    },
    hasTumbLabel: {
      type: Boolean,
      default: false,
    },
    hideValueLabel: {
      type: Boolean,
      default: false,
    },
    isPercentageLabel: {
      type: Boolean,
      default: false,
    },
    straightTitle: {
      type: String,
    },
    titleTranslationPath: {
      type: String,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    label() {
      return this.isPercentageLabel ? `${Math.round(this.computedValue * 100)}%` : this.computedValue;
    },
    sliderThumbLabelStyle() {
      const perc = parseFloat(Math.abs(this.computedValue) / Math.abs(this.$attrs.max - this.$attrs.min)).toFixed(2);
      const marginPerc = Math.abs(0.5 - perc);
      if (perc < 0.5) {
        return `left: calc(${perc * 100}% + ${40 * marginPerc - 35}px)`;
      } else if (perc > 0.5) {
        return `left: calc(${perc * 100}% - ${40 * marginPerc + 35}px)`;
      } else {
        return `left: calc(${perc * 100}% - 35px`;
      }
    },
    title() {
      return this.straightTitle || this.$i18n.t(this.titleTranslationPath) || '';
    },
  },
  data: () => ({
    sliderThumbLabelInput: {
      dataType: { name: 'float' },
    },
  }),
  methods: {
    sliderCountingLabelStyle(side = 'left') {
      const perc = parseFloat(Math.abs(this.computedValue) / Math.abs(this.$attrs.max - this.$attrs.min)).toFixed(2);
      const marginPerc = Math.abs(0.5 - perc);
      if (side === 'left') {
        return `${side}: calc(${perc * 50}% + ${40 * marginPerc - 35}px)`;
      } else {
        return `${side}: calc(${(1 - perc) * 50}% + ${40 * marginPerc - 35}px)`;
      }
    },
    updateTumbValue(value) {
      if (value >= this.$attrs.min && value <= this.$attrs.max) {
        this.computedValue = parseFloat(value);
      } else if (value < this.$attrs.min) {
        this.computedValue = this.$attrs.min;
      } else if (value > this.$attrs.max) {
        this.computedValue = this.$attrs.max;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.min-max-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #202124;
}
.slider-minmax-label {
  position: relative;
  top: -10px;
}
.slider-thumb-label {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  position: absolute;
  top: -6px;
  z-index: 1;
  background-color: white;
}
.slider-counting-label {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  position: absolute;
  z-index: 1;
  top: -20px;
}
.value-label {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
}
</style>
