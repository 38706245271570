import Vue from 'vue';
import { make } from 'vuex-pathify';

const state = {
  loaded: {},
};

const mutations = {
  ...make.mutations(state),
  SET_LOADED: (state, payload) => {
    const { dataSource, value } = payload;
    Vue.set(state.loaded, dataSource, value);
  },
};

const actions = {
  setLoaded({ commit }, payload) {
    commit('SET_LOADED', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
