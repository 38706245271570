<template>
  <v-list style="background-color: rgba(255,255,255,0)">
    <template v-for="(style, index) of computedStyles">
      <v-list-item class="px-0" :key="style.key" style="min-height: 0px">
        <v-list-item-action class="mx-0 my-0 mr-1">
          <v-row no-gutters class="flex-nowrap" align="center">
            <v-col cols="auto">
              <v-checkbox
                :input-value="style.visible"
                :disabled="!layerVisible"
                @click="changeStyleClassVisible($event, index, style)"
              />
            </v-col>
            <v-col cols="auto" class="pr-1">
              <layer-style-preview-icon
                is-icon
                :layer-id="layerId"
                :layer-type="layerType"
                :layer-style="style"
                :layer-style-index="index"
                has-color-picker
              />
            </v-col>
          </v-row>
        </v-list-item-action>
        <v-list-item-title style="text-align: left" class="text-body-2" :class="{ 'text--disabled': !hasPermission }">
          <v-row no-gutters>
            <v-col cols="auto" class="pr-1 text-wrap">
              {{ style.key }}
            </v-col>
            <v-col
              v-if="legendStylesCounts[layerId]"
              cols="auto"
              class="font-italic font-weight-light italic--text pr-1"
            >
              {{ `[${style.count || 0}]` }}
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :key="style.key + '-collapse'"
        class="px-0"
        style="max-height: 30px; min-height: 0px"
        v-if="isExpandedVisible && index === computedStyles.length - 1"
      >
        <v-list-item-action class="mx-0 my-0 mr-1">
          <link-underlined @click.stop="isExpanded = !isExpanded" :translation-path="textPath"> </link-underlined>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { get } from 'vuex-pathify';
import { debounce } from 'lodash';
import LayerStylePreviewIcon from '@/components/LayerStylePreviewIcon';
import LinkUnderlined from '@/components/LinkUnderlined';

import stylePreviewUtilities from '@/mixins/stylePreviewUtilities';

export default {
  name: 'LegendLayersListStyles',
  mixins: [stylePreviewUtilities],
  components: {
    LayerStylePreviewIcon,
    LinkUnderlined,
  },
  props: {
    defaultStyle: {
      type: Object,
    },
    styles: {
      type: Array,
      required: true,
    },
    layerId: {
      type: [Number, String],
    },
    layerType: {
      type: String,
      required: true,
    },
    hasPermission: {
      type: Boolean,
      required: true,
    },
    defaultStyleLabelPath: {
      type: String,
      default: 'default.others',
    },
    maxStyles: {
      type: Number,
      default: 5,
    },
    layerVisible: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mappedStyles: [],
    isExpanded: false,
  }),
  computed: {
    legendStylesCounts: get('legend/legendStylesCounts'),
    layerLegendStylesCounts() {
      return this.legendStylesCounts?.[this.layerId] || undefined;
    },
    computedStyles() {
      return this.isExpanded ? this.mappedStyles : this.mappedStyles.slice(0, this.maxStyles);
    },
    isExpandedVisible() {
      return this.styles.length + 1 > this.maxStyles;
    },
    textPath() {
      return this.isExpanded ? 'sidebar.showLess' : 'sidebar.showMore';
    },
  },
  methods: {
    getMappedStyles() {
      const defaultStyle = JSON.parse(JSON.stringify(this.defaultStyle));
      defaultStyle.color = this.getStyleIconColor(defaultStyle);
      defaultStyle.key = this.$i18n.t(this.defaultStyleLabelPath);
      defaultStyle.icon =
        defaultStyle.externalGraphic && this.getStyleExternalGraphicIcon(defaultStyle.externalGraphic);
      if (this.layerLegendStylesCounts) {
        defaultStyle.count = this.layerLegendStylesCounts['default'] || 0;
      }
      const styles = (this.layerLegendStylesCounts
        ? JSON.parse(JSON.stringify(this.styles)).map(style => {
            if (
              Object.prototype.hasOwnProperty.call(style, 'min-value') &&
              Object.prototype.hasOwnProperty.call(style, 'max-value')
            ) {
              const key = `${style['min-value']}-${style['max-value']}`;
              return { ...style, count: this.layerLegendStylesCounts.values[key] || 0 };
            }
            return { ...style, count: this.layerLegendStylesCounts.values[style.value] || 0 };
          })
        : this.styles
      ).concat([defaultStyle]);
      this.mappedStyles = this.$_sortObjectsByAttribute(
        JSON.parse(JSON.stringify(styles)).map((item, index) => {
          const order = item.order || item.order === 0 ? item.order : index;
          const visible = Object.prototype.hasOwnProperty.call(item, 'visible') ? item.visible : true;
          return { ...item, order, visible };
        }),
        'order',
        false,
        true
      ).reverse();
    },
    changeStyleClassVisible(ev, index, style) {
      ev.stopPropagation();
      const value = !style.visible;
      if (style.ranges || style.uniques) {
        this.$store.set('layers/SET_LAYER_STYLE_KEY_VALUE!', {
          layerId: this.layerId,
          key: 'visible',
          keyValue: value,
        });
        this.setStyleClassChanges();
        return;
      }
      if (
        Object.prototype.hasOwnProperty.call(style, 'min-value') &&
        Object.prototype.hasOwnProperty.call(style, 'max-value')
      ) {
        this.$store.set('layers/SET_LAYER_STYLE_CLASS_KEY_VALUE!', {
          layerId: this.layerId,
          classIndex: index,
          propValue: value,
        });
      } else {
        this.$store.set('layers/SET_LAYER_STYLE_CLASS_KEY_VALUE!', {
          layerId: this.layerId,
          classValue: style.value,
          propValue: value,
        });
      }
      this.setStyleClassChanges();
    },
    setStyleClassChanges() {
      this.$root.$emit('setProjectLayerStyle', this.layerId);
      this.$emit('hasUnsavedChanges', true);
    },
  },
  mounted() {
    this.$watch(
      vm => {
        const { styles, defaultStyle, layerLegendStylesCounts } = vm;
        return { styles, defaultStyle, layerLegendStylesCounts };
      },
      (nV, oV) => {
        if (JSON.stringify(oV) === JSON.stringify(nV)) return;
        this.getMappedStyles();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  created() {
    this.getMappedStyles = debounce(this.getMappedStyles, 100);
  },
};
</script>

<style lang="scss" scoped></style>
