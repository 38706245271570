<template>
  <v-menu
    v-model="isMenuVisible"
    :close-on-content-click="false"
    max-width="290px"
    min-width="290px"
    offset-y
    :disabled="disabled || $attrs.readonly"
  >
    <template #activator="{ on }">
      <data-input-text
        v-if="!splitted"
        v-bind="{ ...$props, ...$attrs }"
        v-model="formattedDate"
        v-on="{ ...on }"
        @input="setValue"
        :rules="computedRules"
        class="included"
      />
      <v-row v-else no-gutters class="justify-start" align="baseline">
        <v-col>
          <data-input-custom-date
            v-model="dates.startDate"
            v-bind="{ ...$props, ...$attrs, ...customDataInputType, ...additionalDataInputProps }"
            :rules="computedRules"
            @saveDate="checkDates"
          />
        </v-col>
        <v-col cols="auto" class="px-2"> - </v-col>
        <v-col>
          <data-input-custom-date
            v-model="dates.endDate"
            v-bind="{ ...$props, ...$attrs, ...customDataInputType, ...additionalDataInputProps }"
            :rules="computedRules"
            @saveDate="checkDates"
          />
        </v-col>
      </v-row>
      <v-container v-if="defaultRanges.length > 0">
        <v-row class="justify-start pt-2">
          <v-col v-for="(defaultRange, idx) in defaultRanges" :key="idx" cols="auto" class="px-0 pr-1 py-0 pb-1">
            <v-chip
              :color="
                date[0] !== defaultRange.value[0] || date[1] !== defaultRange.value[1]
                  ? 'rgba(0,0,0,0.12)'
                  : 'primarybackground'
              "
              label
              small
              @click="date = defaultRange.value"
              :disabled="disabled"
            >
              <v-col
                cols="auto"
                class="px-0 date-range-chip"
                :style="`color: ${
                  date[0] !== defaultRange.value[0] || date[1] !== defaultRange.value[1]
                    ? 'rgba(0,0,0,0.87)'
                    : '#1A73E8'
                }`"
              >
                {{ defaultRange.text }}
              </v-col>
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <input-card
      @save="save"
      @cancel="cancel"
      :is-save-disabled="isSaveDisabled || isRangeInvalid"
      v-click-outside="{
        handler: onClickOutside,
        include,
        closeConditional,
      }"
    >
      <v-date-picker
        v-model="date"
        range
        v-bind="computedDatePickerProps"
        :active-picker.sync="activePicker"
        :class="{
          'custom-year-picker': isDatePickerYear,
          'custom-year-picker__novalue': isDatePickerYear && !value,
        }"
        @click:year="saveDate"
        full-width
      />
    </input-card>
  </v-menu>
</template>
<script>
import inputCard from '@/mixins/inputCard';

import DataInputText from '@/components/DataInputText';
import InputCard from '@/components/InputCard';

export default {
  name: 'DataInputDaterange',
  mixins: [inputCard],
  components: {
    DataInputText,
    InputCard,
    DataInputCustomDate: () => import('@/components/DataInput'),
  },
  props: {
    value: {
      type: [Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    splitted: {
      type: Boolean,
      default: false,
    },
    customDataInputType: {
      type: Object,
      default: () => ({
        dataType: {
          name: 'date',
        },
      }),
    },
    additionalDataInputProps: {
      type: Object,
      default: () => ({}),
    },
    localeDateFormat: {
      default: 'MMMM YYYY',
    },
    datePickerProps: {
      type: Object,
    },
    defaultRanges: {
      type: Array,
      default: () => {
        return [];
      },
    },
    returnType: {
      type: String,
      default: 'month',
      validator: value => {
        return ['month', 'date', 'year'].includes(value);
      },
    },
  },
  data: () => ({
    isMenuVisible: false,
    formattedDate: undefined,
    activePicker: null,
    dates: {},
    datesProxy: [],
  }),
  computed: {
    isDatePickerYear() {
      return this.datePickerProps?.type === 'year' || this.returnType === 'year';
    },
    computedDatePickerProps() {
      if (!this.datePickerProps) return;
      let datePickerPropsProxy = JSON.parse(JSON.stringify(this.datePickerProps));
      if (this.isDatePickerYear) datePickerPropsProxy.type = 'date';
      return datePickerPropsProxy;
    },
    computedFormattedDate() {
      if (!this.value || this.value.length === 0) {
        return;
      }
      return this.value.map(v => this.$dayjs(v).format(this.localeDateFormat)).join(' - ');
    },
    computedRules() {
      return [...(this.$attrs.rules || []), this.isValidRule];
    },
    isRangeInvalid() {
      if (this.value.length < 2) {
        return true;
      }
      const date1 = this.$dayjs(this.value[0]);
      const date2 = this.$dayjs(this.value[1]);
      if (this.datePickerProps && this.datePickerProps.min) {
        const dateMin = this.$dayjs(this.datePickerProps.min);
        if (date1.isBefore(dateMin) || date2.isBefore(dateMin)) {
          return true;
        }
      }
      if (this.datePickerProps && this.datePickerProps.max) {
        const dateMax = this.$dayjs(this.datePickerProps.max);
        if (dateMax.isBefore(date1) || dateMax.isBefore(date2)) {
          return true;
        }
      }
      return false;
    },
    isValid() {
      if (!this.formattedDate) {
        return true;
      }
      for (const date of this.formattedDate.split(' - ')) {
        const dayjsDate = this.$dayjs(date, this.localeDateFormat, import.meta.env.VUE_APP_I18N_LOCALE, true);
        if (!dayjsDate.isValid()) {
          return false;
        }
      }
      if (this.isRangeInvalid) {
        return this.$i18n.t('sidebar.invalidDateRange');
      }
      return true;
    },
    isValidRule() {
      return (
        this.isValid ||
        this.$i18n.t('rules.requiredFormat', {
          format: `${this.localeDateFormat} - ${this.localeDateFormat}`,
        })
      );
    },
    date: {
      get() {
        if (!this.value) {
          const now = this.$dayjs().toISOString().split('T')[0];
          return [now, now];
        }
        return this.value.map(v => v.split('T')[0]);
      },
      set(nV) {
        if (nV.length > 1) {
          const date1 = this.$dayjs(nV[0]);
          const date2 = this.$dayjs(nV[1]);
          if (date2.isBefore(date1)) {
            nV = [nV[1], nV[0]];
            this.$emit('input', nV);
            return;
          }
        }
        this.$emit('input', nV);
      },
    },
  },
  watch: {
    computedFormattedDate: {
      immediate: true,
      handler(nV) {
        this.formattedDate = nV;
      },
    },
    value(nV) {
      if (!nV) return;
      const [start, end] = nV;
      this.dates = {
        startDate: start,
        endDate: end,
      };
    },
    isMenuVisible(val) {
      if (this.isDatePickerYear) {
        val && setTimeout(() => (this.activePicker = 'YEAR'));
      }
    },
  },
  methods: {
    checkDates(e, dates = this.dates) {
      if (!this.isValid) return;
      const { startDate, endDate } = JSON.parse(JSON.stringify(dates));
      if (!startDate || !endDate) return;
      const date1 = this.$dayjs(startDate);
      const date2 = this.$dayjs(endDate);
      this.date = date2.isBefore(date1) ? [endDate, startDate] : [startDate, endDate];
    },
    saveDate(year) {
      if (this.datesProxy.length === 2) this.datesProxy = [];
      this.datesProxy.push(`${year}-01-01`);
      this.date = [...this.datesProxy];
      this.$nextTick(() => {
        this.activePicker = 'YEAR';
      });
    },
    setValue() {
      if (!this.isValid) {
        return;
      }
      this.$emit('input', this.getValue());
    },
    getValue(formattedDate = this.formattedDate) {
      let formattedDateSplit = formattedDate ? formattedDate.split(' - ') : '';
      if (!formattedDate || formattedDateSplit.length < 1) {
        return;
      }

      if (formattedDateSplit.length > 1) {
        const date1 = this.$dayjs(
          formattedDateSplit[0],
          this.localeDateFormat,
          import.meta.env.VUE_APP_I18N_LOCALE,
          true
        );
        const date2 = this.$dayjs(
          formattedDateSplit[1],
          this.localeDateFormat,
          import.meta.env.VUE_APP_I18N_LOCALE,
          true
        );
        if (date2.isBefore(date1)) {
          formattedDateSplit = [formattedDateSplit[1], formattedDateSplit[0]];
        }
      }

      return formattedDateSplit.map(date =>
        this.$dayjs
          .utc(date, this.localeDateFormat, import.meta.env.VUE_APP_I18N_LOCALE, true)
          .toISOString()
          .substring(0, this.returnType === 'month' ? 7 : 10)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-chip--label {
    border-radius: 12px !important;
  }
  .custom-year-picker {
    .v-date-picker-title__date {
      display: none !important;
    }
  }
  .custom-year-picker__novalue {
    .v-date-picker-years .active.primary--text {
      color: #000 !important;
    }
    .v-date-picker-years .active {
      font-size: unset !important;
    }
  }
}
.date-range-chip {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
</style>
