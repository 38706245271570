import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  sqlQueries: [],
  sqlViews: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getSqlQuery({ commit }) {
    const r = await api.get('/sql_query');
    commit('SET_SQL_QUERIES', r.data.data);
  },
  async addSqlQuery({ dispatch }, payload) {
    await api.post('sql_query', payload);
    await dispatch('getSqlQuery');
  },
  async editSqlQuery({ dispatch }, data) {
    const { id, payload } = data;
    await api.put(`sql_query/${id}`, payload);
    await dispatch('getSqlQuery');
  },
  async deleteSqlQuery({ dispatch }, id) {
    await api.delete(`sql_query/${id}`);
    await dispatch('getSqlQuery');
  },
  async runSqlQuery(store, payload) {
    return (await api.post('sql_query/run', payload)).data.data;
  },
  async regiterSqlView(store, payload) {
    await api.post('datasources_sql', payload);
  },
  async getSqlViews({ commit }) {
    const r = await api.get('datasources_sql/views');
    commit('SET_SQL_VIEWS', r.data.data);
  },
  async refreshSqlView(story, name) {
    await api.post(`datasources_sql/${name}/refresh`);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
