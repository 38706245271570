import Transform from 'ol-ext/interaction/Transform';

import { GeoJSON } from 'ol/format';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Fill, Stroke, Circle } from 'ol/style';

import { prepareDrawnGeometry } from '@/assets/js/mapUtils';

export default {
  data: () => ({
    defaultTransformSettings: {
      translate: true,
      translateFeature: false,
      stretch: true,
      scale: false,
      rotate: true,
      hitTolerance: 2,
      selection: false,
    },
  }),
  methods: {
    initTransformGeometry({ geometryValue, transformendCallback, settings } = {}) {
      this.$root.$emit('deactivateAllTools');
      this.initTransformLayer({ geometryValue });
      this.handleTransformInteractionAdding({
        transformendCallback,
        settings,
        withInitGeometry: geometryValue ? true : false,
      });
    },
    addTransformLayerFeature(geometry) {
      const layer = this.getLayerById('transformLayer');
      const feature = new GeoJSON().readFeature(geometry, {
        featureProjection: this.$_config.defaultEpsg || 'EPSG:3857',
        dataProjection: this.$_config.defaultEpsg || 'EPSG:3857',
      });
      layer.getSource().addFeature(feature);
    },
    initTransformLayer({ geometryValue }) {
      const layer = this.getLayerById('transformLayer');
      if (layer) {
        layer.getSource()?.clear();
      } else {
        this.map.addLayer(
          new VectorLayer({
            id: 'transformLayer',
            isEditingLayer: true,
            source: new VectorSource(),
            style: new Style({
              fill: new Fill({
                color: 'rgba(200,0,0,0.6)',
              }),
              stroke: new Stroke({
                color: 'rgba(255,0,0,1)',
                width: 2,
              }),
              image: new Circle({
                radius: 5,
                stroke: new Stroke({
                  color: 'rgba(0, 0, 255, 1)',
                }),
                fill: new Fill({
                  color: 'rgba(63, 127, 191, 0.7)',
                }),
              }),
            }),
            zIndex: 1000,
          })
        );
      }
      if (geometryValue) this.addTransformLayerFeature(geometryValue);
    },
    removeTransformInteraction() {
      const interaction = this.getInteractionByName('transformInteraction');
      if (interaction) {
        this.map.removeInteraction(interaction);
      }
    },
    handleTransformInteractionAdding({
      transformendCallback = this.defaultTransformendCallback,
      settings,
      withInitGeometry = false,
    } = {}) {
      this.removeTransformInteraction();
      const interaction = this.getTransformInteraction({ transformendCallback, settings });
      this.map.addInteraction(interaction);
      if (withInitGeometry) {
        // Feature selection can only take place after adding an interaction to the map (its how ol-ext works)
        interaction.select(
          this.getLayerById('transformLayer')
            .getSource()
            .getFeatures()[0],
          true
        );
      }
    },
    getTransformInteraction({ transformendCallback, settings }) {
      // Interaction parameters doc -> http://viglino.github.io/ol-ext/doc/doc-pages/ol.interaction.Transform.html
      const computedSettings = { ...this.defaultTransformSettings, ...settings };
      const interaction = new Transform({
        layers: [this.getLayerById('transformLayer')],
        ...computedSettings,
      });
      interaction.set('name', 'transformInteraction');
      interaction.on(['rotateend', 'translateend', 'scaleend'], e => {
        this.handleEmitTransformGeometry(transformendCallback, e);
      });
      return interaction;
    },
    handleEmitTransformGeometry(action, e) {
      let transformFeature = e.feature;
      if (!transformFeature && e.features) {
        transformFeature = e.features.getArray()[0];
      }
      if (!transformFeature) {
        action({ geometry: null });
      } else {
        action({ geometry: prepareDrawnGeometry(transformFeature) });
      }
    },
    defaultTransformendCallback(e) {
      this.$root.$emit('transformendGeometry', e);
    },
  },
};
