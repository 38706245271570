<template>
  <v-row no-gutters class="pb-5 networkRow" align="center">
    <v-col cols="auto" class="pr-7 text-left color-select-name-column" v-if="!hideNameColumn">
      {{ `${label}:` }}
    </v-col>
    <v-col cols="auto" class="select-right">
      <v-select
        ref="sel"
        class="select-color"
        v-on="$listeners"
        v-bind="{ ...$props, ...$attrs, ...staticProps }"
        :style="[currentColor]"
        :append-icon="isEditingOn ? '$dropdown' : ''"
        :clearable="[true, false].includes($attrs.clearable) ? $attrs.clearable : isEditingOn"
        :dark="!$_isHexColorLight(currentColor['--current-color']) ? true : false"
        :color="!$_isHexColorLight(currentColor['--current-color']) ? '#fff' : '#202124'"
      >
        <template #prepend-inner v-if="!this.$attrs.value">
          <span class="v-label v-label--is-disabled theme--light" style="font-size: 14px">{{ noDataValue }}</span>
        </template>
        <template v-for="(value, key) in $scopedSlots" #[key]="scope">
          <slot :name="key" v-bind="scope" />
        </template>
        <template #item="{item,on,attrs}">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-content>
              <v-list-item-title style="text-align: left">
                <v-chip
                  small
                  :color="computedColors[item.value ?? item].color"
                  :class="{ 'white--text': !$_isHexColorLight(computedColors[item.value ?? item].color) }"
                  style="font-weight: 500; cursor: pointer"
                >
                  {{ item.text ?? item }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'DataInputSelectcolor',
  props: {
    colors: {
      type: Array,
      required: true,
    },
    hideNameColumn: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    isEditingOn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedColors() {
      const computedColors = {};
      this.$attrs.items.forEach((item, index) => {
        if (item.value && item.text) {
          computedColors[item.value] = {
            text: item.text,
            value: item.value,
            color: this.colors[index],
          };
        } else {
          computedColors[item] = {
            color: this.colors[index],
          };
        }
      });
      return computedColors;
    },
    currentColor() {
      const color = this.computedColors[this.$attrs.value]?.color || '#bababa';
      return {
        '--current-color': color,
        '--main-color': this.$_isHexColorLight(color) ? '#202124' : 'white',
        'font-weight': 500,
      };
    },
  },
  data: vm => ({
    noDataValue: vm.$i18n.t('admin.noData'),
    staticProps: {
      hideDetails: true,
      solo: true,
      menuProps: {
        closeOnContentClick: true,
        offsetY: true,
      },
      label: '',
      color: 'rgba(0,0,0,0)',
      class: 'text-body-2',
      height: '30px',
    },
  }),
};
</script>
<style lang="scss" scoped>
.color-select-name-column {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #202124;
}
.networkRow {
  width: 100%;
  display: grid;
  grid-template-columns: 40% auto 1fr;
  grid-template-areas: 'textLeft selectRight .';
}

.text-left {
  grid-area: textLeft;
  max-width: 10em;
}

.select-right {
  grid-area: selectRight;
  min-width: 5%;
}

.select-color {
  ::v-deep .v-input__control .v-input__slot .v-select__slot .v-label {
    position: static !important;
    right: 0 !important;
    width: 100%;
  }
  ::v-deep .v-input__control .v-input__slot .v-select__slot .v-select__selections input {
    display: none !important;
  }
  ::v-deep .v-input__control .v-input__slot .v-input__prepend-inner {
    padding: 0 !important;
  }
  ::v-deep .v-input__control .v-input__slot .v-select__slot .v-select__selections .v-select__selection,
  ::v-deep .v-input__control .v-input__slot .v-select__slot .v-label,
  ::v-deep .v-input__control .v-input__slot .v-input__prepend-inner span {
    margin-right: 0;
    max-width: 100%;
  }
}

::v-deep {
  .v-input__slot {
    min-height: 30px !important;
    background-color: var(--current-color) !important;
    fieldset {
      border: none !important;
    }
  }
  .v-input__control {
    min-height: unset !important;
  }
  .v-select__selection--comma {
    color: unset !important;
  }
  .v-select__selection {
    color: var(--main-color) !important;
  }
  .v-select__slot label {
    font-size: 14px !important;
  }
  .v-input__icon i {
    color: var(--main-color) !important;
    font-size: 20px;
  }
  .v-icon--disabled {
    display: none;
  }
  .v-input__icon--clear {
    margin-left: 10px;
  }
}
</style>
