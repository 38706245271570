import 'ol/ol.css';
import Map from 'ol/Map';
import { DROP } from 'ol/structs/PriorityQueue';
import TileQueue, { getTilePriority } from 'ol/TileQueue.js';
import TileState from 'ol/TileState';
import EventType from 'ol/events/EventType';
import ReprojTile from 'ol/reproj/Tile';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars

const MAX_DOWNLAD_TILE_BY_HOST =
  'MAP_MAX_DOWNLAD_TILE_BY_HOST' in import.meta.env ? +import.meta.env.VUE_APP_MAP_MAX_DOWNLAD_TILE_BY_HOST : 4;
const MAP_EXTRA_OWN_TILE_PRIORITY = import.meta.env.VUE_APP_MAP_EXTRA_OWN_TILE_PRIORITY === 'true';
const MAP_MAX_TILES_LOADING =
  'MAP_MAX_TILES_LOADING' in import.meta.env ? +import.meta.env.VUE_APP_MAP_MAX_TILES_LOADING : 128;

const URL_GISBOX_MAPPROXY = 'https://gisbox-mapproxy.gis.support/';
const URL_POWERWMS = 'https://powerwms.gis.support/';

/**
 * @param {string} url
 * @param {string} baseUrl
 * @return {boolean}
 */
function testUrl(url, baseUrl) {
  let testUrl;
  if (/^http(s?):\/\//i.test(baseUrl)) {
    testUrl = baseUrl;
  } else if (/^\//i.test(baseUrl)) {
    testUrl = `${import.meta.env.VUE_APP_HOST}${baseUrl}`;
  } else {
    testUrl = `${import.meta.env.VUE_APP_HOST}/${baseUrl}`;
  }
  return url?.startsWith(testUrl) ?? false;
}

class MyTileQueue extends TileQueue {
  /**
   * @param {import('../../node_modules/ol/TileQueue.js').PriorityFunction} tilePriorityFunction Tile priority function.
   * @param {function(): ?} tileChangeCallback Function called on each tile change event.
   */
  constructor(tilePriorityFunction, tileChangeCallback) {
    super(tilePriorityFunction, tileChangeCallback);

    /**
     * @private
     * @type {!Object<string,number>}
     */
    this.tilesLoadingHost_ = {};
  }

  /**
   * @param {number} maxTotalLoading Maximum number tiles to load simultaneously.
   * @param {number} maxNewLoads Maximum number of new tiles to load.
   */
  loadMoreTiles(maxTotalLoading, maxNewLoads) {
    let newLoads = 0;
    let state, tile, tileKey, element, source;
    const elements = [];
    while (this.tilesLoading_ < maxTotalLoading && newLoads < maxNewLoads && this.getCount() > 0) {
      element = this.dequeue();
      tile = /** @type {Tile} */ (element[0]);
      tileKey = tile.getKey();
      state = tile.getState();
      // Pobranie z source w przypadku reprojektowanych wms, wmts i xyz
      source = element[0] instanceof ReprojTile ? element[0].sourceTiles_[0].src_ : element[0].src_;

      if (state === TileState.IDLE && !(tileKey in this.tilesLoadingKeys_)) {
        const execute = () => {
          this.tilesLoadingKeys_[tileKey] = true;
          ++this.tilesLoading_;
          ++newLoads;
          tile.load();
        };

        let host = tileKey.split('/')[2];
        if (
          testUrl(tileKey, import.meta.env.VUE_APP_OSM_URL || '/osm') ||
          testUrl(tileKey, import.meta.env.VUE_APP_API_URL) ||
          testUrl(source, URL_GISBOX_MAPPROXY) ||
          testUrl(source, URL_POWERWMS)
        ) {
          // nasze
          execute();
        } else {
          //zewnętrzne
          if (!(host in this.tilesLoadingHost_)) this.tilesLoadingHost_[host] = 0;

          if (this.tilesLoadingHost_[host] < MAX_DOWNLAD_TILE_BY_HOST) {
            ++this.tilesLoadingHost_[host];
            execute();
          } else {
            elements.push(element);
          }
        }
      }
    }

    elements.forEach(value => this.enqueue(value));
  }

  /**
   * @param {Event} event Event.
   * @protected
   */
  handleTileChange(event) {
    const tile = /** @type {Tile} */ (event.target);
    const state = tile.getState();
    if (state === TileState.LOADED || state === TileState.ERROR || state === TileState.EMPTY) {
      tile.removeEventListener(EventType.CHANGE, this.boundHandleTileChange_);
      const tileKey = tile.getKey();
      if (tileKey in this.tilesLoadingKeys_) {
        let host = tileKey.split('/')[2];
        if (host in this.tilesLoadingHost_) --this.tilesLoadingHost_[host];
        delete this.tilesLoadingKeys_[tileKey];
        --this.tilesLoading_;
      }
      this.tileChangeCallback_();
    }
  }
}

/**
 * @param {import('../../node_modules/ol/PluggableMap.js').FrameState} frameState Frame state.
 * @param {Tile} tile Tile.
 * @param {string} tileSourceKey Tile source key.
 * @param {import("../../node_modules/ol/coordinate.js").Coordinate} tileCenter Tile center.
 * @param {number} tileResolution Tile resolution.
 * @return {number} Tile priority.
 */
function getOwnTilePriority(frameState, tile, tileSourceKey, tileCenter, tileResolution) {
  let value = getTilePriority(frameState, tile, tileSourceKey, tileCenter, tileResolution);
  // zignoruj jeżeli `DROP`
  if (value === DROP) return DROP;

  // zwiększ dziesiętnie według pasującego schematu
  let key = tile.getKey();
  let source = tile.src_;
  if (key) {
    if (
      testUrl(key, import.meta.env.VUE_APP_OSM_URL || '/osm') ||
      testUrl(source, URL_GISBOX_MAPPROXY) ||
      testUrl(source, URL_POWERWMS)
    ) {
      value *= 100;
    } else if (testUrl(key, import.meta.env.VUE_APP_API_URL)) {
      value *= 10;
    }
  }

  return value;
}

class MyMap extends Map {
  /**
   * @param {import("../../node_modules/ol/src/PluggableMap.js").MapOptions} options Map options.
   */
  constructor(options) {
    // jeżeli nie ustawimy podbijamy do 128 z 16
    options.maxTilesLoading = options.maxTilesLoading !== undefined ? options.maxTilesLoading : MAP_MAX_TILES_LOADING;

    super(options);

    //podmiana zmiennej
    this.tileQueue_ = new MyTileQueue(this.getTilePriority.bind(this), this.handleTileChange_.bind(this));
  }

  /**
   * @param {Tile} tile Tile.
   * @param {string} tileSourceKey Tile source key.
   * @param {import("../../node_modules/ol/coordinate.js").Coordinate} tileCenter Tile center.
   * @param {number} tileResolution Tile resolution.
   * @return {number} Tile priority.
   */
  getTilePriority(tile, tileSourceKey, tileCenter, tileResolution) {
    if (MAP_EXTRA_OWN_TILE_PRIORITY) {
      return getOwnTilePriority(this.frameState_, tile, tileSourceKey, tileCenter, tileResolution);
    } else {
      return super.getTilePriority(tile, tileSourceKey, tileCenter, tileResolution);
    }
  }
}

export default MyMap;
