<template>
  <div>
    <slot :name="name" v-bind="{ name, value, setValue }">
      <data-input
        v-bind="{ ...$attrs, ...$props }"
        :dataType="dataType"
        v-model="computedValue"
        :hint="hint"
        v-on="$listeners"
      ></data-input>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'MainInputDialogRowValue',
  components: {
    DataInput: () => import('@/components/DataInput'),
  },
  props: {
    dataType: {
      required: true,
      type: Object,
    },
    hint: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    valueSubproperty: {
      type: String,
      default: '',
    },
    value: {
      required: false,
    },
    minValue: {
      type: Number,
      default: null,
    },
    maxValue: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    dataValue: undefined,
  }),
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
  },
  methods: {
    setValue(value) {
      this.computedValue = value;
    },
  },
};
</script>
