<template>
  <navbar-menu-tool
    v-model="isMenuOpen"
    :menu-data="{ name: 'uldk', position: 'top', allowOtherLeft: true }"
    :button-bind="{ icon, tooltip }"
  >
    <template #content>
      <v-row no-gutters class="pa-5 pb-0" :class="{ 'pb-2': !computedResultList?.length }" justify="center">
        <v-col cols="auto" style="width: calc(100% - 36px)">
          <v-form ref="form" v-model="isValid">
            <v-row no-gutters>
              <v-col v-for="(input, idx) of inputs" :key="idx" :cols="12 / inputs.length" class="px-1">
                <v-row no-gutters>
                  <v-col cols="12">
                    <template v-if="input.name === 'parcelTerrit'">
                      <data-input v-bind="input" v-model="parcelTerrit" v-on="input.listeners" />
                    </template>
                    <template v-else>
                      <data-input v-bind="input" v-model="params[input.name]" v-on="input.listeners" />
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="text-left font-italic font-weight-light" style="font-size: 12px">
                  <template v-if="input.name === 'precinct'">
                    <v-checkbox
                      v-model="params['precinctNotKnown']"
                      @change="updateParcelTerrit"
                      class="pa-0 ma-0"
                      :label="$i18n.t('sidebar.uldk.precinctNotKnown')"
                      :readonly="loading"
                    />
                  </template>
                  <template v-else-if="input.subtitle">
                    <span class="pt-1"> {{ input.subtitle }} </span>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="auto" style="max-width: 36px" class="pl-1 pt-1">
          <base-button
            icon="mdi-magnify"
            icon-size="22px"
            :button-style="{
              backgroundColor: !computedDisabled ? $_colors.primary : 'rgb(210, 210, 210)',
              iconColor: 'white',
              borderRadius: 'unset',
            }"
            is-icon
            :disabled="computedDisabled"
            @click="search"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="px-5 pb-2" align="center" justify="center" v-if="computedResultList?.length">
        <v-col cols="auto" style="font-size: 12px !important">
          {{ `${$i18n.t('sidebar.uldk.searchResult')}: ${computedResultList.length}` }}
        </v-col>
        <v-col cols="auto" style="width: 225px" class="pl-2">
          <data-input v-bind="resultListInput" v-model="currentResult" class="navbar-uldk-result-select" />
        </v-col>
        <v-col cols="auto" class="pl-2">
          <v-icon size="20" color="primary" :disabled="!currentResult" @click="addParcel()">mdi-magnify</v-icon>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-icon size="18" color="error" @click="clear()"> mdi-cancel </v-icon>
        </v-col>
        <v-col cols="auto">
          <link-underlined
            underline-only-on-hover
            classes="error--text font-italic"
            translation-path="sidebar.uldk.clearResult"
            @click="clear"
          />
        </v-col>
      </v-row>
    </template>
  </navbar-menu-tool>
</template>
<script>
import { call } from 'vuex-pathify';

import DataInput from '@/components/DataInput';
import LinkUnderlined from '@/components/LinkUnderlined';
import NavbarMenuTool from '@/components/NavbarMenuTool';

export default {
  name: 'TheNavbarToolsPanelUldk',
  components: { DataInput, LinkUnderlined, NavbarMenuTool },
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
    fontSize: {
      type: String,
      default: '19px',
    },
  },
  computed: {
    inputs() {
      const defaultProps = {
        hideDetails: true,
      };
      return [
        {
          name: 'voivodeship',
          label: `${this.$i18n.t('sidebar.uldk.voivodeship')}*`,
          dataType: { name: 'select' },
          loading: this.admnistrativeParamsItemsLoading.voivodeship,
          readonly: this.admnistrativeParamsItemsLoading.voivodeship || this.loading,
          items: this.$_sortObjectsByAttribute(
            this.$_objectToArray(this.admnistrativeParamsItems['voivodeship']),
            'name'
          ).map(item => {
            return { ...item, text: `${item.name} | ${item.territId}` };
          }),
          itemText: 'text',
          itemValue: 'territId',
          returnObject: true,
          rules: [this.rules.required],
          listeners: {
            change: this.getDistricts,
          },
          ...defaultProps,
        },
        {
          name: 'district',
          label: `${this.$i18n.t('sidebar.uldk.district')}*`,
          dataType: { name: 'select' },
          loading: this.admnistrativeParamsItemsLoading.district,
          readonly: this.admnistrativeParamsItemsLoading.district || this.loading,
          items: this.$_sortObjectsByAttribute(
            this.$_objectToArray(this.admnistrativeParamsItems['district']),
            'name'
          ).map(item => {
            return { ...item, text: `${item.name} | ${item.territId}` };
          }),
          itemText: 'text',
          itemValue: 'territId',
          returnObject: true,
          rules: [this.rules.required],
          disabled: !this.params['voivodeship'],
          listeners: {
            change: this.getCommunities,
          },
          ...defaultProps,
        },
        {
          name: 'community',
          label: `${this.$i18n.t('sidebar.uldk.community')}*`,
          dataType: { name: 'select' },
          loading: this.admnistrativeParamsItemsLoading.community,
          readonly: this.admnistrativeParamsItemsLoading.community || this.loading,
          items: this.$_sortObjectsByAttribute(
            this.$_objectToArray(this.admnistrativeParamsItems['community']),
            'name'
          ).map(item => {
            return { ...item, text: `${item.name} | ${item.territId}`, value: item.territId.split('_')[0] };
          }),
          itemText: 'text',
          returnObject: true,
          rules: [this.rules.required],
          disabled: !this.params['district'],
          listeners: {
            change: this.getPrecincts,
          },
          ...defaultProps,
        },
        {
          name: 'precinct',
          label: `${this.$i18n.t('sidebar.uldk.precinct')}${this.params.precinctNotKnown ? '' : '*'}`,
          dataType: { name: 'select' },
          loading: this.admnistrativeParamsItemsLoading.precinct,
          readonly: this.admnistrativeParamsItemsLoading.precinct || this.loading,
          items: this.$_sortObjectsByAttribute(
            this.$_objectToArray(this.admnistrativeParamsItems['precinct']),
            'name'
          ).map(item => {
            return { ...item, text: `${item.name} | ${item.territId}` };
          }),
          itemText: 'text',
          itemValue: 'territId',
          returnObject: true,
          rules: this.params.precinctNotKnown ? [] : [this.rules.required],
          disabled: this.params.precinctNotKnown || !this.params['community'],
          listeners: {
            change: this.updateParcelTerrit,
          },
          ...defaultProps,
        },
        {
          name: 'parcelId',
          label: `${this.$i18n.t('sidebar.uldk.parcelId')}*`,
          subtitle: this.$i18n.t('sidebar.uldk.parcelIdSubtitle2'),
          dataType: { name: 'text' },
          readonly: this.loading,
          disabled: !this.params['precinct'] && !this.params['precinctNotKnown'],
          rules: [this.rules.required],
          listeners: {
            input: this.updateParcelTerrit,
          },
          ...defaultProps,
        },
        {
          name: 'parcelTerrit',
          label: this.$i18n.t('sidebar.uldk.parcelTerrit'),
          subtitle: this.$i18n.t('sidebar.uldk.parcelTerritSubtitle2'),
          dataType: { name: 'text' },
          readonly: this.loading,
          rules: [this.rules.parcelTerrit],
          listeners: {
            change: this.changeParcelTerrit,
          },
          ...defaultProps,
        },
      ];
    },
    resultListInput() {
      return {
        dataType: { name: 'select' },
        hideDetails: true,
        items: this.computedResultList,
        returnObject: true,
      };
    },
    rules() {
      return {
        required: v => !!v || this.$i18n.t('rules.required'),
        parcelTerrit: () => {
          return (
            this.isParcelTerritValid.valid || this.$i18n.t(`sidebar.uldk.${this.isParcelTerritValid.error}BadTerrit`)
          );
        },
      };
    },
    computedResultList() {
      return this.resultList.map(result => {
        const parcelTerrit = result[1];
        const computedResult = { parcelId: result[0], parcelTerrit, wktGeom: result[2] };
        const dotsIndexes = [];
        for (let i = 0; i < parcelTerrit.length; i++) {
          if (parcelTerrit[i] === '.') {
            dotsIndexes.push(i);
          }
        }
        const numberOfDots = dotsIndexes.length;
        computedResult.precinct = parcelTerrit.substr(dotsIndexes[0] + 1, 4);
        if (numberOfDots > 2) {
          computedResult.mapSheet = parcelTerrit.substring(dotsIndexes[1] + 1, dotsIndexes[2]);
        }
        computedResult.text = `${computedResult.parcelId} (${this.$_loweralize(
          this.$i18n.t('sidebar.uldk.precinct')
        )}: ${computedResult.precinct}${
          computedResult.mapSheet
            ? `, ${this.$_loweralize(this.$i18n.t('sidebar.uldk.sheet'))}: ${computedResult.mapSheet}`
            : ''
        })`;
        return computedResult;
      });
    },
    computedDisabled() {
      return this.loading || !this.isParcelTerritValid.valid;
    },
  },
  data: () => ({
    isMenuOpen: false,
    menuOpenWatch: undefined,
    admnistrativeParamsItems: {
      voivodeship: [],
      district: [],
      community: [],
      precinct: [],
    },
    admnistrativeParamsItemsLoading: {
      voivodeship: false,
      district: false,
      community: false,
      precinct: false,
    },
    isParcelTerritValid: { valid: true },
    isValid: false,
    loading: false,
    params: {},
    parcelTerrit: '',
    resultList: [],
    currentResult: undefined,
  }),
  methods: {
    getUldkCommunities: call('search/getUldkCommunities'),
    getUldkDistricts: call('search/getUldkDistricts'),
    getUldkPrecincts: call('search/getUldkPrecincts'),
    getUldkVoivodeships: call('search/getUldkVoivodeships'),
    searchUldkParcel: call('search/searchUldkParcel'),
    async getVoivodeships() {
      this.admnistrativeParamsItemsLoading.voivodeship = true;
      try {
        const r = await this.getUldkVoivodeships();
        this.admnistrativeParamsItems.voivodeship = r;
        this.$refs.form?.resetValidation();
      } finally {
        this.admnistrativeParamsItemsLoading.voivodeship = false;
      }
    },
    async getDistricts(nV, updateParcelTerrit = true, resetValidation = true) {
      this.params.district = '';
      this.params.community = '';
      this.params = { ...this.params, precinct: '' };
      if (resetValidation) {
        this.$refs.form?.resetValidation();
      }
      if (updateParcelTerrit) {
        this.updateParcelTerrit();
      }
      this.admnistrativeParamsItemsLoading.district = true;
      try {
        const r = await this.getUldkDistricts(nV.territId);
        this.admnistrativeParamsItems.district = r;
      } finally {
        this.admnistrativeParamsItemsLoading.district = false;
      }
    },
    async getCommunities(nV, updateParcelTerrit = true, resetValidation = true) {
      this.params.community = '';
      this.params = { ...this.params, precinct: '' };
      if (resetValidation) {
        this.$refs.form?.resetValidation();
      }
      if (updateParcelTerrit) {
        this.updateParcelTerrit();
      }
      this.admnistrativeParamsItemsLoading.community = true;
      try {
        const r = await this.getUldkCommunities(nV.territId);
        this.admnistrativeParamsItems.community = r;
      } finally {
        this.admnistrativeParamsItemsLoading.community = false;
      }
    },
    async getPrecincts(nV, updateParcelTerrit = true, resetValidation = true) {
      this.params.precinct = '';
      if (resetValidation) {
        this.$refs.form?.resetValidation();
      }
      if (updateParcelTerrit) {
        this.updateParcelTerrit();
      }
      this.admnistrativeParamsItemsLoading.precinct = true;
      try {
        const r = await this.getUldkPrecincts(nV.territId);
        this.admnistrativeParamsItems.precinct = r;
      } finally {
        this.admnistrativeParamsItemsLoading.precinct = false;
      }
    },
    async changeParcelTerrit(nV = '') {
      if (nV.length >= 2) {
        const voivodeshipTerrit = nV.substring(0, 2);
        const voivodeship = this.admnistrativeParamsItems.voivodeship[voivodeshipTerrit];
        if (!voivodeship) {
          this.isParcelTerritValid = { valid: false, error: 'voivodeship' };
          this.$refs.form?.resetValidation();
          return;
        }
        this.params.voivodeship = voivodeship;
        this.$refs.form?.resetValidation();
        await this.getDistricts(voivodeship, false, false);
      }
      if (nV.length >= 4) {
        const districtTerrit = nV.substring(0, 4);
        const district = this.admnistrativeParamsItems.district[districtTerrit];
        if (!district) {
          this.isParcelTerritValid = { valid: false, error: 'district' };
          this.$refs.form?.resetValidation();
          return;
        }
        this.params.district = district;
        this.$refs.form?.resetValidation();
        await this.getCommunities(district, false, false);
      }
      if (nV.length >= 8) {
        const communityTerrit = nV.substring(0, 8);
        const mappedCommunities = this.$_mapObjectKeys(
          this.admnistrativeParamsItems.community,
          key => key.split('_')[0]
        );
        const community = mappedCommunities[communityTerrit.split('_')[0]];
        if (!community) {
          this.isParcelTerritValid = { valid: false, error: 'community' };
          this.$refs.form?.resetValidation();
          return;
        }
        this.params.community = { ...community, value: community.territId.split('_')[0] };
        this.$refs.form?.resetValidation();
        await this.getPrecincts(community, false, false);
      }
      if (nV.length >= 10 && nV.substring(8, 10) === '.?') {
        const precinctNotKnown = true;
        this.params = { ...this.params, precinctNotKnown };
        this.$refs.form?.resetValidation();
      } else if (nV.length >= 13) {
        const precinctTerrit = nV.substring(0, 13);
        const precinct = this.admnistrativeParamsItems.precinct[precinctTerrit];
        if (!precinct) {
          this.isParcelTerritValid = { valid: false, error: 'precinct' };
          this.$refs.form?.resetValidation();
          return;
        }
        const precinctNotKnown = false;
        this.params = { ...this.params, precinct, precinctNotKnown };
      }
      this.params.parcelId = '';
      if (nV.length >= 12 && nV.substring(8, 10) === '.?' && nV[10] === '.') {
        this.params.parcelId = nV.substr(11, nV.length);
      } else if (nV.length >= 15 && nV[13] === '.') {
        this.params.parcelId = nV.substr(14, nV.length);
      }
      this.isParcelTerritValid = { valid: true };
      this.$refs.form?.resetValidation();
    },
    updateParcelTerrit() {
      let territ = '';
      if (this.params.precinct && !this.params.precinctNotKnown) {
        territ += this.params.precinct.territId || '';
        if (this.params.parcelId) {
          territ += `.${this.params.parcelId}`;
        }
      } else if (this.params.community) {
        territ += this.params.community.territId || '';
        if (this.params.precinctNotKnown) {
          territ += '.?';
          if (this.params.parcelId) {
            territ += `.${this.params.parcelId}`;
          }
        }
      } else if (this.params.district) {
        territ += this.params.district.territId || '';
      } else if (this.params.voivodeship) {
        territ += this.params.voivodeship.territId || '';
      }
      this.parcelTerrit = territ;
      this.isParcelTerritValid = { valid: true };
    },
    async init() {
      this.menuOpenWatch();
      await this.getVoivodeships();
      const voivodeship = JSON.parse(localStorage.getItem('uldk2Voivodeship') || '{}');
      if (localStorage.getItem('uldk2Voivodeship')) {
        this.params = { ...this.params, voivodeship };
        await this.getDistricts(voivodeship, true, true, false);
        const district = JSON.parse(localStorage.getItem('uldk2District') || '{}');
        if (localStorage.getItem('uldk2District')) {
          this.params = { ...this.params, district };
          await this.getCommunities(district, true, true, false);
          const community = JSON.parse(localStorage.getItem('uldk2Community') || '{}');
          if (localStorage.getItem('uldk2Community')) {
            this.params = { ...this.params, community };
            await this.getPrecincts(community);
            if (localStorage.getItem('uldk2Precinct')) {
              const precinct = JSON.parse(localStorage.getItem('uldk2Precinct') || '{}');
              this.params = { ...this.params, precinct };
              this.$refs.form?.resetValidation();
            }
          }
        }
      }
      this.$watch(
        vm => vm.params,
        nV => {
          Object.entries({
            community: 'uldk2Community',
            voivodeship: 'uldk2Voivodeship',
            district: 'uldk2District',
            precinct: 'uldk2Precinct',
          }).forEach(([key, storageKey]) => {
            const value =
              key === 'community'
                ? nV[key]
                  ? { ...nV[key], value: nV[key].territId.split('_')[0] }
                  : nV[key]
                : nV[key];
            localStorage.setItem(storageKey, JSON.stringify(value));
          });
        },
        {
          deep: true,
        }
      );
    },
    async search() {
      this.resultList = [];
      this.currentResult = undefined;
      this.$root.$emit('clearUldkParcel');
      this.loading = true;
      try {
        if (this.params.precinctNotKnown) {
          let resultList = [];
          const index = this.parcelTerrit.indexOf('.?');
          for (const item of this.$_objectToArray(this.admnistrativeParamsItems.precinct)) {
            const territ = `${item.territId}${this.parcelTerrit.substring(index + 2)}`;
            const r = await this.searchUldkParcel({ territ });
            if (r.length > 0) {
              resultList = resultList.concat(r);
            }
          }
          if (resultList.length < 1) {
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('sidebar.uldk.noResults'),
            });
          } else {
            if (resultList.length > 1) {
              this.$store.set('snackbar/PUSH_MESSAGE!', {
                message: this.$i18n.t('sidebar.uldk.severalResultsFound'),
              });
            }
            this.resultList = resultList;
          }
        } else {
          const r = await this.searchUldkParcel({ territ: this.parcelTerrit });
          if (r.length < 1) {
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('sidebar.uldk.noResults'),
            });
          } else if (r.length === 1) {
            this.resultList = r;
          } else {
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('sidebar.uldk.severalMapSheetsResults'),
            });
            this.resultList = r;
          }
        }
      } finally {
        this.loading = false;
      }
      if (this.computedResultList.length === 1) {
        this.currentResult = this.computedResultList[0];
      }
    },
    addParcel(wktGeom = this.currentResult?.wktGeom, parcelTerrit = this.currentResult?.parcelTerrit) {
      if (!wktGeom) return;
      this.$root.$emit('addUldkParcelToMap', wktGeom, parcelTerrit);
    },
    clear() {
      this.resultList = [];
      this.currentResult = undefined;
      this.$root.$emit('clearUldkParcel');
    },
  },
  watch: {
    currentResult(nV) {
      if (nV) this.addParcel(nV.wktGeom);
    },
  },
  mounted() {
    this.menuOpenWatch = this.$watch(
      vm => vm.isMenuOpen,
      nV => {
        if (nV) this.init();
      },
      { immediate: true }
    );
  },
};
</script>
<style lang="scss" scoped>
.background-icon {
  @include activeToolButtonStyle();
}
::v-deep {
  .active-icon * {
    color: map-get($colors, 'link') !important;
  }
  .v-input--selection-controls__input {
    margin-right: 2px !important;
  }
  .navbar-uldk-result-select {
    background-color: rgba(207, 207, 207, 0.25);
    .v-input__slot {
      min-height: 26px !important;
      padding: 0 6px !important;
      fieldset {
        border: unset !important;
      }
      .v-select__slot {
        font-size: 12px !important;
        padding-bottom: 1px !important;
        .v-select__selections {
          padding: unset !important;
        }
      }
    }
    .v-input__append-inner {
      margin-top: 2px !important;
    }
    .v-label {
      font-size: 14px;
      top: 7px !important;
    }
  }
  .v-input--checkbox {
    .v-input__slot {
      margin-bottom: unset !important;
      .v-label {
        font-size: 12px !important;
        color: unset !important;
      }
    }
    .v-messages {
      display: none !important;
    }
  }
  .v-input--selection-controls__input {
    margin-right: 2px !important;
  }
}
</style>
