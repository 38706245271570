import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  scadaMeasurements: {},
  selectedDataSource: {},
  scadaThreeMeasurements: [],
  scadaThreeMeasurementsPoint: [],
};

const mutations = {
  ...make.mutations(state),
  SET_SCADA_MEASUREMENTS: (state, data) => {
    const { measurements, id } = data;
    Vue.set(state.scadaMeasurements, id, measurements);
  },
};

const actions = {
  async getScadaPointMeasurements({ commit }, id) {
    const r = await api.get(`scada/measurements/${id}`);
    commit('SET_SCADA_MEASUREMENTS', { measurements: r.data.data, id });
    return r.data.data;
  },
  async postScadaPointMeasurements({ dispatch }, data) {
    const { scadaPointId, payload } = data;
    await api.post('scada/measurements', payload);
    await dispatch('getScadaPointMeasurements', scadaPointId);
  },
  async putScadaPointMeasurement({ dispatch }, data) {
    const { scadaPointId, measurementId, payload } = data;
    await api.put(`scada/measurements/${measurementId}`, payload);
    await dispatch('getScadaPointMeasurements', scadaPointId);
  },
  async deleteScadaPointMeasurement({ dispatch }, data) {
    const { scadaPointId, measurementId } = data;
    await api.delete(`scada/measurements/${measurementId}`);
    await dispatch('getScadaPointMeasurements', scadaPointId);
  },
  async assignMeasurementToScadaPoint({ dispatch }, data) {
    const { scadaPointId, payload } = data;
    await api.post('scada/measure_attributes', payload);
    await dispatch('updateMeasurementsAttributesValues');
    await dispatch('getScadaPointMeasurements', scadaPointId);
  },
  async unassignMeasurementToScadaPoint({ dispatch }, data) {
    const { scadaPointId, relation } = data;
    await api.delete(`scada/measure_attributes/${relation}`);
    await dispatch('updateMeasurementsAttributesValues');
    await dispatch('getScadaPointMeasurements', scadaPointId);
  },
  async editSelectedDataSource({ dispatch }, payload) {
    await api.put('scada/selected_datasource', payload);
    await dispatch('getSelectedDataSource');
  },
  async getSelectedDataSource({ commit }) {
    const r = await api.get('scada/selected_datasource');
    commit('SET_SELECTED_DATA_SOURCE', r.data.data);
  },
  async updateMeasurementsAttributesValues() {
    await api.get('scada/service/measurements/update');
  },
  async getScadaMeasurementSeriesData(store, data) {
    const { measurementId, payload } = data;
    const r = await api.get(`scada/service/measurements/${measurementId}`, { params: payload });
    return r.data.data;
  },
  async getScadaMeasurementParameters() {
    const r = await api.get('/scada/measure_parameters');
    return r.data.data;
  },
  async getScadaThreeAttributes() {
    const r = await api.get('/scada3/relations');
    return r.data.data;
  },
  async addScadaThreeAttribute(store, data) {
    const { attribute_id, display_attribute } = data;
    await api.post(`/scada3/relations/${attribute_id}/attach`, { display_attribute });
  },
  async deleteScadaThreeAttribute(store, id) {
    await api.post(`/scada3/relations/${id}/detach`);
  },
  async getScadaThreeMeasurementParameters() {
    const r = await api.get('/scada3/measure_parameters');
    return r.data.data;
  },
  async getScadaThreeSystems() {
    const r = await api.get(`scada3/service/available_systems`);
    return r.data.data;
  },
  async getScadaThreeMeasurements({ commit }, dataSource) {
    const r = await api.post(`/dataio/data_sources/${dataSource}/read`, { features_filter: {} });
    const measurements = r.data.data.features.features.map(feature => {
      return {
        ...feature.properties,
        id: feature.id,
      };
    });
    commit('SET_SCADA_THREE_MEASUREMENTS', measurements);
  },
  async getScadaThreePoint({ commit }, { dataSource, featureId }) {
    const r = await api.get(`scada3/${dataSource}/${featureId}/measurements`);
    commit('SET_SCADA_THREE_MEASUREMENTS_POINT', r.data.data);
  },
  async updateScadaThreeMeasurements() {
    const r = await api.get('scada3/measurements/update');
    return r.data.data;
  },
  async getScadaThreeMeasurementSeriesData(store, data) {
    const { measurementId, payload } = data;
    const r = await api.get(`scada3/service/series/${measurementId}`, { params: payload });
    return r.data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
