import { get, call, sync } from 'vuex-pathify';
import { debounce } from 'lodash';
import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';

export default {
  computed: {
    isActiveToolNotReplaceable: sync('tools/isActiveToolNotReplaceable'),
    currentTimestepIndex: get('modeling/currentTimestepIndex'),
    isModelingResultsIdentificationActive: sync('tools/toolStatus@isModelingResultsIdentificationActive'),
    netType() {
      return this.$route.params.netType;
    },
  },
  watch: {
    currentTimestepIndex() {
      this.refreshModelingLayersStyles();
    },
  },
  methods: {
    getModelingResultsTile: call('modeling/getModelingResultsTile'),
    addModelingResultsLayers(scenarioId, types) {
      types.forEach(type => {
        this.addModelingResultsLayer(scenarioId, type);
      });
    },
    addModelingResultsLayer(scenarioId, type) {
      const { netType } = this;
      const mvtLayer = new VectorTileLayer({
        className: type,
        name: type,
        id: type,
        visible: true,
        zIndex: 9999,
        isSpecial: true,
        source: new VectorTileSource({
          format: new MVT(),
          projection: this.$_config.defaultEpsg,
          url: `${import.meta.env.VUE_APP_API_URL}/${netType}/modeling_results/scenarios/${scenarioId}/${type}/mvt/{z}/{x}/{y}`,
          tileLoadFunction: tile => {
            tile.setLoader(async (extent, resolution, featureProjection) => {
              const r = await this.getModelingResultsTile({
                scenarioId,
                type,
                envelope: extent,
                netType,
              });
              const features = tile.getFormat().readFeatures(r.data, {
                extent,
                featureProjection,
              });
              tile.setFeatures(features);
            });
          },
        }),
        renderMode: 'image',
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
    },
    removeModelingResultsLayers() {
      this.removeProjectLayers(['links', 'nodes']);
      this.setEmptyObjects();
    },
    setModelingResultsStyle(type, data, labelsVisible, featureStyle = {}) {
      this.getLayerById(type, 'layers').setStyle((feature, resolution) => {
        return this.getModelingLayerFeatureStyle(feature, data, resolution, type, labelsVisible, featureStyle);
      });
    },
    toggleModelingResultsIdentification(value) {
      if (value) {
        this.$root.$emit('deactivateAllTools');
      }
      this.$nextTick(() => {
        this.deactivateToolHandler('modelingResultsIdentification');
        if (value) {
          this.attachListener('singleclick', this.getIdentificationCallback({ specialType: 'modelingResults' }), {
            type: 'on',
            cursor: null,
          });
          this.attachMoveCursorHandler('pointer', layer => layer.get('id') === 'links' || layer.get('id') === 'nodes');
          this.activeTool = 'modelingResultsIdentification';
          this.isActiveToolNotReplaceable = true;
          this.isModelingResultsIdentificationActive = true;
        } else {
          this.dettachCursorMoveHandler();
        }
      });
    },
    refreshModelingLayersStyles() {
      ['nodes', 'links'].forEach(layer => {
        this.refreshLayerSource(layer);
      });
    },
    setEmptyObjects() {
      this.styleCache = {};
      this.arrowStyles = {};
      this.valuesCache = {};
      this.zoomForResolution = {};
    },
  },
  mounted() {
    this.$root.$on('addModelingResultsLayer', this.addModelingResultsLayer);
    this.$root.$on('addModelingResultsLayers', this.addModelingResultsLayers);
    this.$root.$on('setModelingResultsStyle', this.setModelingResultsStyle);
    this.$root.$on('removeModelingResultsLayers', this.removeModelingResultsLayers);
    this.$root.$on('modelingResultsIdentification-action', this.toggleModelingResultsIdentification);
    this.refreshModelingLayersStyles = debounce(this.refreshModelingLayersStyles, 300);
    this.setEmptyObjects();
  },
};
