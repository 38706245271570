<template>
  <navbar-menu-tool
    v-model="isMenuOpen"
    :menu-data="{ name: 'databoxData', position: 'left', allowOtherTop: true }"
    :button-bind="{ icon, tooltip, verboseName: tooltip }"
    has-close-header
    has-footer
  >
    <template #[`content.header.append`]>
      <dots-menu
        :items="dotsItems"
        @multiple="turnOnDrawing('multiple')"
        @freehand="turnOnDrawing('freehand')"
        @single="turnOnDrawing('single')"
      >
        <template #activator="{ on }">
          <base-button
            v-on="getButtonListeners(on)"
            translation-path="sidebar.searchArea.selectArea"
            :disabled="isDrawn || loading"
            :button-style="buttonStyle"
            class="mr-0 mt-0"
            small
          />
          <v-tooltip v-if="isDrawn || !!selectedFeatures.length" color="font" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" color="primary" tag="button" class="mt-1 ml-1" @click="zoomToArea">
                mdi-magnify
              </v-icon>
            </template>
            {{ $i18n.t('sidebar.searchArea.zoomToArea') }}
          </v-tooltip>
        </template>
      </dots-menu>
      <v-icon v-if="isDrawn || selectedFeatures?.length" color="error" :disabled="loading" @click="clearSelection">
        mdi-cancel
      </v-icon>
    </template>
    <template #content>
      <div class="ma-2" v-if="fetched">
        <v-row no-gutters class="mt-3 pb-1 text-body-2 font-weight-medium" style="color: rgba(0, 0, 0, 0.59)">
          <v-col cols="6" style="font-size: 12px">
            {{ $i18n.t('sidebar.databoxData.data') }}
          </v-col>
          <v-col cols="6" style="font-size: 12px">
            {{ $i18n.t('sidebar.databoxData.layer') }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-for="(availableData, idx) of sortedAvailableDatas"
          :key="idx"
          class="text-body-2 py-1"
          align="center"
        >
          <v-col cols="12">
            <v-row no-gutters align="center">
              <v-col cols="6" class="text-left pr-2" style="font-size: 12px">
                {{ availableData.verboseName }}
              </v-col>
              <v-col cols="6">
                <data-input
                  v-model="payload.databox_layers[availableData.name].layer_id"
                  v-bind="getLayerInput(availableData)"
                  @change="onLayerChange(availableData.name)"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="center" v-if="payload.databox_layers[availableData.name].layer_id">
              <v-col cols="6" />
              <v-col cols="auto">
                <v-checkbox
                  class="databox-data-checkbox pa-0 ma-0"
                  dense
                  hide-details
                  :label="$i18n.t('sidebar.databoxData.mapAttributes')"
                  v-model="payload.databox_layers[availableData.name].mapping"
                  :disabled="loading"
                />
              </v-col>
              <v-icon
                v-if="payload.databox_layers[availableData.name].mapping"
                size="14"
                class="pl-1"
                @click="toggleAttributesMappingDialog(availableData.name)"
                :disabled="loading"
              >
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <main-loader v-else />
      <main-input-dialog
        v-if="mappingDialogLoaded"
        :is-visible.sync="mappingDialogVisible"
        :title-translation="$i18n.t('sidebar.databoxData.mapAttributes')"
        agree-text="dialog.agreeSave"
        dialog-width="35%"
        @agree="saveMappingDialog"
        :inputs="mappingDialogInputs"
      >
        <template v-for="(attribute, idx) of mappingDialogAttrs" #[`${attribute.attribute}.input`]="data">
          <v-col cols="6" :key="idx">
            <data-input v-model="currentDataMapping.attributes[attribute.attribute]" v-bind="data" />
          </v-col>
        </template>
      </main-input-dialog>
    </template>
    <template #[`content.footer`]>
      <base-button
        translation-path="dialog.download"
        class="ml-3 px-5"
        @click="run"
        :disabled="!isGeomValid || !isDataValid"
        :loading="loading"
      />
    </template>
  </navbar-menu-tool>
</template>
<script>
import { call, get } from 'vuex-pathify';
import DataInput from '@/components/DataInput';
import DotsMenu from '@/components/DotsMenu';
import NavbarMenuTool from '@/components/NavbarMenuTool';
import MainLoader from '@/components/MainLoader';

import { createBox } from 'ol/interaction/Draw';
import { transformExtent } from 'ol/proj';
import { isObject } from 'lodash';

import getBbox from '@/mixins/map/getBbox';
import prepareAttributesFeatureCardMixin from '@/mixins/prepareAttributesFeatureCardMixin';

export default {
  name: 'TheNavbarToolsPanelDataboxData',
  components: {
    DataInput,
    DotsMenu,
    NavbarMenuTool,
    MainLoader,
    MainInputDialog: () => import('@/components/MainInputDialog'),
  },
  mixins: [getBbox, prepareAttributesFeatureCardMixin],
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
  computed: {
    dataSources: get('layers/metadata'),
    layers: get('layers/layers'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers?.filter(layer => layer.layer_scope === 'core');
    },
    mappedLayers() {
      return this.projectLayers.map(layer => {
        return {
          text: layer.name,
          value: layer.id,
          geometryType: layer.geometry_type,
        };
      });
    },
    selectedFeaturesLayers: get('map/selectedFeatures'),
    lid() {
      return this.$route.params.lid;
    },
    buttonStyle() {
      return this.isDrawing
        ? {
            backgroundColor: `${this.$_colors.primarybackground} !important`,
            border: '1px solid rgba(26, 115, 232, 0.3) !important',
            color: this.$_colors.primary,
          }
        : {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
            border: '1px solid rgba(0, 0, 0, 0.2) !important',
            color: '#61646D',
          };
    },
    sortedAvailableDatas() {
      return this.$_sortObjectsByAttribute(this.availableDatas, 'verboseName');
    },

    mappingDialogLayer() {
      return this.layers?.[this.currentDataMapping.layer_id] || undefined;
    },
    mappingDialogAttributes() {
      return this.dataSources?.[this.mappingDialogLayer?.data_source_name]?.attributes_schema?.attributes || [];
    },
    mappingDialogAttributesSchema() {
      return this.mappingDialogLayer?.form_schema?.elements || [];
    },
    mappingDialogAttrs() {
      return this.$_flattenStructure(this.getForm(this.mappingDialogAttributesSchema, this.mappingDialogAttributes));
    },
    mappingDialogInputs() {
      return this.mappingDialogAttrs
        .filter(attribute => !attribute.readOnly && attribute.editable)
        .map(attribute => {
          return {
            name: attribute.attribute,
            straightTitle: attribute.label,
            dataType: { name: 'select' },
            items: this.currentDataMappingMetadata.attributes
              .filter(attr => attr.dataType === attribute.dataType.name)
              .map(attr => attr.attribute),
            cols: '12',
            clearable: true,
          };
        });
    },
  },
  data: vm => ({
    currentTaskId: null,
    isMenuOpen: false,
    menuOpenWatch: undefined,
    loading: false,
    dotsItems: [
      { name: 'multiple' },
      { name: 'freehand' },
      { name: 'single', customName: vm.$i18n.t('menu.selectFeatures') },
    ],
    fetched: false,

    availableDatas: [],
    availableRawDatas: [
      {
        name: 'ot_ptlz_a',
        verboseName: vm.$i18n.t('sidebar.databoxData.datas.ot_ptlz_a'),
        geomTypes: ['polygon', 'multipolygon'],
      },
      {
        name: 'ot_ptwp_a',
        verboseName: vm.$i18n.t('sidebar.databoxData.datas.ot_ptwp_a'),
        geomTypes: ['polygon', 'multipolygon'],
      },
      {
        name: 'ot_sw',
        verboseName: vm.$i18n.t('sidebar.databoxData.datas.ot_sw'),
        geomTypes: ['linestring', 'multilinestring'],
      },
      {
        name: 'ot_bubd_a',
        verboseName: vm.$i18n.t('sidebar.databoxData.datas.ot_bubd_a'),
        geomTypes: ['polygon', 'multipolygon'],
      },
    ],

    isDrawn: false,
    isDrawing: false,
    isGeomValid: false,
    isDataValid: false,
    selectedFeatures: [],
    payload: {
      bbox: null,
      geojson: null,
      databox_layers: {},
    },

    currentDataMapping: {},
    currentDataMappingMetadata: { attributes: [], name: '' },
    mappingDialogLoaded: false,
    mappingDialogVisible: false,
  }),
  methods: {
    getDbGisBoxMetadata: call('externalSystems/getDbGisBoxMetadata'),
    downloadDbGisBoxData: call('externalSystems/downloadDbGisBoxData'),
    getButtonListeners(on) {
      return this.isDrawing ? { click: this.turnOffSelecting } : on;
    },
    getLayerInput(availableData) {
      return {
        dataType: { name: 'select' },
        items: this.mappedLayers.filter(layer => availableData.geomTypes.includes(layer.geometryType)),
        hideDetails: true,
        clearable: true,
        readonly: this.loading,
      };
    },
    turnOnDrawing(type) {
      this.isDrawing = true;
      if (type === 'single') {
        this.$root.$emit('identification-action', true, { specialType: 'addByMap' });
        this.$root.$off('assignedObjects');
        this.$root.$on('assignedObjects', identifiedFeatures => {
          const features = identifiedFeatures.map(feature => feature.features);
          for (const feature of features) {
            const { dataSource = '', layerId, id: featureId } = feature[0];
            if (!dataSource) return;
            const isFeatureSelected = this.selectedFeatures.some(el => el.layerId === layerId && el.id === featureId);
            if (isFeatureSelected) this.deleteFeature(feature[0]);
            else {
              this.selectedFeatures = this.selectedFeatures.concat(feature);
              this.$store.set('map/ADD_SELECTED_FEATURES!', {
                type: 'selectedFeatures',
                features: {
                  [layerId]: [featureId],
                },
              });
              this.$root.$emit('setProjectLayerStyle', layerId);
            }
          }
        });
      } else {
        this.$root.$emit('drawSidebarGeometry', {
          drawendCallback: e => this.onDrawend(e, type),
          geometryFunction: type === 'multiple' ? createBox() : null,
          geometryType: 'polygon',
          isInitSnapping: false,
          freehand: type === 'freehand' ? true : false,
          ...(type === 'multiple' ? { drawType: 'Circle' } : {}),
        });
      }
    },
    turnOffSelecting() {
      if (this.selectedFeatures.length) this.isDrawn = true;
      this.isDrawing = false;
      this.$root.$emit('selection-action');
    },
    onDrawend(e, type) {
      this.isDrawing = false;
      this.isDrawn = true;
      if (type === 'multiple') {
        this.payload.bbox = transformExtent(
          e.feature.getGeometry().getExtent(),
          this.$_config.defaultEpsg,
          'EPSG:4326'
        );
      }
      if (type === 'freehand') {
        this.payload.geojson = {
          type: 'Polygon',
          crs: {
            type: 'name',
            properties: { name: this.$_config.defaultEpsg || 'EPSG:4326' },
          },
          coordinates: e.feature.getGeometry().getCoordinates(),
        };
      }
    },
    onLayerChange(name) {
      this.payload.databox_layers[name].mapping = false;
      this.payload.databox_layers[name].attributes = {};
    },
    clearSelection() {
      this.isDrawing = false;
      this.isDrawn = false;
      this.payload.bbox = null;
      this.payload.geojson = null;
      if (this.selectedFeatures.length) this.deleteFeatures();
      this.$root.$emit('clearSidebarGeometry');
      this.$root.$emit('identification-action', false);
    },
    deleteFeature({ layerId, id: featureId }) {
      if (featureId && layerId) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [layerId]: [featureId],
          },
        });
      }
      const featureIndex = this.selectedFeatures.findIndex(ft => ft.id === featureId && ft.layerId === layerId);
      this.selectedFeatures.splice(featureIndex, 1);
    },
    deleteFeatures() {
      for (const layerId of Object.keys(this.selectedFeaturesLayers)) {
        this.$store.set('map/DELETE_SELECTED_FEATURES!', {
          type: 'selectedFeatures',
          features: {
            [layerId]: [],
          },
        });
      }
      this.selectedFeatures = [];
    },
    async zoomToArea() {
      if (this.payload.bbox) {
        this.$root.$emit('fitView', transformExtent(this.payload.bbox, 'EPSG:4326', this.$_config.defaultEpsg)); //4326?
      } else if (this.payload.geojson) {
        this.$root.$emit('createGeomAndFitView', this.payload.geojson);
      } else if (this.selectedFeatures.length) {
        const selectedFeatures = Object.entries(this.selectedFeaturesLayers).reduce((total, [layerId, featuresIds]) => {
          if (featuresIds.length) return [...total, { layerId: +layerId, featuresIds: featuresIds }];
          else return total;
        }, []);
        const bbox = await this.getMultipleLayersFeaturesBbox(selectedFeatures);
        this.$root.$emit('fitView', bbox);
      }
    },

    toggleAttributesMappingDialog(dataName) {
      const dataPayload = JSON.parse(JSON.stringify(this.payload.databox_layers[dataName]));
      const dataMetadata = this.availableDatas.find(data => data.name === dataName);
      this.currentDataMapping = dataPayload;
      this.currentDataMappingMetadata = {
        attributes: dataMetadata.attributes,
        name: dataName,
      };
      this.mappingDialogLoaded = this.mappingDialogVisible = true;
    },
    saveMappingDialog() {
      this.payload.databox_layers[this.currentDataMappingMetadata.name].attributes = Object.entries(
        this.currentDataMapping.attributes
      ).reduce((acc, [key, val]) => {
        if (val) return { ...acc, [key]: val };
        return acc;
      }, {});
      this.mappingDialogVisible = false;
    },

    async run() {
      this.loading = true;
      try {
        const payload = JSON.parse(JSON.stringify(this.payload));
        if (this.selectedFeatures.length) {
          payload.layers = [];
          this.selectedFeatures.forEach(feature => {
            const l = payload.layers.find(layer => layer.layer_id === feature.layerId);
            if (!l) payload.layers.push({ layer_id: feature.layerId, ids: [feature.id] });
            else l.ids.push(feature.id);
          });
        }
        if (!payload.bbox) delete payload.bbox;
        if (!payload.geojson) delete payload.geojson;
        if (!payload.layers) delete payload.layers;
        const storageData = {
          ...this.$_filterObjectKeys(payload, { notAllowedKeys: ['bbox', 'geojson', 'layers'] }),
          databox_layers: Object.entries(payload.databox_layers).reduce((acc, [key, val]) => {
            if (val.layer_id) return { ...acc, [key]: val };
            return acc;
          }, {}),
        };
        payload.databox_layers = Object.entries(payload.databox_layers).reduce((acc, [key, val]) => {
          if (val.layer_id)
            return {
              ...acc,
              [key]: this.$_filterObjectKeys(val, {
                allowedKeys: ['layer_id', ...(val.mapping ? ['attributes'] : [])],
              }),
            };
          return acc;
        }, {});
        await this.downloadDbGisBoxData(payload);
        localStorage.setItem('databox_data', JSON.stringify(storageData));
        const layersIds = Object.values(payload.databox_layers).map(val => val.layer_id);
        layersIds.forEach(layerId => {
          if (layerId || layerId === 0) {
            if (+this.lid === layerId) this.$root.$emit('refreshTableData');
            this.$root.$emit('refreshMvtSource', layerId);
          }
        });
        this.$root.$emit('updateLegendStylesCounts', { layersIds });
        this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
          message: this.$i18n.t('sidebar.databoxData.success'),
        });
        this.clearSelection();
      } finally {
        this.loading = false;
      }
    },
    async init() {
      this.menuOpenWatch();
      const r = await this.getDbGisBoxMetadata();
      this.availableDatas = this.availableRawDatas.map(data => {
        return {
          ...data,
          attributes: Object.entries(r[data.name]).map(([key, val]) => {
            return {
              attribute: key,
              dataType: val,
            };
          }),
        };
      });
      const storageData = JSON.parse(localStorage.getItem('databox_data') || '{}');
      this.payload = {
        ...this.payload,
        ...storageData,
        databox_layers: {
          ...this.availableDatas.reduce(
            (acc, el) => ({ ...acc, [el.name]: { attributes: {}, layer_id: null, mapping: false } }),
            {}
          ),
          ...Object.entries(storageData?.databox_layers ?? {}).reduce((acc, [key, val]) => {
            if (isObject(val) && this.projectLayers.some(layer => layer.id === val.layer_id)) {
              return { ...acc, [key]: val };
            } else if (this.projectLayers.some(layer => layer.id === val)) {
              // Fixing old data structure saved in local storage
              return { ...acc, [key]: { attributes: {}, layer_id: val, mapping: false } };
            }
          }, {}),
        },
      };
      this.fetched = true;
    },
  },
  watch: {
    'payload.bbox': {
      handler(nV) {
        this.isGeomValid = !!nV;
      },
    },
    'payload.geojson': {
      handler(nV) {
        this.isGeomValid = !!nV;
      },
    },
    'payload.databox_layers': {
      handler(nV) {
        this.isDataValid = Object.values(nV).some(val => val?.layer_id);
      },
      deep: true,
    },
    selectedFeatures(nV) {
      this.isGeomValid = !!nV.length;
    },
    isMenuOpen(nV) {
      if (!nV) {
        this.loading = false;
        this.clearSelection();
      }
    },
    mappingDialogVisible(nV) {
      if (!nV) {
        this.currentDataMapping = {};
        this.currentDataMappingMetadata = { attributes: [], name: '' };
      }
    },
  },
  mounted() {
    this.menuOpenWatch = this.$watch(
      vm => vm.isMenuOpen,
      nV => {
        if (nV) this.init();
      },
      { immediate: true }
    );
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .databox-data-checkbox {
    .v-input--selection-controls__input {
      margin-right: 0 !important;
    }
    .v-label {
      font-size: 12px;
      color: map-get($colors, 'font') !important;
    }
  }
}
</style>
