<template>
  <v-text-field
    ref="input"
    v-bind="$attrs"
    :value="formattedValue"
    v-on="$listeners"
    @keydown="checkValue"
    persistent-hint
    readonly
  >
    <template v-for="(value, key) in $scopedSlots" #[key]>
      <slot :name="key" />
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: 'DataInputKeybind',
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    formattedValue() {
      const { specialKeys, code } = this.computedValue || {};
      if (specialKeys?.length && code) {
        let keyString = code;
        if (code.startsWith('Key')) {
          keyString = code.split('Key')[1];
        } else if (code.startsWith('Digit')) {
          keyString = code.split('Digit')[1];
        }
        return `${JSON.parse(JSON.stringify(specialKeys))
          .sort((a, b) => a - b)
          .map(key => key.toLocaleUpperCase())
          .join(' + ')} + ${keyString.toLocaleUpperCase()}`;
      }
      return '';
    },
  },
  methods: {
    checkValue(e) {
      const value = {
        specialKeys: [...(e.altKey ? ['alt'] : []), ...(e.ctrlKey ? ['ctrl'] : []), ...(e.shiftKey ? ['shift'] : [])],
        code: e.code.startsWith('Key') || e.code.startsWith('Digit') ? e.code : '',
      };
      const key = ['Control', 'Alt', 'Shift', 'AltGraph'].includes(e.key) ? '' : e.key;
      if (key && value.specialKeys?.length && value.code) {
        this.computedValue = value;
      }
    },
  },
};
</script>
