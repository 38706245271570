import api from '@/services/api';
import { make } from 'vuex-pathify';
const state = {
  statistics: {},
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async getStatistics({ commit }) {
    const r = await api.get('/statistics');
    commit('SET_STATISTICS', r.data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
