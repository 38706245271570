import { call } from 'vuex-pathify';
export default {
  computed: {
    socketDefaultEventsDict() {
      return {
        datasource: 'getDataSource',
      };
    },
  },
  methods: {
    getLayerMetadata: call('layers/getLayerMetadata'),
    getDataSource(data) {
      if (data.method === 'DELETE') {
        this.$store.set('layers/DELETE_DATA_SOURCE!', data.datasource_name);
        this.$store.set('layers/DELETE_LAYERS_BY_DATA_SOURCE!', data.datasource_name);
      } else {
        this.getLayerMetadata({ dataSource: data.datasource_name, params: { with_attributes_schema: true } });
      }
    },
  },
};
