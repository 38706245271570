import { get, sync } from 'vuex-pathify';

export default {
  computed: {
    selectedFeatures: get('map/selectedFeatures'),
    highlitedFeatures: get('map/highlitedFeatures'),
    currentLayerSelectedFeatures: sync('map/selectedFeatures@:layerId'),
    currentLayerHighlitedFeatures: get('map/highlitedFeatures@:layerId'),
    isAnyFeatureSelected() {
      return this.currentLayerSelectedFeatures !== undefined && this.currentLayerSelectedFeatures.length > 0;
    },
    isOneFeatureSelected() {
      return this.currentLayerSelectedFeatures?.length === 1;
    },
  },
};
