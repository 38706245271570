import { Style, Stroke, Fill, Text, Circle } from 'ol/style';
import { Draw } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';

import { transform } from 'ol/proj';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';

import { get, sync } from 'vuex-pathify';
import VectorSource from 'ol/source/Vector';

export default {
  data: () => ({
    id: 1,
  }),
  computed: {
    isCoordinatesToolActive: sync('tools/toolStatus@isCoordinatesToolActive'),
    coordinatesEpsgs: get('map/coordinatesEpsgs'),
    coordinates: sync('map/coordinates'),
  },
  methods: {
    addCoordinatesLayer() {
      const layer = new VectorLayer({
        source: new VectorSource(),
        id: 'coordinates',
        isSpecial: true,
        style: feature =>
          new Style({
            ...this.imageStyle,
            text: new Text({
              text: String(feature.getId()),
              font: '17px sans-serif',
              fill: new Fill({ color: this.$_colors.font }),
              placement: 'point',
              stroke: new Stroke({
                color: this.$_successColor,
                width: 1,
              }),
              offsetY: 20,
            }),
          }),
      });
      // TODO - ogarnąć index
      layer.setZIndex(1000);
      this.map.addLayer(layer);
    },
    addCoordinatesInteraction(value) {
      if (value && this.activeTool !== 'coordinates') {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === 'coordinates') {
        this.deactivateToolHandler('coordinates');
      }
      this.$nextTick(() => {
        if (value) {
          if (!this.getLayerById('coordinates')) {
            this.addCoordinatesLayer();
          }
          const coordinatesInteraction = new Draw({
            source: this.getLayerById('coordinates').getSource(),
            type: 'Point',
            style: new Style({
              ...this.imageStyle,
            }),
          });
          coordinatesInteraction.set('name', 'coordinates');
          coordinatesInteraction.on('drawend', e => {
            const geometryCoordinates = e.feature.getGeometry().getCoordinates();
            const coordinates =
              this.$_config.defaultEpsg === this.cursorPositionEpsg
                ? geometryCoordinates
                : transform(geometryCoordinates, this.$_config.defaultEpsg, this.cursorPositionEpsg);
            e.feature.setId(this.id);
            this.addCoordinates(coordinates);
          });
          this.map.addInteraction(coordinatesInteraction);
          this.activeTool = 'coordinates';
          this.isCoordinatesToolActive = true;
        } else {
          this.clearCoordinatesInteraction();
        }
      });
    },
    addCoordinates(coordinates) {
      const coordinatesObject = Object.keys(this.coordinatesEpsgs).reduce((total, key) => {
        const [x, y] = transform(coordinates, this.cursorPositionEpsg, key);
        return { ...total, id: this.id, [key]: { x, y } };
      }, {});
      this.$store.set('map/PUSH_CLICKED_COORDINATES!', coordinatesObject);
      this.id++;
    },
    addCoordinatesToList(coordinates) {
      if (!this.getLayerById('coordinates')) {
        this.addCoordinatesLayer();
      }
      const transformedCoords = transform(coordinates, this.cursorPositionEpsg, this.$_config.defaultEpsg);
      const newFeature = new Feature({ geometry: new Point(transformedCoords) });
      newFeature.setId(this.id);
      this.getLayerById('coordinates')
        .getSource()
        .addFeature(newFeature);
      this.addCoordinates(coordinates);
    },
    clearCoordinatesInteraction() {
      this.id = 1;
      this.map.removeInteraction(this.getInteractionByName('coordinates'));
      this.map.removeLayer(this.getLayerById('coordinates'));
      this.coordinates = [];
      this.deactivateToolHandler('coordinates');
      this.isCoordinatesToolActive = false;
    },
    turnOffCoordinatesInteraction() {
      this.map.removeInteraction(this.getInteractionByName('coordinates'));
      this.deactivateToolHandler();
      this.isCoordinatesToolActive = false;
    },
  },
  mounted() {
    this.$root.$on('coordinates-action', this.addCoordinatesInteraction);
    this.$root.$on('addCoordinates', this.addCoordinatesToList);
    this.$root.$on('turnOffCoordinatesInteraction', this.turnOffCoordinatesInteraction);
    this.imageStyle = {
      image: new Circle({
        radius: 4,
        stroke: new Stroke({
          color: this.$_successColor,
        }),
        fill: new Fill({
          color: this.$_successColor,
        }),
      }),
    };
  },
};
