<template>
  <span>
    <slot :item="multiple ? assignMultipleParcelButton : assignParcelButton" :default-action="assignParcelAction" />
  </span>
</template>

<script>
import { get, call, sync } from 'vuex-pathify';
import tableButtonsEditUtils from '@/mixins/tableButtonsEditUtils';

export default {
  name: 'AssignParcelTrigger',
  mixins: [tableButtonsEditUtils],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    layer: {
      type: [Number, String],
    },
    datasource: {
      type: String,
      required: true,
    },
    forcedProvider: {
      type: String,
      default: null,
    },
    geometryType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isAssignParcel: true,
    isActive: false,
  }),
  computed: {
    uldkAttributesMappingsFetchStatus: sync('search/uldkAttributesMappingsFetchStatus'),
    isUldkToolsEnabled: get('admin/settingsValues@uldk_tools_module_enabled'),
    uldkToolsMetadata: get('admin/additionalModules@ULDK_TOOLS_MODULE'),
    isUldkToolsModuleConfigured() {
      return this.uldkToolsMetadata?.enabled && this.uldkToolsMetadata?.configured && this.isUldkToolsEnabled;
    },
    uldkAttributesMappings: get('search/uldkAttributesMappings'),
    uldkToolsAttributesMappings: get('search/uldkToolsAttributesMappings'),
    isAssignParcelButtonActive: sync('edit/isAssignParcelButtonActive'),
    isAssignMultipleParcelButtonActive: sync('edit/isAssignMultipleParcelButtonActive'),
    isAssigningMultipleParcelsLoading: get('edit/isAssigningMultipleParcelsLoading'),
    attributesMappings() {
      return this.isUldkToolsModuleConfigured ? this.uldkToolsAttributesMappings : this.uldkAttributesMappings;
    },
    assignParcelButton() {
      return {
        name: 'assignParcel',
        icon: 'mdi-crosshairs-gps',
        disabled:
          (this.isAssignParcelButtonActive && !this.isActive) ||
          this.isButtonDisabled({
            skipButtons: ['isAssignParcelButtonActive'],
            additionalCondition:
              this.activeTool !== 'moduleIdentification' &&
              this.activeTool !== 'identification-assignPlot' &&
              this.isActiveToolNotReplaceable,
          }),
        tooltip: this.getButtonTooltip({
          isDisabledByAnotherTool: this.isButtonDisabled({
            skipButtons: ['isAssignParcelButtonActive'],
            additionalCondition:
              this.activeTool !== 'moduleIdentification' &&
              this.activeTool !== 'identification-assignPlot' &&
              this.isActiveToolNotReplaceable,
          }),
          enableTooltip: 'button.assignParcelGeometry',
          disableTooltip: 'button.toChangeGeometry',
          requirement: 'button.justOneObject',
        }),
        loading: this.uldkAttributesMappingsFetchStatus === 1,
        fontSize: '19px',
        hasState: true,
        isActive: this.isActive && this.isAssignParcelButtonActive,
      };
    },
    assignMultipleParcelButton() {
      return {
        name: 'assignMultipleParcel',
        icon: '$multiparcel',
        disabled: this.isButtonDisabled({
          skipButtons: ['isAssignMultipleParcelButtonActive'],
          additionalCondition:
            this.activeTool !== 'moduleIdentification' &&
            this.activeTool !== 'identification-assignMultiplePlots' &&
            this.isActiveToolNotReplaceable,
        }),
        tooltip: this.getButtonTooltip({
          isDisabledByAnotherTool: this.isButtonDisabled({
            skipButtons: ['isAssignMultipleParcelButtonActive'],
            additionalCondition:
              this.activeTool !== 'moduleIdentification' &&
              this.activeTool !== 'identification-assignMultiplePlots' &&
              this.isActiveToolNotReplaceable,
          }),
          enableTooltip: 'button.assignMultipleParcelsGeometry',
          disableTooltip: 'button.toChangeGeometry',
          requirement: 'button.justOneObject',
        }),
        isLoading: this.isAssigningMultipleParcelsLoading,
        fontSize: '23px',
        hasState: true,
        isActive: this.isActive && this.isAssignMultipleParcelButtonActive,
      };
    },
  },
  watch: {
    activeTool(nV, oV) {
      if (!oV && nV) {
        if (this.isAssignParcelButtonActive && nV !== 'identification-assignPlot') {
          this.turnOffAssignParcel(false);
        }
      }
    },
  },
  methods: {
    getUldkAttributesMappings: call('search/getUldkAttributesMappings'),
    getUldkToolsAttributesMappings: call('search/getUldkToolsAttributesMappings'),
    async fetchUldkMapping() {
      if (this.uldkAttributesMappingsFetchStatus === 0 && !Object.keys(this.attributesMappings)?.length) {
        this.uldkAttributesMappingsFetchStatus = 1;
        this.isUldkToolsModuleConfigured
          ? await this.getUldkToolsAttributesMappings()
          : await this.getUldkAttributesMappings();
        this.uldkAttributesMappingsFetchStatus = 2;
      }
    },
    toggleAssignParcel(type = 'single') {
      if (type === 'single' ? this.isAssignParcelButtonActive : this.isAssignMultipleParcelButtonActive) {
        this.turnOffAssignParcel();
      } else {
        this.turnOnAssignParcel(type);
      }
    },
    turnOnAssignParcel(type = 'single') {
      this.isActive = true;
      const isSingle = type === 'single';
      this.$root.$emit('deactivateAllTools');
      this.$nextTick(() => {
        if (isSingle) {
          this.isAssignParcelButtonActive = true;
        } else {
          this.isAssignMultipleParcelButtonActive = true;
        }
        this.$root.$emit('identification-action', true, {
          specialType: isSingle ? 'assignPlot' : 'assignMultiplePlots',
          geometryType: this.geometryType,
          plotName: `newPlotFeatureGeometry_${this.layer}`,
          isTurnOffOnFail: false,
          identifyCallback: data => this.onNewPlotFeatureGeometry(data),
          forcedProvider: this.forcedProvider,
        });
      });
    },
    getAttributesMapping({ attributesValues, area }) {
      if (this.attributesMappings[this.datasource] && attributesValues) {
        const values = {};
        const uldkAttrsMapping = {
          community: 'commune',
          county: 'county',
          district: 'region',
          plot: 'parcel',
          teryt: 'teryt',
          voivodeship: 'voivodeship',
        };
        Object.keys(uldkAttrsMapping).forEach(key => {
          if (this.attributesMappings[this.datasource][key] && attributesValues[uldkAttrsMapping[key]]) {
            values[this.attributesMappings[this.datasource][key]] = attributesValues[uldkAttrsMapping[key]];
            if (key === 'teryt') {
              const territValue = attributesValues[uldkAttrsMapping[key]];
              const dotsIndexes = [];
              for (let i = 0; i < territValue.length; i++) {
                if (territValue[i] === '.') {
                  dotsIndexes.push(i);
                }
              }
              if (dotsIndexes.length > 2) {
                values[this.attributesMappings[this.datasource]['sheet']] = territValue.substring(
                  dotsIndexes[1] + 1,
                  dotsIndexes[2]
                );
              }
            }
          }
        });
        if (this.attributesMappings[this.datasource].area) {
          values[this.attributesMappings[this.datasource].area] = area.toFixed(2);
        }
        return values;
      }
    },
    turnOffAssignParcel(withToolDisabling = true) {
      this.isActive = false;
      this.isAssignParcelButtonActive = false;
      this.isAssignMultipleParcelButtonActive = false;
      this.$root.$emit('deleteSidebarGeometry');
      if (this.layer) this.pushToParentRoute(this.layer);
      if (withToolDisabling) this.$root.$emit('identification-action', false);
      this.$root.$emit('turnOffEditing', false);
    },
    async onNewPlotFeatureGeometry({ geometry, attributesValues, area }) {
      let customCallback = this.$listeners.customAction;
      if (customCallback) {
        customCallback({
          values: this.getAttributesMapping({ attributesValues, area }),
          geometry,
          layerId: this.layer,
          datasource: this.datasource,
        });
      } else {
        this.onNewPolygonFeatureGeometry({
          inputName: 'newPolygonFeatureGeometry',
          geometry,
          layerId: this.layer,
          attributesValues,
          area,
          postRouteCallback: () => {
            const values = this.getAttributesMapping({ attributesValues, area });
            this.additionalAttributesValues = values;
          },
        });
      }
      this.isActive = false;
      this.multiple ? (this.isAssignMultipleParcelButtonActive = false) : (this.isAssignParcelButtonActive = false);
    },
    assignParcelAction() {
      this.toggleAssignParcel(this.multiple ? 'multiple' : 'single');
    },
  },
  mounted() {
    this.fetchUldkMapping();
  },
};
</script>
