import { make } from 'vuex-pathify';

import api from '@/services/api';
import { saveFileFromBackend } from '@/assets/js/utility.js';
import i18n from '@/plugins/i18n';
import Vue from 'vue';

const state = {
  status: [],
  netKind: [],
  rules: [],
  curves: [],
  patterns: [],
  errors: [],
  recipientPatterns: [],
  scadaJunctions: [],
  scadaSystems: [],
  scadaMeasurements: {},
  recipients: [],
  epanetProfiles: [],
};
const mutations = {
  ...make.mutations(state),
  UPDATE_ERRORS: (state, value) => {
    const { errorType, index } = value;
    state.errors.find(e => e.errorType === errorType).errors.errors.splice(index, 1);
  },
  SET_SCADA_MEASUREMENT: (state, value) => {
    const { system, measures } = value;
    Vue.set(state.scadaMeasurements, system, measures);
  },
};
const actions = {
  async getEpanet(store, payload) {
    const r = await api.post('/water/epanet/export', payload, { timeout: 0 });
    saveFileFromBackend(r.data, r.headers);
    return r;
  },
  async runModel(store, payload) {
    const r = await api.post('/water/epanet/run', payload);
    return r.data.data;
  },
  async getStatus({ commit }) {
    const modules = await api.get('modules_mapping');
    const id = modules.data.data.water.status_dict_id;
    const r = await api.get(`dicts/${id}`);
    commit('SET_STATUS', r.data.data.values);
  },
  async getNetKind({ commit }) {
    const modules = await api.get('modules_mapping');
    const id = modules.data.data.water.net_kind_dict_it;
    const r = await api.get(`dicts/${id}`);
    commit('SET_NET_KIND', r.data.data.values);
  },
  async getRules({ commit }) {
    const r = await api.get('rules');
    commit('SET_RULES', r.data.data);
  },
  async getRule(state, rule_id) {
    const r = await api.get(`rules/${rule_id}`);
    return r.data.data;
  },
  async editRule({ dispatch }, payload) {
    const { rule_id, body } = payload;
    await api.put(`rules/${rule_id}`, body);
    await dispatch('getRules');
  },
  async addRule({ dispatch }, payload) {
    await api.post('rules', payload);
    await dispatch('getRules');
  },
  async deleteRule({ dispatch }, rule_id) {
    await api.delete(`rules/${rule_id}`);
    await dispatch('getRules');
  },
  async getCurves({ commit }) {
    const r = await api.get('curves');
    commit('SET_CURVES', r.data.data);
  },
  async addCurve({ dispatch }, payload) {
    await api.post('curves', payload);
    await dispatch('getCurves');
  },
  async getCurve(state, curve_id) {
    const r = await api.get(`curves/${curve_id}`);
    return r.data.data;
  },
  async editCurve({ dispatch }, payload) {
    const { curve_id, body } = payload;
    await api.put(`curves/${curve_id}`, body);
    await dispatch('getCurves');
  },
  async deleteCurve({ dispatch }, curve_id) {
    await api.delete(`curves/${curve_id}`);
    await dispatch('getCurves');
  },
  async getPatterns({ commit }) {
    const r = await api.get('patterns');
    commit('SET_PATTERNS', r.data.data);
  },
  async addPattern({ dispatch }, payload) {
    await api.post('patterns', payload);
    await dispatch('getPatterns');
  },
  async getPattern(state, pattern_id) {
    const r = await api.get(`patterns/${pattern_id}`);
    return r.data.data;
  },
  async editPattern({ dispatch }, payload) {
    const { pattern_id, body } = payload;
    await api.put(`patterns/${pattern_id}`, body);
    await dispatch('getPatterns');
    await dispatch('getRecipientPatterns');
  },
  async deletePattern({ dispatch }, pattern_id) {
    await api.delete(`patterns/${pattern_id}`);
    await dispatch('getPatterns');
  },
  async getErrors({ commit }, payload) {
    const r = await api.post('/water/validation', payload);
    const k = Object.keys(r.data.data).map(key => {
      return { errorType: key, errors: r.data.data[key] };
    });
    commit('SET_ERRORS', k);
  },
  async fixError(store, payload) {
    const { errorType, dataSource, feature, parent_pipe_id } = payload;
    const body = {
      error: errorType,
      datasource: dataSource,
      feature: feature || null,
      parent_pipe_id: parent_pipe_id || null,
    };
    return await api.post('/water/validation/fix', body);
  },
  async updateErrors({ commit }, payload) {
    await commit('UPDATE_ERRORS', payload);
  },
  async getRecipientPatterns({ commit }) {
    const r = await api.get('water/recipients_patterns');
    commit('SET_RECIPIENT_PATTERNS', r.data.data);
  },
  async addRecipientPattern({ dispatch }, payload) {
    await api.post('water/recipients_patterns', payload);
    await dispatch('getRecipientPatterns');
  },
  async getRecipientPattern(state, recipient_id) {
    const r = await api.get(`water/recipients_patterns/${recipient_id}`);
    return r.data.data;
  },
  async editRecipientPattern({ dispatch }, payload) {
    const { recipient_id, body } = payload;
    await api.put(`water/recipients_patterns/${recipient_id}`, body);
    await dispatch('getRecipientPatterns');
  },
  async deleteRecipientPattern({ dispatch }, recipient_id) {
    await api.delete(`water/recipients_patterns/${recipient_id}`);
    await dispatch('getRecipientPatterns');
  },
  async getRecipients({ commit }) {
    const r = await api.get('water/recipients/kinds');
    commit('SET_RECIPIENTS', r.data.data);
  },
  async getScadaMeasurementsForSystem({ commit }, system) {
    const r = await api.get(`scada/service/${system}/measurements/names`, { timeout: 0 });
    if (r.data.data.system_status.status === 'unreachable') {
      commit('snackbar/PUSH_ERROR', { message: i18n.t('errors.scadaDataFailedToFetch') }, { root: true });
    } else {
      commit('SET_SCADA_MEASUREMENT', { system, measures: r.data.data.measures });
    }
  },
  async getScadaSystems({ commit }) {
    const r = await api.get(`scada/service/available_systems`);
    commit('SET_SCADA_SYSTEMS', r.data.data);
  },
  async getScadaJunctions({ commit }) {
    const r = await api.get('water/scada_junctions');
    commit('SET_SCADA_JUNCTIONS', r.data.data);
    return r.data.data;
  },
  async addScadaJunction({ dispatch }, payload) {
    await api.post('water/scada_junctions', payload);
    await dispatch('getScadaJunctions');
  },
  async deleteScadaJunction({ dispatch }, scada_junction_id) {
    await api.delete(`water/scada_junctions/${scada_junction_id}`);
    await dispatch('getScadaJunctions');
  },
  async editScadaJunction({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`water/scada_junctions/${id}`, body);
    await dispatch('getScadaJunctions');
  },
  async getScadaJunction(store, scada_junction_id) {
    return await api.get(`water/scada_junctions/${scada_junction_id}`);
  },
  async getCalibrationFile(store, payload) {
    const r = await api.post('/water/epanet/calibration', payload, {
      timeout: 0,
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers);
  },
  async getEpanetProfiles({ commit }) {
    const r = await api.get(`/water/epanet/profile`);
    commit('SET_EPANET_PROFILES', r.data.data);
  },
  async saveEpanetProfile({ dispatch }, payload) {
    const r = await api.post(`/water/epanet/profile`, payload);
    await dispatch('getEpanetProfiles');
    return r.data.data;
  },
  async deleteEpanetProfile({ dispatch }, profileId) {
    await api.delete(`/water/epanet/profile/${profileId}`);
    await dispatch('getEpanetProfiles');
  },
  async editEpanetProfile({ dispatch }, data) {
    const { profileId, payload } = data;
    await api.put(`/water/epanet/profile/${profileId}`, payload);
    await dispatch('getEpanetProfiles');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
