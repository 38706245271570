/* eslint-disable */
/**
 * This file is used to register global utils used throught the whole
 * application. Utils are stored in separate JS file, because there might be
 * some cases where they will be required outside .vue file or before Vue
 * instance had been initialized (no `this` access).
 */

import * as utilities from '@/assets/js/utility';

const installUtilities = (Vue, utilities, excludedUtilities = []) => {
  const filteredKeys = Object.keys(utilities).filter(utility => !excludedUtilities.includes(utility));

  filteredKeys.forEach(utility => {
    Vue.filter(utility, utilities[utility]);
    Vue.prototype[`$_${utility}`] = utilities[utility];
  });
};

export default {
  install(Vue) {
    installUtilities(Vue, utilities);
  },
};
