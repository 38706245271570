import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  alarms: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getAlarms({ commit }) {
    const r = await api.get('scada/alarms');
    commit('SET_ALARMS', r.data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
