import { get } from 'vuex-pathify';
export default {
  computed: {
    zdmDataDataSources: get('admin/modulesMapping@zdm_data.datasources'),
    zdmEditorNewObjectsDataSources() {
      return {
        [this.zdmDataDataSources['street_lamp'].datasource]: {
          id: 'street_lamp',
          dataSourceName: this.zdmDataDataSources['street_lamp'].datasource,
          geometryType: 'point',
          labelsVisible: true,
          opcaity: 1,
          style: {
            'circle-color': '#188038',
            'circle-radius': 5,
            'fill-opacity': 1,
            labels: {
              attributes: [this.zdmDataDataSources['street_lamp'].display_attribute_name],
              'font-color': '#188038',
              'font-size': 14,
              'font-weight': 'bold',
              'offset-x': 0,
              'offset-y': -10,
              'stroke-color': '#FFFFFF',
              'stroke-visible': true,
              'stroke-width': 3,
            },
          },
          visible: true,
          zIndex: 4,
        },
        [this.zdmDataDataSources['cable'].datasource]: {
          id: 'cable',
          dataSourceName: this.zdmDataDataSources['cable'].datasource,
          geometryType: 'linestring',
          labelsVisible: true,
          opcaity: 1,
          style: {
            'fill-opacity': 1,
            labels: {
              attributes: [this.zdmDataDataSources['cable'].display_attribute_name],
              'font-color': '#188038',
              'font-size': 14,
              'font-weight': 'bold',
              'offset-x': 0,
              'offset-y': -10,
              'stroke-color': '#FFFFFF',
              'stroke-visible': true,
              'stroke-width': 3,
            },
            'line-color': '#188038',
            'line-dash': [],
            'line-width': 3,
          },
          visible: true,
          zIndex: 1,
        },
        [this.zdmDataDataSources['cabinet'].datasource]: {
          id: 'cabinet',
          dataSourceName: this.zdmDataDataSources['cabinet'].datasource,
          geometryType: 'point',
          labelsVisible: true,
          opcaity: 1,
          style: {
            'circle-color': '#188038',
            'circle-radius': 5,
            'fill-opacity': 1,
            labels: {
              attributes: [this.zdmDataDataSources['cabinet'].display_attribute_name],
              'font-color': '#188038',
              'font-size': 14,
              'font-weight': 'bold',
              'offset-x': 0,
              'offset-y': -10,
              'stroke-color': '#FFFFFF',
              'stroke-visible': true,
              'stroke-width': 3,
            },
          },
          visible: true,
          zIndex: 3,
        },
        [this.zdmDataDataSources['power_station'].datasource]: {
          id: 'power_station',
          dataSourceName: this.zdmDataDataSources['power_station'].datasource,
          geometryType: 'point',
          labelsVisible: true,
          opcaity: 1,
          style: {
            'circle-color': '#188038',
            'circle-radius': 5,
            'fill-opacity': 1,
            labels: {
              attributes: [this.zdmDataDataSources['power_station'].display_attribute_name],
              'font-color': '#188038',
              'font-size': 14,
              'font-weight': 'bold',
              'offset-x': 0,
              'offset-y': -10,
              'stroke-color': '#FFFFFF',
              'stroke-visible': true,
              'stroke-width': 3,
            },
          },
          visible: true,
          zIndex: 2,
        },
      };
    },
  },
};
