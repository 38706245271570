<template>
  <navbar-menu-tool
    v-model="isMenuOpen"
    :menu-data="{ name: 'routingService', position: 'left', allowOtherTop: true }"
    :button-bind="{ icon, tooltip, verboseName: tooltip }"
    has-close-header
  >
    <template #content>
      <div class="pa-4">
        <div class="d-flex flex-column routedraggable_wrapper" style="gap: 1rem; position: relative">
          <draggable
            v-model="routePoints"
            @change="handleDrag"
            handle=".drag-handle"
            class="d-flex flex-column"
            style="gap: 1rem"
          >
            <div v-for="(point, index) in routePoints" :key="index" class="d-flex" style="gap: 0.5rem">
              <div class="drag-handle d-flex align-center justify-center" style="width: 22px">
                <v-icon class="iconMarker" style="display: grid" :color="getMarkerColor(index)"
                  >$mapMarkerCustom</v-icon
                >
                <v-icon class="iconDrag" style="display: none" color="rgba(0,0,0,0.5)">$dragIndicator</v-icon>
              </div>
              <div style="flex-grow: 1">
                <data-input
                  class="stop-input"
                  :value="getStopValue(point.coordinates ?? [])"
                  @focus="e => e.preventDefault()"
                  v-bind="getDataInputProps(index)"
                  :placeholder="$i18n.t('sidebar.routingService.addStopPlaceholder')"
                  readonly
                  :data-type="{ name: 'text' }"
                  hide-details
                >
                  <template #append>
                    <div class="stop-input-clear" style="display: none">
                      <v-icon @click="removeStop(point.id)" small>mdi-close</v-icon>
                    </div>
                  </template>
                </data-input>
              </div>
              <div style="width: 16px" class="d-flex justify-center align-center">
                <v-icon v-if="![0, routePoints.length - 1].includes(index)" @click="removeStop(point.id, true)" small
                  >mdi-delete</v-icon
                >
              </div>
            </div>
          </draggable>
        </div>
        <div class="mt-6 d-flex">
          <span class="d-flex align-center" style="gap: 0.2rem">
            <span cols="auto" class="d-flex align-center">
              <v-icon size="18" color="primary" @click="addStop"> mdi-plus-circle </v-icon>
            </span>
            <span cols="auto">
              <link-underlined
                underline-only-on-hover
                @click="addStop"
                classes="primary--text"
                translation-path="sidebar.routingService.addStop"
              />
            </span>
          </span>
          <div class="ml-auto d-flex" style="gap: 0.5rem">
            <div>
              <data-input
                :data-type="{ name: 'select' }"
                :items="vehicleTypes"
                v-model="vehicleType"
                class="vehicle-type-input"
                outlined="false"
                hide-details
              >
                <template #selection="{ item }">
                  <div class="px-1">
                    <v-icon size="20" color="black">{{ item.icon }}</v-icon>
                  </div>
                </template>
                <template #item="{ item }">
                  <div class="d-flex justify-center align-center" style="gap: 1rem">
                    <v-icon :color="vehicleType === item.value ? 'primary' : 'black'" small>{{ item.icon }}</v-icon>
                    <span class="text-body-2">{{ item.text }}</span>
                  </div>
                </template>
              </data-input>
            </div>
            <div>
              <v-btn
                :disabled="computedDisabled"
                @click="search"
                color="primary"
                elevation="0"
                class="btn-test"
                height="36px"
                style="min-width: 36px !important; padding: 0 !important"
                :loading="isRouteLoading"
              >
                <v-icon color="white">mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <template v-if="result.distance">
          <v-divider class="my-6" />
          <div class="d-flex justify-space-between align-center">
            <div>
              <div class="d-flex align-center" style="gap: 1rem">
                <div style="line-height: normal" class="text-left text-h6">
                  {{ result.distance }}
                </div>
                <span>
                  <v-icon color="primary">{{ result.vehicleTypeIcon }}</v-icon>
                </span>
              </div>
              <div class="text-left text-body-2 text--secondary">
                {{ result.duration }}
              </div>
            </div>
            <div>
              <dots-menu
                :items="[
                  { name: 'geojson', customName: $i18n.t('menu.toGeojsonFile') },
                  { name: 'kml', customName: $i18n.t('menu.toKmlFile') },
                  { name: 'copy', customName: $i18n.t('menu.copyToLayer') },
                ]"
                @geojson="saveFile('geojson')"
                @kml="saveFile('kml')"
                @copy="toggleCopy()"
              >
                <template #activator="{ on }">
                  <v-tooltip bottom color="font">
                    <template #activator="{ on: onTooltip }">
                      <v-icon v-on="{ ...on, ...onTooltip }" color="primary">mdi-content-save</v-icon>
                    </template>
                    Zapisz trasę
                  </v-tooltip>
                </template>
              </dots-menu>
            </div>
          </div>
        </template>
      </div>
      <table-buttons-copy-objects-dialog
        v-if="isCopyLoaded"
        :is-visible.sync="isCopyVisible"
        :is-loading.sync="isCopyLoading"
        v-bind="getResultGeometryBind()"
      />
    </template>
  </navbar-menu-tool>
</template>

<script>
import NavbarMenuTool from '@/components/NavbarMenuTool';
import DataInput from '@/components/DataInput';
import LinkUnderlined from '@/components/LinkUnderlined';
import DotsMenu from '@/components/DotsMenu';

import { parseLength, saveGeometryToFile } from '@/assets/js/mapUtils';
import draggable from 'vuedraggable-multi';
import { get, call } from 'vuex-pathify';
import { transform } from 'ol/proj';
import { Style, Stroke } from 'ol/style';
import { asArray, asString } from 'ol/color';

let REVISION = 1;

/** Whole geojson object don't have to be reactive as it's only needed in export */
let RESULT_GEOMETRY;

export default {
  name: 'TheNavbarToolsPanelRoutingService',
  components: {
    NavbarMenuTool,
    DataInput,
    draggable,
    DotsMenu,
    TableButtonsCopyObjectsDialog: () => import('@/components/TableButtonsCopyObjectsDialog'),
    LinkUnderlined,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
  data: () => ({
    isCopyLoaded: false,
    isCopyVisible: false,
    isCopyLoading: false,
    isMenuOpen: false,
    isRouteLoading: false,
    vehicleType: 'car',
    routePoints: [{}, {}],
    result: {
      vehicleTypeIcon: null,
      distance: null,
      duration: null,
    },
  }),
  watch: {
    isMenuOpen: {
      handler(nV) {
        const enable = nV || !this.isRoutingServiceToolActive;
        if (!enable) this.resetState();
        this.$root.$emit(
          'routingService-action',
          enable,
          this.routingDrawend,
          this.routingModifyend,
          (feature, routingPointMarkerStyle) => {
            const featureOrderIndex = this.routePoints.findIndex(point => point.id === feature.getId());
            return routingPointMarkerStyle(featureOrderIndex, this.routePoints);
          }
        );
      },
    },
  },
  computed: {
    isRoutingServiceToolActive: get('tools/toolStatus@isRoutingServiceToolActive'),
    vehicleTypes() {
      return [
        {
          icon: 'mdi-car',
          value: 'car',
        },
        {
          icon: 'mdi-bike',
          value: 'bike',
        },
        {
          icon: 'mdi-walk',
          value: 'foot',
        },
      ].map(profile => ({ ...profile, text: this.$i18n.t(`sidebar.routingService.profiles.${profile.value}`) }));
    },
    computedDisabled() {
      return this.routePoints.some(point => !point.coordinates?.length);
    },
  },
  methods: {
    getRoute: call('search/getRoutingServiceRoute'),
    getResultGeometryBind() {
      if (!RESULT_GEOMETRY) return {};
      return {
        geojson: RESULT_GEOMETRY,
        geojsonCopyAttributes: Object.keys(RESULT_GEOMETRY.features[0].properties).map(attr => ({
          attribute: attr,
          label: attr,
          dataType: {
            name: 'float',
          },
        })),
      };
    },
    resetState() {
      this.routePoints = [{}, {}];
      this.vehicleType = 'car';
      this.result = {
        vehicleTypeIcon: null,
        distance: null,
        duration: null,
      };
      RESULT_GEOMETRY = null;
    },
    saveFile(type) {
      saveGeometryToFile(type, `${this.$i18n.t('sidebar.routingService.route')}.${type}`, RESULT_GEOMETRY);
    },
    toggleCopy() {
      this.isCopyLoaded = true;
      this.isCopyVisible = true;
    },
    handleDrag() {
      this.$root.$emit('getLayerContextById', {
        id: 'routingServiceStops',
        callback: layer => layer?.getSource()?.changed(),
      });
      if (RESULT_GEOMETRY) this.search();
    },
    routingModifyend(feature) {
      let pointsCopy = [...this.routePoints];
      const foundPoint = pointsCopy.find(point => point.id === feature.getId());
      if (foundPoint) foundPoint.coordinates = feature.getGeometry().getCoordinates();
      this.routePoints = pointsCopy;
      if (RESULT_GEOMETRY) this.search();
    },
    getStopValue(coords) {
      if (!coords.length) return;
      const latLon = transform(coords, this.$_config.defaultEpsg, 'EPSG:4326');
      return `${latLon[1].toFixed(6)}, ${latLon[0].toFixed(6)}`;
    },
    routingDrawend(layer, feature) {
      let pointsCopy = [...this.routePoints];
      let firstEmptyCoords = pointsCopy.findIndex(points => !points.coordinates?.length);
      if (!pointsCopy[firstEmptyCoords]) return;
      const id = `${REVISION}_${firstEmptyCoords}`;
      feature.setId(id);
      layer.getSource().addFeature(feature);
      pointsCopy[firstEmptyCoords] = {
        id,
        coordinates: feature.getGeometry().getCoordinates(),
      };
      REVISION += 1;
      this.routePoints = [...pointsCopy];
      if (RESULT_GEOMETRY) this.search();
    },
    clearResult() {
      this.$root.$emit('toggleCustomVectorLayer', { id: 'routingServiceRoute' });
      RESULT_GEOMETRY = null;
      this.result = {
        distance: null,
        duration: null,
        vehicleTypeIcon: null,
      };
    },
    async search() {
      if (this.routePoints.length < 2 || this.routePoints.some(point => !point.coordinates)) {
        this.clearResult();
        return;
      }
      this.isRouteLoading = true;
      try {
        const data = await this.getRoute({
          profile: this.vehicleType,
          coordinates: this.routePoints.map(point => point.coordinates),
        });
        this.$root.$emit('toggleCustomVectorLayer', {
          id: 'routingServiceRoute',
          geometry: data,
          layerParams: {
            style: new Style({
              stroke: new Stroke({
                color: (() => {
                  const [r, g, b] = Array.from(asArray(this.$_colors.primary));
                  return asString([r, g, b, 0.8]);
                })(),
                width: 8,
              }),
            }),
          },
        });
        RESULT_GEOMETRY = data;
        this.result = {
          distance: parseLength(data.features[0].properties.distance),
          duration: this.$_formatTime(data.features[0].properties.duration),
          vehicleTypeIcon: this.vehicleTypes.find(type => type.value === this.vehicleType)?.icon,
        };
      } catch {
        //
      } finally {
        this.isRouteLoading = false;
      }
    },
    addStop() {
      let pointsCopy = [...this.routePoints];
      pointsCopy.splice(this.routePoints.length - 1, 0, {});
      this.routePoints = [...pointsCopy];
    },
    removeStop(index, withInput = false) {
      let pointsCopy = [...this.routePoints];
      const foundPointIndex = pointsCopy.findIndex(point => point.id === index);
      if (foundPointIndex < 0) return;
      if (withInput) {
        pointsCopy.splice(foundPointIndex, 1);
      } else {
        pointsCopy[foundPointIndex] = {};
      }
      this.routePoints = [...pointsCopy];
      this.$root.$emit('removeRoutingServiceStop', index);
      this.search();
    },
    getDataInputProps(index) {
      switch (index) {
        case 0:
          return {
            label: this.$i18n.t('sidebar.routingService.startStop'),
          };
        case this.routePoints.length - 1:
          return {
            label: this.$i18n.t('sidebar.routingService.endStop'),
          };
        default:
          return {
            label: this.$i18n.t('sidebar.routingService.stop', { number: index }),
          };
      }
    },
    getMarkerColor(index) {
      switch (index) {
        case 0:
          return 'success';
        case this.routePoints.length - 1:
          return 'error';
        default:
          return 'primary';
      }
    },
  },
  beforeDestroy() {
    this.resetState();
  },
};
</script>

<style lang="scss" scoped>
.routedraggable_wrapper::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 10px;
  height: calc(100% - 18px);
  width: 2px;
  border-left: 2px dotted rgba(0, 0, 0, 0.2);
}

:deep(.v-input__append-inner) {
  margin: auto 0 !important;
}
.drag-handle {
  cursor: grab !important;
}

.drag-handle:hover :deep(.iconMarker) {
  display: none !important;
}
.drag-handle:hover :deep(.iconDrag) {
  display: flex !important;
}

.stop-input:hover :deep(.stop-input-clear) {
  display: block !important;
}

.iconMarker :deep(svg),
.iconDrag :deep(svg) {
  padding: 0.3rem 0 0.2rem 0 !important;
  justify-content: center;
  background-color: white;
}

::v-deep {
  .vehicle-type-input {
    height: 100%;
    .v-input__slot {
      min-height: 0px !important;
      padding: 0 4px !important;
    }
    .v-input__icon .v-icon {
      color: black !important;
    }
    .v-input {
      background-color: rgba(207, 207, 207, 0.4);
      height: 100%;
      align-items: center;
    }
    fieldset {
      border: none !important;
    }
    .v-input__append-inner {
      margin: 0 !important;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
    .v-input__prepend-inner {
      margin: 0 !important;
      .v-input__icon {
        .v-icon {
          font-size: 16px;
          padding-bottom: 3px;
        }
      }
    }
    .v-label {
      font-size: 12px;
      top: 0 !important;
    }
    .v-text-field__slot input {
      font-size: 12px !important;
      padding-bottom: 3px !important;
    }
    .v-text-field__suffix {
      color: rgba(0, 0, 0, 0.59);
      font-size: 10px;
    }
  }
}
</style>
