import api from '@/services/api';

import { make } from 'vuex-pathify';
import { v4 as uuid } from 'uuid';

const state = {
  token:
    localStorage.getItem('token') === 'null'
      ? JSON.parse(localStorage.getItem('token'))
      : localStorage.getItem('token'),
  tokenUke:
    localStorage.getItem('tokenUke') === 'null'
      ? JSON.parse(localStorage.getItem('tokenUke'))
      : localStorage.getItem('tokenUke'),
  redirectLogoutUrl:
    localStorage.getItem('redirectLogoutUrl') === 'null'
      ? JSON.parse(localStorage.getItem('redirectLogoutUrl'))
      : localStorage.getItem('redirectLogoutUrl'),
  sessionId: uuid(),
};

const mutations = {
  ...make.mutations(state),
  SET_TOKEN(state, value) {
    state.token = value;
    localStorage.setItem('token', value);
  },
  SET_TOKEN_UKE(state, value) {
    state.tokenUke = value;
    localStorage.setItem('tokenUke', value);
  },
  SET_REDIRECT_LOGOUT_URL(state, value) {
    state.redirectLogoutUrl = value;
    localStorage.setItem('redirectLogoutUrl', value);
  },
};

const actions = {
  async login({ commit }, payload) {
    const r = await api.post(`login`, payload, { returnErrorData: true });
    if (r.data.token) commit('SET_TOKEN', r.data.token);
    return r.status;
  },
  async loginUke({ commit }, payload) {
    const r = await api.post('external_login', payload);
    commit('SET_TOKEN', r.data.token);
    commit('SET_REDIRECT_LOGOUT_URL', r.data.additional_info.redirect_url);
    commit('SET_TOKEN_UKE', payload.credentials.token);
  },
  async logout({ commit }) {
    await api.get(`logout`);
    commit('SET_TOKEN', null);
  },
  async checkToken() {
    try {
      await api.get(`check_token`);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getResetPasswordLink(state, payload) {
    return await api.post(`auth/password_reset`, payload);
  },
  async resetPassword(state, payload) {
    const { hash, body } = payload;
    await api.post(`auth/password_reset/${hash}`, body);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
