<template>
  <v-row no-gutters class="justify-start" :class="classes">
    <v-col v-if="leftCol" :cols="leftCol" class="pr-5 text-left" :style="`${[$attrs.autoGrow && 'padding-top: 6px']}`">
      <v-row no-gutters>
        <v-col cols="12">
          <v-subheader class="font--text px-0" :class="{ 'text--disabled': disabledTitle }" style="height: auto">
            {{ subheader }}
          </v-subheader>
        </v-col>
        <v-col cols="10" class="text-left mt-n1">
          <italic-subheader :translation-path="subtitle" :params="subtitleParams"> </italic-subheader>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer v-if="spacer" />
    <slot :name="`${name}.input`" v-bind="{ ...$attrs, ...$props }">
      <v-col :cols="rightCol">
        <v-row no-gutters :class="rowClass">
          <v-col :cols="cols">
            <main-input-dialog-row-value
              v-if="!isCustom"
              v-bind="{ ...$attrs, ...$props }"
              v-model="computedValue"
              v-on="$listeners"
              @input="emitValue"
            />
          </v-col>
        </v-row>
      </v-col>
    </slot>
  </v-row>
</template>

<script>
import MainInputDialogRowValue from '@/components/MainInputDialogRowValue';
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'MainInputDialogRow',
  components: {
    MainInputDialogRowValue,
    ItalicSubheader,
  },
  inheritAttrs: false,
  props: {
    noResize: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    cols: {
      type: [Number, String],
      default: '6',
    },
    leftCol: {
      type: [Number, String],
      default: 6,
    },
    rightCol: {
      type: Number,
      default: 6,
    },
    rowHeight: {
      type: String,
      default: '24',
    },
    textClass: {
      type: String,
      default: '',
    },
    rowClass: {
      type: String,
      default: '',
    },
    dataType: {
      type: Object,
      default: () => {},
    },
    translationPath: {
      type: String,
      default: '',
    },
    straightTitle: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    subtitleParams: {
      type: Object,
      default: () => {},
    },
    align: {
      type: String,
      default: function() {
        const defaultAlignsForDataTypes = {
          color: 'center',
          textarea: this.$attrs.autoGrow ? 'top' : 'baseline',
        };
        return defaultAlignsForDataTypes[this.dataType?.name] || 'baseline';
      },
    },
    hasSuffix: {
      type: Boolean,
      default: true,
    },
    value: {
      required: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    spacer: {
      type: Boolean,
      default: false,
    },
    disabledTitle: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rowDivider: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    alignClass() {
      return `align-${this.align}`;
    },
    classes() {
      return [...this.inputClasses, ...[this.alignClass], ...(this.rowDivider ? ['row-divider'] : [])];
    },
    subheader() {
      return this.straightTitle
        ? `${this.straightTitle}:`
        : this.$i18n.t(this.translationPath) + (this.hasSuffix ? ':' : '');
    },
  },
  methods: {
    emitValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.row-divider {
  @include border(bottom);
}
</style>
