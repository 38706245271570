import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  analysisResult: {},
  terminalsVisible: false,
};

const mutations = make.mutations(state);

const actions = {
  async getTerminalsResult({ commit }, body) {
    const r = await api.post('sewer_analysis/sewer_congestion', body);
    commit('SET_ANALYSIS_RESULT', r.data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
