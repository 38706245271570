import { get, sync } from 'vuex-pathify';
export default {
  computed: {
    realtimeStationsMeasurementsStations: sync('edit/realtimeStationsMeasurementsStations'),
    realtimeStationsMeasurementsStationsIds() {
      return Object.keys(this.realtimeStationsMeasurementsStations || {});
    },
    realtimeStationsDirectionsStations: sync('edit/realtimeStationsDirectionsStations'),
    realtimeStationsDirectionsStationsIds() {
      return Object.keys(this.realtimeStationsDirectionsStations || {});
    },

    realtimeStationsMeasurementsWsUrl: get('admin/settingsValues@realtime_stations_measurements_websocket_address'),
    socketsRealtimeStationsMeasurements() {
      return {
        name: 'realtimeStationsMeasurements',
        wsUrl: this.realtimeStationsMeasurementsWsUrl,
        wsDev: import.meta.env.VUE_APP_WS_DEV === 'true',
        reconnectionSettings: {
          basic_time: 1000,
          time_multiplier: 2,
          max_time: 64000,
        },
        additionalOnMessageAction: this.onSocketsRealtimeStationsMeasurementsAction,
      };
    },

    realtimeStationsDirectionsWsUrl: get('admin/settingsValues@realtime_stations_directions_websocket_address'),
    socketsRealtimeStationsDirections() {
      return {
        name: 'realtimeStationsDirections',
        wsUrl: this.realtimeStationsDirectionsWsUrl,
        wsDev: import.meta.env.VUE_APP_WS_DEV === 'true',
        reconnectionSettings: {
          basic_time: 1000,
          time_multiplier: 2,
          max_time: 64000,
        },
        additionalOnMessageAction: this.onSocketsRealtimeStationsDirectionsAction,
      };
    },
  },
  methods: {
    onSocketsRealtimeStationsMeasurementsAction(data) {
      const station = JSON.parse(data);
      if (this.realtimeStationsMeasurementsStationsIds.includes(station['StationId'])) {
        this.$root.$emit('updateRealtimeStationsMeasurementsStation', station);
      }
    },

    onSocketsRealtimeStationsDirectionsAction(data) {
      const station = JSON.parse(data);
      if (this.realtimeStationsDirectionsStationsIds.includes(station['StationId'])) {
        this.$root.$emit('updateRealtimeStationsDirectionsStation', station);
      }
    },
  },
};
