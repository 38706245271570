import Vue from 'vue';
import { make } from 'vuex-pathify';

import api from '@/services/api';
import {
  arrayToObject,
  objectToArray,
  saveFileFromBackend,
  findLayerByLayerId,
  getFlatGroupsLayers,
  findLayerIndexByLayerId,
  groupByProperty,
} from '@/assets/js/utility';

const state = {
  metadata: {},
  additionalMetadata: {},
  project: undefined,
  defaultProject: undefined,
  unchangedProject: undefined,
  projects: {},
  projectId: undefined,
  currentLayer: undefined,
  currentLayerSelectedObjects: {},
  currentLayerSelectedObjectsIds: [],
  currentLayerMetadata: undefined,
  dataSourcesForms: {},
  featuresLayers: {},
  dataSourcesFeatures: {},
  layersFeatures: {},
  layersFilters: {},
  layers: {},
  layersCount: {},
  basemapLayer: {},
  dataTypes: [],
  layersSchemaByScope: {},
  expressions: [],
  icons: [],
  templateIcons: [],
  currentLayerSchema: [],
  defaultValuesPolicies: undefined,
  measurementUnits: {},
  relationValuesMapping: {},
  easements: {},
  folders: {},
  folderDocuments: {},
  cacheLayersMetadata: {},
  additionalAttributesValues: undefined,
};

const mutations = {
  ...make.mutations(state),
  SET_PROJECT_ID(state, value) {
    const currentUserId = this.state.users.currentUser.id;
    const stringifiedUsersProjects = JSON.stringify({
      ...JSON.parse(localStorage.getItem('usersProjects')),
      [currentUserId]: value,
    });
    localStorage.setItem('usersProjects', stringifiedUsersProjects);
    state.projectId = value;
  },
  SET_LAYERS: (state, value) => {
    state.layers = { ...state.layers, ...value };
  },
  ADD_PROJECT_GROUP: (state, data) => {
    const { value, id } = data;
    const group = { name: value, type: 'group', layers: [], id };
    Vue.set(state.project, 'layers', [...state.project.layers, ...[group]]);
  },
  CHANGE_GROUP_NAME: (state, data) => {
    const { index, name } = data;
    Vue.set(state.project.layers[index], 'name', name);
  },
  ADD_PROJECT_LAYERS: (state, value) => {
    Vue.set(state.project, 'layers', [...state.project.layers, ...value]);
  },
  ADD_DATA_SOURCE_FEATURES: (state, value) => {
    const { dataSource, features } = value;
    Vue.set(state.dataSourcesFeatures, dataSource, features);
  },
  SET_LAYERS_FILTERS: (state, value) => {
    const { layerId, ...rest } = value;
    Vue.set(state.layersFilters, layerId, { ...rest });
  },
  DELETE_LAYERS_FILTERS: (state, value) => {
    Vue.delete(state.layersFilters, value);
  },
  ADD_DATA_SOURCE_FORM: (state, value) => {
    const { dataSource, form } = value;
    Vue.set(state.dataSourcesForms, dataSource, form);
  },
  ADD_METADATA: (state, value) => {
    const { dataSource, metadata } = value;
    Vue.set(state.metadata, dataSource, metadata);
  },
  DELETE_DATA_SOURCE: (state, value) => {
    Vue.delete(state.metadata, value);
  },
  EDIT_DATA_SOURCE_METADATA: (state, value) => {
    Vue.set(state.metadata, value.name, { ...state.metadata[value.name], ...value.body });
  },
  ADD_LAYER_VISIBILITY: (state, value) => {
    const { dataSource, visible } = value;
    Vue.set(state.layersVisibility, dataSource, visible);
  },
  SET_LAYER_VISIBILITY: (state, value) => {
    const { layerId, visible } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    Vue.set(layer, 'visible', visible);
  },
  SET_ALL_LAYERS_LABELS_VISIBILITY: (state, visible) => {
    const layers = getFlatGroupsLayers(state.project.layers).layers;
    for (const layer of layers) {
      Vue.set(layer, 'labels_visible', visible);
    }
  },
  SET_LAYER_LABELS_VISIBILITY: (state, value) => {
    const { layerId, visible } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    Vue.set(layer, 'labels_visible', visible);
  },
  SET_LAYER_ARROWS_VISIBILITY: (state, value) => {
    const { layerId, visible } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    Vue.set(layer, 'direction_arrows_visible', visible);
  },
  SET_LAYER_OPACITY: (state, value) => {
    const { layerId, opacity } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    Vue.set(layer, 'opacity', opacity);
  },
  SET_MODULE_OPACITY: (state, value) => {
    const { layerId, opacity } = value;
    console.log(`layerid: ${layerId}, opacity: ${opacity} `);
  },
  SET_LAYER_STYLE: (state, value) => {
    const { layerId, style } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    Vue.set(layer, 'style', style);
  },
  SET_LAYER_STYLE_KEY_VALUE: (state, value) => {
    const { layerId, key, keyValue } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    Vue.set(layer, 'style', { ...layer.style, [key]: keyValue });
  },
  SET_LAYER_STYLE_CLASS_KEY_VALUE: (state, value) => {
    const { layerId, classIndex, classValue, prop = 'visible', propValue } = value;
    const layer = findLayerByLayerId(state.project.layers, layerId);
    if (Object.prototype.hasOwnProperty.call(value, 'classIndex')) {
      const ranges = layer.style.ranges;
      if (ranges?.values?.[classIndex]) {
        ranges.values[classIndex][prop] = propValue;
        Vue.set(layer, 'style', { ...layer.style, ranges });
      }
    } else if (Object.prototype.hasOwnProperty.call(value, 'classValue')) {
      const uniques = layer.style.uniques;
      if (uniques?.values?.[classValue]) {
        uniques.values[classValue][prop] = propValue;
        Vue.set(layer, 'style', { ...layer.style, uniques });
      }
    }
  },
  ADD_PROJECT: (state, value) => {
    Vue.set(state.projects, value.id, value);
  },
  DISCARD_PROJECT: (state, value) => {
    Vue.set(state.projects, value.id, value);
  },
  SET_PROJECTS: (state, { data, withFullData = false, withDefault = false } = {}) => {
    const projects = arrayToObject(data);
    const defaultProject = JSON.parse(JSON.stringify(state.projects['default'] || ''));
    if (withFullData) {
      Vue.set(state, 'projects', projects);
    } else {
      Vue.set(
        state,
        'projects',
        Object.keys(projects).reduce((total, current) => {
          const { layers, zoom, map_center } = state.projects[current] || {};
          const stateProjectData = {
            ...(layers ? { layers } : {}),
            ...(zoom ? { zoom } : {}),
            ...(map_center ? { map_center } : {}),
          };
          return { ...total, [current]: { ...projects[current], ...stateProjectData } };
        }, {})
      );
    }
    if ((!withDefault || !withFullData) && defaultProject && !state.projects['default']) {
      Vue.set(state.projects, 'default', defaultProject);
    }
  },
  SET_PROJECT_NAME: (state, value) => {
    state.project.name = value;
    state.projects[state.project.id].name = value;
  },
  DELETE_PROJECT: (state, value) => {
    Vue.delete(state.projects, value);
  },
  DELETE_PROJECT_LAYER: (state, value) => {
    const { parentIndex, index } = findLayerIndexByLayerId(state.project.layers, value);
    if (!parentIndex && parentIndex !== 0) {
      Vue.delete(state.project.layers, index);
    } else {
      const groupLayers = state.project.layers[parentIndex].layers;
      Vue.delete(groupLayers, index);
    }
  },
  DELETE_PROJECT_GROUP: (state, value) => {
    Vue.delete(state.project.layers, value);
  },
  SET_PROJECT_ZOOM(state, value) {
    state.project.zoom = value;
  },
  SET_PROJECT_CENTER(state, value) {
    state.project.map_center = value;
  },
  SET_PROJECT_LAYERS_ORDER(state, value) {
    state.project.layers = value;
  },
  SET_PROJECT_BASEMAP_VISIBLE(state, value) {
    Vue.set(state.project, 'basemap_visible', value);
  },
  SET_LAYERS_COUNT: (state, value) => {
    const { dataSource, count } = value;
    Vue.set(state.layersCount, dataSource, count);
  },
  ADD_ATTRIBUTE: (state, value) => {
    state.metadata[value.dataSourceName].attributes_schema.attributes.push(value.data);
  },
  DELETE_ATTRIBUTE: (state, value) => {
    const attributes = state.metadata[value.dataSourceName].attributes_schema.attributes;
    state.metadata[value.dataSourceName].attributes_schema.attributes = attributes.filter(
      a => a.name !== value.attributeName
    );
  },
  SET_ATTRIBUTE: (state, value) => {
    const attributes = state.metadata[value.payload.dataSourceName].attributes_schema.attributes.map(a => {
      return a.name === value.payload.attributeName ? value.data : a;
    });
    state.metadata[value.payload.dataSourceName].attributes_schema.attributes = attributes;
  },
  ADD_FEATURES_LAYER: (state, payload) => {
    const { id, value } = payload;
    Vue.set(state.featuresLayers, id, value);
  },
  ADD_CURRENT_LAYER_SCHEMA_GROUP: (state, payload) => {
    Vue.set(state.currentLayerSchema, state.currentLayerSchema.length, payload);
  },
  EDIT_CURRENT_LAYER_SCHEMA_GROUP: (state, payload) => {
    const { index, value } = payload;
    const editedItem = state.currentLayerSchema[index];
    Vue.set(state.currentLayerSchema, index, { ...editedItem, ...value });
  },
  DELETE_CURRENT_LAYER_SCHEMA_GROUP: (state, index) => {
    Vue.delete(state.currentLayerSchema, index);
  },
  SET_ATTRIBUTE_PROPERTY: (state, payload) => {
    const { parentLabel, editedAttributeName, value, key } = payload;
    const editedAttribute = state.currentLayerSchema
      .find(group => group.label === parentLabel)
      .elements.find(element => element.attribute === editedAttributeName);
    Vue.set(editedAttribute, key, value);
  },
  SET_CURRENT_LAYER_SELECTION_FEATURE: (state, payload) => {
    const { feature_id, feature } = payload;
    Vue.set(state.currentLayerSelectedObjects, feature_id, feature);
  },
  SET_CURRENT_LAYER_SELECTION_FEATURES: (state, features) => {
    const crs = features.crs;
    for (const feature of features.features) {
      Vue.set(state.currentLayerSelectedObjects, feature.id, { ...feature, ...{ crs } });
    }
  },
  DELETE_CURRENT_LAYER_SELECTION_FEATURE: (state, feature_id) => {
    Vue.delete(state.currentLayerSelectedObjects, feature_id);
  },
  SELECT_CURRENT_LAYER_OBJECT_IDS: (state, feature_ids) => {
    const featuresIds = Array.from(new Set([...feature_ids, ...state.currentLayerSelectedObjectsIds]));
    state.currentLayerSelectedObjectsIds = featuresIds;
  },
  UNSELECT_CURRENT_LAYER_OBJECT_IDS: (state, features_ids) => {
    const featuresIds = state.currentLayerSelectedObjectsIds.filter(oId => !features_ids.includes(oId));
    state.currentLayerSelectedObjectsIds = featuresIds;
  },
  UNSELECT_ALL_CURRENT_LAYER_OBJECT_ID: state => {
    state.currentLayerSelectedObjectsIds = [];
  },
  SET_EASEMENTS: (state, data) => {
    const { easementsProps, crs } = data;
    const easements = {};
    for (const easement of easementsProps) {
      easements[easement.id] = {
        ...easement.properties,
        ...{ id: easement.id },
        ...{ geometry: { ...easement.geometry, ...{ crs } } },
      };
    }
    state.easements = easements;
  },
  DELETE_EASEMENT: (state, easement_id) => {
    Vue.delete(state.easements, easement_id);
  },
  SET_FOLDERS: (state, data) => {
    const { foldersProps, crs } = data;
    const folders = {};
    for (const folder of foldersProps) {
      folders[folder.id] = {
        ...folder.properties,
        ...{ id: folder.id },
        ...{ geometry: { ...folder.geometry, ...{ crs } } },
      };
    }
    state.folders = folders;
  },
  DELETE_FOLDER: (state, folder_id) => {
    Vue.delete(state.folders, folder_id);
  },
  SET_FOLDER_DOCUMENTS: (state, payload) => {
    const { folder_id, data } = payload;
    Vue.set(state.folderDocuments, folder_id, data);
  },

  SET_RELATION_VALUES_MAPPING: (state, payload) => {
    const { data, data_source_name, attribute_name } = payload;
    if (!state.relationValuesMapping[data_source_name]) {
      state.relationValuesMapping[data_source_name] = {};
    }
    const formattedData = {};
    for (const d of data) {
      formattedData[d.value] = d.display_value;
    }
    state.relationValuesMapping[data_source_name][attribute_name] = formattedData;
  },
  SET_LAYERS_SCHEMA: (state, data) => {
    const { groups, layers } = data;
    const groupedGroups = groupByProperty(groups, 'schema_scope');
    const groupedLayers = groupByProperty(layers, 'layer_scope');

    Object.keys(groupedGroups).forEach(scope => {
      state.layersSchemaByScope = {
        ...state.layersSchemaByScope,
        [scope]: { ...state.layersSchemaByScope?.[scope], groups: groupedGroups[scope] },
      };
    });

    Object.keys(groupedLayers).forEach(scope => {
      state.layersSchemaByScope = {
        ...state.layersSchemaByScope,
        [scope]: { ...state.layersSchemaByScope?.[scope], layers: groupedLayers[scope] },
      };
    });
  },
  SET_LAYERS_SCHEMA_BY_SCOPE: (state, payload) => {
    const { data, layer_scope } = payload;
    Vue.set(state.layersSchemaByScope, layer_scope, data);
  },
  DELETE_LAYER: (state, id) => {
    Vue.delete(state.layers, id);
  },
  DELETE_LAYERS_BY_DATA_SOURCE: (state, dataSourceName) => {
    const deletedLayers = objectToArray(state.layers)
      .filter(layer => layer.data_source_name === dataSourceName)
      .map(layer => layer.id);
    state.layers = arrayToObject(
      objectToArray(state.layers).filter(layer => layer.data_source_name !== dataSourceName)
    );
    for (const scope of Object.keys(state.layersSchemaByScope)) {
      if (state.layersSchemaByScope[scope].layers) {
        state.layersSchemaByScope[scope].layers = state.layersSchemaByScope[scope].layers.filter(
          layer => !deletedLayers.includes(layer.id)
        );
      }
      if (state.layersSchemaByScope[scope].groups) {
        state.layersSchemaByScope[scope].groups = state.layersSchemaByScope[scope].groups.map(group => {
          return { ...group, layers: group.layers.filter(layer => !deletedLayers.includes(layer.id)) };
        });
      }
    }
  },
  SET_CACHE_LAYERS_METADATA: (state, data) => {
    state.cacheLayersMetadata = arrayToObject(data);
  },
};

const actions = {
  getProjectLayers({ state }) {
    return state.project.layers;
  },
  async getAdditionalLayersMetadata(
    // Data sources without scope are only used in specific cases
    // and are not return in 'get:dataio/data_sources/metadata'
    // so they are requested and stored separately
    { commit },
    { params = { with_attributes_schema: true }, skipDefaultErrorHandler = true } = {}
  ) {
    const dataSourcesNames = ['users_users'];
    const responses = [];
    for await (const dataSourceName of dataSourcesNames) {
      try {
        const r = await api.get(`/dataio/data_sources/${dataSourceName}/metadata`, {
          params,
          skipDefaultErrorHandler,
        });
        responses.push(r);
      } catch {
        //
      }
    }
    const metadata = responses
      .map(el => el.data.data)
      .reduce((total, current) => {
        return { ...total, [current.name]: current };
      }, {});
    commit('SET_ADDITIONAL_METADATA', metadata);
  },
  async getLayersMetadata({ commit, dispatch }, params) {
    const { withAdditionalMetadata } = params;
    delete params.withAdditionalMetadata;
    if (withAdditionalMetadata) {
      await dispatch('getAdditionalLayersMetadata');
    }
    const r = await api.get('/dataio/data_sources/metadata', {
      params,
    });
    const metadata = r.data.data.reduce((total, current) => {
      return { ...total, [current.name]: current };
    }, {});
    commit('SET_METADATA', metadata);
  },
  async getLayerMetadata({ commit }, payload) {
    const { dataSource, params } = payload;
    const r = await api.get(`/dataio/data_sources/${dataSource}/metadata`, { params });
    commit('ADD_METADATA', { dataSource, metadata: r.data.data });
    return { dataSource, metadata: r.data.data };
  },
  async deleteDataSource({ commit }, name) {
    await api.delete(`/dataio/data_sources/${name}`);
    commit('DELETE_DATA_SOURCE', name);
  },
  async deleteDatasourceFeature({ dispatch }, { datasource, id, params }) {
    await api.delete(`/dataio/data_sources/${datasource}/features/${id}`, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
  },
  async deleteDatasourceFeatures({ dispatch }, { datasource, payload, params }) {
    await api.delete(`/dataio/data_sources/${datasource}/features`, { data: payload, params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
  },
  async addDatasourceFeature({ dispatch }, { datasource, body, params }) {
    const r = await api.post(`/dataio/data_sources/${datasource}/features`, { feature: body }, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async editDatasourceFeature({ dispatch }, { datasource, id, body, params }) {
    const r = await api.put(`/dataio/data_sources/${datasource}/features/${id}`, { feature: body }, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async transformDatasource({ dispatch }, { datasource, payload }) {
    await api.post(`/dataio/data_sources/${datasource}/transform`, payload);
    await dispatch('getLayersMetadata', {
      with_attributes_schema: true,
      with_layers: true,
      with_last_edit: true,
      with_owner: true,
    });
  },
  async addTopoDatasourceFeature({ dispatch }, { datasource, body, params, skipDefaultErrorHandler = false }) {
    const r = await api.post(`/topological_edit/insert_feature/${datasource}`, body, {
      params,
      skipDefaultErrorHandler,
    });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async editTopoDatasourceFeature({ dispatch }, { datasource, id, body, params }) {
    const r = await api.put(`/topological_edit/update_feature/${datasource}/${id}`, body, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async deleteTopoDatasourceFeatures({ dispatch }, { datasource, payload, params }) {
    const r = await api.delete(`/topological_edit/delete_features/${datasource}`, { data: payload, params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async editMultipleDataSourceFeatures({ dispatch }, payload) {
    const r = await api.post(`/dataio/data_sources/${payload.data_source_name}/features/edit`, payload);
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', payload.data_source_name, { root: true });
    return r;
  },
  async invertLine(store, { datasource, id, params }) {
    return await api.post(`/topological_edit/reverse_line/${datasource}/${id}`, { params });
  },
  async splitTopologicalLine({ dispatch }, { datasource, id, body, params }) {
    const r = await api.post(`/topological_edit/split_line/${datasource}/${id}`, body, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async joinTopologicalLines({ dispatch }, { datasource, body, params }) {
    const r = await api.post(`/topological_edit/merge_lines/${datasource}`, body, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async splitLine({ dispatch }, { datasource, id, body, params }) {
    const r = await api.put(`/dataio/data_sources/${datasource}/features/${id}/split`, body, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async joinLines({ dispatch }, { datasource, id, body, params }) {
    const r = await api.put(`/dataio//data_sources/${datasource}/features/${id}/join`, body, { params });
    await dispatch('attributesSchema/updateDatasourceRelationValuesMapping', datasource, { root: true });
    return r;
  },
  async getIntersectingLines(store, { datasource, id }) {
    return await api.get(`/topological_edit/intersecting_lines/${datasource}/${id}`);
  },
  async getTopologicalParentObjects(store, { dataSourceName, attributeName, geometry }) {
    const r = await api.post(`/topological_edit/parent_objects/${dataSourceName}/${attributeName}`, { geometry });
    return r.data.data;
  },
  async getLayerFeature(store, payload) {
    const { layer_id, features_filter, feature_id, skipDefaultErrorHandler = false } = payload;
    const params = {
      with_features: true,
      with_count: true,
      with_features_bbox: false,
      with_collection_bbox: false,
      with_geometry: !payload.only_attributes,
    };
    const r = await api.post(
      `/layers/features_layers/${layer_id}/features/${feature_id}`,
      { features_filter },
      { params, skipDefaultErrorHandler }
    );
    return r.data.data;
  },
  async editDataSourceMetadata({ commit }, payload) {
    const { name, body } = payload;
    await api.put(`/dataio/data_sources/${name}/metadata`, body);
    commit('EDIT_DATA_SOURCE_METADATA', payload);
  },
  /**
   * @param store
   * @param payload
   */
  async getLayerFeatures(store, payload) {
    const { layer_id, features_filter, sorting, params } = payload;
    return await api.post(
      `/layers/features_layers/${layer_id}/features`,
      {
        features_filter,
        sorting,
      },
      {
        params: {
          with_count: true,
          ...params,
        },
      }
    );
  },
  async exportLayerFeatures(store, payload) {
    const { layer_id, attributes, features_filter, sorting, params, format } = payload;
    const r = await api.post(
      `/layers/features_layers/${layer_id}/export`,
      { attributes, features_filter, sorting, format },
      {
        params,
        responseType: 'blob',
        timeout: 0,
      }
    );
    saveFileFromBackend(r.data, r.headers);
    return r;
  },
  async getLayerPermissions(store, layerId) {
    return await api.get(`/layers/${layerId}/permissions`);
  },
  async getDataSourceFeatures(
    { commit },
    { dataSource, filters, countOnly = false, with_geometry = true, idsOnly = false, idsDescsOnly = false }
  ) {
    /**
     * @property {Boolean} with_features - Czy pobrać obiekty
     * @property {Boolean} with_count - Czy pobrać liczbę obiektów
     * @property {Boolean} with_features_bbox - Czy pobrać bbox dla każdego obiektu
     * @property {Boolean} with_collection_bbox - Czy pobrać bbox dla całego FeatureCollection
     */
    const params = {
      with_features: true,
      with_count: true,
      with_features_bbox: false,
      with_collection_bbox: false,
      with_geometry: with_geometry,
    };
    if (countOnly) {
      params.with_features = false;
    }
    if (idsOnly) {
      params.ids_only = true;
    }
    if (idsDescsOnly) {
      params.ids_descs_only = true;
    }

    const r = await api.post(`/dataio/data_sources/${dataSource}/read`, { features_filter: filters }, { params });
    /**
     * @property {Number} features_count - Liczba elementów
     * @property {Object} features - Obiekty zwracane są jako FeatureCollection
     * @property {Array} bbox - Bbox całego zestwu obiektów
     */
    const data = r.data.data;

    commit('SET_LAYERS_COUNT', { dataSource, count: data.features_count });
    if (countOnly) {
      return data.features_count;
    } else {
      commit('ADD_DATA_SOURCE_FEATURES', {
        dataSource,
        features: data.features,
      });
      return idsOnly || idsDescsOnly ? data.ids : data.features;
    }
  },
  async getNearestNeighboursDataSourceFeatures(
    { commit },
    {
      dataSource,
      filters,
      geometry,
      nearest_neighbours_buffer,
      countOnly = false,
      with_geometry = true,
      idsOnly = false,
      idsDescsOnly = false,
      limit = 15,
    }
  ) {
    /**
     * @property {Boolean} with_features - Czy pobrać obiekty
     * @property {Boolean} with_count - Czy pobrać liczbę obiektów
     * @property {Boolean} with_features_bbox - Czy pobrać bbox dla każdego obiektu
     * @property {Boolean} with_collection_bbox - Czy pobrać bbox dla całego FeatureCollection
     */
    const params = {
      with_features: true,
      with_count: true,
      with_features_bbox: false,
      with_collection_bbox: false,
      with_geometry: with_geometry,
      limit: limit,
    };
    if (countOnly) {
      params.with_features = false;
    }
    if (idsOnly) {
      params.ids_only = true;
    }
    if (idsDescsOnly) {
      params.ids_descs_only = true;
    }

    const r = await api.post(
      `/dataio/data_sources/${dataSource}/nearest_neighbours`,
      {
        features_filter: filters,
        geometry,
        nearest_neighbours_buffer,
      },
      { params }
    );
    /**
     * @property {Number} features_count - Liczba elementów
     * @property {Object} features - Obiekty zwracane są jako FeatureCollection
     * @property {Array} bbox - Bbox całego zestwu obiektów
     */
    const data = r.data.data;

    commit('SET_LAYERS_COUNT', { dataSource, count: data.features_count });
    if (countOnly) {
      return data.features_count;
    } else {
      // eslint-disable-next-line
      commit('ADD_DATA_SOURCE_FEATURES', {
        dataSource,
        features: data.features,
      });
      return idsOnly || idsDescsOnly ? data.ids : data.features;
    }
  },
  async getDataSourceFeature(state, payload) {
    const { dataSource, feature_id } = payload;
    const params = {
      with_features_bbox: true,
    };
    const r = await api.post(`/dataio/data_sources/${dataSource}/read/${feature_id}`, {}, { params });
    return r.data.data;
  },
  async getProjects({ commit, dispatch, rootState }, params = {}) {
    const promises = [
      api.get(`/projects`, {
        params: {
          ...params,
          all_users: rootState.users.currentUser.is_superadmin ? true : undefined,
          with_default: false,
        },
      }),
      ...(params.with_default ? [dispatch('getProject', { id: 'default' })] : []),
    ];
    const [r] = await Promise.all(promises);
    commit('SET_PROJECTS', { data: r.data.data, withFullData: params.full_data || false, withDefault: false });
  },
  async getProject({ commit }, { id, params }) {
    const project = (await api.get(`/projects/${id}`, { params })).data.data;
    if (project.role === 'default') project.id = 'default';
    commit('ADD_PROJECT', project);
  },
  async getDataSourceForm({ commit }, dataSource) {
    const r = await api.get(`/dataio/forms/${dataSource}/default`);
    commit('ADD_DATA_SOURCE_FORM', { dataSource, form: r.data.data });
    return r;
  },
  async editProject({ dispatch }, { body, id }) {
    await api.put(`/projects/${id}`, body);
    await Promise.all([dispatch('getProjects'), dispatch('getProject', { id })]);
  },
  async deleteProject({ commit }, id) {
    const r = await api.delete(`/projects/${id}`);
    commit('DELETE_PROJECT', id);
    return r;
  },
  async shareProject(store, { userIds, id }) {
    return await api.post(`/projects/${id}/share`, { sharees: userIds });
  },
  async setDefaultProject({ dispatch }, id) {
    await api.post(`/projects/default/${id}/set`);
    await dispatch('getProjects', { with_default: true });
  },
  async setPredefinedProject({ dispatch }, id) {
    await api.post(`/projects/predefined/${id}/set`);
    await dispatch('getProjects', { with_default: true });
  },
  async unsetPredefinedProject({ dispatch }, id) {
    await api.post(`/projects/predefined/${id}/unset`);
    await dispatch('getProjects', { with_default: true });
  },
  async createProject({ commit, dispatch, state }, { coordinateSystemEpsg, center, zoom, name, layers = [] }) {
    const payload = {
      map_center: {
        coordinates: center,
        crs: {
          properties: {
            name: coordinateSystemEpsg,
          },
          type: 'name',
        },
        type: 'Point',
      },
      name,
      zoom,
      layers,
    };
    const r = await api.post(`/projects`, payload);
    await dispatch('getProjects', { with_default: true });
    const project = state.projects[r.data.data];
    commit('SET_PROJECT', project);
  },
  async getLayers({ commit }, params) {
    const r = await api.get(`/layers`, { params });
    commit('SET_LAYERS', arrayToObject(r.data.data));
    const baseMapLayer = objectToArray(r.data.data).find(layer => layer.role === 'basemap');
    if (baseMapLayer) {
      commit('SET_BASEMAP_LAYER', baseMapLayer);
    }
  },
  async getDataTypes({ commit }) {
    const r = await api.get('dataio/data_types');
    commit('SET_DATA_TYPES', r.data.data);
  },
  async addAttribute({ commit }, payload) {
    const { data, dataSourceName } = payload;
    const r = await api.post(`dataio/data_sources/${dataSourceName}/attribute`, data);
    commit('ADD_ATTRIBUTE', { dataSourceName, data: r.data.data });
    return r;
  },
  async deleteAttribute({ commit }, payload) {
    const { attributeName, dataSourceName } = payload;
    await api.delete(`dataio/data_sources/${dataSourceName}/${attributeName}`);
    commit('DELETE_ATTRIBUTE', payload);
  },
  async getLayersSchema({ commit }) {
    const r = await api.get('layers/schema');
    commit('SET_LAYERS_SCHEMA', r.data.data);
  },
  async getLayersSchemaByScope({ commit }, { layer_scope, schema_scope = 'core' }) {
    const r = await api.get(`layers/schema/${schema_scope}`, { params: { layer_scope } });
    commit('SET_LAYERS_SCHEMA_BY_SCOPE', { data: r.data.data, layer_scope });
  },
  async addNewLayersGroup({ dispatch }, payload) {
    const { schema_scope, name, layer_scope } = payload;
    await api.post(`layers/schema/${schema_scope}/groups`, { name });
    await dispatch('getLayersSchemaByScope', { layer_scope, schema_scope });
  },
  async editLayerGroup({ dispatch }, { group_id, body, scope, schema_scope }) {
    await api.put(`layers/groups/${group_id}`, body);
    await dispatch('getLayersSchemaByScope', { layer_scope: scope, schema_scope });
  },
  async deleteLayerGroup({ dispatch }, { group_id, scope, schema_scope }) {
    await api.delete(`layers/groups/${group_id}`);
    await dispatch('getLayersSchemaByScope', { layer_scope: scope, schema_scope });
  },
  async attachMultipleLayerGroup({ dispatch }, { body, scope, schema_scope, params }) {
    await api.put(`layers/schema/${schema_scope}`, body, { params });
    const promises = [
      dispatch('getLayersSchemaByScope', { layer_scope: scope, schema_scope }),
      ...(schema_scope === 'cache' ? [dispatch('getCacheLayersMetadata')] : []),
    ];
    await Promise.all(promises);
  },
  async attachLayerGroup({ dispatch }, { layer_id, body, scope, schema_scope, params }) {
    await api.put(`layers/schema/${schema_scope}/${layer_id}`, body, { params });
    const promises = [
      dispatch('getLayersSchemaByScope', { layer_scope: scope, schema_scope }),
      ...(schema_scope === 'cache' ? [dispatch('getCacheLayersMetadata')] : []),
    ];
    await Promise.all(promises);
  },
  async addLayer({ dispatch }, payload) {
    const { layer_type, skipMetadataParsing, body } = payload;
    const r = await api.post(`layers/${layer_type}`, body);
    if (!skipMetadataParsing) {
      await Promise.all([
        dispatch('getLayers', { scope: body.layer_scope }),
        dispatch('getLayersSchemaByScope', { layer_scope: body.layer_scope, schema_scope: body.layer_scope }),
      ]);
    }
    return r.data.data;
  },
  async editLayer({ dispatch }, { layer_id, layer_type, body, scope, skipMetadataParsing }) {
    await api.put(`layers/${layer_type}/${layer_id}`, body);
    if (!skipMetadataParsing) {
      await Promise.all([
        dispatch('getLayers', { scope }),
        ...(scope !== 'module'
          ? [dispatch('getLayersSchemaByScope', { layer_scope: scope, schema_scope: scope })]
          : []),
      ]);
    }
  },
  async deleteLayer({ dispatch }, { layer_id, layer_type, scope }) {
    await api.delete(`layers/${layer_type}/${layer_id}`);
    await Promise.all([
      dispatch('getLayers', { scope }),
      dispatch('getLayersSchemaByScope', { layer_scope: scope, schema_scope: scope }),
    ]);
  },
  async deleteLayerWithouUpdate({ commit }, { layer_id, layer_type }) {
    await api.delete(`layers/${layer_type}/${layer_id}`);
    commit('DELETE_LAYER', layer_id);
  },
  async getExpressions({ commit }) {
    const r = await api.get('/dataio/boolean_expressions/metadata');
    r.data.data.push(
      {
        name: 'IS_NULL',
        type: 'operator',
        verbose_name: 'IS NULL',
        allowed_rhs_data_types: { name: 'any' },
        isSpecial: true,
      },
      {
        name: 'IS_NOT_NULL',
        type: 'operator',
        verbose_name: 'IS NOT NULL',
        allowed_rhs_data_types: { name: 'any' },
        isSpecial: true,
        negationName: 'IS_NULL',
      }
    );
    commit('SET_EXPRESSIONS', r.data.data);
  },
  async validateExpression(store, payload) {
    const { params, body } = payload;
    return await api.post('dataio/boolean_expressions/validate', body, { params });
  },
  async getLayerUniqueStyle(store, payload) {
    const { layer_id, attribute_name, signature_style = false } = payload;
    return await api.get(`/layers/features_layers/${layer_id}/style_classes/${attribute_name}`, {
      params: { signature_style },
    });
  },
  async getDataSourceAttributeUniqueValues(store, payload) {
    const { dataSourceName, attributeName, params = {} } = payload;
    const r = await api.post(`/dataio/data_sources/${dataSourceName}/unique_values/${attributeName}`, params);
    return r.data.data;
  },
  async getIcons({ commit }, params = {}) {
    const { templates = false } = params;
    const r = await api.get(`/icons`, { params: { templates } });
    !templates ? commit('SET_ICONS', r.data.data) : commit('SET_TEMPLATE_ICONS', r.data.data);
  },
  async deleteIcon({ dispatch, state }, name) {
    await api.delete(`/icons/${name}`);
    await dispatch('getIcons', { templates: state.templateIcons.find(icon => icon.name === name) ? true : false });
  },
  async getIcon(store, name) {
    const r = await api.get(`/icons/${name}`);
    return r.data;
  },
  async addIcon({ dispatch }, formData) {
    await api.post('/icons', formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    await dispatch('getIcons');
  },
  async getWmsCapabilities(store, payload) {
    return await api.post('/layers/service_layers/capabilities', payload);
  },
  async getFeaturesLayer({ commit }, layer_id) {
    const r = await api.get(`/layers/features_layers/${layer_id}`);
    commit('ADD_FEATURES_LAYER', { id: layer_id, value: r.data.data });
  },
  async deleteQgisStyle({ dispatch }, { layer_id, scope }) {
    await api.delete(`layers/features_layers/${layer_id}/style_qgis`);
    await Promise.all([dispatch('getLayers', { scope }), dispatch('getLayersSchemaByScope', scope)]);
  },
  async getDefaultValuesPolicies({ commit }) {
    const r = await api.get(`/dataio/default_values/variable_policies`);
    commit('SET_DEFAULT_VALUES_POLICIES', r.data.data);
  },
  async getLayersBbox(store, payload) {
    return (await api.post('/layers/features_layers/bbox', payload)).data.data;
  },
  async setAttribute({ commit }, payload) {
    const { attributeName, dataSourceName, type, value } = payload;
    const r = await api.put(`/dataio/data_sources/${dataSourceName}/${attributeName}`, { [type]: value });
    commit('SET_ATTRIBUTE', { payload, data: r.data.data });
    return r;
  },
  async getMeasurementUnits({ commit }) {
    const r = await api.get('/measurement_units');
    commit('SET_MEASUREMENT_UNITS', r.data.data);
  },
  async getTile(store, payload) {
    const { layerId, envelope, features_filter, attributes, zxy } = payload;
    return await api.post(
      // `/layers/features_layers/${layerId}/mvt`,
      `/mvt_service/${layerId}`,
      { features_filter, envelope, attributes, zxy, use_cache: true },
      { responseType: 'arraybuffer', skipDefaultErrorHandler: true }
    );
  },
  async getCurrentLayerSelectionFeature({ commit }, payload) {
    const { layer_id, feature_id } = payload;
    const r = await api.post(`/layers/features_layers/${layer_id}/features/${feature_id}`, {});
    commit('SET_CURRENT_LAYER_SELECTION_FEATURE', { feature_id, feature: r.data.data });
  },
  async addEasement({ dispatch }, feature) {
    const r = await api.post('/transmission_easement', { feature });
    await dispatch('getEasements');
    return r;
  },
  async getEasement(store, id) {
    const r = await api.post(`/transmission_easement/${id}/read`, {});
    return r.data.data;
  },
  async getEasements({ commit }) {
    const r = await api.post('transmission_easement/read', {});
    commit('SET_EASEMENTS', {
      easementsProps: r.data.data.features.features,
      crs: r.data.data.features.crs,
    });
  },
  async getWatSewCollisions(state, data) {
    const { layerId, featureId, bufferValue } = data;
    const r = await api.post(`transmission_easement_2/${layerId}/features/${featureId}`, {
      ...(bufferValue ? { buffer: bufferValue } : {}),
    });
    return r.data.data;
  },
  async deleteEasement({ commit }, easement_id) {
    await api.delete(`/transmission_easement/${easement_id}`);
    commit('DELETE_EASEMENT', easement_id);
  },
  async editEasement({ dispatch }, payload) {
    const { feature, easement_id } = payload;
    await api.put(`/transmission_easement/${easement_id}`, { feature });
    await dispatch('getEasements');
  },
  async addFolder({ dispatch }, feature) {
    const r = await api.post('/folders', { feature });
    await dispatch('getFolders');
    return r;
  },
  async getFolder(store, id) {
    const r = await api.get(`/folders/${id}`);
    return r.data.data;
  },
  async getFolders({ commit }) {
    const r = await api.post('/folders/read', {});
    commit('SET_FOLDERS', {
      foldersProps: r.data.data.features.features,
      crs: r.data.data.features.crs,
    });
  },
  async deleteFolder({ commit }, folder_id) {
    await api.delete(`/folders/${folder_id}`);
    commit('DELETE_FOLDER', folder_id);
  },
  async editFolder({ dispatch }, payload) {
    const { feature, folder_id } = payload;
    await api.put(`/folders/${folder_id}`, { feature });
    await dispatch('getFolders');
  },
  async getFolderSummary() {
    const r = await api.get('/folders/summary');
    return r.data.data;
  },

  async findDocuments(store, searchString) {
    const params = {
      document_name: searchString,
    };
    const r = await api.get('folders/documents', { params });
    return r.data.data;
  },
  async getFolderDocuments({ commit }, folder_id) {
    const r = await api.get(`/folders/${folder_id}/documents`);
    commit('SET_FOLDER_DOCUMENTS', { folder_id, data: r.data.data });
  },
  async deleteFolderDocument({ dispatch }, payload) {
    const { document_id, folder_id } = payload;
    await api.delete(`/folders/documents/${document_id}`);
    await dispatch('getFolderDocuments', folder_id);
  },
  async downloadDocument(store, document_id) {
    const r = await api.get(`folders/documents/${document_id}/file`, { timeout: 0, responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers);
  },
  async downloadFolder(store, folder_id) {
    const r = await api.get(`folders/${folder_id}/documents/zip`, {
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers);
  },
  async editDocumentName({ dispatch }, payload) {
    const { document_id, folder_id, body } = payload;
    await api.put(`/folders/documents/${document_id}`, body);
    await dispatch('getFolderDocuments', folder_id);
  },
  async getDataSourceAttributeStatistics(store, payload) {
    const { dataSourceName, attributeName, params } = payload;
    const r = await api.post(`/dataio/data_sources/${dataSourceName}/statistics/${attributeName}`, params || {});
    return r.data.data;
  },
  async addDataSource({ dispatch }, data) {
    const { payload, skipMetadataParsing } = data;
    const r = await api.post('dataio/data_sources', payload);
    if (!skipMetadataParsing) {
      await dispatch('getLayersMetadata', {
        with_attributes_schema: true,
        with_layers: true,
        with_last_edit: true,
        with_owner: true,
      });
    }
    return r.data.data;
  },
  async convertDatasourceToSingle({ dispatch }, datasourceName) {
    await api.post(`dataio/data_sources/${datasourceName}/geometry/to_single`);
    await dispatch('getLayersMetadata', {
      with_attributes_schema: true,
      with_layers: true,
      with_last_edit: true,
      with_owner: true,
    });
  },
  async copyDataSourceData({ dispatch }, payload) {
    const { fromDataSource, toDataSource } = payload;
    await api.post(`dataio/data_sources/${fromDataSource}/${toDataSource}/copy`);
    await dispatch('getLayersMetadata', {
      with_attributes_schema: true,
      with_layers: true,
      with_last_edit: true,
      with_owner: true,
    });
  },
  async uploadDataSource({ dispatch }, formData) {
    await api.post('dataio/data_sources/upload/geojson', formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    const newDataSource = formData.get('datasource') ? false : true;
    if (newDataSource) {
      await dispatch('getLayersMetadata', { with_attributes_schema: true });
    }
  },
  async getLayersFeaturesInBbox(state, { active_features_filter, active_layer_id, bbox, ids }) {
    const r = await api.post(`/layers/features_layers/geojson`, { active_features_filter, active_layer_id, bbox, ids });
    return r.data.data;
  },
  async getVehicleHistory(state, data) {
    const { featureId, payload } = data;
    const r = await api.post(`vehicles/${featureId}/location_history`, payload);
    return r.data.data;
  },
  async getVehicleHistoryShp(state, data) {
    const { featureId, payload } = data;
    const r = await api.post(`vehicles/${featureId}/location_history/export`, payload, {
      timeout: 0,
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers);
  },
  async exportGeojsonToShapefile(state, payload) {
    const r = await api.post('dataio/data_sources/geojson/export/shp', payload, {
      timeout: 0,
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers);
  },
  async getLocationSelection(state, payload) {
    const r = await api.post('select_by_location', payload);
    return r.data.data;
  },
  async syncLayerMbTiles({ dispatch }, id) {
    await api.post(`sync/tiles/${id}`, {});
    await dispatch('getCacheLayersMetadata');
  },
  async getCacheLayersMetadata({ commit }) {
    const r = await api.get('layers/cache/metadata');
    commit('SET_CACHE_LAYERS_METADATA', r.data.data);
  },
  async editCacheLayerMetadata({ dispatch }, data) {
    const { payload, id } = data;
    await api.put(`layer/cache/metadata/${id}`, payload);
    await dispatch('getCacheLayersMetadata');
  },
  async clearMvtLayerCache(store, params) {
    await api.get('misc/clear_cache_mvt_layer', { params });
  },
  async getInfrastructureCollisions(store, payload) {
    return await api.post('infrastructure_collisions', payload);
  },
  async setBasemapLayer({ dispatch }, layerId) {
    await api.put(`layers/service_layers/${layerId}/basemap`);
    await dispatch('getLayers');
  },
  async uploadCsvToDataSource(store, { formData, datasourceName } = {}) {
    await api.post(`v2/datasources-upload/csv/${datasourceName}`, formData, {
      noWrap: true,
      headers: { 'Content-Type': 'multipart/form-data;' },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
