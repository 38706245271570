import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  searchEngines: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getSearchEngines({ commit }) {
    const r = await api.get('search_objects/search_engines');
    commit('SET_SEARCH_ENGINES', r.data.data);
  },
  async addSearchEngine({ dispatch }, payload) {
    await api.post('search_objects/search_engines', payload);
    await dispatch('getSearchEngines');
  },
  async deleteSearchEngine({ dispatch }, engineId) {
    await api.delete(`search_objects/search_engines/${engineId}`);
    await dispatch('getSearchEngines');
  },
  async editSearchEngine({ dispatch }, data) {
    const { engineId, payload } = data;
    await api.put(`search_objects/search_engines/${engineId}`, payload);
    await dispatch('getSearchEngines');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
