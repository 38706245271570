import api from '@/services/api';
import { make } from 'vuex-pathify';

import { saveFileFromBackend } from '@/assets/js/utility';

const state = {
  reports: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async addLocationLabReport({ dispatch }, payload) {
    await api.post('reports', payload);
    await dispatch('getLocationLabReports');
  },
  async calculateLocationLabReport(store, data) {
    const { id, payload } = data;
    const r = await api.post(`reports/${id}/calculate_statistics`, payload);
    return r.data.data;
  },
  async deleteLocationLabReport({ dispatch }, id) {
    await api.delete(`reports/${id}`);
    await dispatch('getLocationLabReports');
  },
  async editLocationLabReport({ dispatch }, data) {
    const { id, payload } = data;
    await api.put(`reports/${id}`, payload);
    await dispatch('getLocationLabReports');
  },
  async getLocationLabReports({ commit }) {
    const r = await api.get('reports');
    commit('SET_REPORTS', r.data.data);
  },
  async getLocationLabReportXlsx(store, payload) {
    const r = await api.post(`reports/xlsx`, payload, { timeout: 0, responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
