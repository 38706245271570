import { get } from 'vuex-pathify';

import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import { Stroke, Style } from 'ol/style';
import { LineString } from 'ol/geom';
import { transform } from 'ol/proj';
import { getDestinationCoords } from '@/assets/js/mapUtils';

export default {
  computed: {
    realtimeStationsMeasurementsStations: get('edit/realtimeStationsMeasurementsStations'),
    realtimeStationsDirectionsStations: get('edit/realtimeStationsDirectionsStations'),
  },
  methods: {
    addRealtimeStationsLayer(type) {
      const typeCapitalize = this.$_capitalize(type);
      const newLayer = new VectorLayer({
        id: `realtimeStations${typeCapitalize}Layer`,
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          stroke: new Stroke({
            color: type === 'measurements' ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 0, 255, 1)',
            width: 2,
          }),
        }),
      });
      this.map.addLayer(newLayer);
    },
    updateRealtimeStationsStation(type, value) {
      const typeCapitalize = this.$_capitalize(type);
      let layer = this.getLayerById(`realtimeStations${typeCapitalize}Layer`);
      if (!layer) {
        this.addRealtimeStationsLayer(type);
        layer = this.getLayerById(`realtimeStations${typeCapitalize}Layer`);
      }

      const stationData = this[`realtimeStations${typeCapitalize}Stations`][value['StationId']];
      const coordA = stationData?.coordinates || [value['Longitude'], value['Latitude']];
      const coordB = getDestinationCoords(coordA, value['Azimuth'], 100000, 'EPSG:4326');
      const lineStringCoords = [
        transform(coordA, 'EPSG:4326', this.$_config.defaultEpsg),
        transform(coordB, 'EPSG:4326', this.$_config.defaultEpsg),
      ];
      const lineStringGeom = new LineString(lineStringCoords);

      const currentStationFeature = layer
        .getSource()
        .getFeatures()
        .find(f => f.get('StationId') === value['StationId']);
      if (currentStationFeature) {
        currentStationFeature.setGeometry(lineStringGeom);
      } else {
        const lineStringFeature = new Feature({ geometry: lineStringGeom, ...value });
        layer.getSource().addFeature(lineStringFeature);
      }
    },
    clearRealtimeStationsStations(type) {
      const typeCapitalize = this.$_capitalize(type);
      let layer = this.getLayerById(`realtimeStations${typeCapitalize}Layer`);
      if (layer) {
        this.clearLayer(`realtimeStations${typeCapitalize}Layer`);
      }
    },
  },
  mounted() {
    this.$root.$off('updateRealtimeStationsMeasurementsStation');
    this.$root.$on('updateRealtimeStationsMeasurementsStation', value => {
      this.updateRealtimeStationsStation('measurements', value);
    });
    this.$root.$off('clearRealtimeStationsMeasurementsStations');
    this.$root.$on('clearRealtimeStationsMeasurementsStations', () => {
      this.clearRealtimeStationsStations('measurements');
    });

    this.$root.$off('updateRealtimeStationsDirectionsStation');
    this.$root.$on('updateRealtimeStationsDirectionsStation', value => {
      this.updateRealtimeStationsStation('directions', value);
    });
    this.$root.$off('clearRealtimeStationsDirectionsStations');
    this.$root.$on('clearRealtimeStationsDirectionsStations', () => {
      this.clearRealtimeStationsStations('directions');
    });
  },
};
