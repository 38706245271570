import { make } from 'vuex-pathify';
import api from '@/services/api';
import { saveFileFromBackend } from '@/assets/js/utility';

const state = {
  categories: [],
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async getInterCadCategories({ commit }) {
    const r = await api.get('intercad_export/available_layers');
    const sewerData = r.data.data.filter(value => value.type === 'sewer');
    const waterData = r.data.data.filter(value => value.type === 'water');
    commit('SET_CATEGORIES', [
      {
        title: 'waterData',
        links: waterData,
      },
      {
        title: 'sewerData',
        links: sewerData,
      },
    ]);
  },
  async getInterCadData(store, layer_name) {
    const r = await api.get(`intercad_export/${layer_name}/download`, { responseType: 'arraybuffer', timeout: 0 });
    saveFileFromBackend(r.data, r.headers);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
