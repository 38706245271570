<template>
  <v-row no-gutters>
    <v-col :cols="(isRelativePath && !isEditingOn) || (isEditingOn && !$attrs.disabled) ? '11' : '12'" class="pa-0">
      <data-input
        class="data-input-hyperlink"
        :class="{ 'data-input-hyperlink-enabled': clickable }"
        v-model="computedValue"
        v-bind="{
          isEditingOn,
          ...$attrs,
          ...staticProps,
          rules: [...($attrs.rules || []), v => !v || $_isValidUrl(v, !disableRelativePath) || 'rules.notValidUrl'],
        }"
      >
        <template #prepend-inner>
          <v-icon @click="openHyperlink(false)" :style="clickable ? '' : 'cursor: unset'">mdi-web</v-icon>
        </template>
        <template #append v-if="isRelativePath && !isEditingOn && !relativeImgError">
          <img
            @click="openHyperlink(false)"
            @error="onRelativeImg(false)"
            @load="onRelativeImg(true)"
            height="25px"
            :src="urlToken"
            style="cursor: pointer"
          />
        </template>
      </data-input>
    </v-col>
    <v-col cols="1" class="pa-0 pt-2" v-if="isRelativePath && !isEditingOn">
      <v-tooltip bottom color="font">
        <template #activator="{ on }">
          <v-icon v-on="on" color="primary" @click="openHyperlink(true)">mdi-download</v-icon>
        </template>
        {{ $i18n.t('sidebar.downloadAttachment') }}
      </v-tooltip>
    </v-col>
    <v-col cols="1" class="pa-0 pt-2" v-if="isEditingOn && !$attrs.disabled">
      <v-tooltip bottom color="font">
        <template #activator="{ on }">
          <v-icon v-on="on" color="primary" @click="openAttachmentsDialog">mdi-upload</v-icon>
        </template>
        {{ $i18n.t('sidebar.addAttachment') }}
      </v-tooltip>
    </v-col>
    <attachments-dialog
      v-if="isAttachmentDialogLoaded"
      :is-visible.sync="isAttachmentVisible"
      :features="[]"
      @filesAssigned="onFilesAdded"
      @filesUploaded="onFilesAdded"
      :expanded="hasExpandedAttachments"
      :formSchema="attachmentsFormSchema"
    />
    <image-lightbox :items="[urlToken]" :visible.sync="isFullScreenImageVisible" />
  </v-row>
</template>
<script>
import { get } from 'vuex-pathify';
import prepareAttributesFeatureCardMixin from '@/mixins/prepareAttributesFeatureCardMixin';

export default {
  name: 'DataInputHyperlink',
  components: {
    AttachmentsDialog: () => import('@/components/AttachmentsDialog'),
    DataInput: () => import('@/components/DataInput'),
    ImageLightbox: () => import('@/components/ImageLightbox'),
  },
  mixins: [prepareAttributesFeatureCardMixin],
  props: {
    value: {
      required: true,
    },
    isEditingOn: {
      type: Boolean,
      default: false,
    },
    disableRelativePath: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    additionalModules: get('admin/additionalModules'),
    settings: get('admin/settingsValues'),
    toolsPermissions: get('users/toolsPermissions'),

    documentationDataSourceName: get('admin/modulesMapping@documentation.attachment_datasource_name'),
    documentationLayerId: get('admin/modulesMapping@documentation.documentation_layer_id'),
    attachmentsAttributesMetadata: get('layers/metadata@:documentationDataSourceName.attributes_schema.attributes'),
    attachmentsLayerFormSchema: get('layers/layers@:documentationLayerId@form_schema.elements'),

    token: get('authentication/token'),
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
        this.relativeImgError = false;
      },
    },
    clickable() {
      return !this.isEditingOn && this.computedValue;
    },
    staticProps() {
      return {
        dataType: { name: 'text' },
      };
    },
    attachmentsFormSchema() {
      return this.hasExpandedAttachments
        ? this.$_flattenStructure(
            this.getForm(this.attachmentsLayerFormSchema, this.attachmentsAttributesMetadata),
            'elements'
          )
        : [];
    },
    hasExpandedAttachments() {
      const isModuleEnabled = this.additionalModules['DOCUMENTATION']?.enabled;
      const isSettingEnabled = this.settings['documentation_module_enabled'];
      const hasPermission = this.toolsPermissions['DOCUMENTATION']?.main_value > 0;
      return isModuleEnabled && isSettingEnabled && hasPermission;
    },
    isRelativePath() {
      return this.computedValue?.startsWith('/') || false;
    },
    url() {
      return this.isRelativePath ? `${import.meta.env.VUE_APP_API_URL}${this.computedValue}` : this.computedValue;
    },
    urlToken() {
      return `${this.url}?token=${this.token}`;
    },
  },
  data: () => ({
    isAttachmentVisible: false,
    isAttachmentDialogLoaded: false,
    isFullScreenImageVisible: false,
    relativeImgLoaded: false,
    relativeImgError: false,
  }),
  methods: {
    openAttachmentsDialog() {
      this.isAttachmentDialogLoaded = true;
      this.isAttachmentVisible = true;
    },
    openHyperlink(download = false) {
      if (!this.clickable) {
        return;
      }
      if (this.isRelativePath && this.relativeImgLoaded && !download) {
        this.isFullScreenImageVisible = true;
        return;
      }
      const url = this.isRelativePath ? this.urlToken : this.url;
      if (!url.match(/^https?:\/\//i)) {
        window.open(`http://${url}`, '_blank');
        return;
      }
      window.open(url, '_blank');
    },
    onFilesAdded(files) {
      const attachmentId = files?.[0]?.id;
      if (attachmentId || attachmentId === 0) {
        this.computedValue = `/attachments/${attachmentId}/file`;
      }
    },
    onRelativeImg(value) {
      this.relativeImgLoaded = value;
      this.relativeImgError = !value;
    },
  },
};
</script>
<style lang="scss" scoped>
.data-input-hyperlink {
  ::v-deep {
    .v-text-field__slot {
      padding-left: 5px;
    }
  }
}
.data-input-hyperlink-enabled {
  ::v-deep {
    input,
    textarea {
      color: map-get($colors, 'link') !important;
    }
  }
}
</style>
