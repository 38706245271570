import { get } from 'vuex-pathify';

export default {
  data: () => ({
    vehiclesTimeout: undefined,
  }),
  computed: {
    interval: get('admin/settingsValues@vehicles_module_locations_refresh_interval'),
    vehiclesLayerId: get('admin/modulesMapping@vehicles.layer_id'),
    vehiclesLayer: get('layers/featuresLayers@:vehiclesLayerId'),
  },
  methods: {
    async fillVehiclesLayerSource() {
      await this.getFeaturesLayer(this.vehiclesLayerId);
      const action = async () => {
        try {
          const r = await this.getLayerFeatures({ layer_id: this.vehiclesLayerId });
          this.updateVectorLayerFeatures({
            layerId: this.vehiclesLayer.id,
            features: r.data.data.features,
            group: 'layers',
          });
          this.vehiclesTimeout = setTimeout(action, (this.interval || 5) * 1000);
        } catch (e) {
          this.$root.$emit('showSnackbar', 'red', e.message);
        }
      };
      action();
    },
  },
  mounted() {
    this.$root.$on('toggleVehicleHistory', this.toggleVehicleHistory);
  },
};
