<template>
  <v-menu
    v-model="isMenuVisible"
    :close-on-content-click="false"
    max-width="290px"
    min-width="290px"
    offset-y
    :disabled="disabled || $attrs.readonly"
  >
    <template #activator="{ on }">
      <data-input-text
        v-bind="{ ...$props, ...$attrs }"
        v-model="formattedDate"
        v-on="{
          ...on,
          ...$_filterObjectKeys($listeners, { notAllowedKeys: ['input', 'change'] }),
          input: setInputValue,
        }"
        :rules="computedRules"
        @blur="setValue"
        class="included"
      />
    </template>
    <input-card
      @cancel="cancel"
      @save="save"
      :is-save-disabled="isSaveDisabled"
      v-click-outside="{
        handler: onClickOutside,
        include,
        closeConditional,
      }"
    >
      <v-tabs fixed-tabs v-model="activeTab">
        <v-tab key="calendar">
          <slot name="dateIcon">
            <v-icon>{{ dateIcon }}</v-icon>
          </slot>
        </v-tab>
        <v-tab key="timer" :disabled="dateSelected">
          <slot name="timeIcon">
            <v-icon>{{ timeIcon }}</v-icon>
          </slot>
        </v-tab>
        <v-tab-item key="calendar">
          <v-date-picker v-model="date" v-bind="datePickerProps" @input="showTimePicker" full-width></v-date-picker>
        </v-tab-item>
        <v-tab-item key="timer">
          <v-time-picker v-model="time" v-bind="timePickerProps" format="24hr" full-width scrollable></v-time-picker>
        </v-tab-item>
      </v-tabs>
    </input-card>
  </v-menu>
</template>

<script>
import inputCard from '@/mixins/inputCard';

import DataInputText from '@/components/DataInputText';
import InputCard from '@/components/InputCard';

export default {
  name: 'DataInputDatetime',
  mixins: [inputCard],
  components: {
    DataInputText,
    InputCard,
  },
  props: {
    value: {
      type: [Date, String, Number],
      default: null,
    },
    timeIcon: {
      type: String,
      default: 'mdi-clock-outline',
    },
    dateIcon: {
      type: String,
      default: 'mdi-calendar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    /* https://day.js.org/docs/en/display/format#list-of-localized-formats */
    localeTimeFormat: {
      default: 'LT',
    },
    localeDateFormat: {
      default: 'L',
    },
    timeDateFormat: {
      type: String,
      default: 'date, time',
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    hint: {
      type: String,
      default: null,
    },
    timestamp: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isMenuVisible: false,
    activeTab: 0,
    formattedDate: undefined,
  }),
  computed: {
    isValidRule() {
      return (
        this.isValid ||
        this.$i18n.t('rules.requiredFormat', {
          format: this.format,
        })
      );
    },
    isValid() {
      const dayjsDate = this.$dayjs(this.formattedDate, this.format, import.meta.env.VUE_APP_I18N_LOCALE, true);
      return dayjsDate.isValid();
    },
    computedRules() {
      return [...(this.$attrs.rules || []), ...(this.formattedDate ? [this.isValidRule] : [])];
    },
    dateFormat() {
      return (
        this.$dayjs.Ls[import.meta.env.VUE_APP_I18N_LOCALE].formats[this.localeDateFormat] || this.localeDateFormat
      );
    },
    timeFormat() {
      return (
        this.$dayjs.Ls[import.meta.env.VUE_APP_I18N_LOCALE].formats[this.localeTimeFormat] || this.localeTimeFormat
      );
    },
    localeTimeDateFormat() {
      return this.timeDateFormat.replace('time', this.localeTimeFormat).replace('date', this.localeDateFormat);
    },
    format() {
      return this.timeDateFormat.replace('time', this.timeFormat).replace('date', this.dateFormat);
    },
    computedFormattedDate() {
      if (!this.value) {
        return undefined;
      }
      const value = this.timestamp ? this.$dayjs.unix(this.value) : this.$dayjs(this.value);
      return value.format(this.localeTimeDateFormat);
    },
    date: {
      get() {
        if (!this.value) {
          return this.$dayjs().toISOString().split('T')[0];
        }
        const value = this.timestamp ? this.$dayjs.unix(this.value).format('YYYY-MM-DD') : this.value;
        return value.split('T')[0];
      },
      set(nV) {
        if (this.timestamp) {
          this.$emit('input', this.$dayjs(`${nV} ${this.time}`, 'YYYY-MM-DD HH:mm').unix());
          return;
        }
        const dateAsString = this.$dayjs(`${nV}T${this.time}:00.000Z`).toISOString().slice(0, -1);
        this.$emit('input', dateAsString);
      },
    },
    time: {
      get() {
        if (!this.value) {
          const now = this.$dayjs();
          const nowFormat = now.format('HH:mm');
          return nowFormat;
        }
        if (this.timestamp) {
          return this.$dayjs.unix(this.value).format('HH:mm');
        }
        const valueSplit = this.value.split('T')[1];
        return valueSplit ? valueSplit.substr(0, 5) : '';
      },
      set(nV) {
        if (this.timestamp) {
          this.$emit('input', this.$dayjs(`${this.date} ${nV}`, 'YYYY-MM-DD HH:mm').unix());
          return;
        }
        const dateAsString = this.$dayjs(`${this.date}T${nV}:00.000Z`).toISOString().slice(0, -1);
        this.$emit('input', dateAsString);
      },
    },
    dateSelected() {
      return !this.date;
    },
    hasDateChanged() {
      return this.value !== this.valueCopy;
    },
  },
  watch: {
    computedFormattedDate: {
      immediate: true,
      handler(nV) {
        this.formattedDate = nV;
      },
    },
  },
  methods: {
    setValue() {
      if (!this.value || this.isValid) {
        this.$emit('input', this.getValue());
      }
    },
    setInputValue(v) {
      if (this.isValid || !v) {
        this.$emit('input', this.getValue());
      }
    },
    getValue() {
      if (!this.formattedDate) {
        return this.timestamp ? 0 : null;
      }
      const dayjsDate = this.$dayjs.utc(this.formattedDate, this.format, import.meta.env.VUE_APP_I18N_LOCALE, true);
      return this.timestamp
        ? this.$dayjs(this.formattedDate, this.format).unix()
        : dayjsDate.toISOString().slice(0, -1);
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.v-picker {
  border-radius: 0px;
}
::v-deep .v-input__slot {
  margin-bottom: 3px;
  input {
    padding: 0px;
  }
  // label {
  //   top: 0px;
  // }
}
</style>
