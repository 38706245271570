import { isContentWrapped } from '@/assets/js/directives';

export default {
  directives: {
    isContentWrapped,
  },
  data: () => ({
    isContentWrapped: false,
  }),
  methods: {
    setIsContentWrapped(evt) {
      this.isContentWrapped = evt;
    },
  },
};
