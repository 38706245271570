import { call, sync, get } from 'vuex-pathify';
import { Draw } from 'ol/interaction';
import { Style, Stroke } from 'ol/style';

export default {
  computed: {
    isParcelReportToolActive: sync('tools/toolStatus@isParcelReportToolActive'),
    parcelReportCoordinates: get('search/parcelReportCoordinates'),
  },
  methods: {
    searchUldkParcelByXY: call('search/searchUldkParcelByXY'),
    addParcelReportLayer(geometry) {
      this.addCustomLayer(geometry, 'parcelReport', {
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#BF360C',
            width: 3,
          }),
        }),
      });
    },
    clearParcelReportInteraction() {
      this.id = 1;
      this.map.removeInteraction(this.getInteractionByName('parcelReportCoordinates'));
      this.deactivateToolHandler('parcelReport');
      this.isCoordinatesToolActive = false;
    },
    removeParcelReportLayer() {
      if (this.getLayerById('parcelReport')) {
        this.map.removeLayer(this.getLayerById('parcelReport'));
      }
    },
    toggleParcelReport(value) {
      if (value && this.activeTool !== 'parcelReport') {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === 'parcelReport') {
        this.deactivateToolHandler('parcelReport');
      }
      this.$nextTick(() => {
        this.isParcelReportToolActive = value;
        this.dettachCursorMoveHandler();
        if (value) {
          if (!this.getLayerById('parcelReport')) {
            this.addParcelReportLayer();
          }
          const parcelReportInteraction = new Draw({
            source: this.getLayerById('parcelReport').getSource(),
            type: 'Point',
          });
          parcelReportInteraction.set('name', 'parcelReportCoordinates');
          parcelReportInteraction.on('drawend', e => {
            if (this.parcelReportCoordinates.length === 0) {
              this.$router.push({ name: 'parcelReport' });
            }
            this.clearCustomLayer('parcelReportCoordinates');
            const coordinates = e.feature.getGeometry().getCoordinates();
            e.feature.setId(1);
            this.$store.set('search/SET_PARCEL_REPORT_COORDINATES!', coordinates);
          });
          this.map.addInteraction(parcelReportInteraction);

          this.activeTool = 'parcelReport';
        } else {
          this.clearParcelReportInteraction();
        }
      });
    },
  },
  mounted() {
    this.$root.$on('parcelReport-action', this.toggleParcelReport);
    this.$root.$on('addParcelReportLayer', this.addParcelReportLayer);
    this.$root.$on('removeParcelReportLayer', this.removeParcelReportLayer);
  },
};
