import { get } from 'vuex-pathify';

export default {
  computed: {
    metadata: get('layers/metadata'),
    currentLayerForm: get('layers/currentLayer@form_schema.elements'),
  },
  methods: {
    getRelationValuesMapping({ attribute, datasource, representation }) {
      return this.$store.get('attributesSchema/relationValuesMapping')[datasource]?.[attribute]?.[representation] || [];
    },
    getRelationalValues(formSchema, attributes, filteredOutAttributes = []) {
      return (
        this.$_flattenStructure(formSchema, 'elements')
          .filter(attribute => !filteredOutAttributes.includes(attribute.name))
          .map(attribute => {
            const attributeMetadata = attributes.find(
              metadataAttribute => metadataAttribute.name === attribute.attribute
            );
            return { ...attribute, ...attributeMetadata };
          })
          .filter(attribute => attribute.type === 'relation')
          // filter out duplicated realtions (attributes that has relation to one attribute, datasource and representation)
          .filter(
            (attribute, index, array) =>
              array.findIndex(
                nestedAttribute =>
                  nestedAttribute.relation.attribute === attribute.relation.attribute &&
                  nestedAttribute.relation.data_source === attribute.relation.data_source &&
                  nestedAttribute.relation.representation === attribute.relation.representation
              ) === index
          )
          .map(({ relation }) =>
            this.getRelationValuesMapping({
              attribute: relation.attribute,
              datasource: relation.data_source,
              representation: relation.representation,
            })
          )
      );
    },
    async fetchData(datasource, formSchema = this.currentLayerForm) {
      try {
        const metadata = this.metadata[datasource];
        this.getRelationalValues(formSchema, metadata.attributes_schema.attributes);
      } catch (error) {
        console.log('  error', error);
      }
    },
  },
};
