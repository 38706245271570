export default {
  data: () => ({
    valueCopy: undefined,
  }),
  computed: {
    isSaveDisabled() {
      return !this.value || this.valueCopy === this.value;
    },
  },
  watch: {
    isMenuVisible(nV) {
      if (nV) {
        this.valueCopy = this.getValue();
      }
    },
  },
  methods: {
    onClickOutside() {
      this.$emit('input', this.valueCopy);
      this.activeTab = 0;
    },
    save() {
      this.setValue();
      this.$emit('saveDate', this.getValue());
      this.isMenuVisible = false;
      this.activeTab = 0;
    },
    cancel() {
      this.$emit('input', this.valueCopy);
      this.isMenuVisible = false;
      this.activeTab = 0;
    },
    closeConditional() {
      return this.isMenuVisible;
    },
    include() {
      return [document.querySelector('.included')];
    },
  },
};
