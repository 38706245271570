import { make } from 'vuex-pathify';

const state = {
  excludedManholes: [],
};

const mutations = make.mutations(state);

export default {
  namespaced: true,
  state,
  mutations,
};
