import { get, sync } from 'vuex-pathify';
export default {
  computed: {
    project: sync('layers/project'),
    projects: get('layers/projects'),
    isPressed: sync('tools/isPressed'),
    keyBindingsPermissions: get('users/toolsPermissions@KEY_BINDINGS'),
    isKeyBindingsEnabled: get('admin/additionalModules@KEY_BINDINGS.enabled'),
    isKeyBindingActive: get('tools/isKeyBindingActive'),
    shortcuts: get('users/currentUser@settings.key_bindings'),
  },
  methods: {
    initShortcuts() {
      document.onkeydown = e => {
        this.isPressed = { ...this.isPressed, ctrl: e.ctrlKey || e.metaKey };
        this.isPressed = { ...this.isPressed, enter: e.key === 'Enter' };
      };
      document.onkeyup = e => {
        this.isPressed = { ...this.isPressed, ctrl: e.ctrlKey || e.metaKey };
        if (e.key === 'Enter') this.isPressed = { ...this.isPressed, enter: false };
        if (this.isKeyBindingsEnabled && this.keyBindingsPermissions?.main_value > 0 && !this.isKeyBindingActive) {
          const shortcuts = this.shortcuts.filter(
            shortcut =>
              shortcut.keyBind?.code === e.code &&
              shortcut.keyBind?.specialKeys.every(specialKey => e[`${specialKey}Key`])
          );
          if (shortcuts?.length) {
            shortcuts.forEach(shortcut => this.shortcutAction(shortcut));
          }
        }
      };
    },
    shortcutAction(shortcut) {
      if (shortcut.url) {
        this.shortcutUrlAction(shortcut.url);
      }
    },
    shortcutUrlAction(path) {
      const routePath = path.startsWith(import.meta.env.VUE_APP_HOST)
        ? path.split(import.meta.env.VUE_APP_HOST)[1]
        : path;
      if (routePath.startsWith('/')) {
        const projectId = routePath.match(/project=([^&]*)/)?.[1];
        if (projectId && this.$route.query.project != projectId) {
          this.project = this.projects[projectId];
          this.$root.$emit('fetchProject');
        }
        this.$router.push(routePath);
      } else if (routePath.startsWith('http')) {
        window.open(routePath);
      } else {
        const projectId = routePath.match(/project=([^&]*)/)?.[1];
        if (projectId && this.$route.query.project != projectId) {
          this.project = this.projects[projectId];
          this.$root.$emit('fetchProject');
        }
        this.$router.push(`/${routePath}`);
      }
    },
  },
};
