<template>
  <v-menu
    :attach="$attrs.attach"
    ref="menu"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :open-on-hover="isOpenOnHover"
    :transition="transition"
    :disabled="isDisabled || !isConfigurated"
    v-model="isMenuOpen"
  >
    <template #activator="{ on }">
      <slot name="menu.activator" v-bind="{ on, ...$props }">
        <component
          v-if="hasDefaultActivator"
          :is="menuActivator"
          :hasAnchor="hasAnchor"
          v-bind="$props"
          v-on="on"
          @click="onMenuClick"
        />
      </slot>
    </template>
    <v-list dense class="py-2 px-0 elevation-2">
      <template v-for="(tool, idx) in computedTools">
        <the-navbar-top-panel-menu
          v-if="tool.hasSubTools"
          transition="slide-x-transition"
          :is-open-on-hover="false"
          :is-offset-x="true"
          :is-offset-y="false"
          v-bind="tool"
          :key="idx"
          :level="level + 1"
          @close="close"
        >
          <template v-for="slot in tool.slotNames" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </the-navbar-top-panel-menu>
        <template v-else>
          <the-navbar-button v-bind="tool" v-on="$listeners" :key="idx">
            <template #default="{ state }">
              <the-navbar-top-panel-menu-item @click="$emit('close')" v-bind="{ ...tool, state }">
                <template v-for="slot in tool.slotNames" v-bind="{ ...tool, state }" #[slot]="scope">
                  <slot :name="slot" v-bind="scope" />
                </template>
              </the-navbar-top-panel-menu-item>
            </template>
          </the-navbar-button>
        </template>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
/**
 * When having more than 2 nested menus it`s impossible
 * to have it opening on hover due to known bug described here:
 * https://github.com/vuetifyjs/vuetify/issues/1877
 */
import { get } from 'vuex-pathify';
import TheNavbarTopPanelMenuButton from '@/components/TheNavbarTopPanelMenuButton';
import TheNavbarTopPanelMenuItem from '@/components/TheNavbarTopPanelMenuItem';
import TheNavbarButton from '@/components/TheNavbarButton';

export default {
  name: 'TheNavbarTopPanelMenu',
  components: {
    TheNavbarTopPanelMenuButton,
    TheNavbarTopPanelMenuItem,
    TheNavbarButton,
  },
  props: {
    tools: {
      type: Array,
      default: () => [],
    },
    hasDefaultActivator: {
      type: Boolean,
      default: true,
    },
    level: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
    },
    path: {
      type: String,
    },
    icon: {
      type: String,
      default: '',
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
    isOffsetX: {
      type: Boolean,
      default: false,
    },
    isOffsetY: {
      type: Boolean,
      default: true,
    },
    isOpenOnHover: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'scroll-y-transition',
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
    isAuthorized: {
      type: Boolean,
      default: false,
    },
    enableModuleName: {
      type: Array,
    },
    enableSettingName: {
      type: Array,
    },
    onePermissionRequired: {
      type: Boolean,
      default: false,
    },
    verboseName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isMenuOpen: false,
  }),
  computed: {
    additionalModules: get('admin/additionalModules'),
    settingsValues: get('admin/settingsValues'),
    isDisabled() {
      return (
        (this.enableModuleName ? this.isModuleDisabled : false) ||
        (this.enableSettingName ? this.isSettingDisabled : false)
      );
    },
    isModuleDisabled() {
      return this.enableModuleName
        ? this.onePermissionRequired
          ? this.enableModuleName.find(name => this.additionalModules[name]?.enabled)
            ? false
            : true
          : !this.enableModuleName.every(name => this.additionalModules[name]?.enabled)
        : false;
    },
    isSettingDisabled() {
      return this.enableSettingName
        ? this.onePermissionRequired
          ? this.enableSettingName.find(name => this.settingsValues[name])
            ? false
            : true
          : !this.enableSettingName.every(name => this.settingsValues[name])
        : false;
    },
    isConfigurated() {
      return this.enableModuleName
        ? this.onePermissionRequired
          ? this.enableModuleName.find(name => this.additionalModules[name]?.configured)
            ? true
            : false
          : this.enableModuleName.every(name => this.additionalModules[name]?.configured)
        : true;
    },
    menuActivator() {
      return this.level === 0 ? 'TheNavbarTopPanelMenuButton' : 'TheNavbarTopPanelMenuItem';
    },
    hasAnchor() {
      return this.level > 0 && this.hasSubTools(this.tools);
    },
    computedTools() {
      return this.tools.map(tool => {
        return {
          ...tool,
          ...this.getToolConfig(tool.name, tool.tools),
        };
      });
    },
  },
  methods: {
    hasSubTools(tools) {
      return Array.isArray(tools) && tools.length > 0;
    },
    getPath(name) {
      return `${this.path}_${name}`;
    },
    close() {
      this.$emit('close');
      this.$refs.menu.callDeactivate();
    },
    getSlotsNames(path) {
      return Object.keys(this.$scopedSlots).filter(slot => slot.startsWith(path));
    },
    getToolConfig(name, tools) {
      const path = this.getPath(name);
      return {
        path,
        slotNames: this.getSlotsNames(path),
        hasSubTools: this.hasSubTools(tools),
      };
    },
    onMenuClick() {
      if (this.isDisabled) {
        this.$store.set('snackbar/PUSH_MESSAGE!', {
          message: this.$i18n.t('snackbar.moduleDisabledBySuperadmin'),
          color: '#333333',
          buttonType: 'text',
        });
      } else if (!this.isConfigurated) {
        this.$store.set('snackbar/PUSH_MESSAGE!', {
          message: this.$i18n.t(`snackbar.modulesNotConfigurate.${this.name}`),
          color: '#333333',
          buttonType: 'text',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
