<template>
  <v-row no-gutters>
    <v-col v-if="descriptors.inputs && descriptors.inputs.some(input => !!input.title)" cols="10">
      <v-row no-gutters class="mb-2 multi-dict-gap">
        <v-col v-for="(input, index) in descriptors.inputs" :key="index" class="text-left">
          {{ input.title }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row v-for="(v, idx) of value" :key="idx" no-gutters>
        <v-col cols="10">
          <data-input
            v-if="descriptors.valueKey"
            v-model="value[idx][descriptors.valueKey]"
            v-bind="{
              ...$props,
              ...$attrs,
              ...editInputProps,
              clearable: true,
              dataType: descriptors.allowed_data_type,
            }"
            @input="onInput"
          />
          <v-row no-gutters class="multi-dict-gap" v-else-if="descriptors.inputs">
            <v-col v-for="(input, index) in descriptors.inputs" :key="index">
              <data-input
                v-model="value[idx][input.valueKey]"
                v-bind="{
                  ...$props,
                  ...$attrs,
                  ...input.props,
                  clearable: true,
                  dataType: input.allowed_data_type,
                }"
                @input="onInput"
              />
            </v-col>
          </v-row>
          <data-input
            v-else
            v-model="value[idx]"
            v-bind="{
              ...$props,
              ...$attrs,
              ...editInputProps,
              clearable: true,
              dataType: descriptors.allowed_data_type,
            }"
            @input="onInput"
          />
        </v-col>
        <v-col cols="2" class="text-left pt-2 pl-2" align-self="start">
          <v-icon :color="$_colors.iconIdle" size="20" @click="deleteValue(idx)"> mdi-delete </v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col v-if="descriptors.inputs">
          <v-row no-gutters class="multi-dict-gap">
            <v-col v-for="(input, index) in descriptors.inputs" :key="index">
              <data-input
                v-model="newValue[input.valueKey]"
                v-bind="{
                  ...$props,
                  ...$attrs,
                  ...input.props,
                  rules: undefined,
                  hideDetails: true,
                  dataType: input.allowed_data_type,
                }"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="10">
          <data-input
            v-model="newValue[descriptors.valueKey ? descriptors.valueKey : 'default']"
            v-bind="{
              ...$props,
              ...$attrs,
              ...newInputProps,
              rules: undefined,
              dataType: descriptors.allowed_data_type,
            }"
          />
        </v-col>
        <v-col cols="2" class="text-left pt-2 pl-2" align-self="start">
          <v-icon color="success" @click="addNewValue" :disabled="!newValueValid"> mdi-check </v-icon>
        </v-col>
      </v-row>
      <v-row v-if="descriptors.inputs && newInputProps.hint">
        <v-col cols="10" class="py-0">
          <italic-subheader class="text-left" :straight-text="newInputProps.hint" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'DataInputDict',
  components: {
    DataInput: () => import('@/components/DataInput'),
    ItalicSubheader,
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    descriptors: {
      required: false,
      type: Object,
    },
    editInputProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    newInputProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    newValue: {},
    newValueValid: false,
  }),
  methods: {
    addNewValue() {
      this.$set(
        this.value,
        this.value.length,
        this.descriptors.valueKey || this.descriptors.inputs
          ? JSON.parse(JSON.stringify(this.newValue))
          : this.newValue.default
      );
      this.onInput();
      this.$nextTick(() => {
        this.newValue = {};
        this.$forceUpdate();
      });
    },
    deleteValue(idx) {
      this.$delete(this.value, idx);
      this.onInput();
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    onInput() {
      this.$emit('input', this.value);
    },
  },
  watch: {
    newValue: {
      handler(nV) {
        const valuesToCheck = this.descriptors.inputs
          ? this.descriptors.inputs.map(input => input.valueKey)
          : this.descriptors.valueKey
            ? [this.descriptors.valueKey]
            : ['default'];
        this.newValueValid = this.$attrs.rules.every(rule => valuesToCheck.every(key => rule(nV[key]) === true));
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-dict-gap {
  gap: 0.5rem;
}
</style>
