import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  results: {},
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async searchAddress(store, payload) {
    const { params } = payload;
    return await api.get(`addresses/search`, { params });
  },
  async searchGugik(store, address) {
    const res = await fetch(
      `https://services.gugik.gov.pl/uug/?srid=3857&request=GetAddress&` +
        new URLSearchParams({
          address: address,
        })
    ).then(response => response.json());
    const results = res.results;
    const mappedResults = results
      ? Object.values(results).map(v => {
          return {
            ...v,
            geometry: {
              coordinates: [parseFloat(v.x), parseFloat(v.y)],
              crs: {
                properties: {
                  name: 'EPSG:3857',
                },
                type: 'name',
              },
              type: 'Point',
            },
            description: `${v.city}${v.street ? `, ${v.street}` : ''}${v.number ? ` ${v.number}` : ''}`,
          };
        })
      : null;
    return results === null ? [] : mappedResults;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
