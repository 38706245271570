<template>
  <v-text-field
    v-on="computedListeners"
    v-bind="{ ...$attrs, ...$props }"
    type="text"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    persistent-hint
  >
    <template #append v-if="isFocused && showArrows">
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters class="justify-end align-center">
            <v-col cols="12" style="height: 10px">
              <v-icon @click="onArrowClicked(true)" style="font-size: 14px; height: auto; color: inherit">
                mdi-chevron-up
              </v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-end align-center">
            <v-col cols="12" style="height: 10px">
              <v-icon @click="onArrowClicked(false)" style="font-size: 14px; height: auto; color: inherit">
                mdi-chevron-down
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #message="{message}">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
  </v-text-field>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'DataInputFloat',
  components: {
    ItalicSubheader,
  },
  props: {
    label: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
    hint: {
      type: String,
      default: '',
    },
    allowNegative: {
      type: Boolean,
      default: true,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
    incrementFactor: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    incrementDecimalCount() {
      return this.incrementFactor.toString().split('.')[1]?.length || 0;
    },
    computedListeners() {
      return {
        ...this.$listeners,
        focus: () => {
          this.onFocus();
        },
        blur: e => {
          this.checkNumber(e);
        },
        input: e => {
          this.setValue(e);
        },
        paste: e => {
          this.onPaste(e);
          this.$emit('paste', e);
        },
        keydown: e => {
          this.checkValue(e);
          this.$emit('keydown', e);
        },
      };
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onArrowClicked(isIncrement) {
      const currentValue = Number.parseFloat(this.value) || 0;
      let value = isIncrement ? currentValue + this.incrementFactor : currentValue - this.incrementFactor;
      if (this.incrementDecimalCount) value = value.toFixed(this.incrementDecimalCount);
      if (!this.allowNegative && currentValue === 0 && !isIncrement) return;
      this.setValue(value.toString());
    },
    checkNumber(e) {
      const inputValue = e.target.value;
      if (inputValue.slice(-1) === '-') {
        e.target.value = inputValue + '1';
        this.$emit('input', parseInt(inputValue + '1'));
      }
      if (inputValue.slice(-1) === '.') {
        e.target.value = inputValue.slice(0, -1);
        this.$emit('input', parseInt(inputValue.slice(0, -1)));
      }
    },
    checkValue(e) {
      const { keyCode, key, metaKey, ctrlKey } = e;
      if ((ctrlKey || metaKey) && (keyCode === 86 || keyCode === 67 || keyCode === 65)) {
        return;
      }
      const inputValue = e.target.value.toString();
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;
      const allowedKeyCodes = [8, 9, 37, 39];
      if (!inputValue.includes('.')) {
        if ((inputValue.includes('-') && selectionStart > 1) || (!inputValue.includes('-') && selectionStart > 0)) {
          allowedKeyCodes.push(190);
        }
      }
      if (this.allowNegative && selectionStart === 0 && (selectionEnd > 0 || !inputValue.includes('-'))) {
        allowedKeyCodes.push(173);
      }

      if (allowedKeyCodes.includes(keyCode)) {
        return;
      }
      if (!/[0-9]/.test(key)) {
        e.preventDefault();
        return;
      }
    },
    onPaste(e) {
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;
      const oldValue = e.target.value;
      const pasteValue = e.clipboardData.getData('text');
      const sumValue = oldValue.substring(0, selectionStart) + pasteValue + oldValue.substring(selectionEnd);
      if (!/^-?\d+(?:[.]\d*?)?$/.test(sumValue)) {
        console.warn('Wklejana wartość nie jest liczbą zmiennoprzecinkową');
        e.preventDefault();
        return;
      }
      if (!this.allowNegative && Number.parseFloat(sumValue) < 0) {
        console.warn('Wklejana wartość jest ujemna');
        e.preventDefault();
        return;
      }
    },
    setValue(v) {
      if ((Number.parseFloat(v) || v == 0) && !(v === '-' || v.slice(-1) === '.')) {
        this.$emit('input', v || Number.parseFloat(v) <= 0 ? Number.parseFloat(v) : null);
      } else if (v === '') {
        this.$emit('input', null);
      }
    },
  },
};
</script>
