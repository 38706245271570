const getIsContentWrapped = target => {
  return target.offsetWidth < target.scrollWidth;
};

const isContentWrapped = {
  bind: async function(el, binding, vnode) {
    const element = binding.value.elGetter ? binding.value.elGetter(el) : el;
    if (!element) {
      return;
    }
    const { style } = element;
    style.textOverflow = 'ellipsis';
    style.display = 'inline-block';
    style.whiteSpace = 'nowrap';
    style.overflow = 'hidden';

    await vnode.context.$nextTick();
    binding.value.method(getIsContentWrapped(element));
  },
  componentUpdated: async function(el, binding, vnode) {
    const element = binding.value.elGetter ? binding.value.elGetter(el) : el;
    if (!element) {
      return;
    }
    await vnode.context.$nextTick();
    binding.value.method(getIsContentWrapped(element));
  },
};

export { isContentWrapped };
