import { sync } from 'vuex-pathify';
import { Draw, Modify } from 'ol/interaction';
import { Style, Circle, Fill, Stroke, Text } from 'ol/style';

export default {
  computed: {
    isRoutingServiceToolActive: sync('tools/toolStatus@isRoutingServiceToolActive'),
  },
  methods: {
    clearRoutingServiceInteraction() {
      this.map.removeInteraction(this.getInteractionByName('routingServiceStopsDraw'));
      this.map.removeInteraction(this.getInteractionByName('routingServiceStopsModify'));
      ['routingServiceStops', 'routingServiceRoute'].forEach(layerName => {
        if (this.getLayerById(layerName)) {
          this.map.removeLayer(this.getLayerById(layerName));
        }
      });
    },
    routingPointMarkerStyle(index, routingPoints) {
      switch (index) {
        case 0:
          return [
            this.getMarkerStyle({
              path: '/marker_green.svg',
            }),
          ];
        case routingPoints.length - 1:
          return [
            this.getMarkerStyle({
              path: '/marker_red.svg',
            }),
          ];
        default: {
          const radius = 8;
          const stroke = 4;
          return [
            new Style({
              text: new Text({
                text: `${index}`,
                offsetY: radius * 2 + radius,
                textAlign: 'center',
                justify: 'center',
                font: 'bold 16px sans-serif',
                fill: new Fill({
                  color: this.$_colors.primary,
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: stroke,
                }),
              }),
              image: new Circle({
                fill: new Fill({
                  color: this.$_colors.primary,
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: stroke - 1,
                }),
                radius: radius,
              }),
            }),
          ];
        }
      }
    },
    addRoutingServiceLayer(styleFunction) {
      this.addCustomLayer(null, 'routingServiceStops', {
        zIndex: 1000,
        style: feature => styleFunction(feature, this.routingPointMarkerStyle),
      });
    },
    removeRoutingServiceStop(id) {
      const stopsLayer = this.getLayerById('routingServiceStops');
      if (!stopsLayer || !id) return;
      const featureToDelete = stopsLayer.getSource().getFeatureById(id);
      if (!featureToDelete) return;
      stopsLayer.getSource().removeFeature(featureToDelete);
    },
    toggleRoutingService(value, drawendCallback, modifyCallback, styleFunction) {
      if (value && this.activeTool !== 'routingService') {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === 'routingService') {
        this.deactivateToolHandler('routingService');
      }
      this.$nextTick(() => {
        this.isRoutingServiceToolActive = value;
        if (value) {
          if (!this.getLayerById('routingServiceStops')) this.addRoutingServiceLayer(styleFunction);
          const stopsLayer = this.getLayerById('routingServiceStops');
          const modify = new Modify({ source: stopsLayer.getSource() });
          const draw = new Draw({ type: 'Point' });
          draw.set('name', 'routingServiceStopsDraw');
          modify.set('name', 'routingServiceStopsModify');
          draw.on('drawend', e => drawendCallback(stopsLayer, e.feature));
          modify.on('modifyend', e => modifyCallback(e.features.getArray()[0]));
          this.map.addInteraction(modify);
          this.map.addInteraction(draw);
          this.activeTool = 'routingService';
        } else {
          this.clearRoutingServiceInteraction();
        }
      });
    },
  },
  mounted() {
    this.$root.$on('routingService-action', this.toggleRoutingService);
    this.$root.$on('removeRoutingServiceStop', this.removeRoutingServiceStop);
  },
};
