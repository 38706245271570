<template>
  <v-checkbox v-model="computedValue" v-bind="$attrs" v-on="$listeners" class="pa-0 data-input-checkbox text-left">
    <template v-for="(value, key) in $scopedSlots" #[key]>
      <slot :name="key" />
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: 'DataInputCheckbox',
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.data-input-checkbox {
  ::v-deep {
    .v-label {
      font-size: 14px;
    }
    .v-input__slot {
      padding: 0 !important;
    }
  }
}
</style>
