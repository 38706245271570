const sitesTypes = {
  metadata: {
    datasource: 'rzi_sl_przedmiot_typ',
    attributes: {
      code: 'przedmiot_typ_kod',
      name: 'przedmiot_typ_nzw',
    },
  },
  categoriesData: {
    habitats: {
      value: 'habitats',
      allowedTypeValues: ['S'],
      mainKey: 'S',
    },
    species: {
      value: 'species',
      allowedTypeValues: ['G', 'Z', 'P', 'R'],
      mainKey: 'G',
    },
  },
};

const documentation = {
  type: 'Documents',
  mainArea: {
    datasource: 'rzi_sl_forma_ochrony_przyrody_widok',
    attributes: {
      name: 'forma_ochrony_przyrody_nzw',
      typeCode: 'forma_ochrony_przyrody_typ_kod',
    },
  },
  documents: {
    datasource: 'rzi_dok_dok_widok',
    hiddenAttributes: ['dok_rodzaj_kod'],
    attributes: {
      docType: 'dok_rodzaj_id',
      docCode: 'dok_rodzaj_kod',
      mainArea: 'obszar_id',
      docTitle: 'tytul',
      link: 'plik',
    },
  },
  documentsDict: {
    datasource: 'rzi_sl_dok_rodzaj',
    attributes: {
      code: 'dok_rodzaj_kod',
    },
  },
  authorization: {
    datasource: 'rzi_dok_dok_autoryzacja_widok',
    attributes: {
      document: 'dok_id',
      dueDateBoolean: 'bezterminowy_fl',
      fromDate: 'data_obowiazywania_od',
      toDate: 'data_obowiazywania_do',
      acceptanceDate: 'zatwierdzenie_data',
      institution: 'instytucja_id',
    },
  },
  protectedSites: {
    datasource: 'rzi_dok_dok_przedmiot_widok',
    categories: [
      {
        value: 'habitats',
      },
      {
        value: 'species',
      },
    ],
    hiddenAttributes: [
      'przedmiot_kod',
      'przedmiot_lac_nzw',
      'przedmiot_opis',
      'przedmiot_foto',
      'przedmiot_typ_kod',
      'ocena_id',
      'uwagi',
      'przedmiot_typ_nzw',
    ],
    attributes: {
      type: 'przedmiot_typ_kod',
      typeName: 'przedmiot_typ_nzw',
      title: 'przedmiot_id',
      code: 'przedmiot_kod',
      image: 'przedmiot_foto',
      condition: 'warunek',
      notes: 'uwagi',
      sdf_score: 'ocena_sdf_id',
      gios_score: 'ocena_id',
      docsId: 'dok_id',
    },
  },
  protectedSitesDict: {
    datasource: 'rzi_sl_przedmiot',
    attributes: {
      type: 'przedmiot_typ_id',
      code: 'przedmiot_kod',
    },
  },
  sitePlacs: {
    datasources: ['rzi_inw_inw_stanowisko_punkty_widok', 'rzi_inw_inw_stanowisko_poligony_widok'],
    filterAttribute: 'dok_przedmiot_id',
  },
  tableViewsSharedData: {
    filterAttribute: 'dok_przedmiot_id',
  },
  tableViews: {
    threats: {
      hiddenAttributes: ['dok_przedmiot_id', 'zagrozenie_fl', 'oddzialywanie_kod', 'intensywnosc_kod', 'wplyw_kod'],
      statics: [
        {
          attribute: 'zagrozenie_fl',
          value: false,
        },
      ],
      datasources: {
        none: 'rzi_dok_dok_przedmiot_oddzialywanie_tabela_widok',
        polygon: 'rzi_dok_dok_przedmiot_oddzialywanie_poligony_widok',
        point: 'rzi_dok_dok_przedmiot_oddzialywanie_punkty_widok',
      },
    },
    potential: {
      hiddenAttributes: [
        'dok_przedmiot_id',
        'zagrozenie_fl',
        'intensywnosc_kod',
        'intensywnosc_id',
        'oddzialywanie_kod',
        'wplyw_id',
        'wplyw_kod',
      ],
      statics: [
        {
          attribute: 'zagrozenie_fl',
          value: true,
        },
      ],
      datasources: {
        none: 'rzi_dok_dok_przedmiot_oddzialywanie_tabela_widok',
        polygon: 'rzi_dok_dok_przedmiot_oddzialywanie_poligony_widok',
        point: 'rzi_dok_dok_przedmiot_oddzialywanie_punkty_widok',
      },
    },
    protectiveActions: {
      hiddenAttributes: ['dok_przedmiot_id', 'dzialanie_grupa_id', 'jednostka_miary_id', 'dzialanie_kod'],
      datasources: {
        none: 'rzi_dok_dok_przedmiot_dzialanie_tabela_widok',
        polygon: 'rzi_dok_dok_przedmiot_dzialanie_poligony_widok',
        point: 'rzi_dok_dok_przedmiot_dzialanie_punkty_widok',
      },
    },
    objectives: {
      hiddenAttributes: ['dok_przedmiot_id'],
      attributes: {
        parameter: 'parametr_id',
        index: 'wskaznik_id',
      },
      datasources: {
        none: 'rzi_dok_dok_przedmiot_cel_widok',
      },
    },
  },
  score: {
    dictRawIndexes: {
      datasource: 'rzi_sl_przedmiot_wskaznik_widok',
      attributes: {
        protectedSite: 'przedmiot_id',
        parameter: 'parametr_id',
        name: 'wskaznik_id',
        sort: 'sort_nr',
      },
    },
    dictRawParameters: {
      datasource: 'rzi_sl_parametr',
      attributes: {
        name: 'parametr_nzw',
        type: 'przedmiot_typ_id',
      },
    },
    dictGiosScores: {
      datasource: 'rzi_sl_ocena',
      attributes: {
        code: 'ocena_kod',
        name: 'ocena_nzw',
        color: 'kolor',
      },
    },
    parameters: {
      datasource: 'rzi_dok_dok_przedmiot_parametr_widok',
      filterAttribute: 'dok_przedmiot_id',
      attributes: {
        id: 'id',
        score: 'ocena_id',
        title: 'parametr_id',
        description: 'wlasciwy_stan_opis',
      },
    },
    indexes: {
      datasource: 'rzi_dok_dok_przedmiot_wskaznik_widok',
      filterAttribute: '',
      attributes: {
        score: null,
        title: 'wskaznik_id',
        description: 'wlasciwy_stan_opis',
        bindedParameter: 'dok_przedmiot_parametr_id',
      },
    },
  },
};

const monitoring = {
  type: 'Monitoring',
  mainArea: {
    datasource: 'rzi_sl_forma_ochrony_przyrody_widok',
  },
  protectedSites: {
    datasource: 'rzi_mon_stanowisko_widok',
    hiddenAttributes: ['przedmiot_kod', 'przedmiot_lac_nzw', 'przedmiot_typ_nzw'],
    categories: [
      {
        value: 'sites',
      },
    ],
    attributes: {
      title: 'stanowisko_nzw',
      typeName: 'przedmiot_typ_nzw',
      subtitle: 'przedmiot_id',
    },
  },
  monitoringYears: {
    datasource: 'rzi_mon_stanowisko_rok_widok',
    minYear: 2024,
    maxYear: 2080,
    featureCardAppearance: [
      ['powierzchnia'],
      ['z_min', 'z_max'],
      ['stanowisko_opis'],
      ['przedmiot_opis'],
      ['wartosci_przyrodnicze'],
      ['zarzadzajacy_terenem'],
      ['uwagi'],
    ],
    statics: [
      {
        attribute: 'kontrola_fl',
        value: false,
      },
      {
        attribute: 'rezygnacja_fl',
        value: false,
      },
    ],
    attributes: {
      site: 'stanowisko_id',
      year: 'rok',
      area: 'powierzchnia',
      score: 'ocena_id',
      scoreDesc: 'ocena_opis',
      scoreDate: 'ocena_data',
      resign: 'rezygnacja_fl',
      resignDesc: 'rezygnacja_uzasadnienie',
    },
  },
  massImportAttributes: ['stanowisko_id', 'rok', 'stanowisko_rok_geom_nr', 'z'],
  fito: {
    datasource: 'rzi_mon_stanowisko_rok_zdjeciefito_widok',
    hiddenAttributes: ['x', 'y', 'ekspozycja_kod', 'kat_poczatkowy', 'jednfito_pol_nzw'],
    attributes: {
      year: 'stanowisko_rok_id',
      photoDate: 'zdjecie_data',
      altitude: 'z',
    },
  },
  fitoLayers: {
    datasource: 'rzi_mon_stanowisko_rok_zdjeciefito_warstwa_widok',
    hiddenAttributes: ['warstwa_fito_nzw'],
    attributes: {
      fito: 'stanowisko_rok_zdjeciefito_id',
      coverage: 'zwarcie',
      height: 'wysokosc',
      layer: 'warstwa_fito_id',
    },
  },
  fitoSpecies: {
    datasource: 'rzi_mon_stanowisko_rok_zdjeciefito_warstwa_gatunek_widok',
    attributes: {
      layer: 'stanowisko_rok_zdjeciefito_warstwa_id',
      coverage: 'pokrycie',
      specie: 'gatunek_id',
    },
  },
  fitoLayersDict: {
    datasource: 'rzi_sl_warstwa_fito',
  },
  photos: {
    datasource: 'rzi_mon_stanowisko_rok_foto_widok',
    formAttributes: ['plik', 'foto_nr', 'foto_ts', 'z', 'sufix', 'foto_opis'],
    editFormAttributes: ['foto_nr', 'foto_ts', 'x', 'y', 'z', 'sufix', 'foto_opis'],
    attributes: {
      photoFile: 'plik',
      geometry: 'geom',
      mainId: 'stanowisko_rok_id',
      photoNr: 'foto_nr',
      photoTime: 'foto_ts',
      name: 'foto_nzw',
      altitude: 'z',
      lon: 'x',
      lat: 'y',
      description: 'foto_opis',
    },
  },
  tableViewsSharedData: {
    filterAttribute: 'stanowisko_rok_id',
    lowerSectionTables: ['threats', 'potential', 'protectiveActions'],
  },
  tableViews: {
    geometries: {
      hiddenAttributes: ['stanowisko_rok_id'],
      datasources: {
        polygon: 'rzi_mon_stanowisko_rok_geom_poligony_widok',
        point: 'rzi_mon_stanowisko_rok_geom_punkty_widok',
      },
    },
    control: {
      hiddenAttributes: ['stanowisko_rok_id'],
      datasources: {
        none: 'rzi_mon_stanowisko_rok_datakontroli_widok',
      },
    },
    experts: {
      hiddenAttributes: ['stanowisko_rok_id'],
      datasources: {
        none: 'rzi_mon_stanowisko_rok_ekspert_widok',
      },
    },
    flora: {
      hiddenAttributes: ['stanowisko_rok_id'],
      datasources: {
        none: 'rzi_mon_stanowisko_rok_jednfito_widok',
      },
    },
    threats: {
      hiddenAttributes: ['stanowisko_rok_id'],
      datasources: {
        none: 'rzi_mon_stanowisko_rok_oddzialywanie_istniejace_widok',
      },
    },
    potential: {
      hiddenAttributes: ['stanowisko_rok_id'],
      datasources: {
        none: 'rzi_mon_stanowisko_rok_zagrozenie_potencjalne_widok',
      },
    },
    protectiveActions: {
      hiddenAttributes: ['stanowisko_rok_id'],
      // hiddenAttributes: ['stanowisko_rok_id', 'dzialanie_grupa_id', 'jednostka_miary_id', 'dzialanie_kod'],
      datasources: {
        none: 'rzi_mon_stanowisko_rok_dzialanie_tabela_widok',
        polygon: 'rzi_mon_stanowisko_rok_dzialanie_poligony_widok',
        point: 'rzi_mon_stanowisko_rok_dzialanie_punkty_widok',
      },
    },
  },
  score: {
    dictRawIndexes: {
      datasource: 'rzi_sl_przedmiot_wskaznik_widok',
      attributes: {
        protectedSite: 'przedmiot_id',
        parameter: 'parametr_id',
        name: 'wskaznik_id',
        sort: 'sort_nr',
        cardinal: 'kard_fl',
        cardinalDesc: 'kard_warunek',
        required: 'wymagalnosc_fl',
        requiredDesc: 'wymagalnosc_warunek',
      },
    },
    dictRawParameters: {
      datasource: 'rzi_sl_parametr',
      attributes: {
        name: 'parametr_nzw',
        type: 'przedmiot_typ_id',
      },
    },
    dictGiosScores: {
      datasource: 'rzi_sl_ocena',
      attributes: {
        code: 'ocena_kod',
        name: 'ocena_nzw',
        color: 'kolor',
      },
    },
    parameters: {
      datasource: 'rzi_mon_stanowisko_rok_parametr_widok',
      filterAttribute: 'stanowisko_rok_id',
      attributes: {
        id: 'id',
        score: 'ocena_id',
        title: 'parametr_id',
        description: 'parametr_opis',
      },
    },
    indexes: {
      datasource: 'rzi_mon_stanowisko_rok_wskaznik_widok',
      filterAttribute: '',
      attributes: {
        score: 'ocena_id',
        title: 'wskaznik_id',
        value: 'wartosc',
        description: 'wskaznik_opis',
        bindedParent: 'stanowisko_rok_id',
      },
    },
  },
};

export { documentation, monitoring, sitesTypes };
