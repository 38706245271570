import { sync } from 'vuex-pathify';
import Geolocation from 'ol/Geolocation.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import { unByKey } from 'ol/Observable';

export default {
  computed: {
    isGeolocationToolActive: sync('tools/toolStatus@isGeolocationToolActive'),
  },
  methods: {
    clearGeolocationInteraction() {
      [this.geolocationErrorListener, this.geolocationAccuracyListener, this.geolocationPositionListener].forEach(x =>
        unByKey(x)
      );
      if (this.getLayerById('geolocation-layer')) {
        this.map.removeLayer(this.getLayerById('geolocation-layer'));
      }
    },
    async toggleGeolocation(value, withAccuracy = false) {
      if (value && this.activeTool !== 'geolocation') {
        this.$root.$emit('deactivateAllTools');
      } else if (!value && this.activeTool === 'geolocation') {
        this.deactivateToolHandler('geolocation');
      }
      this.$nextTick(() => {
        this.isGeolocationToolActive = value;
        this.geolocation.setTracking(value);
        if (value) {
          if (!this.geolocation.getProjection()) this.geolocation.setProjection(this.map.getView().getProjection());
          this.geolocationErrorListener = this.geolocation.on('error', error => {
            // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
            if (error.code === 1) this.$root.$emit('geolocation-action', false);
            throw Error(`Geolocation error: code: ${error?.code}, message: ${error?.message}`);
          });
          this.addCustomLayer(null, 'geolocation-layer', { defaultOlStyle: true });
          const positionFeature = new Feature();
          this.customLayerAddFeatures([positionFeature], 'geolocation-layer');
          if (withAccuracy) {
            const accuracyFeature = new Feature();
            this.customLayerAddFeatures([accuracyFeature], 'geolocation-layer');
            let firstEventExecution = true;
            this.geolocationAccuracyListener = this.geolocation.on('change:accuracyGeometry', () => {
              const accuracyGeometry = this.geolocation.getAccuracyGeometry();
              accuracyFeature.setGeometry(accuracyGeometry);
              if (!firstEventExecution) return;
              this.$root.$emit('fitView', accuracyGeometry);
              firstEventExecution = false;
            });
          } else {
            this.geolocation.once('change:accuracyGeometry', () => {
              this.$root.$emit('fitView', this.geolocation.getAccuracyGeometry());
            });
          }
          this.geolocationPositionListener = this.geolocation.on('change:position', () => {
            const coordinates = this.geolocation.getPosition();
            positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
          });
          this.activeTool = 'geolocation';
        } else {
          this.clearGeolocationInteraction();
        }
      });
    },
  },
  mounted() {
    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
    });
    this.$root.$on('geolocation-action', this.toggleGeolocation);
  },
};
