import api from '@/services/api';
import { make } from 'vuex-pathify';
import Vue from 'vue';
const state = {
  externalSystems: [],
  availableSystems: [],
  connectedDataSources: {},

  dbGisSystems: [],
  availableDbGisSystems: [],

  dbGisBoxSystems: [],
  availableDbGisBoxSystems: [],
};
const mutations = {
  ...make.mutations(state),
  SET_CONNECTED: (state, value) => {
    const { dataSource, data } = value;
    Vue.set(state.connectedDataSources, dataSource, data);
  },
};
const actions = {
  async deleteConnection({ dispatch }, id) {
    await api.delete(`/external_systems/${id}`);
    await dispatch('getExternalSystems');
  },
  async addConnection({ dispatch }, payload) {
    await api.post(`/external_systems`, payload);
    console.log('added :', payload);
    await dispatch('getExternalSystems');
  },
  async editConnection({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`/external_systems/${id}`, body);
    console.log('edited :', payload);
    await dispatch('getExternalSystems');
  },
  async getExternalSystems({ commit }) {
    const r = await api.get(`/external_systems`);
    commit('SET_EXTERNAL_SYSTEMS', r.data.data);
  },
  async getAvailableSystems({ commit }) {
    const r = await api.get(`/external_systems/available_systems `);
    commit('SET_AVAILABLE_SYSTEMS', r.data.data);
  },
  async checkDatasourceConnection({ commit }, dataSourceName) {
    try {
      const r = await api.get(`/external_systems/${dataSourceName}`, { skipDefaultErrorHandler: true });
      commit('SET_CONNECTED', { dataSource: dataSourceName, data: r.data.data });
    } catch (e) {
      console.warn(e.message);
    }
  },
  async getTableHeaders(store, system_id) {
    const r = await api.get(`/external_systems/${system_id}/metadata`);
    return r.data.data;
  },
  async getTableData(store, payload) {
    const { feature_id, datasource } = payload;
    const r = await api.get(`/external_systems/${datasource}/${feature_id}`);
    return r.data.data;
  },
  async getDbGisSystems({ commit }) {
    const r = await api.get(`/db2gis`);
    commit('SET_DB_GIS_SYSTEMS', r.data.data);
  },
  async getAvailableDbGisSystems({ commit }) {
    const r = await api.get(`/db2gis/available_systems `);
    commit('SET_AVAILABLE_DB_GIS_SYSTEMS', r.data.data);
  },
  async deleteDbGisConnection({ dispatch }, id) {
    await api.delete(`/db2gis/${id}`);
    await dispatch('getDbGisSystems');
  },
  async addDbGisConnection({ dispatch }, payload) {
    await api.post(`/db2gis`, payload);
    await dispatch('getDbGisSystems');
  },
  async editDbGisConnection({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`/db2gis/${id}`, body);
    await dispatch('getDbGisSystems');
  },
  async refreshDbGisView(store, id) {
    await api.get(`/db2gis/migrate/${id} `);
  },
  async refreshKomadresData() {
    await api.get('komadres_orders/migrate');
  },
  async addKomadresOrder({ dispatch }, body) {
    await api.post('komadres_orders', body);
    await dispatch('refreshKomadresData');
  },
  async getKomadresOrderHistory(store, id) {
    const r = await api.get(`/komadres_orders/history/${id}`);
    return r.data.data;
  },
  async getSearchedArea(store, payload) {
    return (await api.post('plots', payload)).data.data;
  },
  async getUsableArea(store, payload) {
    return (
      await api.post('oze/usable_area', payload, {
        params: {
          background: true,
        },
      })
    ).data.tid;
  },
  async importPaihOffer(store, payload) {
    return (await api.post('paih/import_offer', payload)).data.data;
  },
  async exportPaihOffer(store, payload) {
    await api.post('paih/export_offer', payload);
  },

  async getDbGisBoxSystems({ commit }) {
    const r = await api.get(`/external_db/systems`);
    commit('SET_DB_GIS_BOX_SYSTEMS', r.data.data);
  },
  async getAvailableDbGisBoxSystems({ commit }) {
    const r = await api.get('/external_db/available_systems');
    commit('SET_AVAILABLE_DB_GIS_BOX_SYSTEMS', r.data.data);
  },
  async deleteDbGisBoxConnection({ dispatch }, id) {
    await api.delete(`/external_db/systems/${id}`);
    await dispatch('getDbGisBoxSystems');
  },
  async addDbGisBoxConnection({ dispatch }, payload) {
    await api.post(`/external_db/systems`, payload);
    await dispatch('getDbGisBoxSystems');
  },
  async editDbGisBoxConnection({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`/external_db/systems/${id}`, body);
    await dispatch('getDbGisBoxSystems');
  },
  async refreshDbGisBoxView(store, id) {
    await api.get(`/external_db/systems/${id}/migrate`);
  },
  async downloadDbGisBoxData(store, payload) {
    await api.post(`/databox/download_data`, payload);
  },
  async getDbGisBoxMetadata() {
    return (await api.get('/databox/layers/metadata')).data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
