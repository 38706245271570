import api from '@/services/api';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';

const state = {
  dataTrees: {},
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getDataTrees({ commit }) {
    const r = await api.get('data_tree');
    commit('SET_DATA_TREES', arrayToObject(r.data.data));
  },
  async addDataTree({ dispatch }, payload) {
    await api.post('data_tree', payload);
    await dispatch('getDataTrees');
  },
  async editDataTree({ dispatch }, { id, payload }) {
    await api.put(`data_tree/${id}`, payload);
    await dispatch('getDataTrees');
  },
  async deleteDataTree({ dispatch }, id) {
    await api.delete(`data_tree/${id}`);
    await dispatch('getDataTrees');
  },

  async getDataTreeResult(store, id) {
    return (await api.post(`data_tree/${id}/tree`)).data?.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
