import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';
import { Circle, Fill, Stroke, Style } from 'ol/style';
export default {
  methods: {
    addWatSewCollisionsHoverLayer(rgbColor = '35,119,234') {
      const newLayer = new VectorLayer({
        id: 'watSewCollisionsHoverLayer',
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          fill: new Fill({
            color: `rgba(${rgbColor},0.6)`,
          }),
          stroke: new Stroke({
            color: `rgba(${rgbColor},1)`,
            width: 3,
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: `rgba(${rgbColor},1)`,
            }),
            fill: new Fill({
              color: `rgba(${rgbColor},0.6)`,
            }),
          }),
        }),
      });
      this.map.addLayer(newLayer);
    },
    toggleWatSewCollisionHover(watSewCollisionFeature, { value = false, rgbColor = '35,119,234' }) {
      let layer = this.getLayerById('watSewCollisionsHoverLayer');
      if (!value && layer) {
        this.clearLayer('watSewCollisionsHoverLayer');
        return;
      }
      if (!layer) {
        this.addWatSewCollisionsHoverLayer(rgbColor);
        layer = this.getLayerById('watSewCollisionsHoverLayer');
      } else {
        this.clearLayer('watSewCollisionsHoverLayer');
      }
      const olGeometry = new GeoJSON().readGeometry(watSewCollisionFeature.geometry, {
        dataProjection: this.$_config.defaultEpsg,
        featureProjection: this.$_config.defaultEpsg,
      });
      const feature = new Feature({ geometry: olGeometry });
      layer.getSource().addFeature(feature);
    },
    toggleWatSewCollisionBuffer(features) {
      this.getLayerById('watSewCollisionsBuffer')
        ?.getSource()
        ?.clear();
      if (!features || features?.features?.length === 0) return;
      if (!this.getLayerById('watSewCollisionsBuffer')) {
        const newLayer = new VectorLayer({
          id: 'watSewCollisionsBuffer',
          isSpecial: true,
          zIndex: 998,
          opacity: 1,
          source: new VectorSource(),
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 1)',
            }),
          }),
        });
        this.map.addLayer(newLayer);
      }
      this.getLayerById('watSewCollisionsBuffer')
        .getSource()
        .addFeatures(
          new GeoJSON().readFeatures(features, {
            featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
            dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
          })
        );

      this.fitView(
        this.getLayerById('watSewCollisionsBuffer')
          ?.getSource()
          ?.getExtent()
      );
    },
  },
  mounted() {
    this.$root.$off('toggleWatSewCollisionHover');
    this.$root.$on('toggleWatSewCollisionHover', this.toggleWatSewCollisionHover);
    this.$root.$off('toggleWatSewCollisionBuffer');
    this.$root.$on('toggleWatSewCollisionBuffer', this.toggleWatSewCollisionBuffer);
  },
};
