import { memoizer } from '@/assets/js/utility';

import { sync, get } from 'vuex-pathify';
import { inRange } from 'lodash';
import { asArray, asString } from 'ol/color';
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import FillPattern from 'ol-ext/style/FillPattern';
import { getCenter } from 'ol/extent';
import { LineString, MultiLineString, Point, Polygon, MultiPolygon } from 'ol/geom';
import { GeoJSON } from 'ol/format';
import {
  getCircleGeometryRadius,
  getFormatCircleArea,
  getFormatCircleLength,
  getFormatCircleRadius,
  formatLength,
  getGeometryCentroid,
} from '@/assets/js/mapUtils';

import stylePreviewUtilities from '@/mixins/stylePreviewUtilities';

const erroredColor = '#D40000';
const highlitedColor = '#FF0000';
const selectedColor = '#FFF503';
const haloColorHighlited = [255, 255, 255, 0.5];
const haloColorSelected = [255, 255, 0, 0.5];

export default {
  mixins: [stylePreviewUtilities],
  computed: {
    isModificationInteractionActive: sync('edit/isModificationInteractionActive'),
    metadata: sync('layers/metadata'),
    cacheFilterFeatures: get('map/cacheFilterFeatures'),
    erroredFeatures: get('map/erroredFeatures'),
    selectedFeatures: get('map/selectedFeatures'),
    highlitedFeatures: get('map/highlitedFeatures'),
    measuredFeatures: get('map/measuredFeatures'),
    layersToBeChanged: sync('map/layersToBeChanged'),
    currentLayerDataSourceName: get('layers/currentLayer@data_source_name'),
    idPropertyName: get('layers/metadata@:currentLayerDataSourceName.attributes_schema.id_name'),
    modulesPermissions: get('users/toolsPermissions'),
    isDirectionArrowsModuleEnabled: get('admin/additionalModules@DIRECTION_ARROWS_VISIBLE.enabled'),
    isDirectionArrowsVisible() {
      return this.isDirectionArrowsModuleEnabled && this.modulesPermissions['DIRECTION_ARROWS_VISIBLE'].main_value > 0;
    },
    netType() {
      return this.$route.params.netType;
    },
  },
  watch: {
    layersToBeChanged(nV) {
      if (nV.size > 0) {
        nV.forEach(layer => {
          this.refreshLayerSource(layer);
        });
        this.layersToBeChanged = new Set();
      }
    },
  },
  methods: {
    isFeatureIncludesInCacheFilter(id, layer) {
      if (!this.cacheFilterFeatures[layer]) {
        return true;
      }
      return this.cacheFilterFeatures[layer].includes(id);
    },
    isFeatureHighlitedOrSelected(id, layer) {
      if ((this.highlitedFeatures[layer] || []).includes(id)) {
        return 'highlited';
      } else if ((this.selectedFeatures[layer] || []).includes(id)) {
        return 'selected';
      } else if ((this.erroredFeatures[layer] || []).includes(id)) {
        return 'errored';
      }
      return false;
    },
    getFeatureMeasureGeometry(id, layer) {
      return this.measuredFeatures[layer]?.[id]?.geometry || false;
    },
    // returns halo effect for a highleted/selected feature label
    getHaloLabelDefinition(labelsConfig, color) {
      const strokeColor = color === highlitedColor ? haloColorHighlited : '#303030';
      return {
        ...labelsConfig,
        'font-size': labelsConfig['font-size'] * 1.1,
        'font-color': color,
        'stroke-color': strokeColor,
        'stroke-visible': true,
        'stroke-width': (labelsConfig['stroke-width'] || 3) * 2,
      };
    },
    getPointHaloStyleDefinition(haloColor, hasExternalGraphic, size) {
      return {
        'circle-color': haloColor,
        'circle-radius': hasExternalGraphic ? size * 15 : size * 3,
      };
    },
    getLineHaloStyleDefinition(haloColor, width) {
      return {
        'line-color': haloColor,
        'line-width': width * 5,
      };
    },
    // returns array of styles for a given feature containing red/yellow feature style + halo effect
    getHighlitedStyleDefinition(type, style, highlitedType, labelsVisible, selectedColors) {
      const { minzoom, maxzoom } = style;
      if (minzoom && minzoom > this.zoom) {
        return [];
      } else if (maxzoom && maxzoom < this.zoom) {
        return [];
      }
      const {
        erroredColor: customErroredColor,
        highlitedColor: customHighlitedColor,
        selectedColor: customSelectedColor,
        haloColorHighlited: customHaloColorHighlited,
        haloColorSelected: customHaloColorSelected,
        labelAttributesName: customLabelAttributesName,
        labelCustomText: customLabelText,
      } = selectedColors;
      let color = '';
      if (highlitedType === 'highlited') {
        color = customHighlitedColor || highlitedColor;
      } else if (highlitedType === 'selected') {
        color = customSelectedColor || selectedColor;
      } else {
        color = customErroredColor || erroredColor;
      }
      let haloColor = '';
      if (highlitedType === 'highlited') {
        haloColor = customHaloColorHighlited || haloColorHighlited;
      } else if (highlitedType === 'selected') {
        haloColor = customHaloColorSelected || haloColorSelected;
      }
      let labels = '';
      if (customLabelAttributesName || customLabelText) {
        labels = {
          ...(customLabelText ? { text: customLabelText } : { attributes: customLabelAttributesName }),
          'font-color': '#D40000',
          'font-size': 14,
          'font-weight': 'bold',
          'offset-x': 0,
          'offset-y': -15,
          'stroke-color': '#FFFFFF',
          'stroke-visible': true,
          'stroke-width': 3,
        };
      } else if (highlitedType !== 'errored' && labelsVisible && style.labels?.attributes?.length > 0) {
        labels = this.getHaloLabelDefinition(style.labels, color);
      } else {
        labels = style.labels;
      }
      if (type === 'point' || type === 'multipoint') {
        const hasExternalGraphic = !!style.externalGraphic;
        const size = hasExternalGraphic ? style['icon-size'] : style['circle-radius'];
        return [
          ...(haloColor ? [this.getPointHaloStyleDefinition(haloColor, hasExternalGraphic, size)] : []),
          {
            ...style,
            ...(highlitedType === 'errored' ? {} : { 'circle-color': color }),
            ...(hasExternalGraphic
              ? { 'icon-size': highlitedType === 'errored' ? size : size * 1.5 }
              : { 'circle-radius': highlitedType === 'errored' ? size : size * 2 }),
            labels,
          },
          ...(highlitedType === 'errored'
            ? [
                {
                  externalGraphic: 'excluded_red.svg',
                  'icon-size': this.getErroredIconScale(size, hasExternalGraphic),
                  'fill-opacity': style['fill-opacity'],
                  minzoom: style.minzoom,
                  maxzoom: style.maxzoom,
                },
              ]
            : []),
        ];
      } else if (type === 'linestring' || type === 'multilinestring') {
        return [
          ...(haloColor ? [this.getLineHaloStyleDefinition(haloColor, style['line-width'])] : []),
          { ...style, ...(highlitedType === 'errored' ? { 'line-dash': [10, 10] } : {}), 'line-color': color, labels },
        ];
      }
      return [
        {
          ...style,
          ...(highlitedType === 'errored' ? { 'line-dash': [10, 10] } : {}),
          ...(highlitedType === 'errored' ? {} : { 'line-width': style['line-width'] * 5 }),
          ...(haloColor ? { 'fill-outline-color': haloColor } : {}),
          'fill-color': color,
          labels,
        },
      ];
    },
    getStyleForValueInRange(feature, style, { value, parameter }) {
      const ranges = this.$_objectToArray(style.ranges).map(range => range.value);
      if (value) {
        return this.getValueInRange(value, ranges, style);
      }
      return this.getValueInRange(feature.get(parameter), ranges, style);
    },
    getStyleDefinition(style, { value, feature } = {}) {
      if (style.uniques) {
        const uniqueValue = value || feature.get(style.uniques.property);
        if (!uniqueValue && uniqueValue !== false) {
          return style.uniques.values.null || style;
        }
        return style.uniques.values[uniqueValue] || style;
      } else if (style.ranges) {
        const uniqueValue = value || feature.get(style.ranges.property);
        if (!uniqueValue && uniqueValue !== 0) {
          return style;
        }
        const rangeStyle = style.ranges.values.find(
          value => uniqueValue >= value['min-value'] && uniqueValue <= value['max-value']
        );
        return rangeStyle || style;
      }
      return style;
    },

    getFeatureStyle(
      feature,
      style,
      type,
      labelsVisible,
      layer,
      directionArrowsVisible,
      { value, parameter } = {},
      selectedColors = {},
      cacheLayer = false
    ) {
      const styleDefinition = this.getStyleDefinition(style, { feature, value, parameter });
      if (Object.prototype.hasOwnProperty.call(styleDefinition, 'visible') && !styleDefinition.visible) return null;
      const featureId = feature.get(this.idPropertyName) || feature.getId();
      const highlitedType = this.isFeatureHighlitedOrSelected(featureId, layer);
      const hasFeatureMeasure = this.getFeatureMeasureGeometry(featureId, layer) ? true : false;
      const directionArrowsEnabled = this.isDirectionArrowsVisible ? directionArrowsVisible : false;
      const includesInCacheFilter = cacheLayer ? this.isFeatureIncludesInCacheFilter(featureId, layer) : true;
      if (!includesInCacheFilter) {
        return null;
      } else if (highlitedType) {
        const highlitedLabelsVisible = labelsVisible || selectedColors.labelsVisible;
        const styles = this.getHighlitedStyleDefinition(
          type,
          styleDefinition,
          highlitedType,
          highlitedLabelsVisible,
          selectedColors
        );
        return this.getHighlitedStyles({
          layer,
          feature,
          featureId,
          styles,
          type,
          labelsVisible: highlitedLabelsVisible,
          directionArrowsVisible: directionArrowsEnabled,
          hasFeatureMeasure,
        });
      }
      return this.getStyle(feature, styleDefinition, type, labelsVisible, directionArrowsEnabled);
    },
    getHighlitedStyles({
      layer,
      feature,
      featureId,
      styles,
      type,
      labelsVisible,
      directionArrowsVisible,
      hasFeatureMeasure,
    }) {
      if (hasFeatureMeasure) {
        const featureMeasuremGeom = this.getFeatureMeasureGeometry(featureId, layer);
        const featureMeasureGeometry = new GeoJSON().readGeometry(featureMeasuremGeom, {
          featureProjection: this.$_config.defaultEpsg || 'EPSG:3857',
          dataProjection: this.$_config.defaultEpsg || 'EPSG:3857',
        });
        const geomTypeStyleFunction = this.typeStyleFunctionsDist[featureMeasureGeometry?.getType()];
        return [
          ...styles
            .map(style => {
              return this.getStyle(feature, style, type, labelsVisible, directionArrowsVisible);
            })
            .flat(),
          ...(hasFeatureMeasure && geomTypeStyleFunction
            ? this[geomTypeStyleFunction]({ geometry: featureMeasureGeometry, simple: true })
            : []),
        ];
      }
      return styles
        .map(style => {
          return this.getStyle(feature, style, type, labelsVisible, directionArrowsVisible);
        })
        .flat();
    },
    getStyle(feature, style, type, labelsVisible, directionArrowsVisible) {
      let labelsText = false;
      let labelsStyle;
      if (style.minzoom && style.minzoom > this.zoom) {
        return;
      } else if (style.maxzoom && style.maxzoom < this.zoom) {
        return;
      } else if (style.labels?.minzoom && style.labels.minzoom > this.zoom) {
        labelsText = false;
      } else if (style.labels?.maxzoom && style.labels.maxzoom < this.zoom) {
        labelsText = false;
      } else {
        labelsText = labelsVisible && this.getLabels(feature, style.labels);
        labelsStyle = { ...style.labels };
      }
      if (type === 'point' || type === 'multipoint') {
        const {
          externalGraphic,
          'icon-size': iconSize,
          'circle-radius': radius,
          'circle-color': circleColor,
          'fill-opacity': fillOpacity,
          'fill-outline-color': fillOutlineColor,
          'fill-outline-opacity': fillOutlineOpacity,
          'fill-outline-width': fillOutlineWidth,
        } = style;
        const iconSizeScale = this.getIconScale(iconSize);
        return this.getPointStyle({
          externalGraphic,
          iconSize: iconSizeScale,
          radius,
          circleColor,
          fillOpacity,
          fillOutlineColor,
          fillOutlineOpacity,
          fillOutlineWidth,
          labels: labelsText,
          labelsStyle,
        });
      } else if (type === 'linestring' || type === 'multilinestring') {
        const {
          'line-width': lineWidth,
          'line-color': lineColor,
          'line-dash': lineDash,
          'fill-opacity': fillOutlineOpacity,
        } = style;
        return [
          this.getLineStyle({
            lineColor,
            fillOutlineOpacity,
            lineWidth,
            lineDash,
            labels: labelsText,
            labelsStyle,
          }),
          ...(directionArrowsVisible && this.getLineDirectionArrow(feature, lineColor, lineWidth, fillOutlineOpacity)
            ? [this.getLineDirectionArrow(feature, lineColor, lineWidth, fillOutlineOpacity)]
            : []),
        ];
      } else if (type === 'polygon' || type === 'multipolygon') {
        const {
          'fill-color': fillColor,
          'fill-opacity': fillOpacity,
          'line-width': lineWidth,
          'line-dash': lineDash,
          'fill-outline-opacity': fillOutlineOpacity,
          'fill-outline-color': fillOutlineColor,
          externalGraphic,
          'icon-size': iconSize = 1,
        } = style;
        const iconSizeScale = this.getIconScale(iconSize);
        return this.getPolygonStyle({
          fillColor,
          fillOpacity,
          fillOutlineColor,
          fillOutlineOpacity,
          lineWidth,
          lineDash,
          labels: labelsText,
          labelsStyle,
          externalGraphic,
          iconSize: iconSizeScale,
        });
      }
    },
    getPointStyle({
      externalGraphic,
      iconSize,
      radius,
      circleColor,
      fillOpacity,
      fillOutlineColor,
      fillOutlineOpacity,
      fillOutlineWidth,
      labels,
      labelsStyle,
    }) {
      return new Style({
        image: externalGraphic
          ? this.getIcon({
              externalGraphic,
              iconSize,
              opacity: fillOpacity,
            })
          : this.getCircle({
              circleColor,
              fillOpacity,
              radius,
              fillOutlineColor,
              fillOutlineOpacity,
              fillOutlineWidth,
            }),
        text: this.getText({ text: labels, labelsStyle }),
      });
    },
    getLineStyle({ lineColor, fillOutlineOpacity, lineWidth, lineDash, labels, labelsStyle }) {
      return new Style({
        stroke: this.getStroke({
          fill: lineColor,
          opacity: fillOutlineOpacity,
          width: lineWidth,
          dash: lineDash,
        }),
        text: this.getText({
          text: labels,
          labelsStyle,
          textPlacement: 'line',
        }),
      });
    },
    getPolygonStyle({
      fillColor,
      fillOpacity,
      fillOutlineColor,
      fillOutlineOpacity,
      lineWidth,
      lineDash,
      labels,
      labelsStyle,
      externalGraphic,
      iconSize,
    }) {
      return new Style({
        fill: externalGraphic
          ? this.getFillPattern({ externalGraphic, opacity: fillOpacity, iconSize })
          : this.getFill({
              color: fillColor,
              opacity: fillOpacity,
            }),
        stroke: this.getStroke({
          fill: fillOutlineColor,
          opacity: fillOutlineOpacity,
          width: lineWidth,
          dash: lineDash,
        }),
        text: this.getText({ text: labels, labelsStyle }),
      });
    },
    getIcon({ externalGraphic, iconSize = 1, opacity = 1 }) {
      return new Icon({
        opacity,
        src: this.getStyleExternalGraphicIcon(externalGraphic),
        scale: iconSize,
      });
    },
    getFillPattern({ externalGraphic, opacity, iconSize }) {
      return new FillPattern({
        opacity,
        image: this.getIcon({ externalGraphic }),
        scale: iconSize,
      });
    },
    getCircle({ circleColor, fillOpacity, radius, fillOutlineColor, fillOutlineOpacity, fillOutlineWidth }) {
      const hasOutline = fillOutlineColor && fillOutlineOpacity && fillOutlineWidth;
      return hasOutline
        ? new Circle({
            fill: this.getFill({
              color: circleColor,
              opacity: fillOpacity,
            }),
            stroke: this.getStroke({
              fill: fillOutlineColor || '#ffffff',
              opacity: fillOutlineOpacity || 1,
              width: fillOutlineWidth || 1,
            }),
            radius,
          })
        : new Circle({
            fill: this.getFill({
              color: circleColor,
              opacity: fillOpacity,
            }),
            radius,
          });
    },
    getFill({ color, opacity }) {
      return new Fill({
        color: this.hexToRgba(color, opacity),
      });
    },
    getStroke({ fill, opacity, width, dash }) {
      return new Stroke({
        color: this.hexToRgba(fill, opacity),
        width,
        lineDash: dash,
      });
    },
    getText({ text, labelsStyle, color = this.$_colors.font, textPlacement = 'point' }) {
      if (!text || !labelsStyle) {
        return;
      }
      // Fix obsługi dwóch struktur etykiet na raz
      // TODO: Usunąć go
      if (Array.isArray(labelsStyle)) {
        return new Text({
          text,
          fill: this.getFill({
            color,
            opacity: 1,
          }),
        });
      } else {
        let {
          'font-color': fontColor,
          'font-size': fontSize,
          'font-weight': fontWeight,
          'stroke-visible': strokeVisible,
          'stroke-color': strokeColor,
          'stroke-width': strokeWidth,
          'offset-x': offsetX,
          'offset-y': offsetY,
          attributes_advanced: { justify = 'center', text: advancedText = '' } = {},
        } = labelsStyle;

        fontColor = typeof fontColor !== 'undefined' ? fontColor : '#000000';
        fontSize = typeof fontSize !== 'undefined' ? fontSize : 12;
        fontWeight = typeof fontWeight !== 'undefined' ? fontWeight : 'normal';
        strokeVisible = typeof strokeVisible !== 'undefined' ? strokeVisible : false;
        strokeColor = typeof strokeColor !== 'undefined' ? strokeColor : '#FFFFFF';
        strokeWidth = typeof strokeWidth !== 'undefined' ? strokeWidth : 3;
        offsetX = typeof offsetX !== 'undefined' ? offsetX : 0;
        offsetY = typeof offsetY !== 'undefined' ? offsetY : 0;

        const font = `${fontWeight} ${fontSize}px sans-serif`;
        return new Text({
          font,
          text,
          justify,
          fill: this.getFill({
            color: fontColor,
            opacity: 1,
          }),
          placement: textPlacement,
          ...(advancedText ? { maxAngle: 0 } : { maxAngle: Math.PI / 16 }),
          stroke: strokeVisible
            ? this.getStroke({
                fill: strokeColor,
                opacity: 1,
                width: strokeWidth,
                dash: [],
              })
            : '',
          offsetX,
          offsetY,
        });
      }
    },
    getLabels(feature, labels) {
      if (!labels) {
        return '';
      }
      let label = '';
      if (labels.text) {
        label = labels.text;
      } else if (labels.attributes_advanced?.text) {
        label = this.$_getQueryValue(labels.attributes_advanced.text, feature, {
          isFeature: true,
        });
      } else {
        // Fix obsługi dwóch struktur etykiet na raz
        // TODO: Usunąć go
        const labelsArray = Array.isArray(labels) ? labels : labels.attributes || [];
        label = labelsArray.reduce((total, label) => {
          let labelValue = feature.get(label);
          if (labelValue === false) {
            labelValue = labelValue.toString();
          }
          return `${total} ${labelValue || labelValue == 0 ? labelValue : ''}`;
        }, '');
      }
      return label;
    },
    getLength(value, unit) {
      const dict = {
        m: 1,
        km: 1000,
        miles: 1609.344,
      };
      return +value * dict[unit];
    },
    getCongestionManholesText({ value, labelsStyle }) {
      const text = value || value === 0 ? `${parseFloat(value.toFixed(2))}` : this.$i18n.t('default.noData');
      return new Style({
        text: this.getText({ text, labelsStyle }),
      });
    },
    getChoroplethMapFeatureStyle(f, data, layer) {
      const { id } = f.getProperties();
      const { ranges, rangesColors, values } = data;
      const value = values[id];

      let color;
      if (this.isFeatureHighlitedOrSelected(id, layer)) {
        color = highlitedColor;
      } else {
        color = this.getValueInRange(value, ranges, rangesColors);
      }
      let style = this.styleCache[`${color}ChoroplethMap`];
      if (!style) {
        style = this.styleCache[`${color}ChoroplethMap`] = new Style({
          fill: new Fill({
            color,
          }),
          stroke: new Stroke({
            color: 'black',
            width: 1,
          }),
        });
      }
      return style;
    },
    getModelingLayerFeatureStyle(f, data, resolution, layer, labelsVisible, featureStyle) {
      const { id, st_length, label } = f.getProperties();
      const type = f.getType();
      const { name, ranges, unit, abs } = data;
      const parameter = f.get(name);
      let parameterValue = abs
        ? Math.abs(this.getParameterValue(type, id, name, parameter))
        : this.getParameterValue(type, id, name, parameter);
      const zoom = type === 'LineString' && this.getCacheZoomForResolution(resolution);
      const mapUnit = this.map.getView().getProjection().getUnits();
      const metersLength = this.getLength(st_length, mapUnit);
      let color;
      let haloColor;
      let dash = [];
      let size = this.getValueInRange(parameterValue, ranges, featureStyle.sizes);
      const selectedOrHighlited = this.isFeatureHighlitedOrSelected(id, layer);
      if (selectedOrHighlited) {
        color = selectedOrHighlited === 'highlited' ? highlitedColor : selectedColor;
        haloColor = selectedOrHighlited === 'highlited' ? haloColorHighlited : haloColorSelected;
      } else {
        color = this.getValueInRange(parameterValue, ranges, featureStyle.colors);
      }
      const hasLabels = labelsVisible && this.zoom > 16;
      let styleCacheAppendix = '';
      if (featureStyle.dashes) {
        dash = this.getValueInRange(parameterValue, ranges, featureStyle.dashes);
        styleCacheAppendix = `-${dash}`;
      }
      let style =
        this.styleCache[`${color}-${label}-${parameterValue}-${hasLabels}-${unit}-${size}${styleCacheAppendix}`];
      if (!style) {
        style = new Style({
          image: this.getCircle({
            circleColor: color,
            fillOpacity: 1,
            radius: size,
          }),
          stroke: this.getStroke({
            fill: color,
            opacity: 1,
            width: size,
            dash,
          }),
        });
        if (hasLabels) {
          style.setText(
            this.getText({
              text: `${label || ''} (${
                parameterValue && parameterValue != '0' ? parameterValue.toFixed(4) : 0
              } ${unit})`,
              labelsStyle: {
                'font-size': selectedOrHighlited ? 15 : 13,
                'font-weight': 'normal',
                'stroke-visible': true,
                'stroke-width': selectedOrHighlited ? 6 : 3,
                'offset-x': 0,
                'offset-y': -20,
                'font-color': color,
                'stroke-color': this.$_isHexColorLight(color) ? '#303030' : '#ffffff',
              },
            })
          );
        }
        this.styleCache[`${color}-${label}-${parameterValue}-${hasLabels}-${unit}-${size}${styleCacheAppendix}`] =
          style;
      }
      return [
        ...(selectedOrHighlited ? [this.getModelingHighlitedHaloStyle(type, haloColor, style)] : []),
        style,
        ...(type === 'LineString' && zoom > 16 && metersLength / resolution > 100
          ? [
              this.getArrow(
                f,
                id,
                color,
                this.netType === 'water' ? (this.getParameterValue(type, id, 'flow', f.get('flow')) > 0 ? 0 : 1) : 0,
                this.getArrowScale(zoom)
              ),
            ]
          : []),
      ];
    },
    getModelingHighlitedHaloStyle(type, haloColor) {
      return type === 'LineString'
        ? this.getLineStyle({
            lineColor: haloColor,
            fillOutlineOpacity: 1,
            lineWidth: 10,
          })
        : this.getPointStyle({
            circleColor: haloColor,
            fillOpacity: 1,
            radius: 7.5,
          });
    },
    getParameterValue(type, id, name, parameter) {
      const hash = this.$_getHash(id + type + name);
      let parsedParameterValues = this.valuesCache[hash];
      if (!parsedParameterValues) {
        parsedParameterValues = this.valuesCache[hash] = JSON.parse(parameter);
        return parsedParameterValues[this.currentTimestepIndex];
      }
      return parsedParameterValues[this.currentTimestepIndex];
    },
    getValueInRange(value, ranges, dataSet, noDataColor = false) {
      if (value === undefined || value === null) {
        return noDataColor;
      }
      const infinityRanges = [-Infinity, ...ranges, Infinity];
      for (let i = 0; i < infinityRanges.length; i++) {
        if (i < infinityRanges.length) {
          if (inRange(value, infinityRanges[i], infinityRanges[i + 1])) {
            return dataSet[i];
          }
        }
      }
    },
    getArrowScale(zoom) {
      if (zoom < 15) {
        return 0.55;
      } else if (zoom < 18) {
        return 0.7;
      }
      return 0.85;
    },
    getCacheZoomForResolution(resolution) {
      let zoom = this.zoomForResolution[resolution];
      if (!zoom) {
        zoom = this.zoomForResolution[resolution] = this.map.getView().getZoomForResolution(resolution);
      }
      return zoom;
    },
    getArrow(feature, id, color, isReversed, arrowScale) {
      const { center_x, center_y, center_azimuth } = feature.getProperties();
      const rotation = isReversed ? center_azimuth + Math.PI : center_azimuth;
      return this.getArrowStyle([center_x, center_y], id, color, arrowScale, rotation);
    },
    getArrowStyle(extentCenter, id, color, scale, rotation) {
      const hash = this.$_getHash(id + color + scale + rotation);
      let arrow = this.arrowStyles[hash];
      if (!arrow) {
        arrow = this.arrowStyles[hash] = new Style({
          geometry: new Point(extentCenter),
          image: new Icon({
            src: '/arrow.svg',
            rotateWithView: true,
            color,
            scale,
            rotation,
          }),
        });
      }
      return arrow;
    },
    getLineDirectionArrow(feature, lineColor, lineWidth, fillOutlineOpacity) {
      const geometry = feature.getGeometry();
      if (geometry.getType() !== 'LineString') return null;
      if (geometry.getLength() / this.map.getView().getResolution() < 13) return null;
      const zoom = this.map.getView().getZoomForResolution(this.map.getView().getResolution());
      if (zoom < 16) return null;
      const coords = geometry.getCoordinates();
      const scale = lineWidth / 10 + 0.3;
      const dx = coords[coords.length - 1][0] - coords[0][0];
      const dy = coords[coords.length - 1][1] - coords[0][1];
      const rotation = Math.atan2(dy, dx);
      const returnStyle = new Style({
        geometry: new Point(geometry.getFlatMidpoint()),
        image: new Icon({
          src: '/arrow.svg',
          anchor: [0.75, 0.5],
          color: lineColor,
          opacity: fillOutlineOpacity,
          rotateWithView: true,
          rotation: -rotation,
          scale,
        }),
      });
      return returnStyle;
    },
    getMarkerStyle({ path = '/marker_red.svg' } = {}) {
      return new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: path,
        }),
      });
    },
    hexToRgba(hex, opacity) {
      const [r, g, b] = Array.from(asArray(hex));
      return asString([r, g, b, opacity]);
    },
    getErroredIconScale(size, isIcon = false) {
      if (isIcon) {
        return size;
      } else {
        if (this.zoom >= 20) {
          return size / 10 + 0.25;
        } else if (this.zoom >= 15) {
          return size / 5 + 0.5;
        } else {
          return size / 2.5 + 0.75;
        }
      }
    },
    getIconScale(iconSize) {
      if (this.zoom >= 20) {
        return iconSize * 0.5;
      } else if (this.zoom >= 15) {
        return iconSize * 0.25;
      } else {
        return iconSize * 0.125;
      }
    },
    getIntersectMeasurementStyles1({ geometry } = {}) {
      if (!(geometry instanceof Polygon) && !(geometry instanceof MultiPolygon)) {
        return [];
      }
      return [
        new Style({
          fill: new Fill({
            color: 'rgba(237, 234, 183, 0.5)',
          }),
        }),
      ];
    },
    getIntersectMeasurementStyles2({ feature, geometry } = {}) {
      if (!(geometry instanceof Polygon) && !(geometry instanceof MultiPolygon)) {
        return [];
      }
      const unionArea = feature.get('union_area');
      return [
        new Style({
          geometry: geometry.getInteriorPoint(),
          text: new Text({
            backgroundFill: new Fill({
              color: 'rgba(255, 255, 255, 0.6)',
            }),
            text: this.getPolygonMeasurementLabel(geometry, unionArea),
            font: 'bold 13px sans-serif',
            fill: new Fill({
              color: 'black',
            }),
            placement: 'point',
            stroke: new Stroke({
              color: 'transparent',
              width: 0,
            }),
            offsetX: 0,
            offsetY: 0,
            padding: [5, 5, 5, 5],
          }),
        }),
      ];
    },
    getAreaMeasurementStyles({ geometry, simple = false } = {}) {
      if (!(geometry instanceof Polygon) && !(geometry instanceof MultiPolygon)) {
        return [];
      }
      const styles = [
        ...(simple
          ? []
          : [
              new Style({
                fill: new Fill({
                  color: 'rgba(237, 234, 183, 0.35)',
                }),
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 0.5)',
                  lineDash: [10, 10],
                  width: 2,
                }),
              }),
            ]),
      ];
      (geometry instanceof Polygon ? [geometry] : geometry.getPolygons()).forEach(singlePolygonGeom => {
        const [coordinates] = singlePolygonGeom.getCoordinates();
        const line = new LineString(coordinates);
        line.forEachSegment((start, end) => {
          const line = new LineString([start, end]);
          const length = formatLength(line);
          styles.unshift(
            new Style({
              geometry: line,
              ...(simple
                ? {}
                : {
                    stroke: new Stroke({
                      color: 'rgba(0, 0, 0, 0.5)',
                      lineDash: [10, 10],
                      width: 2,
                    }),
                  }),
              text: new Text({
                text: length,
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: this.$_colors.font,
                }),
                stroke: new Stroke({
                  color: 'white',
                  width: 4,
                }),
                placement: 'line',
                offsetX: 20,
                offsetY: 20,
              }),
            })
          );
        });

        if (!simple && coordinates?.length > 3) {
          const ceontroid = getGeometryCentroid(singlePolygonGeom);
          const point = new Point(ceontroid);
          styles.unshift(
            new Style({
              geometry: point,
              image: new Icon({
                src: '/cursormove.svg',
              }),
            })
          );
        }
        if (coordinates.length > 3) {
          styles.push(
            new Style({
              geometry: singlePolygonGeom.getInteriorPoint(),
              text: new Text({
                backgroundFill: new Fill({
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
                text: this.getPolygonMeasurementLabel(singlePolygonGeom),
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: 'white',
                }),
                placement: 'point',
                stroke: new Stroke({
                  color: 'transparent',
                  width: 0,
                }),
                offsetX: 0,
                offsetY: simple ? 0 : 40,
                padding: [5, 5, 5, 5],
              }),
            })
          );
        }
      });
      return styles;
    },
    getLengthMeasurementStyles({ geometry, simple = false } = {}) {
      if (!(geometry instanceof LineString) && !(geometry instanceof MultiLineString)) {
        return [];
      }
      const styles = [];
      (geometry instanceof LineString ? [geometry] : geometry.getLineStrings()).forEach(singleLinestringGeom => {
        if (singleLinestringGeom.getCoordinates().length > 2) {
          const ceontroid = getGeometryCentroid(singleLinestringGeom);
          const point = new Point(ceontroid);
          styles.unshift(
            new Style({
              geometry: point,
              image: new Icon({
                src: '/cursormove.svg',
              }),
            })
          );
          styles.push(
            new Style({
              geometry: new Point(singleLinestringGeom.getLastCoordinate()),
              text: new Text({
                backgroundFill: new Fill({
                  color: 'rgba(0,0,0,.7)',
                }),
                text: formatLength(singleLinestringGeom),
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: 'white',
                }),
                placement: 'point',
                stroke: new Stroke({
                  color: 'transparent',
                  width: 3,
                }),
                offsetX: 0,
                offsetY: 30,
                padding: [5, 5, 5, 5],
              }),
            })
          );
        }
        singleLinestringGeom.forEachSegment((start, end) => {
          const line = new LineString([start, end]);
          styles.push(
            new Style({
              geometry: line,
              ...(simple
                ? {}
                : {
                    fill: new Fill({
                      color: this.$_colors.font,
                    }),
                    stroke: new Stroke({
                      color: 'rgba(0, 0, 0, 0.8)',
                      lineDash: [10, 10],
                      width: 2,
                    }),
                  }),
              text: new Text({
                text: formatLength(line),
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: this.$_colors.font,
                }),
                placement: 'line',
                stroke: new Stroke({
                  color: 'white',
                  width: 4,
                }),
                offsetX: -20,
                offsetY: 20,
              }),
              image: new Circle({
                radius: 5,
                stroke: new Stroke({
                  color: this.$_colors.font,
                }),
                fill: new Fill({
                  color: this.$_colors.font,
                }),
              }),
            })
          );
        });
      });
      return styles;
    },
    getCircleMeasurementLabel(radius, statsToShow = ['area', 'length', 'radius']) {
      const stats = {
        area: {
          translation: this.$i18n.t('map.area'),
          fn: getFormatCircleArea,
        },
        length: {
          translation: this.$i18n.t('map.perimeter'),
          fn: getFormatCircleLength,
        },
        radius: {
          translation: this.$i18n.t('map.radius'),
          fn: getFormatCircleRadius,
        },
      };
      return statsToShow.map(stat => `${stats[stat].translation}: ${stats[stat].fn(radius)}`).join('\n');
    },
    getCircleLabelStyle(centerPointCoords, radius, statsToShow, params = {}) {
      return new Style({
        geometry: new Point(centerPointCoords),
        text: new Text({
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.6)',
          }),
          text: this.getCircleMeasurementLabel(radius, statsToShow),
          font: 'bold 13px sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          placement: 'point',
          stroke: new Stroke({
            color: 'transparent',
            width: 0,
          }),
          offsetX: 0,
          offsetY: 0,
          padding: [5, 5, 5, 5],
          ...params,
        }),
      });
    },
    getCircleMeasurementStyles({ geometry, feature } = {}) {
      if (!['Circle', 'GeometryCollection'].includes(geometry.getType())) {
        return [];
      }
      const polygon = feature.get('modifyGeometry')?.getGeometries()[0] || geometry.getGeometries()[0];
      const ceontroid = getGeometryCentroid(polygon);
      const point = new Point(ceontroid);
      return [
        new Style({
          geometry: feature => {
            return feature.get('modifyGeometry') || feature.getGeometry();
          },
          fill: new Fill({
            color: 'rgba(237, 234, 183, 0.35)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
        }),
        new Style({
          geometry: point,
          image: new Icon({
            src: '/cursormove.svg',
          }),
        }),
        ...[
          this.getCircleLabelStyle(
            getCenter(polygon.getExtent()),
            getCircleGeometryRadius({
              startCoordinate: getCenter(polygon.getExtent()),
              endCoordinate: polygon.getFirstCoordinate(),
            }),
            undefined,
            { offsetY: 50 }
          ),
        ],
      ];
    },
    getPointMeasurementStyle({ geometry } = {}) {
      if (!(geometry instanceof Point)) {
        return [];
      }
      return [
        new Style({
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgb(100, 100, 100)',
            }),
            fill: new Fill({
              color: 'rgba(128, 128, 128, 0.8)',
            }),
          }),
        }),
      ];
    },
  },
  mounted() {
    this.getPointStyle = memoizer(this.getPointStyle);
    this.getLineStyle = memoizer(this.getLineStyle);
    this.getPolygonStyle = memoizer(this.getPolygonStyle);
    this.getIcon = memoizer(this.getIcon);
    this.getCircle = memoizer(this.getCircle);
    this.getFill = memoizer(this.getFill);
    this.getFillPattern = memoizer(this.getFillPattern);
    this.getStroke = memoizer(this.getStroke);
    this.getText = memoizer(this.getText);
    this.getMarkerStyle = memoizer(this.getMarkerStyle);
    this.getCongestionManholesText = memoizer(this.getCongestionManholesText);
  },
};
