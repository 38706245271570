import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from '@/assets/styles/_export.module.scss';
import '@mdi/font/css/materialdesignicons.css';

const icons = import.meta.glob('@/assets/icons/*.svg', { eager: true, query: '?raw' });

const iconComponents = Object.entries(icons).reduce((modules, [path, module]) => {
  const moduleName = path
    .split(/[\\/]/)
    .pop()
    .replace(/\.[^.]+$/, '');
  modules[moduleName] = {
    component: {
      functional: true,
      render: createElement => {
        const t = document.createElement('div');
        t.innerHTML = module.default;
        const svgElement = t.children[0];
        return createElement('svg', {
          attrs: (() => {
            let attrs = {};
            svgElement.attributes.forEach(attr => (attrs[attr.name] = attr.value));
            return attrs;
          })(),
          domProps: {
            innerHTML: svgElement.innerHTML,
          },
        });
      },
    },
  };
  return modules;
}, {});

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: iconComponents,
  },
  lang: {
    current: import.meta.env.VUE_APP_I18N_LOCALE,
  },
  theme: {
    themes: {
      light: Object.fromEntries(Object.entries(colors).filter(([, value]) => !value.includes('rgba'))),
    },
  },
});
