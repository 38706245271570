import api from '@/services/api';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';

const state = {
  inspections: {},
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getInspections({ commit }) {
    const r = await api.get('inspections');
    commit('SET_INSPECTIONS', arrayToObject(r.data.data));
  },
  async addInspection({ dispatch }, payload) {
    await api.post('inspections', payload);
    await dispatch('getInspections');
  },
  async editInspection({ dispatch }, { id, payload }) {
    await api.put(`inspections/${id}`, payload);
    await dispatch('getInspections');
  },
  async deleteInspection({ dispatch }, id) {
    await api.delete(`inspections/${id}`);
    await dispatch('getInspections');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
