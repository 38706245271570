import { make } from 'vuex-pathify';
import api from '@/services/api';
import { sitesTypes } from '@/assets/js/rziData';
import { saveFileFromBackend } from '@/assets/js/utility.js';

const state = {
  scores: [],
  sitesTypesFeatures: [],
};

const mutations = {
  ...make.mutations(state),
  SET_SITES_TYPES_FEATURES(state, data) {
    const parsedData = data.map(item => {
      const category = Object.values(sitesTypes.categoriesData).find(category =>
        category.allowedTypeValues.includes(item.properties[sitesTypes.metadata.attributes.code])
      );
      if (category) {
        item.properties.custom_mainKey = category.mainKey;
        item.properties.custom_category = category.value;
      }
      return item;
    });
    state.sitesTypesFeatures = parsedData;
  },
  SET_SCORES(state, { scores, currentMetadata }) {
    state.scores = scores.features.map(item => ({
      id: item.id,
      code: item.properties[currentMetadata.score.dictGiosScores.attributes.code],
      name: item.properties[currentMetadata.score.dictGiosScores.attributes.name],
      color: item.properties[currentMetadata.score.dictGiosScores.attributes.color],
    }));
  },
};

const actions = {
  async moveRziFeature(store, { fromDatasource, toDatasource, featureId, geometry }) {
    const r = await api.put(`/rzi/features/${fromDatasource}/${toDatasource}/${featureId}/move`, { geometry });
    return r;
  },
  async getProtectionForms(store, featureId) {
    const r = await api.get(`/rzi/monitoring/fop/stanowiska_rok/${featureId}`);
    return r.data.data;
  },
  async generateMonitoringReport(store, monitoringYearId) {
    const r = await api.post(
      `/rzi/monitoring/pdf/stanowiska_rok/${monitoringYearId}`,
      {},
      {
        timeout: 0,
        responseType: 'arraybuffer',
      }
    );
    saveFileFromBackend(r.data, r.headers);
    return r;
  },
  async uploadGeometries(store, formData) {
    const r = await api.post('/rzi/monitoring/upload/stanowiska_rok', formData, {
      noWrap: true,
      returnErrorData: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    return r;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
