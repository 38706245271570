import { call, get } from 'vuex-pathify';

export default {
  computed: {
    statusCheckers: get('asyncStatusChecker/statusCheckers'),
  },
  watch: {
    statusCheckers: {
      handler(nV, oV) {
        // stary arraj był dłuższy, czyli zmiana w arraju byłą usunięciem, więc nie odpalamy nasłuchu
        if (nV.length === 0 || nV?.length < oV?.length) {
          return;
        }
        this.pushChecker(this.getLast(nV));
      },
    },
  },
  methods: {
    getCeleryTaskStatus: call('celeryTasks/getCeleryTaskStatus'),
    stopCeleryTask: call('celeryTasks/stopCeleryTask'),
    getCeleryTaskResult: call('celeryTasks/getCeleryTaskResult'),
    getLast(nV) {
      return nV[nV.length - 1];
    },
    async runChecker(checkingStatusFunction, successCallback, agreeCallback, interval, id) {
      let tiemoutId = undefined;
      const action = async () => {
        try {
          const isReady = await checkingStatusFunction();
          if (isReady) {
            this.agreeCallback = () => agreeCallback(this);
            successCallback(this);
            if (tiemoutId) {
              clearTimeout(tiemoutId);
            }
            return id;
          } else {
            tiemoutId = setTimeout(action, interval);
          }
        } catch (error) {
          console.error(error);
        }
      };
      return await action();
    },
    pushChecker({ checkingStatusFunction, successCallback, agreeCallback, interval, id } = {}) {
      this.runChecker(checkingStatusFunction, successCallback, agreeCallback, interval, id);
    },
    getCeleryFailureStatusError(response, taskId) {
      const errMsg = `Error message:\n${response.result.error_message}`;
      return `Celery status checker for task ID ${taskId}: ${response.status}\n=========\n${
        response.result.error
          ? `${errMsg}\n\nTraceback:\n${response.result.error}`
          : `${errMsg}\n\nNo traceback provided.`
      }\n=========`;
    },
    async celeryTaskHandler({
      currentTaskId,
      taskStartSnackbar,
      taskFailureSnackbar,
      taskFailureCallback = () => {},
      taskSuccessCallback = () => {},
      taskSuccessResultParams = {},
      skipTaskResult = false,
      statusCheckingInterval = 5000,
    }) {
      const checkerId = Date.now();
      if (taskStartSnackbar?.message) {
        this.$store.set('snackbar/PUSH_MESSAGE!', taskStartSnackbar);
      }
      this.$store.set('asyncStatusChecker/PUSH_STATUS_CHECKER!', {
        id: checkerId,
        checkingStatusFunction: async () =>
          await this.handleCheckCeleryTaskStatus(currentTaskId, checkerId, taskFailureSnackbar, taskFailureCallback),
        successCallback: async () =>
          await this.handleCeleryTaskSuccess(
            currentTaskId,
            checkerId,
            taskSuccessCallback,
            taskSuccessResultParams,
            skipTaskResult
          ),
        interval: statusCheckingInterval,
      });
    },
    async handleCheckCeleryTaskStatus(currentTaskId, checkerId, taskFailureSnackbar, taskFailureCallback) {
      const statusResponse = await this.getCeleryTaskStatus(currentTaskId);
      const status = statusResponse?.status;
      const resultMessage = statusResponse?.result.error_message;
      if (['REVOKED', 'FAILURE'].includes(status)) {
        this.$store.set('asyncStatusChecker/REMOVE_STATUS_CHECKER_BY_ID!', checkerId);
        if (resultMessage || taskFailureSnackbar?.message) {
          this.$store.set('snackbar/PUSH_ERROR!', {
            ...taskFailureSnackbar,
            ...(resultMessage && { message: resultMessage }),
          });
        }
        taskFailureCallback();
        throw Error(this.getCeleryFailureStatusError(statusResponse, currentTaskId));
      }
      return status === 'SUCCESS';
    },
    async handleCeleryTaskSuccess(
      currentTaskId,
      checkerId,
      taskSuccessCallback,
      taskSuccessResultParams,
      skipTaskResult
    ) {
      this.$store.set('asyncStatusChecker/REMOVE_STATUS_CHECKER_BY_ID!', checkerId);
      try {
        let response;
        if (!skipTaskResult) {
          response = await this.getCeleryTaskResult({ id: currentTaskId, ...taskSuccessResultParams });
        }
        taskSuccessCallback(response?.result);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  created() {
    this.$globalHub.$on('handleCeleryTask', this.celeryTaskHandler);
  },
};
