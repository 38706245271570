<template>
  <v-col cols="auto">
    <v-badge
      overlap
      :content="badgeValue"
      :value="badgeValue && badgeValue != '0'"
      color="error"
      bordered
      :offset-y="15"
      :offset-x="15"
    >
      <base-rounded-button
        class="pa-4"
        color="rgba(235,235,235,0.5)"
        :icon-color="$_colors.iconIdle"
        size="x-small"
        :icon="icon"
        :tooltip="$i18n.t(`button.${name}`)"
        @click="openPanel()"
        has-border
      />
    </v-badge>
  </v-col>
</template>

<script>
export default {
  name: 'TheNavbarTopPanelInfoButton',
  props: {
    name: {
      type: String,
      required: true,
    },
    badgeValue: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    additionalTableFilterValues: {
      type: Object,
      required: true,
    },
    otherTableRoutes: {
      type: Array,
      required: true,
    },
    isToolWorking: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openPanel() {
      if (!this.isToolWorking) {
        this.pushError(this.$i18n.t('snackbar.toolNotActive'));
        return;
      }
      if (this.otherTableRoutes.includes(this.$route.name)) {
        this.$root.$emit('setAdditionalTableFilterValues', this.additionalTableFilterValues);
      } else if (this.$route.name !== this.name) {
        this.$router.push({
          name: this.name,
          params: { additionalTableFilterValues: this.additionalTableFilterValues },
        });
      } else {
        this.$root.$emit('setAdditionalTableFilterValues', this.additionalTableFilterValues);
      }
    },
    pushError(message) {
      this.$store.set('snackbar/PUSH_MESSAGE!', {
        message,
        color: '#333333',
        buttonType: 'text',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-badge {
  z-index: 2 !important;
}
</style>
