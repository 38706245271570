import { Circle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import { getVectorContext } from 'ol/render';
import { easeOut } from 'ol/easing';
import { unByKey } from 'ol/Observable';

import { debounce } from 'lodash';

export default {
  data: () => ({
    alarmsInterval: undefined,
  }),
  methods: {
    toggleScadaAlarms(features) {
      if (!features) {
        clearInterval(this.alarmsInterval);
        return;
      }
      if (this.getLayerById('scadaAlarms')) {
        clearInterval(this.alarmsInterval);
        this.getLayerById('scadaAlarms')
          .getSource()
          .clear();
        this.getLayerById('scadaAlarms')
          .getSource()
          .addFeatures(
            new GeoJSON().readFeatures(
              {
                type: 'FeatureCollection',
                features,
              },
              {
                featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
                dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
              }
            )
          );
        const layerFeatures = this.getLayerById('scadaAlarms')
          .getSource()
          .getFeatures();
        this.alarmsInterval = setInterval(() => {
          this.flashFeatures(layerFeatures);
        }, 2000);
      } else {
        const newLayer = new VectorLayer({
          id: 'scadaAlarms',
          isSpecial: true,
          zIndex: 999,
          opacity: 1,
          source: new VectorSource({
            features: new GeoJSON().readFeatures(
              {
                type: 'FeatureCollection',
                features,
              },
              {
                featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
                dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
              }
            ),
          }),
          style: new Style({
            image: new Circle({
              radius: 7,
              stroke: new Stroke({
                color: 'rgba(191, 54, 12)',
              }),
              fill: new Fill({
                color: 'rgba(191, 54, 12)',
              }),
            }),
          }),
        });
        this.map.addLayer(newLayer);
        const layerFeatures = this.getLayerById('scadaAlarms')
          .getSource()
          .getFeatures();
        this.alarmsInterval = setInterval(() => {
          this.flashFeatures(layerFeatures);
        }, 2000);
      }
    },
    flashFeatures(features, { duration = 750, color = [191, 54, 12] } = {}) {
      return this.flashGeometries(
        features.map(f => f.getGeometry()),
        {
          color,
          duration,
        }
      );
    },
    flashGeometries(geometries, { duration = 750, color = [191, 54, 12] } = {}) {
      const self = this;
      const start = new Date().getTime();
      let listenerKey;

      function animate(event) {
        const vectorContext = getVectorContext(event);
        const frameState = event.frameState;

        const elapsed = frameState.time - start;
        const elapsedRatio = elapsed / duration;
        const radius = easeOut(elapsedRatio) * 25 + 5;
        const opacity = easeOut(1 - elapsedRatio);

        if (elapsed > duration) {
          unByKey(listenerKey);
          return;
        }

        const style = new Style({
          image: new Circle({
            radius,
            stroke: new Stroke({
              color: `rgba(${color.join(',')}, ${opacity})`,
              width: 0.25 + opacity,
            }),
          }),
        });

        if (vectorContext) {
          vectorContext.setStyle(style);
          for (let geometry of geometries) {
            vectorContext.drawGeometry(geometry);
          }
        }

        self.mapRender();
      }
      if (!this.map) {
        clearInterval(this.alarmsInterval);
        return false;
      }
      listenerKey = this.getLayerById('scadaAlarms').on('postrender', animate);

      this.map.render();

      return true;
    },
    mapRender() {
      if (!this.map) {
        clearInterval(this.alarmsInterval);
        return false;
      }
      this.map.render();
    },
  },
  mounted() {
    this.$root.$off('toggleScadaAlarms');
    this.$root.$on('toggleScadaAlarms', this.toggleScadaAlarms);
  },
  created() {
    this.mapRender = debounce(this.mapRender, 100);
  },
};
