<template>
  <div>
    <slot>
      <base-button-subheader :subheader-translation-path="subtitleTranslationPath">
        <slot name="button" v-bind="{ ...$attrs, ...$props, ...$data }">
          <component :is="buttonComponent" class="mr-0" v-on="computedListeners" v-bind="buttonBind" />
        </slot>
      </base-button-subheader>
    </slot>
  </div>
</template>
<script>
import { createBox } from 'ol/interaction/Draw';
import { get } from 'vuex-pathify';
import { prepareDrawnGeometry, prepareDrawnMultiGeometry } from '@/assets/js/mapUtils';
import { never } from 'ol/events/condition';
import geometryTypeMapping from '@/mixins/map/geometryTypeMapping';
export default {
  name: 'DataInputGeometry',
  mixins: [geometryTypeMapping],
  props: {
    buttonComponent: {
      type: String,
      default: 'BaseButton',
    },
    buttonProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    geometryType: {
      type: String,
      default: 'Point',
    },
    maxMultipleGeometryParts: {
      type: Number,
      default: 1,
    },
    maxNumberOfVertices: {
      type: Number,
      default: null,
    },
    disableModifyAddingNewVertices: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    snapping: {
      type: Boolean,
      default: false,
    },
    snappingActiveLayerId: {
      default: null,
    },
    snappingAdditionalLayers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    additionalGeometryStyles: {
      type: Object,
      default: () => ({
        draw: null,
        modify: null,
      }),
    },
    subtitleTranslationPath: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    drawingStyleFunction: {
      type: Function,
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeTool: get('tools/activeTool'),
    buttonBind() {
      const { disabled, loading } = this.$attrs;
      return {
        ...this.buttonProps,
        buttonStyle: {
          backgroundColor:
            this.activeType && !(this.computedValue && this.disableEditing && this.$attrs.disabled)
              ? `${this.$_colors.primarybackground} !important`
              : 'rgba(0, 0, 0, 0.04) !important',
          border:
            this.activeType && !(this.computedValue && this.disableEditing && this.$attrs.disabled)
              ? '1px solid rgba(26, 115, 232, 0.3) !important'
              : '1px solid rgba(0, 0, 0, 0.2) !important',
          color:
            this.activeType && !(this.computedValue && this.disableEditing && this.$attrs.disabled)
              ? this.$_colors.primary
              : '#61646d',
          ...this.buttonProps.buttonStyle,
        },
        color:
          this.activeType && !(this.computedValue && this.disableEditing && this.$attrs.disabled)
            ? this.$_colors.primarybackground
            : 'rgba(225,225,225,1)',
        iconColor:
          this.activeType && !(this.computedValue && this.disableEditing && this.$attrs.disabled)
            ? this.$_colors.primary
            : this.$_colors.iconIdle,
        disabled: this.disabled || disabled,
        loading: this.loading || loading,
      };
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    mappedGeometryType() {
      // It allows to specify the 'geometryType' prop in both layer and OL format
      return this.getOlGeometryTypeFromLayerGeometry(this.geometryType);
    },
    isMultipleGeometryType() {
      return this.mappedGeometryType.includes('Multi');
    },
    mappedSimpleGeometryType() {
      return this.getOlGeometrySimpleType(this.mappedGeometryType);
    },
    computedListeners() {
      return {
        click: () => {
          this.activeType ? this.toggleOff() : this.toggleOn('drawGeometry');
        },
        ...this.$listeners,
      };
    },
  },
  data: () => ({
    activeType: '',
    disabled: false,
    loading: false,
    temporaryValue: null,
  }),
  methods: {
    drawGeometry() {
      if (this.computedValue) {
        return;
      }
      const drawSidebarGeometryData =
        this.geometryType === 'Extent'
          ? {
              geometryType: 'polygon',
              geometryFunction: createBox(),
              drawType: 'Circle',
            }
          : {
              geometryType: this.mappedSimpleGeometryType,
            };
      this.$root.$emit('drawSidebarGeometry', {
        ...drawSidebarGeometryData,
        drawendCallback: this.onDrawend,
        modifyendCallback: this.onModifyend,
        ...(this.isMultipleGeometryType || this.disableEditing ? {} : { withEditing: true }),
        isInitSnapping: this.snapping,
        snappingAdditionalLayers: this.snappingAdditionalLayers,
        layerId: this.snappingActiveLayerId,
        activeLayer: this.snappingActiveLayerId,
        ...(this.additionalGeometryStyles.draw ? { additionalStyleFunction: this.additionalGeometryStyles.draw } : {}),
        ...(this.maxNumberOfVertices ? { maxPoints: this.maxNumberOfVertices } : {}),
        ...(this.drawingStyleFunction ? { styleFunction: this.drawingStyleFunction } : {}),
      });
    },
    modifyGeometry(geometry) {
      if (this.disableEditing) return;
      this.$root.$emit('modifySidebarGeometry', {
        geometryValue: geometry,
        ...(this.geometryType === 'Extent' && { geometryType: 'Extent' }),
        modifyendCallback: this.onModifyend,
        withEditing: true,
        isInitSnapping: this.snapping,
        snappingAdditionalLayers: this.snappingAdditionalLayers,
        layerId: this.snappingActiveLayerId,
        activeLayer: this.snappingActiveLayerId,
        ...(this.additionalGeometryStyles.modify
          ? { additionalStyleFunction: this.additionalGeometryStyles.modify }
          : {}),
        ...(this.disableModifyAddingNewVertices ? { insertVertexCondition: never } : {}),
      });
    },
    onModifyend(e) {
      this.setSidebarGeometry(e.features.getArray()[0]);
    },
    onDrawend(e, currentFeatures) {
      if (this.isMultipleGeometryType) {
        this.setSidebarMultiGeometry(currentFeatures, true);
        if (currentFeatures.length < this.maxMultipleGeometryParts) {
          this.drawGeometry();
        } else {
          this.saveTemporaryValue();
        }
      } else {
        this.setSidebarGeometry(e.feature);
      }
    },
    setSidebarGeometry(feature) {
      const newValue = feature ? prepareDrawnGeometry(feature) : null;
      this.computedValue = newValue;
      this.$emit('change', newValue);
    },
    setSidebarMultiGeometry(features, temporary = false) {
      if (!features?.length) {
        this[`${temporary ? 'temporary' : 'computed'}Value`] = null;
      } else {
        this[`${temporary ? 'temporary' : 'computed'}Value`] = prepareDrawnMultiGeometry(features);
      }
    },
    toggleOn(type) {
      this.activeType = type;
    },
    toggleOff() {
      this.activeType = '';
      this.computedValue = null;
      this.$root.$emit('deleteSidebarGeometry');
    },

    // ^ Helper functions to be called from outside the component
    clearGeometry() {
      if (!this.computedValue) {
        return;
      }
      this.$root.$emit('deleteSidebarGeometry');
      this.computedValue = null;
    },
    saveTemporaryValue() {
      const activeTypeCache = JSON.parse(JSON.stringify(this.activeType));
      this.toggleOff();
      this.$nextTick(() => {
        this.computedValue = this.temporaryValue;
        this.toggleOn(activeTypeCache);
      });
    },
    zoomToGeometry() {
      if (!this.computedValue) {
        return;
      }
      this.$root.$emit('createGeomAndFitView', this.computedValue);
    },
    // v Helper functions to be called from outside the component
  },
  watch: {
    activeType: {
      immediate: true,
      handler(nV) {
        this.$emit('status', nV);
        if (nV === 'drawGeometry') {
          this.drawGeometry();
        }
      },
    },
    computedValue(nV, oV) {
      if (!oV && nV) {
        this.modifyGeometry(nV);
      } else if (!nV && oV) {
        this.toggleOff();
      }
    },
    activeTool(nV) {
      if (nV) {
        this.toggleOff();
      }
    },
  },
  beforeDestroy() {
    this.toggleOff();
  },
  created() {
    this.$root.$on('addedSidebarGeometry', data => {
      if (data.inputName === this.name) {
        this.computedValue = data.geometry;
      }
    });
  },
};
</script>
