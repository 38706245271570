<template>
  <v-col @click="onClick" cols="auto" class="navbar__button pa-0" :class="{ background: state }">
    <slot v-bind="{ ...$props, state, isToolWorking }"> </slot>
  </v-col>
</template>

<script>
import { get, sync } from 'vuex-pathify';

export default {
  name: 'TheNavbarButton',
  props: {
    name: {
      type: String,
    },
    /**
     * A prop describing how tool should behave on click.
     * Default action is to change the URL while custom one is emiting global
     * event composed of name of tool and `-action` suffix.
     * this.$root.$emit(`${this.name}-action`)
     * @type {Boolean}
     */
    hasCustomAction: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: '',
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: null,
    },
    hasState: {
      type: Boolean,
      default: false,
    },
    isToolWorking: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    moduleLayerId: {
      type: Number,
      default: null,
    },
    routePath: {
      type: String,
    },
  },
  data: () => ({
    state: false,
  }),
  computed: {
    additionalModules: get('admin/additionalModules'),
    currentTab: sync('sidebar/currentTab'),
    layers: get('layers/layers'),
    project: sync('layers/project'),
    projects: get('layers/projects'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    query() {
      return this.$route.query;
    },
    isConfigurated() {
      const additionalModule = this.additionalModules[this.name];
      if (additionalModule) {
        return additionalModule?.configured;
      } else {
        return true;
      }
    },
  },
  methods: {
    // default button on click action is to change URL
    handleDefaultAction() {
      if (this.isRegister) {
        this.$router.push({
          name: 'layerTable',
          params: {
            lid: this.name,
          },
        });
        this.$root.$emit('registerOpened');
      } else {
        if ([this.routeName, this.name].includes(this.$route.name) && this.currentTab !== 1) {
          this.currentTab = 1;
        } else {
          this.$router.push({
            name: this.routeName || this.name,
            params: this.routeParams,
          });
        }
      }
    },
    handleModuleLayerAction(layerId = this.moduleLayerId) {
      if (layerId || layerId === 0) {
        const layer = JSON.parse(JSON.stringify(this.layers[layerId]));
        if (layer) {
          if (this.projectLayers.findIndex(projectLayer => projectLayer.id === layerId) > -1) {
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('snackbar.layerAlreadyAddedToProject', { name: layer.name }),
            });
          } else {
            layer.labels_visible = layer.labels_visible || false;
            layer.style = layer.style_web;
            delete layer.style_web;
            this.$store.set('layers/ADD_PROJECT_LAYERS!', [
              { layer_id: layer.id, visible: true, opacity: 1, has_permission: true, ...layer },
            ]);
            this.$root.$emit('pushProjectLayers', [
              { layer_id: layer.id, visible: true, opacity: 1, has_permission: true, ...layer },
            ]);
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('snackbar.layerAddedToProject', { name: layer.name }),
            });
          }
        }
      }
    },
    handleRoutePathAction() {
      const routePath = this.routePath.startsWith(import.meta.env.VUE_APP_HOST)
        ? this.routePath.split(import.meta.env.VUE_APP_HOST)[1]
        : this.routePath;
      if (routePath.startsWith('/')) {
        const projectId = routePath.match(/project=([^&]*)/)?.[1];
        if (projectId && this.$route.query.project != projectId) {
          this.project = this.projects[projectId];
          this.$root.$emit('fetchProject');
        }
        this.$router.push(routePath);
      } else if (routePath.startsWith('http')) {
        window.open(routePath);
      } else {
        const projectId = routePath.match(/project=([^&]*)/)?.[1];
        if (projectId && this.$route.query.project != projectId) {
          this.project = this.projects[projectId];
          this.$root.$emit('fetchProject');
        }
        this.$router.push(`/${routePath}`);
      }
    },
    customAction() {
      if (this.$listeners[this.name]) {
        this.$emit(this.name, this.$listeners[this.name]);
        return;
      }
      if (this.hasState) {
        this.state = !this.state;
        if (this.state) {
          this.$root.$emit('deactivateAllTools');
          this.$nextTick(() => {
            this.$root.$emit(`${this.name}-action`, true);
          });
        } else {
          this.$root.$emit(`${this.name}-action`, false);
        }
      } else {
        this.$root.$emit('deactivateAllTools');
        this.$nextTick(() => {
          this.$root.$emit(`${this.name}-action`);
        });
      }
    },
    pushError(message) {
      this.$store.set('snackbar/PUSH_MESSAGE!', {
        message,
        color: '#333333',
        buttonType: 'text',
      });
    },
    onClick() {
      if (this.level === 0) {
        return;
      } else if (!this.isToolWorking) {
        this.pushError(this.$i18n.t('snackbar.toolNotActive'));
        return;
      } else if (!this.isConfigurated && !this.isAdmin) {
        this.$store.set('snackbar/PUSH_MESSAGE!', {
          message: this.$i18n.t(`snackbar.modulesNotConfigurate.${this.name}`),
          color: '#333333',
          buttonType: 'text',
        });
        return;
      } else if (this.hasCustomAction) {
        return this.customAction();
      } else if (this.moduleLayerId || this.moduleLayerId === 0) {
        return this.handleModuleLayerAction();
      } else if (this.routePath) {
        return this.handleRoutePathAction();
      } else {
        return this.handleDefaultAction();
      }
    },
    changeState(name, value) {
      if (this.name !== name) {
        return;
      }
      if (value !== undefined) {
        this.state = value;
        return;
      }
      this.state = !this.state;
    },
    clearState(clickedTool) {
      if (this.hasState && this.name !== clickedTool) {
        this.state = false;
      }
    },
  },
  mounted() {
    if (this.hasState) {
      this.$root.$on('changeButtonState', this.changeState);
      this.$once('hook:beforeDestroy', () => {
        this.$off();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar__button {
  &:hover {
    background-color: rgba(235, 235, 235, 0.534);
    cursor: pointer;
  }
}
.background {
  @include activeToolButtonStyle();
}
.disabled {
  cursor: none;
  pointer-events: none;
  background-color: #0000001f !important;
  color: red !important;
  caret-color: #fff !important;
  .v-icon {
    color: map-get($colors, 'font') !important;
  }
  span {
    color: map-get($colors, 'font');
  }
}
</style>
