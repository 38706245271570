import { scaleLinear, scaleQuantize } from 'd3-scale';
export default {
  computed: {
    valvesStateStyles() {
      return [
        {
          externalGraphic: 'valves_closed.svg',
          'icon-size': 2,
          'fill-opacity': 1,
          text: 'close',
        },
        {
          externalGraphic: 'valves_open.svg',
          'icon-size': 2,
          'fill-opacity': 1,
          text: 'open',
        },
        {
          externalGraphic: 'valves_noinfo.svg',
          'icon-size': 2,
          'fill-opacity': 1,
          text: 'noData',
        },
        {
          externalGraphic: 'excluded.svg',
          'icon-size': 2,
          'fill-opacity': 1,
          text: 'excluded',
        },
        {
          externalGraphic: 'valves_toclose.svg',
          'icon-size': 2,
          'fill-opacity': 1,
          text: 'toClose',
        },
      ];
    },
    hydrantsDeaerationDefaultStyle() {
      return {
        'circle-color': '#8A8A8A',
        'circle-radius': 8,
        'fill-opacity': 1,
        labels: {
          attributes: ['deaeration'],
          'font-color': '#202124',
          'font-size': 12,
          'font-weight': 'bold',
          'offset-x': 0,
          'offset-y': 14,
          'stroke-color': '#FFFFFF',
          'stroke-visible': true,
          'stroke-width': 3,
        },
      };
    },
  },
  methods: {
    getHydrantsDeaerationRange(minValue, maxValue, color) {
      return {
        'circle-color': color,
        'circle-radius': 8,
        'fill-opacity': 1,
        label: minValue === maxValue ? `${minValue}` : `${minValue} - ${maxValue}`,
        labels: {
          attributes: ['deaeration'],
          'font-color': '#202124',
          'font-size': 12,
          'font-weight': 'bold',
          'offset-x': 0,
          'offset-y': 14,
          'stroke-color': '#FFFFFF',
          'stroke-visible': true,
          'stroke-width': 3,
        },
        'max-value': maxValue,
        'min-value': minValue,
      };
    },
    getHydrantsDeaerationRanges(uniqueValues = [], colors = ['#1D6934', '#188038', '#329F54', '#F44B4B', '#FF0000']) {
      const values = [];
      const classesLength = uniqueValues.length > 5 ? 5 : uniqueValues.length;
      if (classesLength) {
        const classesColors = scaleQuantize()
          .domain([1, classesLength])
          .range(colors);
        if (uniqueValues.length > 5) {
          const classesValues = scaleLinear()
            .domain([1, classesLength + 1])
            .range([Math.min(...uniqueValues), Math.max(...uniqueValues)]);
          for (let idx = 1; idx < classesLength + 1; idx++) {
            const rangeMin = Math.round(classesValues(idx) * 100) / 100;
            const rangeMax = Math.round(classesValues(idx + 1) * 100) / 100;
            const value = this.getHydrantsDeaerationRange(rangeMin, rangeMax, classesColors(idx));
            values.push(value);
          }
        } else {
          for (let idx = 1; idx < classesLength + 1; idx++) {
            const range = uniqueValues[idx - 1];
            const value = this.getHydrantsDeaerationRange(
              range,
              range,
              classesLength === 1 ? colors[idx] : classesColors(idx)
            );
            values.push(value);
          }
        }
      }
      return { property: 'deaeration', values };
    },
    getHydrantsDeaerationStyle(ranges, defaultStyle = this.hydrantsDeaerationDefaultStyle) {
      return { ...defaultStyle, ranges };
    },
  },
};
