import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  failures: {},
};

const mutations = {
  ...make.mutations(state),
  DELETE_FAILURE: (state, failure_id) => {
    Vue.delete(state.failures, failure_id);
  },
  SET_FAILURES: (state, data) => {
    const { failuresProps, crs } = data;
    const failures = {};
    for (const failure of failuresProps) {
      failures[failure.id] = {
        ...failure.properties,
        ...{ id: failure.id },
        ...{ assigned_features: [...(failure.assigned_features ? failure.assigned_features : [])] },
        ...{ geometry: { ...failure.geometry, ...{ crs } } },
      };
    }
    state.failures = failures;
  },
};

const actions = {
  async editFailure({ dispatch }, payload) {
    const { feature, failure_id, features_to_assign, features_to_unassign } = payload;
    await api.put(`/zdm_failures/${failure_id}`, { feature, features_to_assign, features_to_unassign });
    await dispatch('getFailures');
  },
  async getFailure(store, id) {
    const r = await api.get(`/zdm_failures/${id}`);
    return r.data.data.feature;
  },
  async getFailures({ commit }) {
    const r = await api.post('zdm_failures/read', {});
    commit('SET_FAILURES', { failuresProps: r.data.data.features.features, crs: r.data.data.features.crs });
  },
  async deleteFailure({ commit }, failure_id) {
    await api.delete(`/zdm_failures/${failure_id}`);
    commit('DELETE_INVESTMENT', failure_id);
  },
  async findFailures(store, id) {
    const params = {
      failure_id: id,
    };
    const r = await api.get('zdm_failures/search', { params });
    if (Object.keys(r.data.data).length === 0) {
      return [];
    } else {
      return [r.data.data];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
