import api from '@/services/api';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';

const state = {
  joins: {},
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getJoins({ commit }) {
    const r = await api.get('joins');
    commit('SET_JOINS', arrayToObject(r.data.data));
  },
  async addJoin({ dispatch }, payload) {
    await api.post('joins', payload);
    await dispatch('getJoins');
  },
  async editJoin({ dispatch }, { id, payload }) {
    await api.put(`joins/${id}`, payload);
    await dispatch('getJoins');
  },
  async deleteJoin({ dispatch }, id) {
    await api.delete(`joins/${id}`);
    await dispatch('getJoins');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
