import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = import.meta.glob('@/locales/*.json', { eager: true });
  const messages = {};
  Object.entries(locales).forEach(([key, value]) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = value.default;
    }
  });
  return messages;
}

// modified pluralizaton rules from
// http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
const pluralizationRules = {
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  en: choice => {
    return choice === 0 ? 0 : choice === 1 ? 1 : 2;
  },
  de: choice => {
    return choice === 0 ? 0 : choice === 1 ? 1 : 2;
  },
  nl: choice => {
    return choice === 0 ? 0 : choice === 1 ? 1 : 2;
  },
  pl: choice => {
    return choice === 0
      ? 0
      : choice === 1
        ? 1
        : choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)
          ? 2
          : 3;
  },
};

// Dates are not use in i18n and are formatted by dayjs library,
// but the same date-time format is added for each language just in case.
const dateTimeFormat = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
};
const dateTimeFormats = {
  en: dateTimeFormat,
  de: dateTimeFormat,
  nl: dateTimeFormat,
  pl: dateTimeFormat,
};

export default new VueI18n({
  locale: import.meta.env.VUE_APP_I18N_LOCALE || 'pl',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pl',
  messages: loadLocaleMessages(),
  pluralizationRules,
  dateTimeFormats,
});
