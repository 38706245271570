import { call } from 'vuex-pathify';

export default {
  methods: {
    getBboxGetLayerFeatures: call('layers/getLayerFeatures'),
    getLayersBbox: call('layers/getLayersBbox'),
    async getFeaturesBbox({ layerId, featuresIds } = {}) {
      try {
        const dataSourceName = this.$store.get('layers/layers')[layerId]?.data_source_name;
        const dataSourceMetadata = this.$store.get('layers/metadata')[dataSourceName];
        const r = await this.getBboxGetLayerFeatures({
          features_filter: {
            $IN: {
              [dataSourceMetadata?.attributes_use_datasource_qualified_names
                ? dataSourceMetadata?.attributes_schema.id_name
                : `${dataSourceName}.${dataSourceMetadata?.attributes_schema.id_name}`]: featuresIds,
            },
          },
          layer_id: layerId,
          params: {
            with_collection_bbox: true,
            with_features: false,
          },
        });
        return r.data.data.bbox.map(coords => +coords);
      } catch {
        //
      }
    },
    /**
     * layersArray variable is just array version of above's {layerId, featuresIds}
     */
    async getMultipleLayersFeaturesBbox(layersArray) {
      try {
        const currentFeaturesMetadataPayload = layersArray.map(item => {
          const datasourceName = this.$store.get('layers/layers')[item.layerId]?.data_source_name;
          const dataSourceMetadata = this.$store.get('layers/metadata')[datasourceName];
          const idName = dataSourceMetadata?.attributes_schema.id_name;
          const attrName = dataSourceMetadata?.attributes_use_datasource_qualified_names
            ? idName
            : `${datasourceName}.${idName}`;
          return {
            layer_id: item.layerId,
            filter_expression: {
              $IN: {
                [attrName]: item.featuresIds,
              },
            },
          };
        }, []);
        const bbox = await this.getLayersBbox({ layers: currentFeaturesMetadataPayload });
        return bbox;
      } catch {
        //
      }
    },
  },
};
