import { sync } from 'vuex-pathify';
import { GeoJSON } from 'ol/format';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

export default {
  computed: {
    excludedManholes: sync('manholes/excludedManholes'),
    excludedManholesStyle() {
      return {
        externalGraphic: 'excluded.svg',
        'icon-size': 2,
        'fill-opacity': 1,
        text: 'excluded',
      };
    },
  },
  methods: {
    async addExcludedManholeFeature(featureId, layerId) {
      if (!this.getLayerById('excludedManholes')) {
        this.map.addLayer(
          new VectorLayer({
            id: 'excludedManholes',
            source: new VectorSource({}),
            style: f => this.getFeatureStyle(f, this.excludedManholesStyle, 'point', false, 'excludedManholes'),
            zIndex: 999,
          })
        );
      }
      const layer = this.getLayerById('excludedManholes');
      const feature = await this.getLayerFeature({ layer_id: layerId, feature_id: featureId });
      const geojsonFeature = new GeoJSON().readFeature(feature, {
        dataProjection: feature.crs.properties.name,
        featureProjection: this.$_config.defaultEpsg,
      });
      layer.getSource().addFeature(geojsonFeature);
      const featureProperties = { ...feature.properties, id: feature.id };
      this.excludedManholes = this.excludedManholes.concat([featureProperties]);
    },
    deleteExcludedManholeFeature(featureId) {
      this.$root.$emit('deleteFeature', 'excludedManholes', featureId);
      this.excludedManholes = this.excludedManholes.filter(manhole => manhole.id != featureId);
    },
    deleteExcludedManholesFeatures() {
      const layer = this.getLayerById('excludedManholes');
      if (layer) {
        layer.getSource().clear();
      }
      this.excludedManholes = [];
    },
  },
  mounted() {
    this.$root.$off('addExcludedManholeFeature');
    this.$root.$on('addExcludedManholeFeature', this.addExcludedManholeFeature);
    this.$root.$off('deleteExcludedManholesFeatures');
    this.$root.$on('deleteExcludedManholesFeatures', this.deleteExcludedManholesFeatures);
    this.$root.$on('deleteExcludedManholeFeature', this.deleteExcludedManholeFeature);
  },
};
