<template>
  <draggable
    :value="value"
    :group="draggableGroup"
    id="treeview-draggable"
    class="v-treeview v-treeview-draggable treeview-draggable-box"
    :ghost-class="`treeview-draggable-ghost-${treeviewNodeBind.level}`"
    @input="updateItems"
    :move="onMove"
  >
    <treeview-draggable-node
      v-for="(item, index) in value"
      :key="item[elementUidKey]"
      :group-id="index"
      :value="item"
      :all-values="value"
      v-bind="treeviewNodeBind"
      @input="updateItem"
    >
      <template v-for="(value, key) in $scopedSlots" #[key]="scope">
        <slot :name="key" v-bind="scope" />
      </template>
    </treeview-draggable-node>
  </draggable>
</template>
<script>
import TreeviewDraggableNode from '@/components/TreeviewDraggableNode';
import draggable from 'vuedraggable-multi';
export default {
  name: 'TreeviewDraggable',
  components: { TreeviewDraggableNode, draggable },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allowNestedGroups: {
      type: Boolean,
      default: false,
    },
    elementChildrenKey: {
      type: String,
      default: 'children',
    },
    elementNameKey: {
      type: String,
      default: 'name',
    },
    elementUidKey: {
      type: String,
      default: 'id',
    },
    groupOpenKey: {
      type: String,
      default: null,
    },
    emptyGroupTranslationPath: {
      type: String,
      default: 'default.noElementsInGroup',
    },
    expandIcon: {
      type: String,
      default: 'mdi-menu-down',
    },
    disableDraggableBetweenGroups: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    draggableGroup() {
      return this.disableDraggableBetweenGroups ? null : 'draggable-group';
    },
    treeviewNodeBind() {
      return {
        draggableGroup: this.draggableGroup,
        expandIcon: this.expandIcon,
        elementChildrenKey: this.elementChildrenKey,
        elementNameKey: this.elementNameKey,
        elementUidKey: this.elementUidKey,
        groupOpenKey: this.groupOpenKey,
        emptyGroupTranslationPath: this.emptyGroupTranslationPath,
        level: 0,
      };
    },
  },
  methods: {
    updateItem(item) {
      const index = [...this.value].findIndex(v => v[this.elementUidKey] === item[this.elementUidKey]);
      this.$set(this.value, index, item);
      this.$emit('input', this.value);
    },
    updateItems(e) {
      this.$nextTick(() => {
        // Let subitems update first
        this.$emit('input', e);
      });
    },
    onMove(e) {
      if (
        !this.allowNestedGroups &&
        e.draggedContext.element[this.elementChildrenKey] &&
        e.to.id !== 'treeview-draggable'
      ) {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  @for $i from 0 through 4 {
    $padding: 24px * ($i + 1);
    $paddingExpandable: $padding - 24px;
    .treeview-draggable-ghost-#{$i} {
      padding-left: $padding !important;
    }
    .treeview-draggable-ghost-#{$i}[class*='v-treeview-expandable'] {
      padding-left: $paddingExpandable !important;
    }
  }
  .sortable-chosen[class*='treeview-draggable-ghost'] {
    .node-levels-box {
      display: none !important;
    }
  }
}
</style>
