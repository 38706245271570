import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import { Style, Fill, Stroke } from 'ol/style';
import { Translate } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { createOrUpdate } from 'ol/extent';
import { getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import { get, sync } from 'vuex-pathify';
import { Point, Polygon } from 'ol/geom';
import { GeoJSON } from 'ol/format';
import { getLength } from 'ol/sphere';
import { getPointResolution, transform } from 'ol/proj';

import turfDestination from '@turf/destination';

export default {
  data: () => ({
    paperSeries: {
      a0: [118.9, 84.1],
      a1: [84.1, 59.4],
      a2: [59.4, 42],
      a3: [42, 29.7],
      a4: [29.7, 21],
    },
    mapPercentageCoverage: {
      vertical: {
        height: 87.016,
        width: 94.628,
      },
      horizontal: {
        height: 80.074,
        width: 96.3,
      },
    },
    mapPercentageCoverageWithoutHeader: {
      vertical: {
        height: 97.723,
        width: 94.628,
      },
      horizontal: {
        height: 97.723,
        width: 96.3,
      },
    },
  }),
  computed: {
    isCardInfoPrint: sync('tools/toolStatus@isCardInfoPrintActive'),
    isMapPrintModuleEnabled: get('admin/additionalModules@MAP_PRINT.enabled'),
    printingSrid: get('admin/settingsValues@printing_2_srid'),
  },
  methods: {
    addPrintExtentLayer() {
      const feature = new Feature({});
      const features = new Collection();
      features.push(feature);
      const printExtentLayer = new VectorLayer({
        id: 'printExtentLayer',
        source: new VectorSource({
          features,
        }),
        zIndex: 10000,
        visible: true,
        style: new Style({
          fill: new Fill({
            color: 'rgba(200, 0, 0, 0.6)',
          }),
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            width: 2,
          }),
        }),
      });
      this.map.addLayer(printExtentLayer);

      const translate = new Translate({ features });
      translate.set('name', 'printExtentTranslate');
      translate.on('translateend', () => {
        const layer = this.getLayerById('printExtentLayer');
        if (layer) {
          const geometry = layer.feature.getGeometry();
          const center = getCenter(geometry.getExtent());
          this.$root.$emit('updatePrintExtentCenter', center);
        }
      });
      this.map.addInteraction(translate);

      printExtentLayer.feature = feature;
      printExtentLayer.translate = translate;
    },
    calculatePrintExtent(props, center = this.map.getView().getCenter()) {
      const isPseudo = this.isMapPrintModuleEnabled || this.printingSrid == '3857';
      const centerPointRes = isPseudo ? 1 : getPointResolution(this.map.getView().getProjection(), 1, center);
      const mapCoveragePaperToMeters = this.getMapCoveragePaperSize(props, centerPointRes);

      const centerPoint = new Point(center);
      const dx = mapCoveragePaperToMeters[0] / 2;
      const dy = mapCoveragePaperToMeters[1] / 2;

      const southWestPoint = centerPoint.clone();
      southWestPoint.translate(-dx, -dy);
      const northEastPoint = centerPoint.clone();
      northEastPoint.translate(dx, dy);

      const [x1, y1] = southWestPoint.getCoordinates();
      const [x2, y2] = northEastPoint.getCoordinates();
      return createOrUpdate(Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2));
    },
    getMapCoveragePaperSize(props, pointResolution = 1) {
      const { orientation, paper_format, scale, hasHeader } = props;
      const paperSize =
        orientation === 'vertical'
          ? [this.paperSeries[paper_format][1], this.paperSeries[paper_format][0]]
          : this.paperSeries[paper_format];
      const mapPercentageCoverage = this[`mapPercentageCoverage${hasHeader ? '' : 'WithoutHeader'}`][orientation];
      const mapCoveragePaperSize = [
        (paperSize[0] * mapPercentageCoverage.width) / 100,
        (paperSize[1] * mapPercentageCoverage.height) / 100,
      ];
      return mapCoveragePaperSize.map(size => (size * scale) / pointResolution / 100);
    },
    refreshRibbonPrintExtentGeometry(props) {
      const { geometry } = props;

      const olGeometry = new GeoJSON().readGeometry(geometry, {
        featureProjection: this.$_config.defaultEpsg,
        dataProjection: this.$_config.defaultEpsg,
      });
      const lineCoveragePaperHeightRealMeters = getLength(olGeometry);
      const olGeometryCoords = olGeometry.getCoordinates();
      const olGeometryMidPoint = olGeometry.getCoordinateAt(0.5);

      const [mapCoveragePaperWidthRealMeters, mapCoveragePaperHeightRealMeters] = this.getMapCoveragePaperSize(props);

      const rotationRadians = Math.atan(
        (olGeometryCoords[1][1] - olGeometryCoords[0][1]) / (olGeometryCoords[1][0] - olGeometryCoords[0][0])
      );
      const rotationDegrees = (rotationRadians * 180) / Math.PI;

      if (lineCoveragePaperHeightRealMeters > mapCoveragePaperHeightRealMeters) {
        let coords = [];
        const pointsRotatesValues = [180 - rotationDegrees, 0 - rotationDegrees];
        olGeometryCoords.forEach((coord, index) => {
          // Turf destination function requires EPSG:4326
          const coordGjson = {
            type: 'Feature',
            geometry: {
              coordinates: transform(coord, 'EPSG:3857', 'EPSG:4326'),
              type: 'Point',
            },
          };
          const destinationCoords = pointsRotatesValues.map(rotate => {
            const destinationCoord = turfDestination(
              coordGjson,
              mapCoveragePaperWidthRealMeters / 2 / 1000, // Half width converted meters to kilometers
              rotate
            )?.geometry.coordinates;
            return transform(destinationCoord, 'EPSG:4326', 'EPSG:3857');
          });
          coords = coords.concat(index === 0 ? destinationCoords : destinationCoords.reverse());
        });
        this.getLayerById('printExtentLayer').feature.setGeometry(new Polygon([coords]));
        this.$root.$emit('updatePrintExtentCenter', olGeometryMidPoint);
      } else {
        const printExtentGeometry = fromExtent(this.calculatePrintExtent(props, olGeometryMidPoint));
        printExtentGeometry.rotate(rotationRadians + Math.PI / 2, olGeometryMidPoint);
        this.getLayerById('printExtentLayer').feature.setGeometry(printExtentGeometry);
        this.$root.$emit('updatePrintExtentCenter', olGeometryMidPoint);
      }
    },
    refreshSinglePrintExtentGeometry(props) {
      const geometry = fromExtent(this.calculatePrintExtent(props));
      const center = getCenter(geometry.getExtent());
      geometry.rotate((props.map_rotation * Math.PI) / 180, center);
      this.getLayerById('printExtentLayer')?.feature.setGeometry(geometry);
      this.$root.$emit('updatePrintExtentCenter', center);
    },
    updatePrintBox(props) {
      const { print_type: printType, geometry } = props;
      if (printType === 'ribbon' && !geometry) {
        this.clearPrintBox();
        return;
      } else if (printType === 'serial') {
        this.clearPrintBox();
        if (geometry) {
          const olGeometry = new GeoJSON().readGeometry(geometry, {
            featureProjection: this.$_config.defaultEpsg,
            dataProjection: this.$_config.defaultEpsg,
          });
          const olGeometryMidPoint = olGeometry.getCoordinateAt(0.5);
          this.$root.$emit('updatePrintExtentCenter', olGeometryMidPoint);
        }
        return;
      }
      if (!this.getLayerById('printExtentLayer')) this.addPrintExtentLayer();
      this.getInteractionByName('printExtentTranslate')?.setActive(printType === 'single');
      this[`refresh${this.$_capitalize(printType)}PrintExtentGeometry`](props);
    },
    clearPrintBox() {
      this.getLayerById('printExtentLayer')?.feature.setGeometry(null);
    },
    printCardInfo() {
      this.isCardInfoPrint = true;
      this.$nextTick(() => {
        window.print();
      });
      window.onafterprint = () => {
        this.isCardInfoPrint = false;
      };
    },
  },
  mounted() {
    this.$root.$off('updatePrintBox');
    this.$root.$off('clearPrintBox');
    this.$root.$off('printCardInfo');
    this.$root.$on('updatePrintBox', this.updatePrintBox);
    this.$root.$on('clearPrintBox', this.clearPrintBox);
    this.$root.$on('printCardInfo', this.printCardInfo);
  },
};
