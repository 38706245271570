import { make } from 'vuex-pathify';

const state = {
  /**
   * TEMPLATE (UPDATE ON CHANGE):
   * {
   *  tool: <origin tool name>
   *  destinationTool: <destination tool name>
   *  geometry: <geometry geojson string>
   * }
   */
  moduleSharingData: null,
};

const mutations = {
  ...make.mutations(state),
  SET_SHAREABLE_DATA: (state, value) => {
    state.moduleSharingData = value;
  },
  CLEAR_SHAREABLE_DATA: state => {
    state.moduleSharingData = null;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
