import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  drivers: [],
  configurations: [],
};
const mutations = {
  ...make.mutations(state),
};
const actions = {
  async getDataCalculatorConfigurations({ commit }) {
    const r = await api.get('/data_calculator/configurations');
    commit('SET_CONFIGURATIONS', r.data.data);
  },
  async addDataCalculatorConfiguration({ dispatch }, payload) {
    await api.post('/data_calculator/configurations', payload);
    await dispatch('getDataCalculatorConfigurations');
  },
  async editDataCalculatorConfiguration({ dispatch }, data) {
    const { id, payload } = data;
    await api.put(`/data_calculator/configurations/${id}`, payload);
    await dispatch('getDataCalculatorConfigurations');
  },
  async deleteDataCalculatorConfiguration({ dispatch }, id) {
    await api.delete(`/data_calculator/configurations/${id}`);
    await dispatch('getDataCalculatorConfigurations');
  },
  async dataCalculatorCalculate(store, data) {
    const { datasource, payload } = data;
    const r = await api.post(`/data_calculator/${datasource}/calculate`, payload, { params: { background: true } });
    return r.data.data.tid;
  },
  async getDataCalculatorDrivers({ commit }) {
    const r = await api.get('/data_calculator/drivers');
    commit('SET_DRIVERS', r.data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
