import api from '@/services/api';
import { make } from 'vuex-pathify';

import { saveFileFromBackend } from '@/assets/js/utility';

const state = {
  failureDescriptions: [],
  failureStatusDescriptions: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getDescriptions({ commit }) {
    const r = await api.get('failure_report/failure_description');
    commit('SET_FAILURE_DESCRIPTIONS', r.data.data);
  },
  async addDescription({ dispatch }, payload) {
    await api.post('failure_report/failure_description', payload);
    await dispatch('getDescriptions');
  },
  async editDescription({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`failure_report/failure_description/${id}`, body);
    await dispatch('getDescriptions');
  },
  async deleteDescription({ dispatch }, id) {
    await api.delete(`failure_report/failure_description/${id}`);
    await dispatch('getDescriptions');
  },
  async getStatusDescriptions({ commit }) {
    const r = await api.get('failure_status/failure_description');
    commit('SET_FAILURE_STATUS_DESCRIPTIONS', r.data.data);
  },
  async addStatusDescription({ dispatch }, payload) {
    await api.post('failure_status/failure_description', payload);
    await dispatch('getStatusDescriptions');
  },
  async editStatusDescription({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`failure_status/failure_description/${id}`, body);
    await dispatch('getStatusDescriptions');
  },
  async deleteStatusDescription({ dispatch }, id) {
    await api.delete(`failure_status/failure_description/${id}`);
    await dispatch('getStatusDescriptions');
  },
  async sendReportEmail(store, payload) {
    let { model, files, objects } = payload;
    let formData = new FormData();
    for (const key in model) {
      formData.append(`${key}`, `${model[key]}`);
    }
    for (let file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('objects', `${objects}`);
    await api.post('failure_report/send_report', formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
  },
  async getEmailSettings() {
    const r = await api.get('failure_report/email_settings');
    return r.data.data;
  },
  async editEmailSettings(store, payload) {
    await api.put('failure_report/email_settings', payload);
  },
  async getReportChangeEmailSettings() {
    const r = await api.get('failure_status/email_settings');
    return r.data.data;
  },
  async editReportChangeEmailSettings(store, payload) {
    await api.put('failure_status/email_settings', payload);
  },
  async getZdmObjectTreeStructure(store, { id, datasource }) {
    return await api.get(`/zdm_data/${datasource}/features/${id}/tree`);
  },
  async generateNewReport() {
    return await api.post(`/monthly_report`);
  },
  async getReports() {
    return await api.get(`/monthly_report`);
  },
  async deleteReport(store, id) {
    return await api.delete(`/monthly_report/${id}`);
  },
  async getObjectsReportId(store, payload) {
    return (await api.post(`/report_from_selected`, payload)).data.data.tid;
  },
  async getModernizationReportId(store, payload) {
    return (await api.post(`/zdm/modernization/report`, payload)).data.data.tid;
  },
  async getFeatureReport(store, data) {
    const { layerId, featureId } = data;
    const r = await api.get(`/feature_report/${layerId}/${featureId}`, { timeout: 0, responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers);
  },
  async getStreetReport(store, data) {
    const { layerId, featureId } = data;
    const r = await api.get(`/masterplan_streets/reports/${layerId}/${featureId}`, {
      timeout: 0,
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
