export default {
  data: () => ({
    layerGeometryTypeToOlDict: {
      point: 'Point',
      multipoint: 'MultiPoint',
      linestring: 'LineString',
      multilinestring: 'MultiLineString',
      polygon: 'Polygon',
      multipolygon: 'MultiPolygon',
    },
    layerSimpleGeometryTypeDict: {
      point: 'point',
      multipoint: 'point',
      linestring: 'linestring',
      multilinestring: 'linestring',
      polygon: 'polygon',
      multipolygon: 'polygon',
    },
    olGeometryTypeToLayerDict: {
      Point: 'point',
      MultiPoint: 'multipoint',
      LineString: 'linestring',
      MultiLineString: 'multilinestring',
      Polygon: 'polygon',
      MultiPolygon: 'multipolygon',
    },
    olSimpleGeometryTypeDict: {
      Point: 'Point',
      MultiPoint: 'Point',
      LineString: 'LineString',
      MultiLineString: 'LineString',
      Polygon: 'Polygon',
      MultiPolygon: 'Polygon',
    },
  }),
  methods: {
    getOlGeometryTypeFromLayerGeometry(geometryType) {
      return this.layerGeometryTypeToOlDict[geometryType] || geometryType;
    },
    getOlGeometrySimpleType(geometryType) {
      return this.olSimpleGeometryTypeDict[geometryType] || geometryType;
    },
    getLayerGeometryTypeFromOlGeometry(geometryType) {
      return this.olGeometryTypeToLayerDict[geometryType] || geometryType;
    },
    getLayerGeometrySimpleType(geometryType) {
      return this.layerSimpleGeometryTypeDict[geometryType] || geometryType;
    },
  },
};
