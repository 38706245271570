import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import { Style, Stroke, Fill, Text } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import WKT from 'ol/format/WKT';

export default {
  methods: {
    addUldkParcelLayer() {
      const feature = new Feature({});
      const features = new Collection();
      features.push(feature);
      const uldkParcelLayer = new VectorLayer({
        id: 'uldkParcelLayer',
        source: new VectorSource({
          features,
        }),
        zIndex: 10000,
        visible: true,
        style: feature => {
          const parcelTerrit = feature.get('parcelTerrit');
          return new Style({
            stroke: new Stroke({
              color: 'rgba(191, 54, 12, 1)',
              width: 2,
            }),
            ...(parcelTerrit
              ? {
                  text: new Text({
                    text: parcelTerrit,
                    font: 'normal 14px sans-serif',
                    fill: new Fill({ color: 'rgba(191, 54, 12, 1)' }),
                    stroke: new Stroke({
                      color: '#FFFFFF',
                      opacity: 1,
                      width: 3,
                      dash: [],
                    }),
                    overflow: true,
                  }),
                }
              : {}),
          });
        },
      });
      this.map.addLayer(uldkParcelLayer);
      uldkParcelLayer.feature = feature;
    },
    addUldkParcelToMap(parcel, parcelTerrit) {
      const layer = this.getLayerById('uldkParcelLayer');
      if (!layer) {
        this.addUldkParcelLayer();
      }
      let wktText = parcel;
      if (wktText.indexOf(';') >= 0) {
        wktText = wktText.split(';')[1];
      }
      const feature = new WKT().readFeature(wktText, {
        dataProjection: this.$_config.defaultEpsg,
        featureProjection: this.$_config.defaultEpsg,
      });
      const geometry = feature.getGeometry();
      if (geometry) {
        const layer = this.getLayerById('uldkParcelLayer');
        if (layer) {
          layer.feature.setGeometry(geometry);
          layer.feature.set('parcelTerrit', parcelTerrit);
        }
        this.$root.$emit('fitView', geometry);
      }
    },
    clearUldkParcel() {
      const layer = this.getLayerById('uldkParcelLayer');
      if (layer) {
        layer.feature.setGeometry(null);
      }
    },
  },
  mounted() {
    this.$root.$off('addUldkParcelToMap');
    this.$root.$off('clearUldkParcel');
    this.$root.$on('addUldkParcelToMap', this.addUldkParcelToMap);
    this.$root.$on('clearUldkParcel', this.clearUldkParcel);
  },
};
