import api from '@/services/api';
import { make } from 'vuex-pathify';

import { saveFileFromBackend } from '@/assets/js/utility';

const state = {};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getCeleryTaskStatus(store, id) {
    return (await api.get(`/celery/status/${id}`)).data.data;
  },
  async stopCeleryTask(store, id) {
    await api.get(`/celery/cancel/${id}`);
  },
  async getCeleryTaskResult(store, { id, type = 'file', download = true }) {
    if (type === 'file') {
      const r = await api.get(`/celery/get/${id}`, { timeout: 0, responseType: 'arraybuffer' });
      if (download) {
        saveFileFromBackend(r.data, r.headers);
      } else {
        return { result: r.data };
      }
    } else if (type === 'data') {
      return (await api.get(`/celery/get/${id}`)).data.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
