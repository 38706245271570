import api from '@/services/api';
import Vue from 'vue';

import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';

import { cloneDeep } from 'lodash';

const state = {
  currentUser: undefined,
  users: {},
  groups: {},
  permissionsSchema: undefined,
  toolsPermissions: undefined,
  layersPermissions: undefined,
  currentGroupPermissions: undefined,
  groupsPermissions: {},
};

const mutations = {
  ...make.mutations(state),
  SET_CURRENT_USER(state, value) {
    state.currentUser = value;
    sessionStorage.setItem('currentUser', JSON.stringify(value));
  },
  SET_USER(state, payload) {
    const { user, value } = payload;
    Vue.set(state.users, user, value);
  },
  DELETE_USER(state, id) {
    Vue.delete(state.users, id);
  },
  SET_GROUP(state, payload) {
    const { group, value } = payload;
    Vue.set(state.groups, group, value);
  },
  DELETE_GROUP(state, id) {
    Vue.delete(state.groups, id);
  },
  SET_CURRENT_GROUP_PERMISSIONS(state, value) {
    state.currentGroupPermissions = cloneDeep(value);
  },
  SET_CURRENT_GROUP_PERMISSIONS_VALUE(state, payload) {
    const { group, id, valueKey, value } = payload;
    const idKey = group === 'modules' ? 'module_name' : 'layer_id';
    const permissionGroup = state.currentGroupPermissions[group].find(permission => permission[idKey] === id);
    Vue.set(permissionGroup, valueKey, value);
  },
  ADD_GROUP_PERMISSIONS(state, payload) {
    const { group, value } = payload;
    Vue.set(state.groupsPermissions, group, value);
  },
};

const actions = {
  async getUsers({ commit }) {
    const r = await api.get('users');
    commit('SET_USERS', arrayToObject(r.data.data, 'id'));
  },
  async getUser({ commit }, userId) {
    const r = await api.get(`users/${userId}`);
    commit('SET_USER', { user: userId, value: r.data.data });
    return r.data.data;
  },
  async getCurrentUser({ commit }) {
    const r = await api.get('users/current_user');
    let { modules, layers } = r.data.data.permissions;
    commit('SET_TOOLS_PERMISSIONS', arrayToObject(modules, 'module_name'));
    commit('SET_LAYERS_PERMISSIONS', arrayToObject(layers, 'layer_id'));
    commit('SET_CURRENT_USER', r.data.data);
    return r.data.data;
  },
  async addUser({ dispatch }, payload) {
    const r = await api.post(`users`, payload);
    await dispatch('getUser', r.data.data);
    return r.data.data;
  },
  async editUser({ dispatch, commit, state }, payload) {
    const { id, body } = payload;
    await api.put(`users/${id}`, body);
    const r = await dispatch('getUser', id);
    if (id === state.currentUser.id) {
      commit('SET_CURRENT_USER', r);
    }
  },
  async deleteUser({ commit }, id) {
    await api.delete(`users/${id}`);
    commit('DELETE_USER', id);
  },
  async logoutUser({ dispatch, commit, state }, payload) {
    const { id, tokenId } = payload;
    await api.get(`auth/deactivate_token/${tokenId}`);
    const r = await dispatch('getUser', id);
    if (id === state.currentUser.id) {
      commit('SET_CURRENT_USER', r);
    }
  },
  async getGroups({ commit }) {
    const r = await api.get('users/groups');
    commit('SET_GROUPS', arrayToObject(r.data.data, 'id'));
  },
  async getGroup({ commit }, id) {
    const r = await api.get(`users/groups/${id}`);
    commit('SET_GROUP', { group: id, value: r.data.data });
  },
  async addGroup({ dispatch }, payload) {
    const r = await api.post(`users/groups`, payload);
    await dispatch('getGroup', r.data.data);
    return r.data.data;
  },
  async copyGroup({ dispatch }, payload) {
    const { id, body } = payload;
    const r = await api.post(`users/groups/${id}/copy`, body);
    await dispatch('getGroup', r.data.data);
    return r.data.data;
  },
  async editGroup({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`users/groups/${id}`, body);
    await dispatch('getGroup', id);
  },
  async deleteGroup({ commit }, id) {
    await api.delete(`users/groups/${id}`);
    commit('DELETE_GROUP', id);
    //TODO delete permissions?
  },

  async getPermissionsSchema({ commit }) {
    const r = await api.get(`users/permissions/schema`);
    //TODO pogadać z Kamilem
    commit('SET_PERMISSIONS_SCHEMA', r.data.data);
  },
  async getGroupPermissions({ commit }, id) {
    const r = await api.get(`users/groups/${id}/permissions`);
    commit('ADD_GROUP_PERMISSIONS', { group: id, value: r.data.data });
  },
  async editGroupPermissions({ dispatch }, payload) {
    const { id, body } = payload;
    await api.put(`users/groups/${id}/permissions`, body);
    await dispatch('getGroupPermissions', id);
  },
  async editUserSettings({ dispatch }, data) {
    const { userId, payload } = data;
    await api.put(`users/settings/${userId}`, payload);
    await dispatch('getCurrentUser');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
