import Vue from 'vue';
import { make } from 'vuex-pathify';

import api from '@/services/api';

const state = {
  cutoffProject: undefined,
  cutoffResult: undefined,
  cutoffLayersFiltered: {},
  cutoffLayersFilters: {},

  excludesValves: [],
  valvesToClose: [],
  resultLayers: [],
};

const mutations = {
  ...make.mutations(state),
  ADD_CUTOFF_LAYER_FILTERED: (state, value) => {
    const { layer_id, data } = value;
    state.cutoffLayersFiltered[layer_id] = data;
  },
  DELETE_CUTOFF_LAYER_FEATURES_FILTERS: (state, value) => {
    Vue.delete(state.cutoffLayersFiltered, value);
  },
  SET_CUTOFF_LAYER_FEATURES_FILTERS: (state, value) => {
    const { filters, layerId, filterExpression } = value;
    state.cutoffLayersFiltered[layerId].filters = filters;
    state.cutoffLayersFiltered[layerId].filterExpression = filterExpression;
  },
  SET_CUTOFF_LAYER_FILTER(state, data) {
    const { layerId, filter } = data;
    Vue.set(state.cutoffLayersFilters, layerId, filter);
  },
  SET_LOCKS_LAYER_VISIBILITY(state, data) {
    const { layerId, visible } = data;
    const layer = state.cutoffProject.layers.find(layer => layer.layer_id === layerId);
    Vue.set(layer, 'visible', visible);
  },
  SET_LOCKS_LAYERS_ORDER(state, value) {
    state.cutoffProject.layers = value;
  },
};

const actions = {
  async getCutoffResult({ commit }, payload) {
    const r = await api.get('/cutoff', { params: payload });
    commit('SET_CUTOFF_RESULT', r.data);
    // commit('SET_CUTOFF_LAYERS_FILTERS', r.data);
  },
  async getCutoffProject({ commit }) {
    const r = await api.get('/cutoff/project');
    commit('SET_CUTOFF_PROJECT', r.data.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
