import { get } from 'vuex-pathify';
export default {
  computed: {
    dataSources: get('layers/metadata'),
    layerId: get('layers/currentLayer@id'),
    layers: get('layers/layers'),
    projectElements: get('layers/project@layers'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    currentMapZoom: get('map/zoom'),
  },
  methods: {
    getLayerStyleFilter(layerId = this.layerId, { mode = 'project' } = {}) {
      if (!layerId && layerId !== 0) return undefined;
      const getModeLayersList = mode => {
        if (mode === 'project') return this.projectLayers;
        else if (mode === 'system') return this.layers;
        else return [];
      };
      const layer = getModeLayersList(mode).find(layer => layer.id === layerId);

      if (!layer?.style?.uniques && !layer?.style?.ranges) return undefined;
      else {
        const datasource = this.dataSources[layer?.data_source_name];
        let filters = [];
        if (layer.style.uniques) {
          const styleAttribute = layer.style.uniques.property?.split('mvt_int_').slice(-1)[0];
          const getNotVisibleUniqueFilter = (unique, attribute = styleAttribute) => {
            return {
              '!OR': [
                {
                  $NOT_EQUAL: {
                    [this.datasource?.attributes_use_datasource_qualified_names
                      ? attribute
                      : `${datasource.name}.${attribute}`]: {
                      value: unique.value,
                    },
                  },
                },
                {
                  IS_NULL: this.datasource?.attributes_use_datasource_qualified_names
                    ? attribute
                    : `${datasource.name}.${attribute}`,
                },
              ],
            };
          };
          const allVisibleUniqesValues = [];
          filters = Object.values(layer.style.uniques.values)
            .filter(value => {
              const minZoom = this.getMinzoomFromStyle(value) + 1;
              const maxZoom = this.getMaxzoomFromStyle(value);
              const visible =
                minZoom <= this.currentMapZoom &&
                this.currentMapZoom <= maxZoom &&
                (Object.prototype.hasOwnProperty.call(value, 'visible') ? value.visible : true);
              if (visible) allVisibleUniqesValues.push(value.value);
              return !visible;
            })
            .map(unique => getNotVisibleUniqueFilter(unique));

          if (!(Object.prototype.hasOwnProperty.call(layer.style, 'visible') ? layer.style.visible : true)) {
            const notVisibleOthersFilter = {
              $IN: {
                [this.datasource?.attributes_use_datasource_qualified_names
                  ? styleAttribute
                  : `${datasource.name}.${styleAttribute}`]: allVisibleUniqesValues,
              },
            };
            filters.push(notVisibleOthersFilter);
          }
        } else if (layer.style.ranges) {
          const styleAttribute = layer.style.ranges.property?.split('mvt_int_').slice(-1)[0];
          const getNotVisibleRangeFilter = (
            range,
            attribute = layer.style.ranges.property?.split('mvt_int_').slice(-1)[0]
          ) => {
            return {
              NOT: {
                '!AND': [
                  {
                    [range.originalIndex === 0 ? '$GREATER_OR_EQUAL' : '$GREATER_THAN']: {
                      [this.datasource?.attributes_use_datasource_qualified_names
                        ? attribute
                        : `${datasource.name}.${attribute}`]: {
                        value: range['min-value'],
                      },
                    },
                  },
                  {
                    $LESS_OR_EQUAL: {
                      [this.datasource?.attributes_use_datasource_qualified_names
                        ? attribute
                        : `${datasource.name}.${attribute}`]: {
                        value: range['max-value'],
                      },
                    },
                  },
                ],
              },
            };
          };
          const allVisibleRangesValues = [];
          filters = layer.style.ranges.values
            .map((range, index) => {
              return { ...range, originalIndex: index };
            })
            .filter((value, index) => {
              const minZoom = this.getMinzoomFromStyle(value) + 1;
              const maxZoom = this.getMaxzoomFromStyle(value);
              const visible =
                minZoom <= this.currentMapZoom &&
                this.currentMapZoom <= maxZoom &&
                (Object.prototype.hasOwnProperty.call(value, 'visible') ? value.visible : true);
              if (visible)
                allVisibleRangesValues.push({ min: value['min-value'], max: value['max-value'], originalIndex: index });
              return !visible;
            })
            .map(range => getNotVisibleRangeFilter(range));

          const othersVisible = Object.prototype.hasOwnProperty.call(layer.style, 'visible')
            ? layer.style.visible
            : true;
          if (!othersVisible) {
            const notVisibleOthersFilter = {
              '!OR': allVisibleRangesValues.map(range => {
                return {
                  '!AND': [
                    {
                      [range.originalIndex === 0 ? '$GREATER_OR_EQUAL' : '$GREATER_THAN']: {
                        [this.datasource?.attributes_use_datasource_qualified_names
                          ? styleAttribute
                          : `${datasource.name}.${styleAttribute}`]: {
                          value: range.min,
                        },
                      },
                    },
                    {
                      $LESS_OR_EQUAL: {
                        [this.datasource?.attributes_use_datasource_qualified_names
                          ? styleAttribute
                          : `${datasource.name}.${styleAttribute}`]: {
                          value: range.max,
                        },
                      },
                    },
                  ],
                };
              }),
            };
            filters.push(notVisibleOthersFilter);
          }
        }
        if (filters.length === 1) return filters[0];
        else if (filters.length > 1) return { '!AND': filters };
        else return undefined;
      }
    },
  },
};
