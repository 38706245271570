<template>
  <v-text-field
    ref="input"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    v-on="computedListeners"
    v-bind="$attrs"
    :hint="hint"
    persistent-hint
    :error="error"
    :rules="[...($attrs.rules || []), rules.maxValue, rules.minValue]"
  >
    <template #append v-if="isFocused && !hideArrows">
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters class="justify-end align-center">
            <v-col cols="12" style="height: 10px">
              <v-icon @click="onArrowClicked(true)" style="font-size: 14px; height: auto; color: inherit">
                mdi-chevron-up
              </v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters class="justify-end align-center">
            <v-col cols="12" style="height: 10px">
              <v-icon @click="onArrowClicked(false)" style="font-size: 14px; height: auto; color: inherit">
                mdi-chevron-down
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #message="{message}">
      <italic-subheader :translation-path="message"> </italic-subheader>
    </template>
  </v-text-field>
</template>

<script>
import ItalicSubheader from '@/components/ItalicSubheader';

export default {
  name: 'DataInputInteger',
  components: {
    ItalicSubheader,
  },
  props: {
    label: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
    hint: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    incrementFactor: {
      type: Number,
      default: 1,
    },
    allowNegative: {
      type: Boolean,
      default: true,
    },
    minValue: {
      type: Number,
      default: null,
    },
    maxValue: {
      type: Number,
      default: null,
    },
    hideArrows: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    rules() {
      return {
        maxValue: v =>
          this.maxValue
            ? (!v && v !== 0) || v <= this.maxValue || this.$i18n.t('rules.maxValue', { value: this.maxValue })
            : true,
        minValue: v =>
          this.minValue
            ? (!v && v !== 0) || v >= this.minValue || this.$i18n.t('rules.minValue', { value: this.minValue })
            : true,
      };
    },
    computedListeners() {
      return {
        ...this.$listeners,
        focus: () => {
          this.onFocus();
        },
        blur: e => {
          this.onBlur(e);
        },
        input: e => {
          this.setValue(e);
        },
        paste: e => {
          this.onPaste(e);
          this.$emit('paste', e);
        },
        keydown: e => {
          this.checkValue(e);
          this.$emit('keydown', e);
        },
      };
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onBlur(e) {
      this.checkNumber(e);
      this.isFocused = false;
    },
    checkNumber(e) {
      const { value } = e.target;
      if (value.slice(-1) === '-') {
        this.$emit('input', -1);
      }
    },
    checkValue(e) {
      const { key, ctrlKey, metaKey } = e;
      if ((ctrlKey || metaKey) && (key === 'v' || key === 'c' || key === 'a')) {
        return;
      }
      const { selectionStart, selectionEnd, value } = e.target;
      const inputValue = value.toString();
      const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Tab', 'Backspace'];
      if (this.allowNegative && selectionStart === 0 && (selectionEnd > 0 || !inputValue.includes('-'))) {
        allowedKeys.push('-');
      }
      if (allowedKeys.includes(key)) {
        return;
      }
      if (
        (selectionEnd <= selectionStart ||
          (selectionStart === 0 && selectionEnd === 1 && inputValue.toString()[0] === '-')) &&
        inputValue.toString().replace('-', '').length >= 15
      ) {
        e.preventDefault();
        return;
      }
      if (!/[0-9]/.test(key)) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      const { selectionStart, selectionEnd, value: oldValue } = e.target;
      const pasteValue = e.clipboardData.getData('text');
      const sumValue = oldValue.substring(0, selectionStart) + pasteValue + oldValue.substring(selectionEnd);
      if (!/^-?\d+$/.test(sumValue)) {
        console.log('Wklejana wartość nie jest liczbą całkowitą');
        //TODO: Zmienić console.log na alert
        e.preventDefault();
        return;
      }
      if (sumValue.length > 15) {
        console.log('Wklejana wartość wykracza poza zakres');
        //TODO: Zmienić console.log na alert
        e.preventDefault();
        return;
      }
    },
    onArrowClicked(isIncrement) {
      const currentValue = Number.parseInt(this.value) || 0;
      const value = isIncrement ? currentValue + this.incrementFactor : currentValue - this.incrementFactor;
      if (!this.allowNegative && currentValue === 0 && !isIncrement) {
        return;
      }
      this.setValue(value);
    },
    setValue(v) {
      this.$emit('input', this.$_isInt(v) ? +v : null);
    },
  },
};
</script>
