import { make } from 'vuex-pathify';
import { saveFileFromBackend } from '@/assets/js/utility';

import api from '@/services/api';
import Vue from 'vue';

const state = {
  newFeatureGeometry: undefined,
  preciseNewFeatureCoordinates: undefined,
  isAddingButtonActive: false,
  isEditingButtonActive: false,
  isMoveRotateButtonActive: false,
  isCopyButtonActive: false,
  isSplittingLineButtonActive: false,
  isSplittingPolygonButtonActive: false,
  isAssignParcelButtonActive: false,
  isAssignParcelButtonAdditionalData: {
    layerId: null,
    trigger: null,
  },
  isAssignMultipleParcelButtonActive: false,
  isAssigningMultipleParcelsLoading: false,
  isAttributesPainterButtonActive: false,
  isPreciseDigitizingButtonActive: false,
  isRealtimeStationsMeasurementsButtonActive: false,
  realtimeStationsMeasurementsStations: {},
  isRealtimeStationsDirectionsButtonActive: false,
  realtimeStationsDirectionsStations: {},
  attributesPainterObject: undefined,
  attributesPainterObjects: [],
  copiedObject: false,
  tableSelectionType: undefined,
  isDrawingInteractionActive: false,
  isModificationInteractionActive: false,
  isModifyMultipartActive: false,
  isModifySnappingActive: false,
  newTopoIndexes: [],
  deletedTopoIndexes: [],
};

const mutations = {
  ...make.mutations(state),
  PUSH_TOPO_INDEX: (state, { type, value } = {}) => {
    const stateVarName = `${type}TopoIndexes`;
    const newArray = [...state[stateVarName], value];
    newArray.sort((a, b) => {
      return a - b;
    });
    Vue.set(state, stateVarName, newArray);
  },
  SPLICE_TOPO_INDEX: (state, { type, value } = {}) => {
    const stateVarName = `${type}TopoIndexes`;
    const indexOfDelete = state[stateVarName].indexOf(value);
    const newArray = [...state[stateVarName]];
    newArray.splice(indexOfDelete, 1);
    newArray.sort((a, b) => {
      return a - b;
    });
    Vue.set(state, stateVarName, newArray);
  },
};

const actions = {
  async buffer(state, data) {
    const { payload, layerId } = data;
    const { format } = payload;
    if (format === 'shp') {
      const r = await api.post(`/layers/${layerId}/buffer`, payload, { timeout: 0, responseType: 'arraybuffer' });
      saveFileFromBackend(r.data, r.headers);
    } else {
      return await api.post(`/layers/${layerId}/buffer`, payload);
    }
  },
  async calculateFields(state, { expressions, sorting, features_filter, dataSource, params }) {
    const r = await api.post(
      `/dataio/data_sources/${dataSource}/field_calculator`,
      {
        expressions,
        features_filter,
        sorting,
      },
      { params }
    );
    return r.data.data;
  },
  async calculateFieldsTopo(state, { calculator_expressions, features_filter, dataSource, params }) {
    const r = await api.post(
      `/topological_edit/${dataSource}/field_calculator`,
      {
        calculator_expressions,
        features_filter,
      },
      { params }
    );
    return r.data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
