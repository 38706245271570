<template>
  <v-row v-if="isStickyButtonsVisible && stickyButtons.length > 0" align="end" class="pa-5 sticky-buttons" no-gutters>
    <v-col cols="auto" v-for="(button, idx) of stickyButtons" :key="idx">
      <v-tooltip top color="font" :disabled="!hasTooltip(button) || !isDisabled(button)">
        <template #activator="{ on }">
          <div v-on="on" class="extra-div">
            <base-button-subheader
              :loading="(isStickyButtonsLoading && !button.cancel) || button.loading"
              :disabled="isDisabled(button)"
              v-bind="{ ...button, cancel: button.cancel || button.cancelLike }"
              @click="action(button.action)"
            />
          </div>
        </template>
        {{ button.tooltip || $i18n.t('rules.formHasErrors') }}
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'SidebarStickyButtons',
  computed: {
    stickyButtons: get('sidebar/stickyButtons'),
    isStickyButtonsLoading: get('sidebar/isStickyButtonsLoading'),
    isStickyButtonsValid: get('sidebar/isStickyButtonsValid'),
    isStickyButtonsVisible: get('sidebar/isStickyButtonsVisible'),
  },
  methods: {
    hasTooltip(button) {
      return button.hasTooltip === false ? button.hasTooltip : true;
    },
    isDisabled(button) {
      return (
        (this.isStickyButtonsLoading && button.cancel) ||
        (!this.isStickyButtonsValid && !button.cancel) ||
        button.disabled
      );
    },
    action(action) {
      this.$root.$emit(action);
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky-buttons {
  background-color: white;
  z-index: 2;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.15);

  width: 100%;
  position: sticky;
  bottom: 0px;
}
</style>
