import { Fill, Stroke, Style } from 'ol/style';
import { VectorImage as VectorImageLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';

export default {
  methods: {
    addVisibilityAnalysisLayer() {
      const newLayer = new VectorImageLayer({
        id: 'visibilityAnalysisLayer',
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          fill: new Fill({
            color: 'rgba(42, 189, 86, 1)',
          }),
          stroke: new Stroke({
            color: 'rgba(43, 46, 44, 1)',
            width: 1,
          }),
        }),
      });
      this.map.addLayer(newLayer);
    },
    toggleVisibilityAnalysisFeatures(features) {
      let layer = this.getLayerById('visibilityAnalysisLayer');
      if (!features?.features?.length) {
        if (layer) {
          this.clearLayer('visibilityAnalysisLayer');
        }
        return;
      }
      if (!layer) {
        this.addVisibilityAnalysisLayer();
        layer = this.getLayerById('visibilityAnalysisLayer');
      } else {
        this.clearLayer('visibilityAnalysisLayer');
      }
      const olFeatures = new GeoJSON().readFeatures(features, {
        featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
        dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
      });
      layer.getSource().addFeatures(olFeatures);
    },
  },
  mounted() {
    this.$root.$off('toggleVisibilityAnalysisFeatures');
    this.$root.$on('toggleVisibilityAnalysisFeatures', this.toggleVisibilityAnalysisFeatures);
  },
};
