import { make } from 'vuex-pathify';

const state = {
  companyName: '',
};
const mutations = make.mutations(state);

export default {
  namespaced: true,
  state,
  mutations,
};
