import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  formPrints: [],
  formPrintTemplates: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getFormPrintTemplates({ commit }) {
    const r = await api.get('form_print/forms/registry');
    commit('SET_FORM_PRINT_TEMPLATES', r.data.data);
  },
  async getFormPrints({ commit }) {
    const r = await api.get('form_print/forms');
    commit('SET_FORM_PRINTS', r.data.data);
  },
  async addFormPrint({ dispatch }, payload) {
    await api.post('form_print/forms', payload);
    dispatch('getFormPrints');
  },
  async editFormPrint({ dispatch }, body) {
    const { id, ...payload } = body;
    await api.put(`form_print/forms/${id}`, payload);
    dispatch('getFormPrints');
  },
  async deleteFormPrint({ dispatch }, id) {
    await api.delete(`form_print/forms/${id}`);
    dispatch('getFormPrints');
  },
  async generateFormPrintScl(store, { sclType, featureId, payload }) {
    return (await api.post(`form_print/scl/${sclType}/${featureId}`, payload)).data.data.tid;
  },
  async generateFormPrintLegnica(store, { fileFormat, featureId, payload }) {
    return (await api.post(`form_print/${fileFormat}/legnica/${featureId}`, payload)).data.data.tid;
  },
  async generateFormPrintMasterplanSquares(store, { featureId, payload }) {
    return (await api.post(`form_print/masterplan_2_squares/${featureId}`, payload)).data.data.tid;
  },
  async generateFormPrintMasterplanIlluminations(store, { featureId, payload }) {
    return (await api.post(`form_print/masterplan_2_illuminations/${featureId}`, payload)).data.data.tid;
  },
  async generateFormPrintMasterplanStreets(store, { featureId, payload }) {
    return (await api.post(`form_print/masterplan_2_streets/${featureId}`, payload)).data.data.tid;
  },
  async generateFormPrintLodz(store, { featureId, payload }) {
    return (await api.post(`form_print/onepager_lodz/${featureId}`, payload)).data.data.tid;
  },
  async generateFormPrintKsse(store, { featureId, payload }) {
    return (await api.post(`form_print/ksse/${featureId}`, payload)).data.data.tid;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
