<template>
  <span>
    <v-tooltip bottom color="font" :disabled="!loading">
      <template #activator="{ on }">
        <span v-on="on">
          <base-button
            v-on="$listeners"
            v-bind="{ ...$attrs, ...$props, disabled: loading }"
            style="font-size: 10px !important"
            color="primary"
            height="24px !important"
            :translation-path="`navbar.${name}`"
            @click="toggleZdmPowerSource"
          />
        </span>
      </template>
      {{ $i18n.t('snackbar.zdmPowerSource') }}
    </v-tooltip>

    <main-dialog
      v-if="isDialogLoaded"
      :is-visible.sync="isDialogVisible"
      :title="$i18n.t(`navbar.${name}`)"
      :body="$i18n.t(`navbar.${name}Confirm`)"
      @agree="runZdmPowerSource"
      :loading="loading"
      agree-text="dialog.agreeRun"
    />
  </span>
</template>
<script>
import { call } from 'vuex-pathify';
export default {
  name: 'TheNavbarToolsZdmPowerSourceButton',
  components: { MainDialog: () => import('@/components/MainDialog') },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentTaskId: null,
    loading: false,
    isDialogLoaded: false,
    isDialogVisible: false,
  }),
  methods: {
    zdmPowerSource: call('zdm_data/zdmPowerSource'),
    async runZdmPowerSource() {
      try {
        this.loading = true;
        this.currentTaskId = await this.zdmPowerSource();
        this.$globalHub.$emit('handleCeleryTask', {
          skipTaskResult: true,
          currentTaskId: this.currentTaskId,
          taskStartSnackbar: {
            message: this.$i18n.t('snackbar.zdmPowerSource'),
          },
          taskFailureSnackbar: {
            message: this.$i18n.t('snackbar.zdmPowerSourceError'),
          },
          taskFailureCallback: this.endZdmPowerSourceCallback,
          taskSuccessCallback: () => {
            this.$store.set('snackbar/PUSH_SUCCESSFULLY_MESSAGE!', {
              message: this.$i18n.t('snackbar.zdmPowerSourceCompleted'),
            });
            this.endZdmPowerSourceCallback();
          },
        });
      } catch {
        this.endZdmPowerSourceCallback();
      } finally {
        this.isDialogVisible = false;
      }
    },
    toggleZdmPowerSource() {
      this.isDialogLoaded = true;
      this.isDialogVisible = true;
    },
    endZdmPowerSourceCallback() {
      this.loading = false;
      this.currentTaskId = null;
    },
  },
};
</script>
