<template>
  <v-row no-gutters align="center">
    <v-col cols="auto" style="width: 250px">
      <v-menu
        v-model="isResultListVisible"
        nudge-bottom="20"
        min-width="350px"
        :close-on-content-click="false"
        :close-on-click="false"
        :open-on-click="false"
      >
        <template #activator="{ on }">
          <data-input
            v-on="on"
            v-bind="streetsSearchItem"
            v-model="searchText"
            class="streets-search-input"
            @click:clear="clearSearchedGeom"
            @focus="onFocus"
            @input="search"
          />
        </template>
        <v-list
          v-if="resultStreets.length > 0"
          class="streets-list"
          v-click-outside="{
            handler: onClickOutside,
            include,
            closeConditional,
          }"
          dense
        >
          <v-list-item
            v-for="(street, idx) in resultStreets"
            :key="idx"
            class="street-element"
            @click="zoomToStreet(street)"
          >
            <v-list-item-content>
              <v-list-item-title class="text-body-2 street-element-text">
                {{ street.description }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import { call } from 'vuex-pathify';
import { debounce } from 'lodash';

import { Stroke, Style } from 'ol/style';

import DataInput from '@/components/DataInput';
export default {
  name: 'TheNavbarToolsZdmStreets',
  components: {
    DataInput,
  },
  computed: {
    streetsSearchItem() {
      return {
        dataType: {
          name: 'text',
        },
        prependInnerIcon: 'mdi-magnify',
        clearable: true,
        dense: true,
        height: '20',
        hideDetails: true,
        label: 'Szukaj ulic',
        outlined: true,
        singleLine: true,
      };
    },
  },
  data: () => ({
    isResultListVisible: false,
    resultStreets: [],
    searchText: '',
  }),
  methods: {
    searchStreets: call('search/searchStreets'),
    closeConditional() {
      return this.isResultListVisible;
    },
    include() {
      return [document.querySelector('.streets-search-input')];
    },
    onClickOutside() {
      this.isResultListVisible = false;
    },
    onFocus() {
      this.isResultListVisible = true;
    },
    clearSearchedGeom() {
      this.$root.$emit('clearVisibleGeomLayerAndFitView', 'ZdmStreet');
    },
    zoomToStreet(street) {
      this.searchText = street.description;
      this.isResultListVisible = false;
      const layerParams = {
        style: new Style({
          stroke: new Stroke({
            color: 'red',
            lineDash: [4, 10],
            width: 4,
          }),
        }),
        opacity: 0.5,
      };
      this.$root.$emit('createVisibleGeomLayerAndFitView', street.geometry, 'ZdmStreet', {}, layerParams);
    },
    async search() {
      if (!this.searchText) {
        this.resultStreets = [];
        return;
      }
      const payload = {
        params: {
          limit: 20,
          search_text: this.searchText,
        },
      };
      try {
        const streets = await this.searchStreets(payload);
        this.resultStreets = streets;
      } catch {
        this.resultStreets = [];
      }
    },
  },
  created() {
    this.search = debounce(this.search, 500);
  },
};
</script>
<style lang="scss" scoped>
.streets-list {
  max-height: 300px;
  overflow-y: auto;
}
.street-element {
  text-align: left;
  min-height: 36px;
  max-height: 36px;
}
.street-element:hover {
  background-color: rgba(188, 188, 188, 0.2);
}
.street-element-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal !important;
}
::v-deep {
  .streets-search-input {
    .v-input__slot {
      min-height: 0px !important;
      padding: 0 4px !important;
    }
    .v-input__append-inner {
      margin: 0 !important;
    }
    .v-input__prepend-inner {
      margin: 0 !important;
      .v-input__icon {
        .v-icon {
          font-size: 16px;
          padding-bottom: 3px;
        }
      }
    }
    .v-label {
      font-size: 12px;
      top: 0 !important;
    }
    .v-text-field__slot input {
      font-size: 12px !important;
      padding-bottom: 3px !important;
    }
  }
}
</style>
