import { groupByProperty } from '@/assets/js/utility';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  // ILS EASY
  layers: [],

  // ILS SESSIONS
  session: {},

  // ILS SEARCH OBJECTS
  searchEngines: [],
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  // ILS EASY
  async ilsEasySync() {
    return (await api.post('ils_easy/sync', {}, { params: { background: true } })).data.data?.tid;
  },
  async getIlsLayers({ commit, rootState }) {
    const schema = await api.get('ils_easy/layers/schema');
    commit(
      'SET_LAYERS',
      schema.data.data
        .map(group => {
          return {
            ...group,
            layers: group.layers
              .map(layer => {
                return { ...layer, ...rootState.layers.layers[layer.layer_id], ilsLayerId: layer.id };
              })
              .sort((a, b) => a.order_in_group - b.order_in_group),
          };
        })
        .sort((a, b) => a.order - b.order)
    );
  },
  async addIlsLayersGroup({ dispatch }, payload) {
    await api.post('ils_easy/layers/groups', payload);
    await dispatch('getIlsLayers');
  },
  async editIlsLayerInGroup({ dispatch }, { id, payload }) {
    await api.put(`ils_easy/layers/${id}/group`, payload);
    await dispatch('getIlsLayers');
  },
  async deleteIlsLayersGroup({ dispatch }, id) {
    await api.delete(`ils_easy/layers/groups/${id}`);
    await dispatch('getIlsLayers');
  },
  async editIlsLayersGroup({ dispatch }, { id, payload } = {}) {
    await api.put(`ils_easy/layers/groups/${id}`, payload);
    await dispatch('getIlsLayers');
  },

  // ILS SESSIONS
  async getSession({ commit }) {
    const r = await api.get('ils/ils_sessions');
    commit('SET_SESSION', groupByProperty(r.data.data?.features || [], 'metadata', 'operation'));
  },
  async addSessionFeature({ dispatch }, payload) {
    await api.post('ils/ils_sessions', payload);
    await dispatch('getSession');
  },
  async editSessionFeature({ dispatch }, { id, payload }) {
    await api.put(`ils/ils_sessions/${id}`, payload);
    await dispatch('getSession');
  },
  async deleteObjectsFromSession({ dispatch }, operation) {
    await api.delete(`ils/ils_sessions/clear`, { params: { operation } });
    await dispatch('getSession');
  },
  async deleteObjectFromSession({ dispatch }, id) {
    await api.delete(`ils/ils_sessions/${id}`);
    await dispatch('getSession');
  },
  async deleteObjectToSession({ dispatch }, { featureId, layerId } = {}) {
    await api.delete(`ils/ils_sessions//${layerId}/${featureId}`);
    await dispatch('getSession');
  },
  async mergeSession({ dispatch }) {
    await api.post('ils/ils_sessions/merge');
    await dispatch('getSession');
  },

  // ILS SEARCH OBJECTS
  async getSearchEngines({ commit }) {
    const r = await api.get('ils_easy/search_engines');
    commit('SET_SEARCH_ENGINES', r.data.data);
  },
  async addSearchEngine({ dispatch }, payload) {
    await api.post('ils_easy/search_engines', payload);
    await dispatch('getSearchEngines');
  },
  async deleteSearchEngine({ dispatch }, engineId) {
    await api.delete(`ils_easy/search_engines/${engineId}`);
    await dispatch('getSearchEngines');
  },
  async editSearchEngine({ dispatch }, data) {
    const { engineId, payload } = data;
    await api.put(`ils_easy/search_engines/${engineId}`, payload);
    await dispatch('getSearchEngines');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
