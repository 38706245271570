import { sync } from 'vuex-pathify';
import { Snap } from 'ol/interaction';

export default {
  computed: {
    snappingConfigToolToleranceValue: sync('tools/snappingConfigToolToleranceValue'),
  },
  methods: {
    registerSnappingListeners() {
      this.$root.$on('toggleSnappingInteraction', this.toggleSnappingInteraction);
    },
    deactivateSnappingInteraction() {
      const interaction = this.getInteractionByName('snappingInteraction');
      if (interaction) {
        interaction.setActive(false);
        this.map.removeInteraction(interaction);
      }
    },
    toggleSnappingInteraction({ value, source }) {
      if (value) {
        this.initSnapping(source);
      } else {
        this.getLayerById('snappingLayer')
          ?.getSource()
          .clear();
        this.deactivateSnappingInteraction();
      }
    },
    initSnapping(source) {
      const interaction = this.getInteractionByName('snappingInteraction');
      // pseudo singleton
      if (interaction) {
        this.map.removeInteraction(interaction);
      }
      this.createSnappingInteraction(source);
    },
    createSnappingInteraction(source) {
      const pixelTolerance = this.snappingConfigToolToleranceValue || 10;
      const snap = new Snap({
        source,
        pixelTolerance,
      });
      snap.set('name', 'snappingInteraction');
      this.map.addInteraction(snap);
    },
  },
  mounted() {
    this.registerSnappingListeners();
  },
};
