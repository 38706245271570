<template>
  <span style="text-align: left">
    <label style="font-size: 12px; display: block; color: rgba(0, 0, 0, 0.6)" class="theme--light">{{ label }}</label>
    <v-switch
      style="margin-top: 2px"
      v-model="computedValue"
      v-on="$listeners"
      v-bind="$attrs"
      label=""
      placeholder=""
    />
  </span>
</template>

<script>
export default {
  name: 'DataInputSwitch',
  props: {
    label: {
      required: true,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
  },
};
</script>
