<template>
  <div>
    <v-menu
      :value="isSpatialBookmarksToolActive"
      :close-on-content-click="false"
      :close-on-click="false"
      min-width="250px"
      offset-y
      attach
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="{ ...on, ...$listeners }"
          v-bind="{ ...$attrs, attrs, ...$props }"
          :class="{ background: $attrs.isActive, divider: $attrs.hasDivider }"
          :color="$_colors.iconIdle"
          icon
          small
          @click="toogleSpatialBookmarks"
        >
          <v-icon size="19px" :class="{ activeicon: $attrs.isActive }">
            {{ icon }}
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list class="pt-0 pb-3">
          <v-list-item dense>
            <v-btn
              color="success"
              class="pa-0 text-caption"
              :ripple="false"
              depressed
              text
              @click="openAddSpatialBookmarkDialog"
            >
              <v-icon left> mdi-plus-circle </v-icon>
              {{ $i18n.t('navbar.addBookmark') }}
            </v-btn>
          </v-list-item>
          <v-list-item dense>
            <v-row justify="space-between" align="center" no-gutters>
              <v-col cols="10">
                <data-input v-model="spatialBookmarkId" v-bind="spatialBookmarkInput" @click="clearSpatialBookmarkId" />
              </v-col>
              <v-col cols="auto">
                <v-tooltip color="font" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="icon-delete"
                      :disabled="!spatialBookmarkId"
                      @click="openDeleteSpatialBookmarkDialog"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $i18n.t('navbar.deleteBookmark') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <main-input-dialog
      v-if="isAddSpatialBookmarkLoaded"
      :is-visible.sync="isAddSpatialBookmarkVisible"
      :inputs="addSpatialBookmarkInput"
      :title-translation="$i18n.t('dialog.addSpatialBookmark')"
      agree-text="dialog.agreeAdd"
      :loading="isLoading"
      @agree="addSpatialBookmarkHandler"
    />
    <main-dialog
      v-if="isDeleteSpatialBookmarkLoaded"
      :is-visible.sync="isDeleteSpatialBookmarkVisible"
      :title="$i18n.t('dialog.title.deleteSpatialBookmark')"
      :body="
        $i18n.t('dialog.body.deleteSpatialBookmarkConfirmation', {
          name: bookmarkToDelete ? bookmarkToDelete.name : '',
        })
      "
      agree-text="dialog.agreeDelete"
      :loading="isLoading"
      @agree="deleteSpatialBookmarkHandler"
    />
  </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';

import DataInput from '@/components/DataInput';

export default {
  name: 'TheNavbarToolsPanelSpatialBookmarks',
  components: {
    DataInput,
    MainDialog: () => import('@/components/MainDialog'),
    MainInputDialog: () => import('@/components/MainInputDialog'),
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isAddSpatialBookmarkLoaded: false,
    isAddSpatialBookmarkVisible: false,
    isDeleteSpatialBookmarkLoaded: false,
    isDeleteSpatialBookmarkVisible: false,
    isLoading: false,
    spatialBookmarkId: null,
    bookmarkToDelete: null,
  }),
  computed: {
    zoom: sync('map/zoom'),
    coordinates: sync('map/center'),
    activeTool: sync('tools/activeTool'),
    spatialBookmarks: get('map/spatialBookmarks'),
    isSpatialBookmarksToolActive: sync('tools/toolStatus@isSpatialBookmarksToolActive'),
    userId: get('users/currentUser@id'),
    userSpatialBookmarks() {
      return this.spatialBookmarks.filter(bookmark => bookmark.owner === this.userId);
    },
    spatialBookmarkInput() {
      return {
        dataType: { name: 'select' },
        items: this.userSpatialBookmarks,
        itemText: 'name',
        itemValue: 'id',
        hideDetails: true,
      };
    },
    addSpatialBookmarkInput() {
      return [
        {
          name: 'spatialBookmarkName',
          translationPath: 'dialog.body.bookmarkName',
          dataType: { name: 'text' },
          rules: [v => !!v || v == '0' || 'rules.required'],
          cols: '12',
          leftCol: 4,
          rightCol: 7,
          spacer: true,
          value: '',
        },
      ];
    },
    selectedSpatialBookmark() {
      return this.spatialBookmarks?.find(bookmark => bookmark.id === this.spatialBookmarkId);
    },
  },
  watch: {
    spatialBookmarkId(nV) {
      if (nV) {
        this.zoom = this.selectedSpatialBookmark?.zoom;
        this.coordinates = this.selectedSpatialBookmark?.map_center.coordinates;
      }
    },
  },
  mounted() {
    this.$root.$off('spatialBookmarks-action');
    this.$root.$on('spatialBookmarks-action', this.deactivateSpatialBookmarksTool);
    this.getSpatialBookmarks();
  },
  methods: {
    getSpatialBookmarks: call('map/getSpatialBookmarks'),
    addSpatialBookmark: call('map/addSpatialBookmark'),
    deleteSpatialBookmark: call('map/deleteSpatialBookmark'),
    toogleSpatialBookmarks() {
      this.isSpatialBookmarksToolActive = !this.isSpatialBookmarksToolActive;
      if (this.isSpatialBookmarksToolActive && this.activeTool !== 'spatialBookmarks') {
        this.$root.$emit('deactivateAllTools');
        this.activeTool = 'spatialBookmarks';
      } else {
        this.activeTool = undefined;
      }
    },
    deactivateSpatialBookmarksTool(value) {
      if (!value) {
        this.isSpatialBookmarksToolActive = false;
      }
    },
    async addSpatialBookmarkHandler() {
      try {
        this.isLoading = true;
        const payload = {
          name: this.addSpatialBookmarkInput[0].value,
          zoom: this.zoom,
          map_center: {
            coordinates: this.coordinates,
            crs: {
              properties: {
                name: this.$_config.defaultEpsg,
              },
              type: 'name',
            },
            type: 'Point',
          },
        };
        await this.addSpatialBookmark(payload);
        this.spatialBookmarkId = null;
        this.isAddSpatialBookmarkLoaded = false;
        this.isAddSpatialBookmarkVisible = false;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteSpatialBookmarkHandler() {
      try {
        this.isLoading = true;
        await this.deleteSpatialBookmark(this.bookmarkToDelete.id);
        this.spatialBookmarkId = null;
        this.bookmarkToDelete = null;
        this.isDeleteSpatialBookmarkLoaded = false;
        this.isDeleteSpatialBookmarkVisible = false;
      } finally {
        this.isLoading = false;
      }
    },
    openAddSpatialBookmarkDialog() {
      this.addSpatialBookmarkInput[0].value = '';
      this.isAddSpatialBookmarkLoaded = true;
      this.isAddSpatialBookmarkVisible = true;
    },
    openDeleteSpatialBookmarkDialog() {
      this.bookmarkToDelete = this.selectedSpatialBookmark;
      this.isDeleteSpatialBookmarkLoaded = true;
      this.isDeleteSpatialBookmarkVisible = true;
    },
    clearSpatialBookmarkId() {
      this.spatialBookmarkId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .activeicon * {
  color: map-get($colors, 'link') !important;
}
.background {
  @include activeToolButtonStyle();
}
.divider {
  position: relative;
  margin-right: 18px;
  &::after {
    content: '';
    position: absolute;
    @include border(right);
    height: 100%;
    top: 0;
    right: -9px;
  }
}
.v-btn {
  text-transform: none;
  &::before {
    background-color: transparent;
  }
}
.icon-delete {
  cursor: pointer;
  &:hover {
    color: black;
  }
}
</style>
