<template>
  <v-menu offset-y @input="handleToggleTool" :close-on-content-click="false" :attach="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="{ background: $attrs.isActive }"
        icon
        small
        v-bind="{ ...$attrs, attrs, ...$props }"
        v-on="{ ...on, ...$listeners }"
        :disabled="$attrs.disabled || isLoading"
        :color="$_colors.iconIdle"
      >
        <v-icon size="19px">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card :loading="currentSearchEngine?.kind === 'list' && isCardLoading">
      <v-list class="py-4">
        <v-list-item>
          <data-input
            :data-type="{ name: 'select' }"
            :items="searchEngines"
            item-value="layer"
            item-text="name"
            style="width: 310px"
            :disabled="currentSearchEngine?.kind === 'list' && isCardLoading"
            v-model="currentSearchEngine"
            return-object
            class="objects-select"
            hide-details
          >
          </data-input>
        </v-list-item>
        <v-list-item>
          <data-input
            :menu-props="{ contentClass: 'menu-max-width-content' }"
            :data-type="{ name: 'autocomplete' }"
            style="width: 310px"
            class="pa-0 ma-0 text-body-2 mt-2"
            :color="$_colors.iconIdle"
            :items="queryResults"
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            item-text="name"
            :placeholder="$i18n.t('default.find')"
            item-value="id"
            :disabled="(currentSearchEngine?.kind === 'list' && isCardLoading) || !hasPermissions"
            v-model="selectedItem"
            :tooltip="!hasPermissions ? $i18n.t('sidebar.noLayerPermissions') : null"
            :search-input.sync="queryText"
            @update:search-input="e => (currentSearchEngine.kind === 'text' ? search(e) : null)"
            @change="zoomTo"
            clearable
            return-object
            hide-details
            hide-no-data
          >
          </data-input>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { debounce } from 'lodash';
import { get, call } from 'vuex-pathify';
import DataInput from '@/components/DataInput';
import { Style, Stroke } from 'ol/style';

export default {
  name: 'TheNavbarToolsPanelSearchObjects',
  components: {
    DataInput,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentSearchEngine: null,
    selectedItem: null,
    queryText: null,
    queryResults: [],
    isLoading: true,
    isCardLoading: false,
    isToolVisible: false,
  }),
  computed: {
    layers: get('layers/layers'),
    dataSources: get('layers/metadata'),
    searchEngines: get('searchObjects/searchEngines'),
    isSearchObjectsConfigured: get('admin/additionalModules@SEARCH_OBJECTS_MODULE.configured'),
    permissions: get('users/layersPermissions'),
    hasPermissions() {
      return this.permissions[this.currentSearchEngine?.layer]?.main_value > 0;
    },
    isCurrentDataSourceUseAttributesQualifiedNames() {
      return this.dataSources[this.layers[this.currentSearchEngine.layer]?.data_source_name]
        ?.attributes_use_datasource_qualified_names;
    },
  },
  watch: {
    /**
     * These two watches make sure that requests are made only when tool has been opened
     * to avoid requesting huge layer's features initially when app is loaded
     */
    currentSearchEngine: {
      handler(nV) {
        if (this.isToolVisible) this.handleChangeEngine(nV);
      },
    },
    isToolVisible(nV, oV) {
      if (!!nV && !oV) this.handleChangeEngine(this.currentSearchEngine);
    },
  },
  methods: {
    getSearchEngines: call('searchObjects/getSearchEngines'),
    getLayerFeatures: call('layers/getLayerFeatures'),
    handleToggleTool(nV) {
      if (nV) this.isToolVisible = true;
    },
    handleChangeEngine(nV) {
      localStorage.setItem('lastSearchEngineId', nV.id);
      this.queryText = null;
      this.selectedItem = null;
      this.zoomTo();
      const haveCachedItems = this.listEnginesItemsCache && this.listEnginesItemsCache[this.currentSearchEngine.name];
      this.queryResults =
        nV.kind === 'list' && haveCachedItems
          ? Object.freeze(this.listEnginesItemsCache[this.currentSearchEngine.name])
          : [];
      if (!nV) return;
      if (nV.kind === 'list' && !haveCachedItems) this.search(null, true);
    },
    async search(query, processAll = false) {
      if (!this.hasPermissions || (!query && !processAll)) {
        return;
      }
      this.isCardLoading = true;
      try {
        const name = this.isCurrentDataSourceUseAttributesQualifiedNames
          ? this.currentSearchEngine.attribute
          : `${this.layers[this.currentSearchEngine.layer]?.data_source_name}.${this.currentSearchEngine.attribute}`;
        const r = await this.getLayerFeatures({
          layer_id: this.currentSearchEngine.layer,
          features_filter: !processAll
            ? {
                $LIKE_CASE_INSENSITIVE: {
                  [name]: query,
                },
              }
            : {
                NOT: {
                  IS_NULL: name,
                },
              },
          params: {
            ...(!processAll && { limit: 50 }),
            page: 0,
          },
        });
        this.queryResults = Object.freeze(
          r.data.data.features.features.map(feature => {
            return {
              id: feature.id,
              geometry: JSON.stringify({
                ...feature.geometry,
                crs: r.data.data.features.crs,
              }),
              name: feature.properties[this.currentSearchEngine.attribute] + '',
            };
          })
        );
        /**
         * Results are stored in non-reactive variable to prevent fetching big data from backend
         */
        if (!processAll) return;
        if (!this.listEnginesItemsCache) this.listEnginesItemsCache = {};
        this.listEnginesItemsCache[this.currentSearchEngine.name] = this.queryResults;
      } catch (error) {
        console.log('~ error', error);
      } finally {
        this.isCardLoading = false;
      }
    },
    zoomTo(selected) {
      if (!selected) {
        this.$root.$emit('clearVisibleGeomLayerAndFitView', 'searchEngineLayer');
        return;
      }
      this.$root.$emit(
        'createVisibleGeomLayerAndFitView',
        JSON.parse(selected.geometry),
        'searchEngineLayer',
        {},
        {
          style: new Style({
            stroke: new Stroke({
              color: '#BF360C',
              width: 3,
            }),
          }),
          drawPointMarker: true,
        }
      );
    },
    async init() {
      try {
        if (!this.isSearchObjectsConfigured) return;
        await this.getSearchEngines();
        if (!this.searchEngines || this.searchEngines.length === 0) return;
        if (localStorage.getItem('lastSearchEngineId')) {
          const currentEngine =
            this.searchEngines.find(x => x.id === parseInt(localStorage.getItem('lastSearchEngineId'))) ||
            this.searchEngines[0];
          this.currentSearchEngine = JSON.parse(JSON.stringify(currentEngine));
        } else {
          this.currentSearchEngine = JSON.parse(JSON.stringify(this.searchEngines[0]));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.search = debounce(this.search, 475);
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.objects-select ::v-deep .v-input {
  background-color: rgba(207, 207, 207, 0.4);
  border: 1px solid rgba(207, 207, 207, 0.75);
  .v-input__icon .v-icon {
    color: rgb(48, 48, 48) !important;
  }
}
.objects-select ::v-deep fieldset {
  border: none;
}
</style>
