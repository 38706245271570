<template>
  <table-button
    v-bind="{ ...$props, ...$attrs, isActive: computedIsActive, tooltip: computedTooltip }"
    v-on="computedListeners"
    :font-size="fontSize"
    button-size="small"
  >
    <template #default="button">
      <slot v-bind="{ ...button, ...$props, ...$attrs, isActive: computedIsActive }"> </slot>
    </template>
  </table-button>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import TableButton from '@/components/TableButton';

export default {
  name: 'TheNavbarToolsPanelItem',
  components: {
    TableButton,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    /**
     * A prop describing how tool should behave on click.
     * Default action is to change the URL while custom one is emiting global
     * event composed of name of tool and `-action` suffix.
     * this.$root.$emit(`${this.name}-action`)
     * @type {Boolean}
     */
    hasCustomAction: {
      type: Boolean,
      default: true,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
    hasState: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isToolWorking: {
      type: Boolean,
      default: true,
    },
    routeName: {
      type: String,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    fontSize: {
      type: String,
      default: '19px',
    },
    actionParams: {
      type: Object,
      default: () => {},
    },
    moduleLayerId: {
      type: Number,
    },
    tooltip: {
      type: String,
    },
    moduleLayerTooltipPath: {
      type: String,
    },
    moduleLayerTooltipSuffix: {
      type: String,
      default: '',
    },
  },
  computed: {
    activeTool: get('tools/activeTool'),
    layers: get('layers/layers'),
    projectElements: get('layers/project@layers'),
    currentTab: sync('sidebar/currentTab'),
    projectLayers() {
      return this.$_getFlatGroupsLayers(this.projectElements).layers;
    },
    computedIsActive() {
      if (this.moduleLayerId || this.moduleLayerId === 0) {
        return this.isModuleLayerAdded(this.moduleLayerId);
      }
      return this.isActive;
    },
    computedTooltip() {
      return this.moduleLayerTooltipPath
        ? this.$i18n.t(this.moduleLayerTooltipPath, {
            prefix: this.$i18n.t(`navbar.${this.computedIsActive ? 'off' : 'on'}`),
            suffix: this.moduleLayerTooltipSuffix,
          })
        : this.tooltip || '';
    },
    computedListeners() {
      return {
        ...this.$listeners,
        click: () => {
          this.$emit('click');
          if (this.name === 'searchParcel' && this.activeTool) {
            this.$root.$emit('deactivateAllTools');
          }
          if (!this.isToolWorking) {
            this.pushError(this.$i18n.t('snackbar.toolNotActive'));
            return;
          }
          if (this.hasCustomAction) {
            this.customAction();
            return;
          }
          if (this.moduleLayerId || this.moduleLayerId === 0) {
            const layer = JSON.parse(JSON.stringify(this.layers[this.moduleLayerId]));
            if (layer) {
              if (this.computedIsActive) {
                this.$store.set('layers/DELETE_PROJECT_LAYER!', layer.id);
                this.$root.$emit('deleteProjectLayer', layer.id);
              } else {
                layer.labels_visible = layer.labels_visible || false;
                layer.style = layer.style_web;
                delete layer.style_web;
                this.$store.set('layers/ADD_PROJECT_LAYERS!', [
                  { layer_id: layer.id, visible: true, opacity: 1, has_permission: true, ...layer },
                ]);
                this.$root.$emit('pushProjectLayers', [
                  { layer_id: layer.id, visible: true, opacity: 1, has_permission: true, ...layer },
                ]);
                this.$store.set('snackbar/PUSH_MESSAGE!', {
                  message: this.$i18n.t('snackbar.layerAddedToProject', { name: layer.name }),
                });
              }
            }
            return;
          }
          this.handleDefaultAction();
        },
      };
    },
  },
  methods: {
    isModuleLayerAdded(layerId) {
      return Boolean(this.projectLayers.find(layer => layer.id === layerId));
    },
    pushError(message) {
      this.$store.set('snackbar/PUSH_MESSAGE!', {
        message,
        color: '#333333',
        buttonType: 'text',
      });
    },
    customAction() {
      if (this.computedIsActive) {
        this.$root.$emit(`${this.name}-action`, false, this.actionParams);
      } else {
        if (this.hasState) {
          this.$root.$emit('deactivateAllTools');
        }
        this.$nextTick(() => {
          this.$root.$emit(`${this.name}-action`, true, this.actionParams);
        });
      }
    },
    handleDefaultAction() {
      if (this.isRegister) {
        this.$router.push({
          name: 'layerTable',
          params: {
            lid: this.name,
          },
        });
        this.$root.$emit('registerOpened');
      } else {
        if ([this.routeName, this.name].includes(this.$route.name) && this.currentTab !== 1) {
          this.currentTab = 1;
        } else {
          this.$router.push({
            name: this.routeName || this.name,
            params: this.routeParams,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
