import { call, get } from 'vuex-pathify';

import { MVT } from 'ol/format';
import { VectorTile as VectorTileLayer } from 'ol/layer';
import { VectorTile as VectorTileSource } from 'ol/source';

import { defaultMvtSourceLoader } from '@/assets/js/mapUtils';

export default {
  computed: {
    zdmFailuresDataSource: get('admin/modulesMapping@zdm_failures.datasource_name'),
    zdmFailuresLayerId: get('admin/modulesMapping@zdm_failures.layer_id'),
    zdmFailuresLayer: get('layers/featuresLayers@:zdmFailuresLayerId'),
    zdmFailuresFeatures: get('layers/layersFeatures@:zdmFailuresLayerId'),
    layersFilters: get('layers/layersFilters'),
  },
  methods: {
    getLayerFeatures: call('layers/getLayerFeatures'),
    getFeaturesLayer: call('layers/getFeaturesLayer'),
    setLoaded: call('modules/setLoaded'),
    async toggleZDMFailures(value) {
      if (value) {
        await Promise.all([
          this.getFeaturesLayer(this.zdmFailuresLayerId),
          this.getLayerFeatures({ layer_id: this.zdmFailuresLayerId }),
        ]);
        this.addZDMFailuresLayer();
        this.toggleModuleIdentification({ isActive: true, moduleRoute: 'zdmFailure' });
      } else {
        this.toggleModuleIdentification({ isActive: false, moduleRoute: '' });
        this.removeProjectLayers([this.zdmFailuresLayerId]);
        if (this.layersFilters[this.zdmFailuresLayerId]) {
          delete this.layersFilters[this.zdmFailuresLayerId];
        }
        this.setLoaded({ dataSource: this.zdmFailuresDataSource, value: false });
      }
    },
    toggleZDMFailuresFilter() {
      this.removeProjectLayers([this.zdmFailuresLayerId]);
      this.addZDMFailuresLayer();
    },
    addZDMFailuresLayer() {
      this.removeProjectLayers([this.zdmFailuresLayerId]);
      const { data_source_name, id, type, group_id, name } = this.zdmFailuresLayer;
      const style = JSON.parse(JSON.stringify(this.zdmFailuresLayer.style_web));
      style.minzoom = 1;

      this.mvtVisibleLayersCounter++;
      const styleAttributes = this.getRequiredAttributes(id, style);
      const mvtLayer = new VectorTileLayer({
        opacity: 1,
        data_source_name,
        id,
        type,
        group_id,
        name,
        isSpecial: true,
        visible: true,
        source: new VectorTileSource({
          format: new MVT(),
          url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
          projection: this.$_config.defaultEpsg,
          tileLoadFunction: tile => {
            defaultMvtSourceLoader(tile, id, {
              filters: this.layersFilters[this.zdmFailuresLayer.id]
                ? this.layersFilters[this.zdmFailuresLayer.id].filterExpression
                : {},
              styleAttributes,
            });
          },
        }),
        zIndex: 100,
        style: f => this.getFeatureStyle(f, style, this.zdmFailuresLayer.geometry_type, true, id),
        renderMode: 'image',
      });
      mvtLayer.getSource().once('tileloadend', () => {
        ++this.mvtVisibleLayersLoaded;
        this.identifyCoordinatesOnInit();
      });
      this.getLayerById('layers').getLayers().push(mvtLayer);
      this.setLoaded({ dataSource: this.zdmFailuresDataSource, value: true });
    },
  },
};
