import api from '@/services/api';
import Vue from 'vue';
import { make } from 'vuex-pathify';
const state = {
  notes: {},
};
const mutations = {
  ...make.mutations(state),
  SET_NOTES: (state, data) => {
    const { dataSourceName, featureId, notes } = data;
    if (!state.notes.dataSourceName) {
      Vue.set(state.notes, dataSourceName, {});
    }
    Vue.set(state.notes[dataSourceName], featureId, notes);
  },
};
const actions = {
  async getNotes({ commit, rootState }, payload) {
    const { data_source_name, feature_id } = payload;
    const ilsEasyModule = rootState.admin.additionalModules.ILS_EASY_MODULE;
    const ilsEasySetting = rootState.admin.settingsValues.ils_easy_module_enabled;
    const ilsEasyPermissions = rootState.users.toolsPermissions.ILS_EASY_MODULE?.main_value;
    const ilsEasy = ilsEasyModule?.enabled && ilsEasyModule?.configured && ilsEasySetting && ilsEasyPermissions > 0;
    const promises = [
      api.get(`/dataio/data_sources/${data_source_name}/features/${feature_id}/notes`),
      ...(ilsEasy ? [api.get(`/ils_easy/notes/${data_source_name}/${feature_id}`)] : []),
    ];
    const r = await Promise.all(promises);
    commit('SET_NOTES', {
      dataSourceName: data_source_name,
      featureId: feature_id,
      notes: [
        ...r[0].data.data,
        ...(ilsEasy
          ? r[1].data.data.map(note => {
              return { ...note, module: 'ils_easy' };
            })
          : []),
      ],
    });
  },
  async addNote({ dispatch }, payload) {
    await api.post(`/notes`, payload);
    const name = payload.target_objects[0].datasource_name;
    const id = payload.target_objects[0].feature_id;
    await dispatch('getNotes', { data_source_name: name, feature_id: id });
  },
  async deleteNote({ dispatch }, payload) {
    const { id, data_source_name, feature_id } = payload;
    await api.delete(`/notes/${id}`);
    await dispatch('getNotes', { data_source_name: data_source_name, feature_id: feature_id });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
