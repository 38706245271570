<template>
  <v-row no-gutters>
    <v-col class="pt-3" cols="12" style="max-width: 100%;">
      <template v-for="(element, idx) in computedElements">
        <template v-if="element.element_type === 'attribute_holder'">
          <feature-card-data-row
            v-bind="{
              ...$props,
              ...$attrs,
              ...element,
              ...(element.attributeType === 'variable_relation' ? { values: value, geometry } : {}),
              ...(element.dataType?.name === 'text' ? { wrapText: true } : {}),
            }"
            :key="idx"
            wrap-text
            :dynamic-icon-column="dynamicIconColumn"
            :attribute="element.attribute"
            v-model="value[element.attribute]"
            @change="onChange($event, element.attribute)"
            @input="onChange($event, element.attribute)"
            :disable-width-limit="disableWidthLimit"
            :values.sync="computedValues"
          >
            <template v-for="(value, key) in $scopedSlots" #[key]="scope">
              <slot :name="key" v-bind="scope" />
            </template>
          </feature-card-data-row>
        </template>
        <template v-else>
          <v-row
            no-gutters
            :key="idx"
            align="center"
            @click="collapseArrowsVisible ? toggle(idx) : ''"
            :style="{ ...(collapseArrowsVisible ? { cursor: 'pointer' } : {}) }"
          >
            <v-col cols="1" class="pb-1">
              <v-icon v-if="collapseArrowsVisible">
                {{ element.hide ? 'mdi-menu-right' : 'mdi-menu-down' }}
              </v-icon>
            </v-col>
            <v-col cols="auto">
              <v-subheader class="text-left text-body-2 font-weight-medium font--text pa-0" style="height: auto">
                {{ element.label }}
              </v-subheader>
            </v-col>
          </v-row>
          <feature-card-data
            v-bind="{ ...$props, ...$attrs }"
            :elements.sync="element.elements"
            :value="value"
            v-on="$listeners"
            :key="`${element.label}_elements`"
            v-show="!element.hide"
          >
            <template v-for="(value, key) in $scopedSlots" #[key]="scope">
              <slot :name="key" v-bind="scope" />
            </template>
          </feature-card-data>
        </template>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import FeatureCardDataRow from '@/components/FeatureCardDataRow';

export default {
  name: 'FeatureCardData',
  components: { FeatureCardDataRow },
  model: {
    event: 'change',
  },
  props: {
    isEditingOn: {
      type: Boolean,
      default: false,
    },
    elements: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    dynamicIconColumn: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disableWidthLimit: {
      type: Boolean,
      default: false,
    },
    arrowsMode: {
      type: String,
      default: 'off',
      validator: value => {
        // off - no arrows
        // on - arrows without saving state in storage
        // on-with-storage - arrows with saving state in storage
        return ['off', 'on', 'on-with-storage'].includes(value);
      },
    },
    layerId: {
      type: [Number, String],
    },
    geometry: {
      type: Object,
      default: null,
    },
  },
  computed: {
    collapseArrowsVisible() {
      return this.arrowsMode.includes('on');
    },
    collapseArrowsSaving() {
      return this.collapseArrowsVisible && this.arrowsMode === 'on-with-storage';
    },
    computedElements: {
      get() {
        if (this.collapseArrowsSaving) {
          const currentState = JSON.parse(localStorage.getItem('collapsedAttributesGroups')) || {};
          const currentLayerState = currentState[this.layerId] || [];
          return this.elements.map(element => {
            return { ...element, hide: currentLayerState.includes(element.label) };
          });
        } else {
          return this.elements;
        }
      },
      set(elements) {
        this.$emit(
          'update:elements',
          elements.map(element => {
            delete element.hide;
            return element;
          })
        );
      },
    },
    computedValues: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
  },
  methods: {
    onChange(value, key) {
      this.$emit('change', { ...this.value, [key]: value });
    },
    toggle(idx) {
      if ((!idx && idx !== 0) || !this.collapseArrowsVisible) return;
      this.$set(this.computedElements[idx], 'hide', !this.computedElements[idx].hide);
      this.$forceUpdate();
      if (this.collapseArrowsSaving) {
        const collapsedGroupsLabels = this.computedElements.filter(group => group.hide).map(group => group.label);
        const currentState = JSON.parse(localStorage.getItem('collapsedAttributesGroups')) || {};
        localStorage.setItem(
          'collapsedAttributesGroups',
          JSON.stringify({ ...currentState, [this.layerId]: collapsedGroupsLabels })
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
