import { get } from 'vuex-pathify';
import * as sockets from '@/mixins/sockets';
export default {
  mixins: [...Object.values(sockets)],
  computed: {
    token: get('authentication/token'),
    sessionId: get('authentication/sessionId'),
    socketsData() {
      return {
        [this.socketsDefault.name]: this.socketsDefault,
        [this.socketsRealtimeStationsMeasurements.name]: this.socketsRealtimeStationsMeasurements,
        [this.socketsRealtimeStationsDirections.name]: this.socketsRealtimeStationsDirections,
      };
    },
  },
  methods: {
    socketClose(data) {
      this.addSocketSettingsState(data.name);
      (this.socketsData[data.name].socketClose || this.socketCloseDefault)(data);
    },
    socketConnect(data) {
      this.addSocketSettingsState(data.name);
      (this.socketsData[data.name].socketConnect || this.socketConnectDefault)(data);
    },
    socketSend(data) {
      this.addSocketSettingsState(data.name);
      (this.socketsData[data.name].socketSend || this.socketSendDefault)(data);
    },
    addSocketSettingsState(name) {
      if (!this.socketsData[name].socketState) this.socketsData[name].socketState = {};
    },
    initSocket() {
      this.$root.$off('socket.connect');
      this.$root.$on('socket.connect', this.socketConnect);
      this.$root.$off('socket.send');
      this.$root.$on('socket.send', this.socketSend);
      this.$root.$off('socket.close');
      this.$root.$on('socket.close', this.socketClose);
    },
  },
};
