import dayjs from 'dayjs/esm/index.js';

import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import LocaleData from 'dayjs/plugin/localeData';
import Weekday from 'dayjs/plugin/weekday';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

/** Current `en` locale is set to British English */
import en from 'dayjs/locale/en-gb';

const currentLanguage = import.meta.env.VUE_APP_I18N_LOCALE;

(async () => {
  const locale =
    currentLanguage === 'en' ? en : await import(`/dayjsLocales/${currentLanguage}`).then(locale => locale.default);
  if (!locale) return;
  dayjs.locale({
    ...locale,
    weekStart: 1,
  });
})();

dayjs.extend(LocalizedFormat);
dayjs.extend(LocaleData);
dayjs.extend(Weekday);
dayjs.extend(CustomParseFormat);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

dayjs.updateLocale('en', en);

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs });
  },
};
