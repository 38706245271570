import { get } from 'vuex-pathify';

export default {
  data: () => ({
    isHydrantStatusVisualisation: false,
  }),
  computed: {
    hydrantsInspectionsDateStatuses: get('admin/modulesMapping@hydrants_inspections.next_inspection_date_statuses'),
    hydrantsLayerId: get('hydrants/hydrantsLayerId'),
    featuresLayers: get('layers/featuresLayers'),
    hydrantsLayer: get('layers/featuresLayers@:hydrantsLayerId'),
    hydrantsStatusesColors() {
      return {
        ok: 'rgb(24,128,56)',
        soon_past_due: 'rgb(251, 140, 0)',
        past_due: 'rgb(191,54,12)',
      };
    },
    project: get('layers/project'),
  },
  methods: {
    async toggleHydrantStatusVisualisation() {
      this.isHydrantStatusVisualisation = !this.isHydrantStatusVisualisation;
      if (this.isHydrantStatusVisualisation) {
        if (!this.hydrantsLayer) {
          await this.getFeaturesLayer(this.hydrantsLayerId);
        }
        const values = {};
        for (const status in this.hydrantsInspectionsDateStatuses) {
          values[this.hydrantsInspectionsDateStatuses[status]] = {
            'circle-color': this.hydrantsStatusesColors[status] || 'grey',
            'circle-radius': 3,
            'fill-opacity': 1,
            labels: [],
            value: this.hydrantsInspectionsDateStatuses[status],
          };
        }
        const style = {
          'circle-color': 'grey',
          'circle-radius': 3,
          'fill-opactity': 1,
          labels: [],
          uniques: {
            property: '_next_inspection_date_status',
            values,
          },
        };
        this.$root.$emit('setProjectLayerStyle', this.hydrantsLayerId, { style });
      } else {
        const hydrantLayerInProject = this.$_getFlatGroupsLayers(this.project.layers).layers.find(
          layer => layer.id === this.hydrantsLayerId
        );
        const style = JSON.parse(JSON.stringify(hydrantLayerInProject.style));
        style.minzoom = 1;
        style.labels = { attributes: [] };
        this.$root.$emit('setProjectLayerStyle', this.hydrantsLayerId, { style });
      }
    },
  },
  mounted() {
    this.$root.$on('toggleHydrantStatusVisualisation', this.toggleHydrantStatusVisualisation);
  },
};
