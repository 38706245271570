import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  failures: {},
};

const mutations = {
  ...make.mutations(state),
  DELETE_FAILURE: (state, failure_id) => {
    Vue.delete(state.failures, failure_id);
  },
  SET_FAILURES: (state, data) => {
    const { failuresProps, crs } = data;
    const failures = {};
    for (const failure of failuresProps) {
      failures[failure.id] = {
        ...failure.properties,
        ...{ id: failure.id },
        ...{ geometry: { ...failure.geometry, ...{ crs } } },
      };
    }
    state.failures = failures;
  },
};

const actions = {
  async addFailure({ dispatch }, feature) {
    const r = await api.post(`/failures`, { feature });
    await dispatch('getFailures');
    return r;
  },
  async deleteFailure({ commit }, failure_id) {
    await api.delete(`/failures/${failure_id}`);
    commit('DELETE_FAILURE', failure_id);
  },
  async editFailure({ dispatch }, payload) {
    const { feature, failure_id } = payload;
    await api.put(`/failures/${failure_id}`, { feature });
    await dispatch('getFailures');
  },
  async getFailure(store, id) {
    const r = await api.post(`/failures/${id}`, {});
    return r.data.data;
  },
  async getFailures({ commit }) {
    const r = await api.post('failures/read', {});
    commit('SET_FAILURES', { failuresProps: r.data.data.features.features, crs: r.data.data.features.crs });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
