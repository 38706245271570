import api from '@/services/api';
import { make } from 'vuex-pathify';
import { getFlatGroupsLayers, getArraySummarize, mapObjectKeys } from '@/assets/js/utility';

const state = {
  legendStylesCounts: {},
};
const mutations = {
  ...make.mutations(state),
  UPDATE_LEGEND_STYLES_COUNTS: (state, data) => {
    state.legendStylesCounts = { ...state.legendStylesCounts, ...data };
  },
};
const actions = {
  getDataSourceAttributeUniqueValuesPromise(store, payload) {
    const { dataSourceName, attributeName, params = {} } = payload;
    return api.post(`/dataio/data_sources/${dataSourceName}/unique_values/${attributeName}`, params);
  },
  getLayerRangeClassesCountsPromise(store, data) {
    const { layerId, attributeName, payload } = data;
    return api.post(`/layers/features_layers/${layerId}/classes_count/${attributeName}`, payload);
  },
  async updateLegendStylesCounts({ commit, dispatch, rootState }, { layersIds = [] } = {}) {
    const legendStylesCounts = {};
    let projectLayers = getFlatGroupsLayers(rootState.layers.project.layers).layers;
    if (layersIds?.length) {
      projectLayers = projectLayers.filter(layer => layersIds.includes(layer.id));
    }
    const uniqueStyleProjectLayers = projectLayers.filter(layer => layer?.style?.uniques);
    const rangeStyleProjectLayers = projectLayers.filter(layer => layer?.style?.ranges);
    const uniqueStylePromises = uniqueStyleProjectLayers.map(layer => {
      const { data_source_name: dataSourceName, style, filter_expression } = layer;
      const tableFilter = rootState.layers.layersFilters[layer.id]?.filterExpression;
      let filter = filter_expression;
      if (tableFilter && filter) {
        filter = { '!AND': [filter, tableFilter] };
      } else if (tableFilter) {
        filter = tableFilter;
      }
      const attributeName = style.uniques.property.split('mvt_int_').slice(-1)[0];
      const payload = { dataSourceName, attributeName, params: { features_filter: filter } };
      return dispatch('getDataSourceAttributeUniqueValuesPromise', payload);
    });
    const rangeStylePromises = rangeStyleProjectLayers.map(layer => {
      const { id: layerId, style } = layer;
      const tableFilter = rootState.layers.layersFilters[layerId]?.filterExpression;
      const attributeName = style.ranges.property.split('mvt_int_').slice(-1)[0];
      const classes = style.ranges.values.map(value => {
        const { 'min-value': minValue, 'max-value': maxValue } = value;
        return { 'min-value': minValue, 'max-value': maxValue };
      });
      const data = { layerId, attributeName, payload: { classes, features_filter: tableFilter || null } };
      return dispatch('getLayerRangeClassesCountsPromise', data);
    });
    const rUniquePromises = (await Promise.all(uniqueStylePromises)).map(r => r.data.data);
    const rRangePromises = (await Promise.all(rangeStylePromises)).map(r => r.data.data);
    uniqueStyleProjectLayers.forEach((layer, index) => {
      const values = {};
      const rCounts = rUniquePromises[index];
      const dataSource = rootState.layers.metadata[layer?.data_source_name];
      if (dataSource) {
        const dataTypeName = dataSource.attributes_schema.attributes.find(
          attribute => attribute.name === layer.style.uniques.property
        )?.data_type.name;
        if (dataTypeName === 'float' || dataTypeName === 'decimal') {
          rCounts.values = mapObjectKeys(rCounts.values, keyName => parseFloat(keyName));
        }
      }
      Object.keys(layer.style.uniques.values).forEach(key => {
        values[key] = rCounts.values[key] || 0;
        delete rCounts.values[key];
      });
      legendStylesCounts[layer.id] = {
        values,
        default: getArraySummarize([rCounts.null_values, ...Object.values(rCounts.values)]),
      };
    });
    rangeStyleProjectLayers.forEach((layer, index) => {
      const values = {};
      const { classes, other } = rRangePromises[index];
      classes.forEach(c => {
        const { 'min-value': minValue, 'max-value': maxValue, count } = c;
        const key = `${minValue}-${maxValue}`;
        values[key] = count;
      });
      legendStylesCounts[layer.id] = { values, default: other };
    });
    if (layersIds?.length) {
      commit('UPDATE_LEGEND_STYLES_COUNTS', legendStylesCounts);
    } else {
      commit('SET_LEGEND_STYLES_COUNTS', legendStylesCounts);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
