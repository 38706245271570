import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';
import { Circle, Fill, Stroke, Style } from 'ol/style';
export default {
  methods: {
    addVariableRelationHoverLayer() {
      const newLayer = new VectorLayer({
        id: 'variableRelationHoverLayer',
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.6)',
          }),
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            width: 3,
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(255, 0, 0, 1)',
            }),
            fill: new Fill({
              color: 'rgba(255, 0, 0, 0.6)',
            }),
          }),
        }),
      });
      this.map.addLayer(newLayer);
    },
    toggleVariableRelationHover(variableRelationFeature, value) {
      let layer = this.getLayerById('variableRelationHoverLayer');
      if (!value && layer) {
        this.clearLayer('variableRelationHoverLayer');
        return;
      }
      if (!layer) {
        this.addVariableRelationHoverLayer();
        layer = this.getLayerById('variableRelationHoverLayer');
      } else {
        this.clearLayer('variableRelationHoverLayer');
      }
      const olGeometry = new GeoJSON().readGeometry(variableRelationFeature.geometry, {
        dataProjection: this.$_config.defaultEpsg,
        featureProjection: this.$_config.defaultEpsg,
      });
      const feature = new Feature({ geometry: olGeometry });
      layer.getSource().addFeature(feature);
    },
  },
  mounted() {
    this.$root.$off('toggleVariableRelationHover');
    this.$root.$on('toggleVariableRelationHover', this.toggleVariableRelationHover);
  },
};
